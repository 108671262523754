"use strict";

/**
 * A collection of d3 string formatters.
 */

define(["$vendor/d3.min"], function() {
  function D3Formatters() {
    this.formatMoney = d3.format(",.2f");

    this.moneyFormatter = function(d) {
      return "$" + this.formatComma(d / 100);
    }.bind(this);

    this.formatComma = d3.format(",f");

    this.commaFormatter = function(d) {
      return d % 1 == 0 ? this.formatComma(d) : "";
    }.bind(this);

    this.formatPercent = d3.format(".2%");

    this.percentFormatter = function(d) {
      return this.formatPercent(d);
    }.bind(this);
  }

  return D3Formatters;
});
