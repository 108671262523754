define([], function() {
  const FileUtils = {
    getReadableFileSize: function(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(1) + " GB";
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(1) + " MB";
      } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(1) + " KB";
      } else if (bytes > 1) {
        bytes = bytes + " bytes";
      } else if (bytes == 1) {
        bytes = bytes + " byte";
      } else {
        bytes = "0 byte";
      }
      return bytes;
    },
    getFullFileSizeString: function(bytes) {
      return FileUtils.getReadableFileSize(bytes);
    },
    getS3Key: function(guid, fileName, userExternalId, rootBucket) {
      var s3Bucket = rootBucket + "/" + userExternalId + "/";
      return s3Bucket + guid + "/original/" + fileName;
    },
    getGuidBase: function() {
      return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
    },
    getGuidLength: function() {
      return FileUtils.getGuidBase().length;
    },
    generateGuid: function() {
      // neat hack from http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/2117523#2117523
      return FileUtils.getGuidBase().replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    getFileExtension: function(name) {
      // from http://stackoverflow.com/a/680982/2624068
      return /(?:\.([^.]+))?$/.exec(name)[1];
    },
    getFilenameWithoutExtension: function(filename) {
      const extension = FileUtils.getFileExtension(filename).toUpperCase();
      const fileName = filename.substr(
        0,
        filename.length - extension.length - 1
      );
      return fileName;
    },
    extractUniqueUrlIdentifier: function(url) {
      const guidLength = FileUtils.getGuidLength();
      const guid = url.split("/original/")[0].slice(-1 * guidLength);
      // NOTE: ideally, this shouldn't encode the url identifier
      if (guid.length == guidLength && isAlphanumeric(guid)) {
        return guid;
      } else {
        return encodeURIComponent(url);
      }
    },
    determineS3BucketForForm: function($form) {
      var s3Url = $form.data("s3-url");
      if (s3Url) {
        var splittedS3Url = s3Url.split("/");
        return splittedS3Url[splittedS3Url.length - 1];
      }
      throw "Could not determine S3 bucket, please make sure to set data-s3-url on your uploader form element";
    },
    determineAWSAccessKeyIdForForm: function($form) {
      var awsAccessKeyId = $form.data("aws-access-key-id");
      if (awsAccessKeyId) {
        return awsAccessKeyId;
      }
      throw "Could not determine AWSAccessKeyId, please make sure to set aws-access-key-id on your uploader form element";
    },
    determineUserExternalIdForForm: function($form) {
      var userExternalId = $form.data("user-external-id");
      if (userExternalId) {
        return userExternalId;
      }
      throw "Could not determine user external id, please make sure to set data-user-external-id on your uploader form element";
    }
  };

  const isAlphanumeric = function(text) {
    return /^[a-zA-Z0-9]+$/.test(text);
  };

  return FileUtils;
});
