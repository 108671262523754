"use strict";

define(["flight/lib/component", "$app/utils/with_url"], function(
  defineComponent,
  WithUrl
) {
  return defineComponent(Navigation, WithUrl);

  function Navigation() {
    this.defaultAttrs({
      contentWrapper: ".content-wrapper",
      accountLinksTriggerSelector: ".js-account-dropdown-trigger",
      accountLinksDropdownSelector: ".js-account-dropdown",
      navMobileDropdownTrigger: ".js-nav-mobile-dropdown-trigger",
      cancelNavMobileDropdownTrigger: ".js-nav-mobile-dropdown-cancel-trigger",
      cancelSearchTrigger: ".js-discover-search-cancel-trigger",
      searchInputTrigger: ".js-discover-search-input-trigger",
      searchFieldInputSelector: ".js-discover-search-field",
      searchFieldAutocompleteDropdown:
        ".js-discover-search-autocomplete-dropdown",
      searchFilterDropdownTrigger:
        ".js-discover-search-filter-dropdown-trigger",
      navigationLinks: ".js-navigation-links",
      mobileSearchInputVisible: false,
      titleMap: {
        "features/pricing": I18n.t("js.pricing"),
        features: I18n.t("js.features"),
        following: I18n.t("js.following")
      },
      autocompleteNavOverlay: ".js-autocomplete-nav-overlay",
      autocompleteOverlay: ".js-autocomplete-overlay"
    });

    this.toggleAccountDropdown = function(ev) {
      ev.preventDefault();

      this.select("accountLinksDropdownSelector").toggleClass("open");

      return false;
    };

    this.hideDropdownsWhenUnfocused = function(ev) {
      if (this.select("navMobileDropdownTrigger").hasClass("hidden")) {
        return null;
      }
      var $accountsLinksDropdown = this.select("accountLinksDropdownSelector");
      if ($(ev.target).closest($accountsLinksDropdown).length == 0) {
        $accountsLinksDropdown.removeClass("open");
      }
    };

    this.toggleMobileNavDropbown = function(ev) {
      ev.preventDefault();
      this.trigger("uiToToggleMobileOverlayToDiscover");
      this.select("navigationLinks").toggleClass("showing");
      this.select("contentWrapper").toggleClass("overlay");
      this.select("searchInputTrigger").toggleClass("hidden");
      this.select("accountLinksDropdownSelector").toggleClass("open");
      this.select("navMobileDropdownTrigger").toggleClass("hidden");
      this.select("cancelNavMobileDropdownTrigger").toggleClass("hidden");
      return false;
    };

    this.toggleSearchInput = function(ev) {
      ev.preventDefault();

      this.attr.mobileSearchInputVisible = !this.attr.mobileSearchInputVisible;

      this.select("searchInputTrigger").toggleClass("hidden");
      this.select("cancelSearchTrigger").toggleClass("hidden");
      this.select("searchFieldInputSelector").toggleClass("mobile-hidden");
      this.select("navMobileDropdownTrigger").toggleClass("hidden");
      this.trigger("uiToToggleQueryClass");
      this.select("searchFilterDropdownTrigger").text(I18n.t("js.discover"));

      this.select("searchFieldInputSelector").css(
        "padding-left",
        this.select("searchFilterDropdownTrigger").outerWidth() + 20
      );

      if (!this.select("searchInputTrigger").hasClass("mobile-hidden")) {
        this.select("searchFieldInputSelector").focus();
      }

      if (this.attr.mobileSearchInputVisible) {
        this.select("searchFilterDropdownTrigger").removeClass("title-only");
      } else {
        this.setMobileNavTitle();
      }

      return false;
    };

    this.hideSearchAndHamburgerButtons = function() {
      if (this.getUrlParams().category || this.getUrlParams().query) {
        this.select("searchFieldInputSelector").removeClass("mobile-hidden");
        this.select("navMobileDropdownTrigger").addClass("hidden");
        this.select("searchInputTrigger").addClass("hidden");
        this.select("cancelSearchTrigger").removeClass("hidden");
      } else {
        this.select("searchFilterDropdownTrigger").toggleClass("hidden");
      }
    };

    this.showSearchAndHamburgerButtons = function() {
      this.select("searchFieldInputSelector").addClass("mobile-hidden");
      this.select("navMobileDropdownTrigger").removeClass("hidden");
      this.select("searchInputTrigger").removeClass("hidden");
      this.select("cancelSearchTrigger").addClass("hidden");
    };

    this.isCurrentPath = function(path) {
      return window.location.pathname.indexOf(path) !== -1;
    };

    this.setMobileNavTitle = function() {
      for (let key of Object.keys(this.attr.titleMap)) {
        if (this.isCurrentPath(key)) {
          this.select("searchFilterDropdownTrigger").text(
            this.attr.titleMap[key]
          );
          this.select("searchFilterDropdownTrigger").addClass("title-only");
          break;
        }
      }
    };

    this.handleCancelSearchTrigger = function(ev) {
      this.toggleSearchInput(ev);
      this.select("searchFieldAutocompleteDropdown").removeClass("showing");
      this.hideAutocompleteOverlay();
    };

    this.hideAutocompleteOverlay = function() {
      this.trigger("uiNeedsToHideAutocompleteOverlay");
    };

    this.after("initialize", function() {
      this.on("html", "click", this.hideDropdownsWhenUnfocused);

      this.setMobileNavTitle();

      this.on("uiNeedsToHideOtherDropdowns", this.hideDropdownsWhenUnfocused);
      this.on(
        document,
        "uiNeedsToHideSearchAndHamburgerButtons",
        this.hideSearchAndHamburgerButtons
      );
      this.on(
        document,
        "uiNeedsToShowSearchAndHamburgerButtons",
        this.showSearchAndHamburgerButtons
      );

      this.on("click", {
        accountLinksTriggerSelector: this.toggleAccountDropdown,
        navMobileDropdownTrigger: this.toggleMobileNavDropbown,
        cancelNavMobileDropdownTrigger: this.toggleMobileNavDropbown,
        searchInputTrigger: this.toggleSearchInput,
        cancelSearchTrigger: this.handleCancelSearchTrigger,
        autocompleteNavOverlay: this.hideAutocompleteOverlay,
        autocompleteOverlay: this.hideAutocompleteOverlay
      });
    });
  }
});
