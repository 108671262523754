"use strict";

define(["$vendor/jquery.oauthpopup"], function() {
  return WithSocialConnect;

  function WithSocialConnect() {
    this.defaultAttrs({
      twitterPath: "/users/auth/twitter?state=async_link_twitter_account"
    });

    // returns true or false based on whether perm is verified to be enabled
    this.getPermissionStatusFromFBResponse = function(response, permission) {
      if (typeof response.error == "undefined") {
        if (
          typeof response.data != "undefined" &&
          typeof response.data[0] != "undefined"
        ) {
          return !!response.data[0][permission];
        }
      }
    };

    this.persistFacebookAccessToken = function(token) {
      $.ajax({
        type: "POST",
        dataType: "JSON",
        url: Routes.ajax_facebook_access_token_path(),
        data: { accessToken: token }
      });
      return false;
    };

    /* Connects current user to Facebook and persists user info
     *
     * takes the following options
     *
     * success: success callback
     * failure: failure callback
     * facebookOptions: (optional) options to pass to FB.login
     * verifyPermissions: (optional) array of permissions to check are enabled
     *  after connecting
     *
     */
    this.connectFacebook = function(options) {
      var that = this;
      FB.login(function(response) {
        var token,
          verified = true;
        if (response && response.authResponse) {
          token = response.authResponse.accessToken;
          FB.api("me/permissions", function(response) {
            if (response && typeof response.error == "undefined") {
              $.each(options.verifyPermissions || [], function(i, permission) {
                verified =
                  verified &&
                  that.getPermissionStatusFromFBResponse(response, permission);
              });
              if (verified) {
                that.persistFacebookAccessToken(token);
                options.success();
              } else {
                options.failure();
              }
            } else {
              options.failure();
            }
          });
        } else {
          options.failure();
        }
      }, options.facebookOptions || {});
    };

    this.connectTwitter = function(options) {
      var popupOptions = {
        path: this.attr.twitterPath,
        callback: function() {
          $.ajax({
            type: "GET",
            url: Routes.check_twitter_link_path(),
            dataType: "JSON"
          }).done(function(response) {
            if (response.success) {
              options.success();
            } else {
              options.failure();
            }
          });
        }
      };

      $.oauthpopup(popupOptions);
    };
  }
});
