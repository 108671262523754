"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ConsumptionAnalytics);

  function ConsumptionAnalytics() {
    this.createConsumptionEvent = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.create_consumption_event_path({ format: "json" }),
        dataType: "json",
        data: {
          event_type: data.eventType,
          platform: data.platform,
          url_redirect_id: data.urlRedirectId,
          product_file_id: data.productFileId,
          purchase_id: data.purchaseId,
          media_location_percentage: data.mediaLocationPercentage
        }
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("data_created_consumption_event", response);
          } else {
            this.trigger("data_failed_to_create_consumption_event", response);
          }
        }.bind(this)
      );
    };

    this.getConsumptionAnalytics = function(ev, data) {
      var startTime = data.startTime,
        endTime = data.endTime;
      $.ajax({
        type: "GET",
        url: Routes.consumption_stats_path({
          start_time: startTime,
          end_time: endTime,
          format: "json"
        }),
        dataType: "json"
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataAudienceConsumptionAnalytics", {
              total_consumption_stats: response.total_consumption_stats,
              unique_by_purchase_consumption_stats:
                response.unique_by_purchase_consumption_stats
            });
          } else {
            this.trigger(
              "dataFailedToGetAudienceConsumptionAnalytics",
              response
            );
          }
        }.bind(this)
      );
    };

    this.getPerFileConsumptionAnalytics = function(ev, data) {
      var startTime = data.startTime,
        endTime = data.endTime,
        uniquePermalinks = data.uniquePermalinks,
        shouldGetAllProducts = false,
        shouldDisplayUniqueData = data.shouldDisplayUniqueData || false,
        limit = data.limit;

      if (uniquePermalinks == null) {
        // This is so that we can differentiate between the original call that should show all products' stats (uniquePermalinks is null), vs
        // a call when the creator has de-selected all products (uniquePermalinks will be []).
        // This hack is necessary since we cannot easily send an empty array in GET params.
        shouldGetAllProducts = true;
      }
      $.ajax({
        type: "GET",
        url: Routes.per_file_consumption_stats_path({
          start_time: startTime,
          end_time: endTime,
          unique_permalinks: uniquePermalinks,
          should_get_all_products: shouldGetAllProducts,
          unique_by_purchase: shouldDisplayUniqueData,
          limit: limit,
          format: "json"
        }),
        dataType: "json"
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataAudiencePerFileConsumptionAnalytics", {
              per_file_consumption_stats: response.per_file_consumption_stats,
              has_more_results: response.has_more_results
            });
          } else {
            this.trigger(
              "dataFailedToGetAudiencePerFileConsumptionAnalytics",
              response
            );
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToTrackConsumptionEvent",
        this.createConsumptionEvent
      );
      this.on(document, "uiDateRangeChanged", this.getConsumptionAnalytics);
      this.on(
        document,
        "uiDateRangeChanged",
        this.getPerFileConsumptionAnalytics
      );
      this.on(
        document,
        "uiNeedsPerFileConsumptionAnalytics",
        this.getPerFileConsumptionAnalytics
      );
    });
  }
});
