"use strict";

define(["$vendor/jquery.jplayer.min"], function() {
  return WithPlayer;

  function WithPlayer() {
    // Empty functions for overriding.
    this.onPlay = function() {};
    this.onPause = function() {};
    this.onTimeUpdate = function() {};
    this.onEnded = function() {};

    this.play = function() {
      this.select("playerSelector").jPlayer("play");
    };

    this.pause = function() {
      this.select("playerSelector").jPlayer("pause");
    };

    this.getContentTypeFromUrl = function() {
      return this.attr.audioUrl
        .split("?")[0]
        .split(".")
        .pop();
    };

    this.initializePlayer = function() {
      var $player = this.select("playerSelector");

      this.attr.audioUrl = $player.data("url");

      if (!this.attr.audioUrl || this.attr.audioUrl == "") return;

      $player.jPlayer({
        ready: function(event) {
          this.select("playerSelector").jPlayer("setMedia", {
            mp3: this.attr.audioUrl,
            wav: this.attr.audioUrl,
            m4a: this.attr.audioUrl
          });
        }.bind(this),
        preload: "none",
        swfPath: "/js",
        supplied: this.getContentTypeFromUrl()
      });

      $player.bind($.jPlayer.event.play, this.onPlay.bind(this));
      $player.bind($.jPlayer.event.pause, this.onPause.bind(this));
      $player.bind($.jPlayer.event.timeupdate, this.onTimeUpdate.bind(this));
      $player.bind($.jPlayer.event.ended, this.onEnded.bind(this));
    };
  }
});
