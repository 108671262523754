var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"sold_out") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\"#\" class=\"js-variant-option variant-holder__variant-option disabled\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"displayable") : depth0), depth0))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\"#\" class=\"js-variant-option variant-holder__variant-option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":74},"end":{"line":8,"column":103}}})) != null ? stack1 : "")
    + "\" data-price-offset-cents=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"price_difference_cents") : depth0), depth0))
    + "\" data-price-offset=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"price_difference_in_currency_units") : depth0), depth0))
    + "\" data-quantity-left=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"quantity_left") : depth0), depth0))
    + "\" data-value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayed_price") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "        <strong class=\"variant-holder__variant-option__name\">\n          "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"quantity_left") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":25,"column":17}}})) != null ? stack1 : "")
    + "        </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "      </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"variant-holder__variant-option__price-tag\">\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"displayed_price") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"displayed_original_price") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "          </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"variant-holder__variant-option__price-tag__original-price\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"displayed_original_price") : depth0), depth0))
    + "\n              </span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <small class=\"variant-holder__variant-option__name__notice\">\n              "
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"left_with_count",{"name":"t","hash":{"count":(depth0 != null ? lookupProperty(depth0,"quantity_left") : depth0)},"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":23,"column":57}}}))
    + "\n            </small>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"variant-holder__variant-option__description\">"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/breaklines.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"breaklines","hash":{},"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":91}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"variant-holder js-variant\" name=\"variants["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"i") : depth0), depth0))
    + "]\" data-var=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"i") : depth0), depth0))
    + "\">\n  <input type=\"hidden\" name=\"variants["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"i") : depth0), depth0))
    + "]\" class=\"js-variant-input\" />\n  <label class=\"variant-holder__variant-category-label\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});