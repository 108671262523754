"use strict";

define(["flight/lib/component", "$app/utils/with_event"], function(
  defineComponent,
  WithEventUtils
) {
  return defineComponent(Event, WithEventUtils);

  function Event() {
    this.trackProductSpecificEvent = function(ev, data) {
      $.ajax({
        type: "POST",
        data: {
          event_name: data.name,
          referrer: this.attr.referrer,
          plugins: this.attr.pluginNames,
          is_modal: this.attr.isModal,
          from_profile: this.attr.fromProfile,
          from_multi_overlay: this.attr.fromMultiOverlay,
          source: this.attr.source,
          was_product_recommended: !!data.wasProductRecommended,
          view_url: window.location.pathname
        },
        url: Routes.track_user_action_link_path(data.permalink),
        dataType: "json"
      });
    };

    this.trackViewEvent = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.increment_views_link_path(data.permalink),
        data: {
          is_modal: this.attr.isModal,
          plugins: this.attr.pluginNames,
          referrer: this.attr.referrer,
          got_cookies: navigator.cookieEnabled,
          from_profile: this.attr.fromProfile,
          from_multi_overlay: this.attr.fromMultiOverlay,
          source: this.attr.source,
          was_product_recommended: !!data.wasProductRecommended,
          recommended_by: data.recommendedBy,
          window_location: window.location.toString(),
          view_url: window.location.pathname
        },
        dataType: "json"
      });
    };

    this.trackUpdatePostViewEvent = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.increment_post_views_path(data.post_id),
        data: {
          referrer: this.attr.referrer,
          from_profile: this.attr.fromProfile,
          source: this.attr.source,
          window_location: window.location.toString(),
          view_url: window.location.pathname
        },
        dataType: "json"
      });
    };

    this.trackUserActionEvent = function(ev, data) {
      $.ajax({
        type: "POST",
        data: {
          event_name: data.name,
          referrer: this.attr.referrer,
          plugins: this.attr.pluginNames,
          is_modal: this.attr.isModal,
          from_multi_overlay: this.attr.fromMultiOverlay,
          from_profile: this.attr.fromProfile,
          source: this.attr.source,
          view_url: window.location.pathname
        },
        url: Routes.events_track_user_action_path(),
        dataType: "json"
      });
    };

    this.after("initialize", function() {
      this.attr.pluginNames = this.getPluginNames();
      this.attr.referrer = this.getReferrer();
      this.attr.source = this.getSource();
      this.attr.isModal =
        !!window.location.search.match(/as_modal/) && top != self;
      this.attr.fromProfile =
        $("body").attr("id") === "user_page" ||
        // Count events as "from the profile" if we started from the profile,
        // even if we're on a separate check out page.
        window.location.search.indexOf("from_profile=1") !== -1;
      this.attr.fromMultiOverlay = $("body").attr("id") === "overlay-page";

      this.on(
        document,
        "uiNeedsToTrackProductSpecificEvent",
        this.trackProductSpecificEvent
      );
      this.on(document, "uiNeedsToTrackViewEvent", this.trackViewEvent);
      this.on(
        document,
        "uiNeedsToTrackUserActionEvent",
        this.trackUserActionEvent
      );
      this.on(
        document,
        "uiNeedsToTrackUpdatePostViewEvent",
        this.trackUpdatePostViewEvent
      );
    });
  }
});
