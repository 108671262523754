// Refer the link below for a list of popular languages
// https://en.wikipedia.org/wiki/List_of_languages_by_number_of_native_speakers

export const SUBTITLE_LANGUAGES = [
  "English",
  "Català",
  "官话",
  "Dansk",
  "Deutsch",
  "Español",
  "Euskara",
  "Ελληνικά",
  "Français",
  "Hrvatski",
  "Italiano",
  "日本語",
  "Ladino",
  "Magyar",
  "Nederlands",
  "Polskie",
  "Português",
  "русский язык",
  "Română",
  "Svenska",
  "Türkçe",
  "हिंदी",
  "বাংলা",
  "ਪੰਜਾਬੀ",
  "मराठी",
  "తెలుగు",
  "한국어",
  "Tiếng Việt",
  "தமிழ்",
  "اردو",
  "baṣa Jawa",
  "ألعامّيّة ألعريّة ألمصريّة",
  "ગુજરાતી",
  "فارسی",
  "भोजपुरी",
  "客家话",
  "廣東話",
  "臺語",
  "هَرْشَن هَوْسَ",
  "ಕನ್ನಡ",
  "Bahasa",
  "Èdè Yorùbá",
  "മലയാളം",
  "ଓଡ଼ିଆ",
  "မြန်မာစာ, မြန်မာစကား",
  "الدارجة",
  "українська мова",
  "Tagalog",
  "Dutch",
  "ภาษาไทย",
  "af somali",
  "नेपाली",
  "Қазақ тілі",
  "čeština"
];
