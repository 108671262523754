"use strict";

define(["flight/lib/component"], function() {
  return WithFacebookLogin;

  function WithFacebookLogin() {
    this.defaultAttrs({
      facebookLoginSelector: ".facebook-login"
    });

    this.loginWithFacebook = function(ev) {
      ev.preventDefault();
      var $facebookLoginButton = this.select("facebookLoginSelector");

      $facebookLoginButton.data("default", $facebookLoginButton.text());
      this.trigger("uiNeedsToLoginWithFacebook");

      return false;
    };

    this.showFacebookLoginVerifyingState = function() {
      this.select("facebookLoginSelector").text(
        I18n.t("js.verifying_information")
      );
    };

    this.refreshWindowWithWanted = function() {
      window.location = window.location + "?wanted=true";
    };

    this.showFacebookLoginFailedMessage = function() {
      var $facebookLoginButton = this.select("facebookLoginSelector");

      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.something_went_wrong")
      });
      $facebookLoginButton.text($facebookLoginButton.data("default"));
    };

    this.after("initialize", function() {
      this.on(
        document,
        "dataFackbookLoginSucceeded",
        this.refreshWindowWithWanted
      );
      this.on(
        document,
        "dataFackbookLoginFailed",
        this.showFacebookLoginFailedMessage
      );
      this.on(
        document,
        "dataFacebookLoginVerifying",
        this.showFacebookLoginVerifyingState
      );
      this.on("click", { facebookLoginSelector: this.loginWithFacebook });
    });
  }
});
