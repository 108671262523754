var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"receipt-entry purchase_error js-receipt-entry-failed\">\n    <span class=\"purchase_failed_notice\">"
    + alias1(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"purchase_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":64}}}))
    + "</span>\n    <h2 class=\"product-name\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h2>\n    <p class=\"purchase_error_message\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"error_message") : depth0), depth0))
    + "</p>\n  </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"receipt-entry js-receipt-entry-successful\">\n    <div class=\"product-details\">\n      <h2 class=\"product-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"variants_displayable") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_quantity") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":65},"end":{"line":10,"column":137}}})) != null ? stack1 : "")
    + "</h2>\n      <span class=\"receipt-price\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"price") : depth0), depth0))
    + "</span>\n    </div>\n    <div class=\"view-content-actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"has_content") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"additional-text-items\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_gift_sender_purchase") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":32,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_gift_receiver_purchase") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":35,"column":13}}})) != null ? stack1 : "")
    + "      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"test_purchase_notice") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":6},"end":{"line":36,"column":72}}})) != null ? stack1 : "")
    + "\n    </div>\n  </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"has_shipping_to_show") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":2},"end":{"line":46,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"has_sales_tax_to_show") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":2},"end":{"line":54,"column":9}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"quantity\">× "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "</span>";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"content_url") : depth0), depth0))
    + "\" class=\"button button-primary button-block button-full-width js-track-product-click-event\" data-product-event-name=\"receipt_view_content\" target=\"_blank\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"view_content_button_text") : depth0), depth0))
    + "</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"custom_delivery_url") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"custom_delivery_url") : depth0), depth0))
    + "\" class=\"button button-primary button-block button-full-width js-track-product-click-event js-custom-delivery-url\" data-product-event-name=\"receipt_click_custom_delivery_url\" target=\"_blank\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"view_content_button_text") : depth0), depth0))
    + "</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"gift_sender_text") : depth0), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"has_files") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "          <p>"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"emailed_gift_notice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":13},"end":{"line":26,"column":40}}}))
    + "</p>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "          <p>"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"emailed_gift_notice_physical",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":13},"end":{"line":28,"column":49}}}))
    + "</p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"extra_purchase_notice") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":78}}})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"extra_purchase_notice") : depth0), depth0)) != null ? stack1 : "")
    + "</p>";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"gift_receiver_text") : depth0), depth0))
    + "</p>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"test_purchase_notice") : depth0), depth0))
    + "</p>";
},"23":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"receipt-entry\">\n      <div class=\"product-details\">\n        <h2 class=\"product-name\">"
    + alias1(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"shipping",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":33},"end":{"line":42,"column":49}}}))
    + "</h2>\n        <span class=\"receipt-price\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"shipping_amount") : depth0), depth0))
    + "</span>\n      </div>\n    </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"receipt-entry\">\n      <div class=\"product-details\">\n        <h2 class=\"product-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sales_tax_label") : depth0), depth0))
    + "</h2>\n        <span class=\"receipt-price\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sales_tax_amount") : depth0), depth0))
    + "</span>\n      </div>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":55,"column":7}}})) != null ? stack1 : "");
},"useData":true});