"use strict";

define(["handlebars"], function(Handlebars) {
  function RoutesHelper(path, args) {
    if (args == undefined) {
      return Routes[path]();
    } else {
      return Routes[path](args);
    }
  }
  Handlebars.registerHelper("routes", RoutesHelper);
  return RoutesHelper;
});
