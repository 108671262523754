"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(OfferCode);

  function OfferCode() {
    this.computeDiscount = function(ev, data) {
      $.ajax({
        type: "GET",
        url: Routes.compute_discount_offer_codes_path(),
        data: data,
        dataType: "json"
      }).done(
        function(response) {
          this.trigger("dataOfferCode", response);
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToComputeOfferDiscount", this.computeDiscount);
    });
  }
});
