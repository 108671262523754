"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(InputPlaceholders);

  function InputPlaceholders() {
    this.after("initialize", function() {
      this.$node.placeholder();
    });
  }
});
