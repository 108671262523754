"use strict";

define(["$app/utils/with_url", "flight/lib/compose"], function(
  WithUrl,
  compose
) {
  return WithEvent;

  function WithEvent() {
    compose.mixin(this, [WithUrl]);

    this.getPluginNames = function() {
      var pluginNames = [];

      for (var i = 0; i < navigator.plugins.length; i++) {
        pluginNames.push(navigator.plugins[i].name);
      }

      return pluginNames.join();
    };

    this.getReferrer = function() {
      var modalReferrer =
        this.getUrlParams().referrer && this.getUrlParams().referrer.length > 0
          ? this.getUrlParams().referrer
          : undefined;

      return modalReferrer || document.referrer || "direct";
    };

    this.getSource = function() {
      if (this.getUrlParams().src) {
        return this.getUrlParams().src;
      }
    };
  }
});
