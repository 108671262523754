"use strict";

define([
  "$app/ui/shared/with_d3_transformation",
  "flight/lib/compose",
  "$vendor/d3.min"
], function(WithD3Transformation, compose) {
  return WithChartAxis;

  function WithChartAxis() {
    compose.mixin(this, [WithD3Transformation]);

    this.defaultAttrs({
      containerSelector: ".chart-container",
      axisSelector: ".axis",
      xAxisSelector: ".x-axis",
      yAxisSelector: ".y-axis",
      yLeftAxisSelector: ".left",
      yRightAxisSelector: ".right",
      axisLabelSelector: ".axis-label"
    });

    this.appendChartContainer = function(
      dom,
      width,
      height,
      marginLeft,
      marginTop
    ) {
      this.attr.container = d3
        .select(dom)
        .append("svg")
        .attr("class", this.attr.containerSelector.slice(1))
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", this.getTransformTranslation(marginLeft, marginTop));
      return this.attr.container;
    };

    this.appendXAxis = function(container, width, height, padding) {
      this.createXAxis(width, padding);
      return container
        .append("g")
        .attr("class", this.getAxisClass(this.attr.xAxisSelector.slice(1)))
        .attr("transform", this.getTransformTranslation(0, height))
        .call(this.attr.xAxis);
    };

    this.appendYLeftAxis = function(container, height) {
      this.createYLeftAxis(height);
      return container
        .append("g")
        .attr(
          "class",
          this.getYAxisClass(this.attr.yLeftAxisSelector.slice(1))
        );
    };

    this.appendYRightAxis = function(container, width, height) {
      this.createYRightAxis(height);
      return container
        .append("g")
        .attr(
          "class",
          this.getYAxisClass(this.attr.yRightAxisSelector.slice(1))
        )
        .attr("transform", this.getTransformTranslation(width, 0))
        .call(this.attr.yRightAxis);
    };

    this.createXAxis = function(width, padding) {
      this.attr.x = d3.scale.ordinal().rangeBands([0, width], padding);
      this.attr.xAxis = this.createAxis(this.attr.x, "bottom", 0, 0);
      return this.attr.xAxis;
    };

    this.createYLeftAxis = function(height) {
      this.attr.yLeft = d3.scale.linear().range([height, 0]);
      this.attr.yLeftAxis = this.createAxis(this.attr.yLeft, "left", 2, 5);
      return this.attr.yLeftAxis;
    };

    this.createYRightAxis = function(height) {
      this.attr.yRight = d3.scale.linear().range([height, 0]);
      this.attr.yRightAxis = this.createAxis(this.attr.yRight, "right", 2, 5);
      return this.attr.yRightAxis;
    };

    this.createAxis = function(scale, orientation, ticks, majorTickSize) {
      return d3.svg
        .axis()
        .scale(scale)
        .orient(orientation)
        .ticks(ticks)
        .tickSize(majorTickSize, 0, 0);
    };

    this.getAxisClass = function(customClass) {
      return this.attr.axisSelector.slice(1) + " " + customClass;
    };

    this.getYAxisClass = function(customClass) {
      return (
        this.getAxisClass(this.attr.yAxisSelector.slice(1)) + " " + customClass
      );
    };

    this.appendLabelToAxis = function(x, y, anchor, cssClass, dy, text) {
      return function(axis) {
        var label = axis
          .append("text")
          .attr("x", x)
          .attr("y", y)
          .style("text-anchor", anchor)
          .attr(
            "class",
            [this.attr.axisLabelSelector.slice(1), cssClass].join(" ")
          )
          .text(text || "");
        if (dy) {
          label.attr("dy", dy);
        }
        return label;
      }.bind(this);
    };
  }
});
