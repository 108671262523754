"use strict";

define([], function() {
  var GumroadUtils = {
    isOverlay: function() {
      return $("body").attr("id") === "overlay-page";
    },
    isModal: function() {
      return !!window.location.search.match(/as_modal/) && top != self;
    },
    isEmbed: function() {
      return !!window.location.search.match(/as_embed/) && top != self;
    },
    isOutboundEmbed: function() {
      return !!window.location.search.match(/outbound_embed/) && top != self;
    },
    isRecommended: function() {
      return !!window.location.search.match(/recommended_by/);
    },
    isExternalPresentation: function() {
      return (
        this.isOutboundEmbed() ||
        this.isEmbed() ||
        this.isModal() ||
        this.isOverlay()
      );
    }
  };
  return GumroadUtils;
});
