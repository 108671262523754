(function() {
  "use strict";

  define([
    "flight/lib/compose",
    "$app/templates/preview",
    "$app/ui/shared/with_audio_player",
    "$app/ui/shared/with_video_player",
    "$app/templates/helpers/t"
  ], function(compose, PreviewTemplate, WithAudioPlayer, WithVideoPlayer) {
    return WithPreview;

    function WithPreview() {
      compose.mixin(this, [WithAudioPlayer, WithVideoPlayer]);

      const isArrowRelevantClassname = "is-arrow-relevant";

      this.defaultAttrs({
        defaultImageWidth: 1005,
        iframeSelector: ".js-oembed-iframe",
        multiPreviewContainerSelector: ".js-preview-container",
        closeOverlaySelector: ".js-close-popped-product-modal",
        previewArrowSelector: ".js-preview-arrow",
        previewArrowVisibleSelector: `.js-preview-arrow.${isArrowRelevantClassname}`,
        previewContentSelector: ".js-preview-content",
        previewImageSelector: "img.preview",
        previewPickerSelector: ".js-preview-picker",
        previewPickerItemSelector: ".js-preview-picker-item",
        isArrowRelevantClassname: isArrowRelevantClassname
      });

      this.addOrientationChangeListeners = function() {
        if (!this.attr.isMobile) {
          return;
        }
        $.each(
          ["portrait", "landscape"],
          function(_i, orientation) {
            window.matchMedia("(orientation: " + orientation + ")").addListener(
              function(m) {
                if (m.matches) {
                  this.showPreview();
                }
              }.bind(this)
            );
          }.bind(this)
        );
      };

      this.getDimensions = function() {
        var ratio =
            this.$node.parent().width() / this.attr.activePreview.native_width,
          dimensions = {
            width: this.attr.activePreview.width,
            height: this.attr.activePreview.height
          };
        if (ratio < 1 && this.attr.activePreview && !this.attr.isFixedHeight) {
          dimensions.width = this.attr.activePreview.native_width * ratio;
          dimensions.height = this.attr.activePreview.native_height * ratio;
        }
        return dimensions;
      };

      this.initializePreviewMediaPlayer = function() {
        if (!this.attr.activePreview) {
          return;
        }
        if (this.attr.activePreview.type === "audio") {
          this.initializePlayer();
        } else if (this.attr.activePreview.type === "video") {
          this.initializeVideoPlayer({
            height: this.select("multiPreviewContainerSelector").css("height"),
            width: this.select("multiPreviewContainerSelector").css("width")
          });
        }
      };

      this.initializePreview = function() {
        this.attr.activePreviewId = this.select(
          "multiPreviewContainerSelector"
        ).data("active-preview-id");
        this.attr.productPermalink = this.select(
          "multiPreviewContainerSelector"
        ).data("product-permalink");
        this.attr.assetPreviews = this.select(
          "multiPreviewContainerSelector"
        ).data("asset-previews");
        this.setActivePreview();
        if (this.attr.activePreview) {
          this.showPreview();
        }
      };

      this.keyAction = function(ev) {
        if (
          $(ev.target).filter("input, [data-medium-element]").length ||
          !this.attr.onProductPage
        ) {
          return;
        }
        if (ev.which === 37 && this.attr.activePreviewIndex > 0) {
          this.attr.activePreviewId = this.attr.assetPreviews[
            this.attr.activePreviewIndex - 1
          ].id;
          this.showPreview();
        } else if (
          ev.which === 39 &&
          this.attr.activePreviewIndex < this.attr.assetPreviews.length - 1
        ) {
          this.attr.activePreviewId = this.attr.assetPreviews[
            this.attr.activePreviewIndex + 1
          ].id;
          this.showPreview();
        }
      };

      this.pauseVideo = function() {
        this.toggleVideo(false);
      };

      this.playVideo = function() {
        this.toggleVideo(true);
      };

      this.populateOembedUrl = function() {
        var $iframe = this.select("previewContentSelector").find("iframe");
        if ($iframe.data("oembed-url") && !$iframe.attr("src")) {
          $iframe.attr("src", $iframe.data("oembed-url"));
        }
      };

      this.populatePreview = function() {
        if (!this.attr.activePreviewId) {
          this.select("multiPreviewContainerSelector")
            .css({ background: "", height: "" })
            .addClass("default-preview");
          this.select("previewContentSelector").empty();
          return;
        }
        this.select("multiPreviewContainerSelector").removeClass(
          "default-preview"
        );
        $(".product.popped .preview-container").css({ height: "" });
        var dimensions = this.getDimensions(),
          css = { width: "auto", height: "auto" },
          html = "",
          url =
            this.$node.width() > this.attr.defaultImageWidth
              ? this.attr.activePreview.original_url
              : this.attr.activePreview.url;
        if (
          this.attr.activePreview.type === "image" &&
          this.attr.isImageAsBackground
        ) {
          css = $.extend(css, {
            "background-repeat": "no-repeat",
            "background-position": "top center",
            "background-image": "url(" + url + ")",
            "background-size": "cover"
          });
          if (this.attr.isMobile) {
            $.extend(css, dimensions);
          }
          if (
            this.attr.isFixedHeight ||
            this.$node.parents(".product-grid").length
          ) {
            $.extend(css, { width: "100%", height: "290px" });
          }
        } else {
          css.background = "";
          if (this.attr.activePreview.type === "image") {
            css.height = dimensions.height;
          }
          html = PreviewTemplate(
            $.extend({}, this.attr.activePreview, dimensions)
          );
        }
        this.select("previewContentSelector").html(html);
        this.select("multiPreviewContainerSelector").css(css);
        if (
          !this.attr.isMobile &&
          this.attr.activePreview.type === "image" &&
          !this.attr.isOverlayPage
        ) {
          $(".product.popped .preview-container").css({
            height: this.attr.activePreview.height
          });
        }
        this.readyPreview();
      };

      this.populatePreviewFlipper = function() {
        if (
          !this.attr.assetPreviews ||
          this.attr.assetPreviews.length <= 1 ||
          this.select("previewArrowSelector").length < 1
        ) {
          this.select("previewArrowSelector").removeClass(
            this.attr.isArrowRelevantClassname
          );
          return;
        }
        var $prev = this.select("previewArrowSelector").filter(".previous"),
          $next = this.select("previewArrowSelector").filter(".next");
        if (this.attr.activePreviewIndex === 0) {
          $prev
            .removeClass(this.attr.isArrowRelevantClassname)
            .removeData("asset-preview-id");
        } else {
          $prev
            .addClass(this.attr.isArrowRelevantClassname)
            .data(
              "asset-preview-id",
              this.attr.assetPreviews[this.attr.activePreviewIndex - 1].id
            );
        }
        if (
          this.attr.activePreviewIndex >=
          this.attr.assetPreviews.length - 1
        ) {
          $next
            .removeClass(this.attr.isArrowRelevantClassname)
            .removeData("asset-preview-id");
        } else {
          $next
            .addClass(this.attr.isArrowRelevantClassname)
            .data(
              "asset-preview-id",
              this.attr.assetPreviews[this.attr.activePreviewIndex + 1].id
            );
        }
      };

      this.populatePreviewPicker = function() {
        var $previewPicker = this.select("previewPickerSelector");
        $previewPicker.addClass("empty").empty();
        if (!$previewPicker.length) {
          return;
        }
        $.each(
          this.attr.assetPreviews,
          function(idx, el) {
            var $li = $("<li>", {
              class: "js-preview-picker-item",
              "data-asset-preview-id": el.id
            });
            if (el.id === this.attr.activePreviewId) {
              $li.addClass("active");
            }
            this.select("previewPickerSelector").append($li);
          }.bind(this)
        );
        if (this.attr.assetPreviews.length > 1) {
          $previewPicker.removeClass("empty").show();
          this.select("multiPreviewContainerSelector").addClass(
            "has-multiple-previews"
          );
        }
      };

      this.readyPreview = function() {
        this.initializePreviewMediaPlayer();
        this.populatePreviewPicker();
        this.populatePreviewFlipper();
        this.updateCurrentProductPageTabHeight();
        this.populateOembedUrl();
      };

      this.selectPreview = function(ev, ui) {
        ev && ev.stopPropagation();
        this.attr.activePreviewId = $(ui.el).data("asset-preview-id");
        this.showPreview();
      };

      this.setActivePreview = function() {
        delete this.attr.activePreviewIndex;
        delete this.attr.activePreview;
        if (!this.attr.assetPreviews) {
          return;
        }
        $.each(
          this.attr.assetPreviews,
          function(loopIndex, el) {
            if (el.id === this.attr.activePreviewId) {
              this.attr.activePreviewIndex = loopIndex;
              this.attr.activePreview = { ...el };
              this.attr.activePreview[
                this.attr.activePreview.type + "_preview"
              ] = true;
              this.attr.activePreview["disallowPreviewPlayback"] =
                this.attr.isLibraryPage || this.attr.isUserPage;
              if (this.attr.activePreview.type === "video") {
                var url = this.attr.activePreview.url;
                this.attr.activePreview["playlist"] = JSON.stringify([
                  { sources: [url] }
                ]);
              }
              return false;
            }
          }.bind(this)
        );
      };

      this.showPreview = function(ev) {
        this.select("multiPreviewContainerSelector").removeClass("uploading");
        this.select("uploadURLInputSelector").val("");
        this.setActivePreview();
        this.populatePreview();
        this.updateCurrentProductPageTabHeight();
      };

      this.toggleVideo = function(play) {
        var iframe = this.select("iframeSelector")[0],
          message = null;
        if (iframe) {
          if (iframe.src.match(/vimeo/)) {
            message = { method: play ? "play" : "pause" };
          } else if (iframe.src.match(/youtube/)) {
            message = {
              event: "command",
              func: play ? "playVideo" : "pauseVideo",
              args: ""
            };
          }
          if (message) {
            iframe.contentWindow.postMessage(JSON.stringify(message), "*");
          }
        }
      };

      this.updateCurrentProductPageTabHeight = function() {
        this.trigger("uiUpdateCurrentProductPageTabHeight");
      };

      this.hideProductAndProductOverlay = function() {
        this.trigger("uiNeedsToHideProductOverlay");
      };

      this.after("initialize", function() {
        this.attr.onProductPage =
          this.$node.parents("body#product_page").length > 0;
        this.attr.isFixedHeight = this.select(
          "multiPreviewContainerSelector"
        ).hasClass("js-fixed-preview-height");
        this.attr.isImageAsBackground = this.$node.hasClass(
          "image-as-background"
        );
        this.attr.isOverlayPage =
          this.$node.parents("#overlay-page").length > 0;
        this.attr.isLibraryPage = this.$node.parents(".library").length > 0;
        this.attr.isUserPage = this.$node.parents("#user_page").length > 0;

        this.on("click", {
          previewArrowVisibleSelector: this.selectPreview,
          previewPickerItemSelector: this.selectPreview,
          closeOverlaySelector: this.hideProductAndProductOverlay
        });

        this.on(document, "keydown", this.keyAction);
        this.on(document, "showPreview", this.showPreview);
        this.on(document, "uiNeedsToPauseVideo", this.pauseVideo);

        this.addOrientationChangeListeners();
        this.initializePreview();
      });
    }
  });
})();
