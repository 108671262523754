"use strict";

define(["$app/ui/shared/with_credit_card", "flight/lib/compose"], function(
  WithCreditCard,
  compose
) {
  return WithCreditCardOnPaymentForm;

  function WithCreditCardOnPaymentForm() {
    compose.mixin(this, [WithCreditCard]);

    this.defaultAttrs({
      creditCardHolderSelector: ".credit_card_holder",
      cardErrorSelector: ".card_error_popup",
      cardErrorPopupMessageSelector: ".card_error_popup .message",
      rememberedCardIconSelector: ".remembered .card_icon",
      presetCCNumberSelector: ".preset_cc_number",
      presetCCInputSelector: ".saved_card .js-expiry_date_p .preset_cc_input",
      saveCardSelector: 'input[name="save_card"]',
      savePayPalSelector: ".js-save-paypal-selector",
      keepOnFileSelector: ".keep-on-file-notice",
      creditCardRequiredFieldSelector:
        ".credit_card_holder .credit_card.new-card .required",
      numericSelector: ".credit_card_holder .numeric",
      numericSpacesSelector: ".credit_card_holder .numeric-spaces",
      isCreditCardFormInUse: false
    });

    this.setCreditCardFormInUse = function(isCreditCardFormInUse) {
      this.attr.isCreditCardFormInUse = isCreditCardFormInUse;
    };

    this.isCreditCardFormInUse = function() {
      return this.attr.isCreditCardFormInUse;
    };

    this.setCreditCard = function() {
      this.setCreditCardFormState(this.attr.user.card.credit);

      var $testCard = this.select("testCardSelector"),
        $newCard = this.select("newCardSelector"),
        $savedCard = this.select("savedCardSelector"),
        $required = this.select("creditCardRequiredFieldSelector");

      if (this.attr.user.card.credit == "test") {
        this.attr.isSamplePurchase = true;
        $testCard.fadeIn();
        $newCard.fadeOut();
        $savedCard.remove();
        $required.removeClass("required");
      } else if (this.attr.user.card.credit == "saved") {
        $testCard.remove();
        if (this.attr.user.card.type == "paypal") {
          this.select("rememberedSelector").hide();
          this.select("keepOnFileSelector").hide();
          $savedCard.hide();
          $newCard.fadeIn();
          this.setSavedPayPalCardInUse(this.attr.user.card.visual);
        } else {
          this.select("presetCCNumberSelector").html(
            this.attr.user.card.visual
          );
          this.select("presetCCInputSelector").html(this.attr.user.card.date);
          this.select("rememberedCardIconSelector").addClass(
            this.attr.user.card.type
          );
          $savedCard.fadeIn();
          $newCard.fadeOut();
        }
        $required.removeClass("required");
      } else if (this.attr.user.card.credit == "new") {
        $savedCard.remove();
        $testCard.remove();
      }
    };

    this.setCreditCardFormVisibility = function(totalPrice) {
      if (
        totalPrice + this.attr.shippingCents > 0 &&
        !this.attr.isMultiBuyEnabled
      ) {
        this.showCreditCardForm();
      } else if (totalPrice == 0 && this.attr.shippingCents == 0) {
        this.hideCreditCardForm();
      }
    };

    this.showCreditCardForm = function() {
      var $creditCardHolder = this.select("creditCardHolderSelector"),
        $payButton = this.select("payButtonSelector");

      if (this.attr.zeroPlusEnabled) {
        $payButton.text(I18n.t("js.give_prompt"));
      } else {
        $payButton.text($payButton.data("default"));
      }

      $creditCardHolder.show();
      this.trigger("uiAnimatePaymentFormHeight", { animate: false });
    };

    this.hideCreditCardForm = function() {
      var $creditCardHolder = this.select("creditCardHolderSelector"),
        $payButton = this.select("payButtonSelector");

      if (!$creditCardHolder.is(":visible")) return;

      $payButton.text(I18n.t("js.get_prompt"));

      $creditCardHolder.hide();
      this.trigger("uiAnimatePaymentFormHeight", { animate: false });
    };

    this.popupCreditCardError = function(error_message) {
      var $cardErrorPopup = this.select("cardErrorSelector");

      this.select("cardErrorPopupMessageSelector").text(error_message);

      $cardErrorPopup.removeClass("hidden").fadeIn(400);
      setTimeout(function() {
        $cardErrorPopup.fadeOut(400);
      }, 3000);
    };

    this.showCreditCardError = function(ev, data) {
      this.popupCreditCardError(data.error_message);
    };

    this.payIfEnterKeyPressed = function(ev) {
      if (ev.which == 13) {
        this.payIfAllowed();
      }
    };

    this.syncSaveCardCheckboxes = function(ev) {
      this.select("saveCardSelector").prop(
        "checked",
        $(ev.target).prop("checked")
      );
    };

    this.after("initialize", function() {
      this.on("keypress", {
        numericSelector: this.payIfEnterKeyPressed,
        numericSpacesSelector: this.payIfEnterKeyPressed
      });
      this.on("click", {
        saveCardSelector: this.syncSaveCardCheckboxes
      });

      this.on(
        document,
        "uiNeedsToShowCreditCardError",
        this.showCreditCardError
      );
    });
  }
});
