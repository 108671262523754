"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Purchase);

  function Purchase() {
    this.defaultAttrs({
      timeout: 30000
    });

    this.makePayment = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.purchases_path(),
        data: data.serializedForm,
        timeout: this.attr.timeout,
        error: function(jqXHR, textStatus, errorThrown) {
          if (data.is_bundled) {
            if (data.payingWithPayPal) {
              data.response = {
                permalink: data.permalink,
                name: data.name,
                sha: data.sha,
                price: data.formattedPrice,
                textStatus: textStatus,
                success: false
              };

              this.markBundleProductAndSendPayRequest(data);
            } else {
              this.trigger("uiBundledProductPaymentFailed", {
                permalink: data.permalink
              });
            }
          } else {
            if (textStatus === "timeout" && data.payingWithPayPal) {
              this.trigger("dataPurchaseTimeout", {
                permalink: data.permalink
              });
            } else {
              this.trigger("dataPurchaseFail", { permalink: data.permalink });
            }
          }
        }.bind(this)
      }).done(
        function(response) {
          response.permalink = data.permalink;
          response.sha = data.sha;
          if (data.is_bundled) {
            if (data.payingWithPayPal) {
              data.response = response;
              this.markBundleProductAndSendPayRequest(data);
            } else {
              if (response.success) {
                this.trigger("uiBundledProductPaymentCompleted", response);
              } else {
                this.trigger("uiBundledProductPaymentFailed", response);
              }
            }
          } else {
            if (response.success) {
              if (data.payingWithPayPal && data.paypalOrderDetails) {
                this.trigger("uiNeedsPaypalOrderPayment", {
                  paymentDetails: data.paypalOrderDetails,
                  purchaseResponse: response
                });
              } else {
                this.trigger("dataPurchaseSuccessful", response);
              }
            } else {
              this.trigger("dataPurchaseFail", response);
            }
          }
        }.bind(this)
      );
    };

    this.updatePurchase = function(ev, data) {
      $.ajax({
        url: Routes.purchase_path(data),
        type: "PUT",
        dataType: "json",
        data: data
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataPurchaseUpdateSuccessful", res);
          } else {
            this.trigger("dataPurchaseUpdateFail");
          }
        }.bind(this)
      );
    };

    this.executeRefund = function(ev, data) {
      $.ajax({
        type: "PUT",
        url: Routes.refund_purchase_path(data.sale),
        data: { amount: data.inputValue },
        dataType: "json"
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataRefundComplete", res);
          } else {
            this.trigger("dataRefundFailed");
          }
        }.bind(this)
      );
    };

    this.sendInvoice = function(ev, data) {
      $.ajax({
        url: Routes.send_invoice_purchase_path(data.id),
        type: "POST",
        dataType: "json",
        data: data
      }).done(
        function(res) {
          this.trigger("dataInvoice", res);
        }.bind(this)
      );
    };

    this.deleteImportedCustomer = function(ev, data) {
      $.ajax({
        url: Routes.destroy_imported_customer_path(data.id),
        type: "DELETE",
        dataType: "json",
        data: data
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataDeletedImportedCustomerComplete", {
              ...res,
              id: data.id
            });
          } else {
            this.trigger("dataDeletedImportedCustomerFailed", res);
          }
        }.bind(this)
      );
    };

    this.deleteFreeCustomer = function(ev, data) {
      $.ajax({
        url: Routes.destroy_free_customer_path(data.id),
        type: "DELETE",
        dataType: "json",
        data: data
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataDeletedFreeCustomerComplete", {
              ...res,
              id: data.id
            });
          } else {
            this.trigger("dataDeletedFreeCustomerFailed", res);
          }
        }.bind(this)
      );
    };

    this.markPurchaseAsShipped = function(ev, data) {
      $.ajax({
        url: Routes.mark_as_shipped_path(data.purchase_id),
        type: "POST",
        dataType: "json",
        data: data
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataMarkedPurchaseAsShippedSuccessful", res);
          } else {
            this.trigger("dataMarkedPurchaseAsShippedFail");
          }
        }.bind(this)
      );
    };

    this.changeCanContact = function(ev, data) {
      $.ajax({
        url: Routes.change_can_contact_purchase_path(data.id),
        type: "POST",
        dataType: "json"
      }).done(
        function(res) {
          this.trigger("dataChangedCanContact", res);
        }.bind(this)
      );
    };

    this.markBundleProductAndSendPayRequest = function(data) {
      this.trigger("uiNeedsMarkBundleProductAndOrderPayment", data);
    };

    this.resendPing = function(ev, data) {
      $.ajax({
        url: Routes.purchase_pings_path(data.id),
        type: "POST"
      })
        .done(data => {
          if (data.success) {
            this.trigger("dataResentPingSuccessful");
          } else {
            this.trigger("dataResentPingFail");
          }
        })
        .fail(() => this.trigger("dataResentPingFail"));
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToMakePayment", this.makePayment);
      this.on(document, "uiNeedsToUpdatePurchase", this.updatePurchase);
      this.on(
        document,
        "uiNeedsToMarkPurchaseAsShipped",
        this.markPurchaseAsShipped
      );
      this.on(document, "uiNeedsToRefund", this.executeRefund);
      this.on(
        document,
        "uiNeedsToDeleteImportedCustomer",
        this.deleteImportedCustomer
      );
      this.on(document, "uiNeedsToDeleteFreeCustomer", this.deleteFreeCustomer);
      this.on(document, "uiNeedsToSendInvoice", this.sendInvoice);
      this.on(document, "uiNeedsToChangeCanContact", this.changeCanContact);
      this.on(document, "uiNeedsToResendPing", this.resendPing);
    });
  }
});
