"use strict";

define(["$app/utils/currency_utils"], function(CurrencyUtils) {
  return WithMultiBuy;

  function WithMultiBuy() {
    this.defaultAttrs({
      isMultiBuyEnabled: false,
      completedBundle: false
    });

    this.isMultiBuyable = function() {
      return (
        this.attr.isMultiBuyEnabled &&
        !this.attr.completedBundle &&
        !this.attr.singleProductMode
      );
    };

    this.completedBundle = function(ev) {
      this.attr.completedBundle = true;
    };

    this.addSelfToBundle = function() {
      var $priceRange = this.select("priceRangeSelector");

      if (typeof $priceRange.val() !== "undefined") {
        this.attr.priceToAddToProductBundle =
          this.attr.totalPriceCents / (this.attr.isSingleUnit ? 1 : 100);
      }

      if (!this.attr.priceToAddToProductBundle) {
        this.setPrice();
      }

      var bundledProductData = {
        permalink: this.attr.permalink,
        externalId: this.attr.externalId,
        name: this.attr.name,
        currency: this.select("productSelector").data("currency-symbol"),
        isSingleUnit: this.attr.isSingleUnit,
        price: this.attr.priceToAddToProductBundle,
        isRentalModeActive: this.attr.isRentalModeActive,
        shippingRequired: this.attr.shippingInfoRequired,
        isRecurringBilling: this.attr.isRecurringBilling,
        hasOfferCodeField:
          this.attr.hasOfferCodeField && !this.attr.offerCodeUsed,
        offerCodeName: this.attr.offerCodeName,
        offerCodeUsed: this.attr.offerCodeUsed,
        selectedPriceInfo: this.attr.selectedPriceInfo,
        serializedForm: this.getSerializedFormExcludingCreditCard(),
        originalPriceCents: parseInt(
          this.attr.priceToAddToProductBundle *
            (this.attr.isSingleUnit ? 1 : 100)
        ),
        perceivedPriceCents: parseInt(
          this.attr.priceToAddToProductBundle *
            (this.attr.isSingleUnit ? 1 : 100)
        ),
        exchangeRate: this.attr.exchangeRate,
        priceRange: $priceRange.val(),
        isPreorder: this.attr.isPreorder,
        isPhysical: this.attr.isPhysical,
        isTaxedExclusively: this.attr.isTaxedExclusively,
        sellerId: this.attr.sellerId,
        variants: [],
        customFields: this.attr.customFields,
        quantity: this.attr.quantity,
        formattedPrice:
          this.attr.priceToAddToProductBundle % 1 == 0
            ? this.attr.priceToAddToProductBundle
            : this.attr.priceToAddToProductBundle.toFixed(2),
        showQuantity: this.attr.quantity > 1,
        wasProductRecommended: this.attr.wasProductRecommended,
        recommendedBy: this.attr.recommendedBy
      };

      this.select("variantOptionSelector").map((_index, variantSelect) => {
        if ($(variantSelect).hasClass("selected"))
          bundledProductData.variants.push($(variantSelect).data("value"));
      });

      this.trigger("addProductToBundle", bundledProductData);

      // Note that the product can be added to the bundle with the overlay
      // open (the user viewed the product first, whether on gumroad or
      // external overlay) or closed (the user clicked the quick-add
      // shopping cart button).
      var $animatedProduct = this.select("productSelector");
      $animatedProduct.addClass("to-animate-to-bundle");

      setTimeout(
        function() {
          var height = $animatedProduct.height(),
            width = $animatedProduct.width(),
            initialLeft = $animatedProduct.offset().left;

          $animatedProduct.addClass("animate-to-bundle").css({
            top: 30 - height / 2,
            left: $(window).width() - 160 - width / 2 - initialLeft
          });

          this.trigger("uiAddProductToBundledList", bundledProductData);
          setTimeout(
            function() {
              // This either hides the overlay or switches the quick-add
              // shopping cart button back from a success checkmark to
              // the cart icon.
              this.trigger("uiNeedsToHideProductOverlay", {
                permalink: this.attr.permalink
              });
            }.bind(this),
            1000
          );
        }.bind(this),
        10
      );
    };

    this.after("initialize", function() {
      this.on(document, "completedBundle", this.completedBundle);
      this.attr.singleProductMode = $(".js-product-display").data(
        "single-product-mode"
      );
    });
  }
});
