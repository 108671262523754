"use strict";

function WithFacebookPixel() {
  const defaultSellerFbPixelId = "";

  this.defaultAttrs({
    metaFbPixelEnabledSelector: 'meta[property="gr:fb_pixel:enabled"]',
    gumroadPixelId: "1016982791823619",
    sellerFbPixelId: defaultSellerFbPixelId,
    initializedPixelIds: []
  });

  this.setupFacebookPixel = function() {
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    fbq("init", this.attr.gumroadPixelId);
    fbq("trackSingle", this.attr.gumroadPixelId, "PageView");
  };

  this.gumroadFbPixelTrack = function(event) {
    if (this.isFbPixelInitialized()) {
      fbq("trackSingle", this.attr.gumroadPixelId, event);
    }
  };

  this.sellerFbPixelInit = function(pixel_id) {
    if (this.isFbPixelInitialized()) {
      this.attr.sellerFbPixelId = pixel_id;
      if (this.attr.initializedPixelIds.indexOf(pixel_id) === -1) {
        this.attr.initializedPixelIds.push(pixel_id);
        fbq("init", pixel_id);
      }
    }
  };

  this.sellerFbPixelTrack = function(event, data) {
    if (this.isSellerAnalyticsIdPresent() && this.isFbPixelInitialized()) {
      fbq("trackSingle", this.attr.sellerFbPixelId, event, data);
    }
  };

  this.isFbPixelInitialized = function() {
    return typeof fbq !== "undefined";
  };

  this.isSellerAnalyticsIdPresent = function() {
    return this.attr.sellerFbPixelId !== defaultSellerFbPixelId;
  };

  this.after("initialize", function() {
    if (
      $(this.attr.metaFbPixelEnabledSelector).attr("content") === "true" &&
      !this.isFbPixelInitialized()
    ) {
      this.setupFacebookPixel();
    }
  });
}

module.exports = WithFacebookPixel;
