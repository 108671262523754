"use strict";

define([
  "flight/lib/component",
  "$app/templates/megaphone_tasks_holder",
  "$vendor/jquery.tipsy"
], function(defineComponent, MegaphoneTasksHolder) {
  return defineComponent(MegaphoneDropdown);

  function MegaphoneDropdown() {
    this.defaultAttrs({
      taskSelector: ".js-megaphone-task",
      actionSelector: ".js-megaphone-action",
      trackingSelector: ".js-megaphone-task a:not(.js-megaphone-task-dismiss)",
      taskListSelector: ".js-megaphone-tasks",
      defaultMessageSelector: ".js-megaphone-default-message",
      viewMoreGuidesSelector:
        '.js-megaphone-default-message a[data-megaphone-action="uiNeedsToGoToGuides"]',
      taskDismissSelector: ".js-megaphone-task-dismiss",
      megaphoneDropdownSelector: ".js-megaphone-dropdown",
      taskCounterSelector: ".js-megaphone-task-counter",
      taskCountSelector: ".js-megaphone-task-count",
      actionDataField: "megaphone-action",
      taskDataField: "megaphone-task",
      megaphoneDropdownTriggerSelector: ".js-megaphone-dropdown-trigger",
      greeterDismissedTooltipSelector: ".js-greeter-dismissed-tooltip"
    });

    this.getCurrentTaskCount = function() {
      return parseInt(this.select("taskCountSelector").html());
    };

    this.setCurrentTaskCount = function(n) {
      this.select("taskCountSelector").html(n);
    };

    this.enableDropdown = function() {
      this.select("megaphoneDropdownTriggerSelector").removeClass("disabled");
    };

    this.parentHasChild = function($parent, $child) {
      return $child.parents().index($parent) != -1;
    };

    this.toggleDropdown = function(ev) {
      var $dropdown = this.select("megaphoneDropdownSelector"),
        $tooltip = $(this.attr.greeterDismissedTooltipSelector);

      if (
        this.select("megaphoneDropdownTriggerSelector").hasClass("disabled")
      ) {
        return false;
      }

      if ($tooltip.is(":visible")) {
        $tooltip.hide();
      }

      if (!this.parentHasChild($dropdown, $(ev.target))) {
        this.select("megaphoneDropdownTriggerSelector").tipsy("hide");
        this.$node.toggleClass("open");
        if (this.$node.hasClass("open")) {
          this.trigger("uiNeedsToTrackUserActionEvent", {
            name: "megaphone_dropdown_open"
          });
        } else {
          this.dismissMegaphoneTasks();
        }

        this.trigger("uiNeedsToHideOtherDropdowns");
        return false;
      }
    };

    this.hideDropdown = function() {
      this.$node.removeClass("open");
    };

    this.hideDropdownWhenUnfocused = function(ev) {
      var $dropdown = this.select("megaphoneDropdownSelector");
      if (!this.parentHasChild($dropdown, $(ev.target))) {
        this.$node.removeClass("open");
      }
    };

    this.toggleNoTasksDisplay = function() {
      this.select("taskCounterSelector").toggleClass("no-tasks");
      this.select("defaultMessageSelector").fadeToggle(600);
      this.select("taskListSelector").fadeToggle(600);
    };

    this.selectMegaphoneTask = function(task) {
      return this.select("taskSelector").filter(
        "[data-" + this.attr.taskDataField + "=" + task + "]"
      );
    };

    this.dismissMegaphoneTasks = function() {
      const tasks = this.select("taskSelector");
      const tasksToDismiss = tasks
        .map(function(idx, task) {
          return task.dataset.megaphoneTask;
        })
        .get();
      if (tasksToDismiss.length > 0) {
        this.trigger("uiNeedsToDismissMegaphoneTasks", {
          megaphoneTasks: tasksToDismiss
        });
      }
    };

    this.dismissMegaphoneTask = function(ev) {
      ev.preventDefault();
      var task = $(ev.target)
        .closest(this.attr.taskSelector)
        .data(this.attr.taskDataField);
      this.trigger("uiNeedsToDismissMegaphoneTasks", {
        megaphoneTasks: [task],
        userDismissed: true
      });
      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: "megaphone_dropdown_dismiss_task_" + task
      });
      return false;
    };

    this.completeMegaphoneTask = function(ev, data) {
      var $task = this.selectMegaphoneTask(data.megaphone_task);
      $task.addClass("completed");
      this.setCurrentTaskCount(this.getCurrentTaskCount() - 1);
      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: "megaphone_complete_task_" + data.megaphone_task
      });
    };

    this.dispatchMegaphoneAction = function(ev) {
      ev.preventDefault();
      var action = $(ev.target).data(this.attr.actionDataField),
        actionEvent = action + "WithMegaphone";
      if (typeof action != "undefined") {
        this.trigger(actionEvent);
      }
      return false;
    };

    this.trackMegaphoneAction = function(ev) {
      var action = $(ev.target).data(this.attr.actionDataField);
      if (typeof action != "undefined") {
        this.trigger("uiNeedsToTrackUserActionEvent", {
          name: "megaphone_dropdown_action_" + action
        });
      }
    };

    this.resetMegaphoneTasks = function(ev, data) {
      this.setCurrentTaskCount(data.tasks.length);

      if (data.tasks.length == 0) {
        this.toggleNoTasksDisplay();
      } else {
        this.select("taskListSelector").html(MegaphoneTasksHolder(data));
      }
    };

    this.after("initialize", function() {
      this.on("click", this.toggleDropdown);
      this.on("html", "click", this.hideDropdownWhenUnfocused);
      this.on("click", {
        actionSelector: this.dispatchMegaphoneAction,
        trackingSelector: this.trackMegaphoneAction,
        viewMoreGuidesSelector: this.trackMegaphoneAction,
        taskDismissSelector: this.dismissMegaphoneTask
      });
      this.on(
        document,
        "dataCompletedMegaphoneTask",
        this.completeMegaphoneTask
      );
      this.on(document, "uiNeedsToEnableDropdown", this.enableDropdown);
      this.on(document, "uiHideMegaphone", this.hideDropdown);
      this.on(document, "dataMegaphoneTasks", this.resetMegaphoneTasks);
    });
  }
});
