var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"audio-preview-container\">\n    <div class=\"audio-player js-audio-player\">\n      <div class=\"player\" data-url=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\"></div>\n      <a class=\"play-button media-control jp-play\">"
    + alias1(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias2,"play",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":65}}}))
    + "</a>\n      <a class=\"pause-button media-control jp-pause\">"
    + alias1(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias2,"pause",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":68}}}))
    + "</a>\n      <div class=\"audio-progress\">\n        <svg height=\"80\" version=\"1.1\" width=\"80\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path class=\"circle-progress\" d=\"M 40 3 a 37 37 0 0 1 0 0\" fill=\"none\" opacity=\"1\" stroke-width=\"5\" stroke=\"#aaa\" style=\"opacity: 1;\"></path>\n        </svg>\n      </div>\n    </div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"image-preview-container\">\n    <img class=\"preview\" src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" itemprop=\"image\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"width") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":57},"end":{"line":18,"column":111}}})) != null ? stack1 : "")
    + "></img>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"max-width: "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"width") : depth0), depth0))
    + "px;\"";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"oembed-preview-container\">\n    <iframe class=\"js-oembed-iframe\" data-oembed-url=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" allowfullscreen=\"true\" frameborder=\"0\"></iframe>\n  </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"video-preview-container js-video-player-container\" data-playlist=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"playlist") : depth0), depth0))
    + "\">\n    <div id=\"video-player\" class=\"js-player\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"disallowPreviewPlayback") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"preview-playback-overlay\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"audio_preview") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"image_preview") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"oembed_preview") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"video_preview") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"useData":true});