(function() {
  "use strict";

  define(["flight/lib/component"], function(defineComponent) {
    return defineComponent(Discover);

    function Discover() {
      this.discoverSearch = function(ev, data) {
        this.getDiscoverResults(Routes.discover_search_path(), data);
      };

      this.getDiscoverResults = function(path, data) {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: path,
          data: data
        })
          .done(
            function(response) {
              this.trigger("dataGotDiscoverSearchResult", response);
            }.bind(this)
          )
          .fail(
            function() {
              this.trigger("dataDiscoverSearchError");
            }.bind(this)
          );
      };

      this.after("initialize", function() {
        this.on("uiNeedsSearchResults", this.discoverSearch);
      });
    }
  });
})();
