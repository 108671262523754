"use strict";

define([
  "$app/ui/shared/with_form_base",
  "$app/utils/currency_utils",
  "flight/lib/compose"
], function(WithFormBase, CurrencyUtils, compose) {
  return WithPayWhatYouWant;

  function WithPayWhatYouWant() {
    compose.mixin(this, [WithFormBase]);

    this.defaultAttrs({
      productPriceWrapperSelector: ".link_price_wrapper",
      payWhatYouWantSelectionSelector: ".js-pay-what-you-want-selection",
      priceRangeSelector: ".link_price_range",
      zeroPlusEnabled: false
    });

    this.setPriceRange = function(totalPrice) {
      var displayedPrice =
          totalPrice % 1 == 0 ? totalPrice : totalPrice.toFixed(2),
        $priceRange = this.select("priceRangeSelector");

      if (this.attr.isPayWhatYouWant) {
        displayedPrice += "+";
      }

      if (
        !$priceRange.data("suggested-price") ||
        $priceRange.data("suggested-price") < totalPrice
      ) {
        $priceRange.attr("placeholder", displayedPrice);
      }

      if (this.attr.isShowingPaymentForm) {
        $priceRange.val(totalPrice);
      }

      $priceRange.data("min-price", totalPrice);
    };

    this.validatePriceRange = function() {
      var $priceRange = this.select("priceRangeSelector"),
        min = parseFloat($priceRange.data("min-price")),
        perceivedPrice = CurrencyUtils.parseCurrencyString($priceRange.val()),
        priceRange = parseFloat(perceivedPrice || -1);

      if (
        typeof priceRange == "undefined" ||
        priceRange < min ||
        (min == 0 && priceRange < 0)
      ) {
        this.showError($priceRange);
        $priceRange.focus();
        return false;
      }

      return true;
    };

    this.toggleCreditCardForm = function() {
      var $priceRange = this.select("priceRangeSelector");

      if (
        ($priceRange.length > 0 && $priceRange.val().length == 0) ||
        this.select("paymentFormSelector").is(":animated") ||
        this.attr.isMultiBuyEnabled
      )
        return false;

      var price = parseFloat($priceRange.val());

      if (this.attr.zeroPlusEnabled) {
        this.trigger("uiNeedsToEvaluateCreditCardFormVisibility", price);
      }

      return false;
    };

    this.customizedPriceCents = function() {
      return this.validatePriceRangeString()
        ? CurrencyUtils.parseCurrencyString(
            this.select("priceRangeSelector").val()
          ) * (this.attr.isSingleUnit ? 1 : 100)
        : null;
    };

    this.validatePriceRangeString = function() {
      return !isNaN(
        parseFloat(
          CurrencyUtils.parseCurrencyString(
            this.select("priceRangeSelector").val()
          )
        )
      );
    };

    this.updateInitialPrice = function() {
      if (!$.isNumeric(this.attr.initialPrice)) {
        return;
      }

      this.select("priceRangeSelector").val(
        this.attr.initialPrice * this.attr.quantity
      );
      this.setPrice();
    };

    this.setInitialPayWhatYouWantPrice = function() {
      if (!this.attr.isPayWhatYouWant) return;

      if (this.attr.overlayPrice) {
        this.attr.initialPrice = this.attr.overlayPrice;
      } else {
        this.attr.initialPrice = this.getUrlParams().price;
      }

      this.updateInitialPrice();
    };

    this.after("initialize", function() {
      this.on("keyup", { priceRangeSelector: this.toggleCreditCardForm });
      this.on("keyup", { priceRangeSelector: this.setPrice });

      this.setInitialPayWhatYouWantPrice();
    });
  }
});
