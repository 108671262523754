"use strict";

define(["$app/templates/variant_category"], function(VariantCategoryTemplate) {
  return WithVariants;

  function parsePrice(numberOrString) {
    if (typeof numberOrString === "number") {
      return numberOrString;
    } else if (numberOrString == null) {
      return numberOrString;
    } else {
      return parseFloat(numberOrString.replace(/,/g, ""));
    }
  }

  function formatPrice(currencySymbol, prePrice) {
    let price = Math.max(prePrice, 0);
    return currencySymbol + (price % 1 == 0 ? price : price.toFixed(2));
  }

  function WithVariants() {
    this.defaultAttrs({
      variantSelectionSelector: ".js-variant-selection",
      variantInputSelector: ".js-variant-input",
      variantSelector: ".js-variant",
      variantOptionSelector: ".js-variant-option",
      selectedPriceChanges: []
    });

    this.setVariants = function() {
      if (this.attr.user.product.variants) {
        this.select("variantSelector").remove();

        if (
          this.attr.user.product.variants.skus &&
          this.attr.user.product.variants.skus.length > 0
        ) {
          var category = {
            name: this.attr.user.product.variants.skus_title,
            options: this.attr.user.product.variants.skus,
            i: 0
          };
          var $variantCategory = VariantCategoryTemplate(category),
            $variantSelection = this.select("variantSelectionSelector");
          $variantSelection.append($variantCategory);
        } else if (
          this.attr.user.product.variants.categories &&
          this.attr.user.product.variants.categories.length > 0
        ) {
          const categoryCount = this.attr.user.product.variants.categories
            .length;
          // When more than one, we use + so we don't need to re-calculate.
          if (categoryCount == 1) {
            const currencySymbol = this.select("productSelector").data(
              "currency-symbol"
            );
            const productPrice =
              this.attr.selectedPriceInfo.price_cents /
              (this.attr.isSingleUnit ? 1 : 100);
            const recurrenceFormatted =
              this.attr.selectedPriceInfo.recurrence_formatted || "";
            const offerCode = this.attr.user.product.offer_code;
            const isOfferCodeApplicable = this.isOfferCodeApplicable();
            const singleUnit = this.attr.isSingleUnit;

            this.attr.user.product.variants.categories.forEach(function(
              category
            ) {
              category.options.forEach(function(option) {
                // It's price_difference for SKUs and price for variants.
                let priceDifference = option.price || option.price_difference;

                let totalPrice =
                  parsePrice(priceDifference) + parsePrice(productPrice);
                let priceBeforeDiscount = totalPrice;

                if (isOfferCodeApplicable) {
                  if (offerCode.is_percent) {
                    totalPrice -= (
                      totalPrice *
                      (offerCode.amount / 100)
                    ).toFixed(singleUnit ? 0 : 2);
                  } else {
                    totalPrice -= offerCode.amount / (singleUnit ? 1 : 100);
                  }
                }

                option.displayed_price =
                  formatPrice(currencySymbol, totalPrice) + recurrenceFormatted;

                if (totalPrice != priceBeforeDiscount) {
                  option.displayed_original_price = formatPrice(
                    currencySymbol,
                    priceBeforeDiscount
                  );
                }
              });
            });
          }

          $.each(
            this.attr.user.product.variants.categories,
            function(i, category) {
              var $variantCategory = VariantCategoryTemplate(category),
                $variantSelection = this.select("variantSelectionSelector");
              $variantSelection.append($variantCategory);
            }.bind(this)
          );
        }
        if (this.attr.variantSelectionRequired) {
          delete this.attr.variantSelectionRequired;
        }

        this.setInitialVariant();

        this.attr.selectedPriceChanges = [];
        this.select("variantOptionSelector").map((_index, variantSelect) => {
          if ($(variantSelect).hasClass("selected"))
            this.updateSelectedPriceChanges($(variantSelect));
        });

        this.attr.variantsHaveBeenShown = true;

        this.setPurchaseNotice();
        this.setPrice();
      }
    };

    this.setPurchaseNotice = function() {
      var text = I18n.t("js.purchase_form_notice"),
        variantList = this.select("variantOptionSelector")
          .filter(".selected")
          .map(function() {
            return $(this).data("name");
          })
          .get();

      if (this.attr.isRecurringBilling) {
        text = I18n.t("js.subscription_signup_notice", {
          tier: variantList[0],
          recurrence: this.select("recurrenceSelectTriggerSelector").text()
        });
      } else if (variantList.length > 0) {
        text = I18n.t("js.purchase_form_notice_with_variants", {
          variants: variantList.join(", ")
        });
      }

      this.trigger("uiShouldUpdatePurchaseFormNotice", { text: text });
    };

    this.updateVariantSelection = function(ev) {
      ev.preventDefault();
      var $variant = $(ev.target || ev.srcElement);
      var $variantHolder = $variant.parents(this.attr.variantSelector);
      $variantHolder
        .children(this.attr.variantOptionSelector)
        .removeClass("selected");
      $variant.addClass("selected");
      this.updateSelectedPriceChanges($variant);
      this.setPrice();
      this.setPurchaseNotice();
      this.trigger("uiShouldResetQuantityUI");
    };

    this.updateSelectedPriceChanges = function($variant) {
      var $variantHolder = $variant.parents(this.attr.variantSelector);
      $variantHolder
        .children(this.attr.variantInputSelector)
        .val($variant.data("value"));
      var index = $variantHolder.data("var");
      this.attr.selectedPriceChanges[index] = [
        $variant.data("price-offset"),
        $variant.data("price-offset-cents")
      ];
    };

    this.preselectVariant = function() {
      const $variantOptions = this.select("variantOptionSelector");

      $.each(this.attr.initialVariants.split(","), function() {
        var initialVariantName = this;
        $variantOptions.each(function(_index, element) {
          if (
            initialVariantName.toLowerCase() ===
            $(element)
              .data("name")
              .toLowerCase()
          ) {
            $(element).click();
          }
        });
      });
    };

    this.preselectSku = function() {
      const initialSku = this.attr.initialVariants.replace(/,/g, " - ");
      const $variantOptions = this.select("variantOptionSelector");

      $variantOptions.each(function(_index, element) {
        if (
          $(element)
            .data("name")
            .toLowerCase() === initialSku.toLowerCase()
        ) {
          $(element).click();
        }
      });
    };

    this.setInitialVariant = function() {
      if (!this.attr.hasVariants) return;

      if (this.attr.overlayVariant) {
        this.attr.initialVariants = decodeURI(this.attr.overlayVariant);
      } else {
        this.attr.initialVariants = this.getUrlParams().variant;
      }

      if (!this.attr.initialVariants) {
        if (this.attr.overlayTier) {
          this.attr.initialVariants = decodeURI(this.attr.overlayTier);
        } else {
          this.attr.initialVariants = this.getUrlParams().tier;
        }
      }

      if (!this.attr.initialVariants) return;

      if (
        this.attr.user.product.variants.skus &&
        this.attr.user.product.variants.skus.length > 0
      ) {
        this.preselectSku();
      } else {
        this.preselectVariant();
      }
    };

    this.updateProductPriceForVariantsPreview = function(ev, data) {
      if (data.buy !== undefined || data.rent !== undefined) {
        if (data.buy != null && data.buy.length > 0) {
          this.attr.price = data.buy.replace("+", "");
        } else if (data.rent != null && data.rent.length > 0) {
          this.attr.price = data.rent.replace("+", "");
        }

        if (this.attr.variants) {
          this.updateVariantsForPreview(ev, {
            isPhysical: this.attr.isPhysical,
            currencySymbol: this.attr.currencySymbol,
            variants: this.attr.variants,
            skus: this.attr.skus
          });
        }
      }
    };

    this.updateVariantsForPreview = function(ev, data) {
      const { variants, skus, isPhysical, currencySymbol } = data;

      // Persisted to use when price changes.
      this.attr.variants = variants;
      this.attr.skus = skus;
      this.attr.isPhysical = isPhysical;
      this.attr.currencySymbol = currencySymbol;

      this.attr.hasVariants = variants.length > 0;

      if (!this.attr.user.product.variants) {
        this.attr.user.product.variants = {};
      }

      if (isPhysical) {
        this.attr.user.product.variants.categories = [
          {
            name: variants.map(variant => variant.name).join(" - "),
            options: skus,
            i: "0"
          }
        ];
        this.attr.user.product.variants.skus = [];
      } else {
        this.attr.user.product.variants.categories = variants;
      }

      const categoryCount = this.attr.user.product.variants.categories.length;
      const productPrice = this.attr.price;

      this.attr.user.product.variants.categories.forEach(function(category) {
        category.options.forEach(function(option) {
          // It's price_difference for SKUs and price for variants.
          let priceDifference = option.price_difference || option.price;

          if (categoryCount === 1) {
            option.displayed_price = formatPrice(
              currencySymbol,
              parsePrice(priceDifference) + parsePrice(productPrice)
            );
          } else {
            option.displayed_price =
              parsePrice(priceDifference) > 0
                ? "+" + formatPrice(currencySymbol, parsePrice(priceDifference))
                : undefined;
          }
        });
      });

      this.setVariants();
    };

    this.after("initialize", function() {
      this.on("click", { variantOptionSelector: this.updateVariantSelection });
      this.on(document, "uiUpdateVariants", this.updateVariantsForPreview);
      this.on(
        document,
        "uiUpdateProductPrice",
        this.updateProductPriceForVariantsPreview
      );
    });
  }
});
