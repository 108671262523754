"use strict";

define([
  "flight/lib/compose",
  "$app/utils/with_url",
  "$app/utils/with_social_share",
  "$app/ui/product/with_credit_card_on_payment_form",
  "$app/templates/receipt_entry",
  "$app/templates/total_receipt_entry",
  "$app/templates/helpers/t",
  "$app/templates/helpers/t_upcase"
], function(
  compose,
  WithUrl,
  WithSocialShare,
  WithCreditCard,
  ReceiptEntryTemplate,
  TotalReceiptEntryTemplate
) {
  return WithReceipt;

  function WithReceipt() {
    compose.mixin(this, [WithUrl, WithSocialShare, WithCreditCard]);

    this.defaultAttrs({
      receiptContainerSelector: ".receipt_container",
      receiptSelector: ".receipt",
      receiptEntriesSelector: ".receipt-entries",
      receiptEntryFailedSelector: ".js-receipt-entry-failed",
      receiptEntrySuccessfulSelector: ".js-receipt-entry-successful",
      receiptSuccessStateParagraphSelector: ".js-receipt-success-notice-p",
      emailReceiptFormSelector: ".email-receipt-form",
      emailedLinkNoticeSelector: ".emailed-link-notice",
      receiptPriceSelector: ".receipt-price",
      receiptShadowSelector: ".receipt_shadow",
      customTextSelector: ".js-receipt-custom-text",
      receiptCreateUserMsgSelector: ".receipt_create_user_p",
      addedToLibrarySelector: ".js-added-to-library",
      userPurchaseIdSelector: ".user_purchase_id",
      createAccountButtonSelector: ".js-create-account-button",
      userEmailSelector: ".user_email",
      userExpDateSelector: ".user_exp_date",
      userNumberSelector: ".user_number",
      userCvcSelector: ".user_cvc",
      twitterSelector: ".js-twitter-share-trigger",
      facebookSelector: ".js-facebook-share-trigger",
      viewReceiptSelector: ".js-view-receipt-container",
      receiptTwitterShareSelector:
        ".receipt_container .js-twitter-share-trigger",
      receiptFacebookShareSelector:
        ".receipt_container .js-facebook-share-trigger",
      passwordFieldSelector: ".js-password-field",
      libraryUrlSelector: ".js-library-url",
      customDeliveryUrlSelector: ".js-custom-delivery-url",
      customDeliveryRedirectDelayInSeconds: 5
    });

    this.setReceipt = function() {
      if (
        this.attr.onUserProfile &&
        this.$node.parents(".product-grid").length
      ) {
        return;
      }

      var $receipt = this.select("receiptSelector");

      this.select("receiptContainerSelector").css({
        position: "absolute",
        visibility: "hidden",
        display: "block"
      });
      var height = $receipt.height() + 30;
      this.select("receiptContainerSelector").css({
        position: "relative",
        visibility: "visible",
        display: "none"
      });
      $receipt.css({ marginTop: -height });
    };

    this.showReceipt = function(animate) {
      var $receiptContainer = this.select("receiptContainerSelector"),
        $receipt = this.select("receiptSelector"),
        $purchaseForm = this.select("purchaseFormSelector"),
        $payButtonContainer = this.select("payButtonContainerSelector"),
        $receiptShadow = this.select("receiptShadowSelector"),
        $changedMindButton = this.select("changedMindButtonSelector");

      this.setPaymentFormHeight(
        this.select("paymentFormSelector").outerHeight()
      );
      if (animate) {
        $purchaseForm.slideUp();
        $changedMindButton.fadeOut();
        $receiptContainer.animate({ "margin-top": 0 });
        $payButtonContainer.slideUp(function() {
          $receiptShadow.animate({ opacity: 1 }, 600, "linear");
          $receipt
            .animate({ "margin-top": 3 }, 600, "swing")
            .animate({ "margin-top": 0 }, 200, "linear");
        });

        $receiptContainer.height($receipt.height() + 45).show();
        this.select("paymentFormSelector").animate({
          height: $receiptContainer.height() + 45
        });
      } else {
        $changedMindButton.hide();
        $purchaseForm.hide();
        $receiptContainer.show();
        $receipt.show();
        $receiptContainer.css({ "margin-top": 0 });
        $payButtonContainer.hide();
        $receiptShadow.css({ opacity: 1 });
        $receipt.css({ "margin-top": 0 });
        $receiptContainer.height($receipt.height() + 45).show();
        // no need to set height as animate buy form happens right after.
      }

      this.attr.isShowingReceipt = true;
      this.startRedirect();
    };

    this.performRedirect = function() {
      clearInterval(this.redirectTimer);
      var url = this.select("customDeliveryUrlSelector").attr("href");
      if (this.attr.isOverlayPage) {
        this.trigger("uiNeedsToRedirectOverlayFromReceipt", { url: url });
      } else {
        window.location.href = url;
      }
    };

    this.showRedirectCountdown = function() {
      this.select("customDeliveryUrlSelector").text(
        I18n.t("js.custom_delivery_redirect", {
          seconds: this.attr.customDeliveryRedirectDelayInSeconds
        })
      );
    };

    this.startRedirect = function() {
      if (
        this.select("receiptEntryFailedSelector").length === 0 &&
        this.select("receiptEntrySuccessfulSelector").length === 1 &&
        this.select("customDeliveryUrlSelector").length === 1
      ) {
        this.showRedirectCountdown();
        this.redirectTimer = setInterval(
          function() {
            this.attr.customDeliveryRedirectDelayInSeconds -= 1;
            this.showRedirectCountdown();
            if (this.attr.customDeliveryRedirectDelayInSeconds <= 0) {
              this.performRedirect();
            }
          }.bind(this),
          1000
        );
      }
    };

    this.showCheckEmailReceipt = function() {
      this.select("receiptEntriesSelector").text(
        I18n.t("js.purchase_processing_check_email_notice")
      );

      this.showReceipt(true);
    };

    this.hideReceipt = function(animate) {
      var $receiptContainer = this.select("receiptContainerSelector"),
        $receipt = this.select("receiptSelector"),
        $purchaseForm = this.select("purchaseFormSelector"),
        $payButtonContainer = this.select("payButtonContainerSelector"),
        $payButton = this.select("payButtonSelector"),
        $receiptShadow = this.select("receiptShadowSelector"),
        $changedMindButton = this.select("changedMindButtonSelector");

      if (animate) {
        $changedMindButton.fadeIn();
        $receiptContainer.hide();
        $purchaseForm.show();
        $payButtonContainer.show();
        if (this.attr.purchaseWasGift) {
          this.toggleGiftPaymentForm(false);
          this.select("paymentFormSelector").stop();
        } else {
          this.select("paymentFormSelector").animate({
            height: this.select("paymentContainerSelector").outerHeight()
          });
        }
        $receiptContainer.show();
        $purchaseForm.hide();
        $payButtonContainer.hide();
        $payButton.text($payButton.data("default"));
        $receiptShadow.animate({ opacity: 0 }, 600, "linear");
        $receipt.animate(
          { "margin-top": -($receipt.height() + 45) },
          600,
          "swing"
        );

        setTimeout(function() {
          $purchaseForm
            .show()
            .css({ position: "relative", top: -($purchaseForm.height() + 40) });
          $receiptContainer.animate({ "margin-top": 20 });
          $payButtonContainer.show();
          $purchaseForm.animate({ top: 0 });
          $receiptContainer.height("auto").hide();
        }, 600);
      } else {
        $changedMindButton.show();
        $purchaseForm.show();
        $payButtonContainer.show();
        this.select("paymentFormSelector").height(this.getPaymentFormHeight());
        $receipt.show();
        $receiptContainer.css({ "margin-top": 20 });
        $receiptShadow.css({ opacity: 0 });
        $receipt.css({ "margin-top": -($receipt.height() + 45) });
        $receiptContainer.hide().height("auto");
      }
      this.attr.isShowingReceipt = false;
    };

    this.setPasswordPromptForAccountCreationInReceipt = function() {
      this.select("receiptCreateUserMsgSelector").text(
        I18n.t("js.enter_pwd_to_create_account_free")
      );
    };

    this.fillReceiptWithPurchaseDetails = function(purchase) {
      this.updateReceiptSuccessText();
      this.select("userPurchaseIdSelector").val(purchase.id);
      this.select("parentIdSelector").val(purchase.id);

      purchase.name = this.attr.name;
      this.select("receiptEntriesSelector").html(
        ReceiptEntryTemplate(purchase) + TotalReceiptEntryTemplate(purchase)
      );

      this.attr.user.purchase.parent_id = purchase.id;

      if (purchase.is_following) {
        this.showFollowing();
      }

      if (
        this.attr.user.is_logged_in ||
        (this.attr.payingWithPayPal &&
          this.attr.implementationInUse ==
            this.attr.PayPalImplementations.NATIVE)
      ) {
        this.select("emailReceiptFormSelector").hide();
      } else if (purchase.account_by_this_email_exists) {
        this.select("addedToLibrarySelector").show();
        this.select("emailReceiptFormSelector").hide();
      }
    };

    this.createAccount = function(ev) {
      ev.preventDefault();

      this.select("userEmailSelector").val(this.select("emailSelector").val());
      const $button = this.select("createAccountButtonSelector");
      $button.data("default", $button.text());
      $button.text("...");

      if (this.isCreditCardFormInUse()) {
        this.getCardParams(
          function(data) {
            this.createAccountWithCardParams(data.cardParams);
          }.bind(this)
        );
      } else {
        this.createAccountWithCardParams({});
      }
      return false;
    };

    this.createAccountWithCardParams = function(cardParams) {
      var serializedForm =
        this.select("emailReceiptFormSelector").serialize() +
        "&" +
        $.param({ user: cardParams });
      this.trigger("uiNeedsToCreateAccount", {
        permalink: this.attr.permalink,
        serializedForm: serializedForm
      });
    };

    this.reloadPage = function(ev, data) {
      if (this.attr.permalink == data.permalink) {
        location.reload(true);
      }
    };

    this.showAccountCreationError = function(ev, data) {
      if (this.attr.permalink == data.permalink) {
        this.select("receiptCreateUserMsgSelector")
          .text(data.message)
          .addClass("error");
        const $button = this.select("createAccountButtonSelector");
        $button.text($button.data("default"));
      }
    };

    this.updateReceiptSuccessText = function() {
      let localization_key =
        this.attr.totalPriceCents > 0
          ? "js.charged_card_and_sent_receipt_to_email"
          : "js.sent_receipt_to_email";

      this.select("receiptSuccessStateParagraphSelector").text(
        I18n.t(localization_key, {
          email: this.select("emailSelector").val()
        })
      );
    };

    this.updateReceiptSuccessTextForProductBundle = function() {
      let localization_key =
        this.attr.productsInBundle.reduce(
          (sum, product) => sum + product.price,
          0
        ) > 0
          ? "js.charged_card_and_sent_receipt_to_email"
          : "js.sent_receipt_to_email";

      this.select("receiptSuccessStateParagraphSelector").text(
        I18n.t(localization_key, {
          email: this.select("emailSelector").val()
        })
      );
    };

    this.updateSocialShareTextForProductBundle = function(
      succeededBundledProducts
    ) {
      var url = window.location.host + window.location.pathname,
        productUrl = [
          window.location.host,
          "l",
          this.attr.productsInBundle[0].permalink
        ].join("/"),
        $facebook = this.select("facebookSelector"),
        $twitter = this.select("twitterSelector");
      if (this.attr.isOverlayPage) {
        url = this.attr.overlayPageShareUrl || productUrl;
      } else if (this.attr.productsInBundle.length == 1) {
        url = productUrl;
      }
      $facebook.data("url", url);
      if (succeededBundledProducts.length == 2) {
        var twitterShareText = I18n.t(
          "js.twitter_share_text_for_multi_buy.counting",
          {
            name: succeededBundledProducts[0],
            another_name: succeededBundledProducts[1],
            count: 1,
            url: url
          }
        );

        $twitter.attr("href", this.getTwitterShareUrl(twitterShareText));
      } else if (succeededBundledProducts.length > 0) {
        var twitterShareText = I18n.t(
          "js.twitter_share_text_for_multi_buy.counting",
          {
            name: succeededBundledProducts[0],
            count: succeededBundledProducts.length - 1,
            url: url
          }
        );

        $twitter.attr("href", this.getTwitterShareUrl(twitterShareText));
      } else {
        $twitter.hide();
        $facebook.hide();
      }
    };

    this.showFollowing = function() {
      this.trigger("uiShowFollowing");
    };

    this.updateCustomText = function(ev, data) {
      if (data.text.length > 0) {
        var receipt_content = $("<div></div>")
          .text(data.text)
          .html();
        this.select("customTextSelector").html(
          "<p>" + receipt_content.replace(/\n/g, "</p><p>") + "</p>"
        );
        this.select("customTextSelector").show();
      } else {
        this.select("customTextSelector").hide();
      }

      this.trigger("uiAnimatePaymentFormHeight", { animate: false });
    };

    this.initializeModalShareURLs = function() {
      var $twitter = this.select("receiptTwitterShareSelector"),
        $facebook = this.select("receiptFacebookShareSelector"),
        url = this.getUrlParams()["source_url"];

      if (
        typeof url != "undefined" &&
        $twitter.length > 0 &&
        $facebook.length > 0
      ) {
        $twitter.attr(
          "href",
          $twitter.attr("href").replace(/%20.*$/, "%20" + url)
        );
        $facebook.attr("data-url", url);
      }
    };

    this.hideReceiptWithoutAnimation = function(ev) {
      ev.preventDefault();

      this.hideReceipt(false);

      return false;
    };

    this.showReceiptExample = function(ev, data) {
      ev.preventDefault();

      if (data) {
        this.fillReceiptWithPurchaseDetails(data);
      }

      this.showReceipt(false);

      return false;
    };

    this.updateReceiptHeight = function() {
      this.showReceipt(false);
    };

    this.showMobileAppPopover = function() {
      this.select("productInformationSelector").hide();
      this.select("previewContainerSelector").hide();
      this.select("paymentFormSelector").css({ overflow: "visible" });
      this.select("productSelector").css({ overflow: "visible" });
      this.select("productMainSelector").css({ overflow: "visible" });
      this.select("receiptContainerSelector").css({ overflow: "visible" });
    };

    this.hideMobileAppPopover = function() {
      this.select("productInformationSelector").show();
      this.select("previewContainerSelector").show();
      this.select("paymentFormSelector").css({ overflow: "hidden" });
      this.select("productSelector").css({ overflow: "hidden" });
      this.select("productMainSelector").css({ overflow: "hidden" });
      this.select("receiptContainerSelector").css({ overflow: "hidden" });
    };

    this.setOverlayPageShareUrl = function(ev, data) {
      this.attr.isOverlayPage = true;
      if (data.url) {
        this.attr.overlayPageShareUrl = data.url;
      }
    };

    this.after("initialize", function() {
      this.on(document, "dataAccount", this.reloadPage);
      this.on(
        document,
        "dataFailedToCreateAccount",
        this.showAccountCreationError
      );
      this.on(document, "uiUpdateReceiptCustomText", this.updateCustomText);
      this.on(document, "uiShowReceipt", this.showReceiptExample);
      this.on(document, "uiUpdateReceiptHeight", this.updateReceiptHeight);
      this.on(document, "uiHideReceipt", this.hideReceipt);
      this.on(
        document,
        "uiHideReceiptWithoutAnimation",
        this.hideReceiptWithoutAnimation
      );
      this.on(
        document,
        "uiNeedsToDisplayMobileAppPopover",
        this.showMobileAppPopover
      );
      this.on(
        document,
        "uiNeedsToHideMobileAppPopover",
        this.hideMobileAppPopover
      );
      this.on(
        document,
        "uiSetOverlayPageShareUrl",
        this.setOverlayPageShareUrl
      );

      this.on("click", {
        createAccountButtonSelector: this.createAccount
      });

      if (this.attr.isModal) {
        this.initializeModalShareURLs();
      }
    });
  }
});
