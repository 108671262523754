"use strict";

define([
  "flight/lib/component",
  "$app/ui/shared/with_bundled_product_list",
  "$app/ui/shared/with_persistent_bundle",
  "$app/ui/product/third_party_analytics",
  "$app/ui/product/with_payment_form",
  "$app/ui/product/with_credit_card_on_payment_form",
  "$app/ui/product/with_offer_code",
  "$app/ui/product/with_payment_blurb",
  "$app/ui/product/with_receipt",
  "$app/ui/product/with_variants",
  "$app/templates/receipt_entry",
  "$app/templates/buyer_bar",
  "$app/templates/custom_field_set",
  "$app/templates/try_again",
  "$app/utils/gumroad_utils",
  "$app/utils/with_sha",
  "$app/templates/helpers/t",
  "$app/templates/helpers/t_upcase"
], function(
  defineComponent,
  WithBundledProductList,
  WithPersistentBundle,
  ThirdPartyAnalytics,
  WithPaymentForm,
  WithCreditCard,
  WithOfferCode,
  WithPaymentBlurb,
  WithReceipt,
  WithVariants,
  ReceiptEntryTemplate,
  BuyerBarTemplate,
  CustomFieldSetTemplate,
  TryAgainTemplate,
  GumroadUtils,
  WithSha
) {
  return defineComponent(
    BundleButton,
    WithBundledProductList,
    WithPersistentBundle,
    WithPaymentForm,
    WithCreditCard,
    WithOfferCode,
    WithPaymentBlurb,
    WithReceipt,
    WithVariants,
    WithSha
  );

  function BundleButton() {
    this.defaultAttrs({
      bundleCircleSelector: ".js-bundle-circle",
      bundleTooltipTriggerSelector: ".js-bundle-tooltip-trigger",
      bundleStartCheckoutButtonSelector: ".js-bundle-start-trigger",
      bundleCancelCheckoutButtonSelector: ".js-bundle-cancel-trigger",
      bundlePayButtonSelector: ".js-bundle-pay-trigger",
      bundleToolTipSelector: ".js-bundle-tool-tip",
      bundledListContainerSelector: ".js-bundled-product-list-holder",
      bundleCheckoutFormSelector: ".js-bundle-checkout-form",
      bundleMessageSelector: ".js-bundle-message",
      buyerBarContentSelector: ".js-buyer-bar-content",
      customFieldsContainerSelector: ".js-custom-fields-container",
      customFieldSetSelector: ".js-custom-field-set",
      offerCodeContainerSelector: ".js-offer-code-container",
      tryAgainSelector: ".js-try-again",
      bundleGiftTriggerSelector: ".js-bundle-gift-trigger",
      showingCheckout: false,
      productsInBundle: [],
      bundleSubtotalSelector: ".js-bundle-subtotal-amount",
      bundleCloseButtonSelector: ".js-bundle-close",
      bundleCartSelector: ".js-bundle-cart",
      bundleOverlaySelector: ".js-bundle-overlay"
    });

    this.showProductCountInCircle = function() {
      if (this.select("bundleCircleSelector").length === 0) {
        this.select("buyerBarContentSelector").html(
          BuyerBarTemplate($(".js-login").data("user") || {})
        );
      }
      if (this.attr.productsInBundle.length > 0) {
        this.select("bundleCircleSelector").text(
          this.attr.productsInBundle.length
        );
        this.select("bundleTooltipTriggerSelector").addClass("showing");
        this.select("bundleToolTipSelector").addClass("showing");
        this.select("bundleOverlaySelector").addClass("bundle-overlay");
      } else {
        this.select("bundleCircleSelector").text("");
        this.select("bundleTooltipTriggerSelector").removeClass("showing");
        this.select("bundleToolTipSelector").removeClass("showing");
        this.select("bundleOverlaySelector").removeClass("bundle-overlay");
      }
    };

    this.updateBundleButton = function() {
      this.updateBundleMessage();
      this.setCardSaveRequired(this.bundleNeedsCardSaved());

      if (this.attr.user && this.attr.user.is_logged_in) {
        this.select("keepOnFileSelector").show();
      } else {
        this.select("keepOnFileSelector").hide();
      }

      if (this.bundleContainsOfferCodeField()) {
        this.select("offerCodeContainerSelector").show();
      } else {
        this.select("offerCodeContainerSelector").hide();
      }

      if (this.attr.onUserProfile && this.bundleIsGiftable()) {
        this.select("bundleGiftTriggerSelector").show();
      } else {
        this.select("bundleGiftTriggerSelector").hide();
      }

      if (this.attr.productsInBundle.length > 0) {
        this.select("bundleCircleSelector").text(
          this.attr.productsInBundle.length
        );
        // Fetch braintree client token for payment from discover page if there are any products in the bundle
        if (
          $('meta[property="gr:paypal:pay_with_paypal_enabled"]').attr(
            "value"
          ) === "true"
        ) {
          this.trigger("uiNeedsBraintreeClientTokenFetched", {
            isOverlay: this.attr.isOverlay,
            isMultiBuyEnabled:
              this.attr.onMultiOverlay || this.attr.isMultiBuyEnabled
          });
        }
        if (
          this.totalBundlePriceCents() > 0 ||
          this.totalBundleShippingRateCents() > 0
        ) {
          this.showCreditCardForm();
        } else {
          this.hideCreditCardForm();
        }

        if (this.productsWithShipping() > 0) {
          this.showShippingInputs();
        } else {
          this.hideShippingInputs();
        }

        this.initiatePriceInformationUpdate();

        if (this.attr.showingCheckout) {
          this.$node.addClass("showing-checkout").show();
        } else {
          this.$node.removeClass("showing-checkout");
        }
        this.$node.addClass("showing-bundle").show();
        this.trigger("uiNeedsToHideBuyerMenu");
      }
      this.showProductCountInCircle();
      this.trigger("uiNeedsToSetPersistentBundle", {
        products: this.attr.productsInBundle
      });
      this.updateBundleSubtotal();
    };

    this.updateBundleSubtotal = function() {
      this.select("bundleSubtotalSelector").text(
        this.formattedUSDTotalBundlePrice()
      );
    };

    this.updateBundleMessage = function() {
      this.select("bundleMessageSelector").text(
        I18n.t("js.back_to_bundle_product_count", {
          count: this.attr.productsInBundle.length
        })
      );
    };

    this.toggleBundleTooltip = function(ev) {
      ev.preventDefault();

      this.select("bundleToolTipSelector").toggleClass("showing");
      if (this.select("bundleToolTipSelector").hasClass("showing")) {
        this.$node.addClass("showing-bundle");
        this.select("bundleOverlaySelector").addClass("bundle-overlay");
        this.trigger("uiNeedsToHideBuyerMenu");
      } else {
        this.$node.removeClass("showing-bundle");
        this.select("bundleOverlaySelector").removeClass("bundle-overlay");
      }
    };

    this.hideBundle = function(ev) {
      this.select("bundleToolTipSelector").removeClass("showing");
      this.$node.removeClass("showing-bundle");
      this.select("bundleOverlaySelector").removeClass("bundle-overlay");
    };

    this.addProductToBundle = function(ev, data) {
      if (this.attr.productsInBundle.length == 0) {
        this.attr.failedBundledProducts = [];
        this.attr.succeededBundledProducts = [];
        this.attr.numProductsInBundlePaymentCompleted = 0;
        this.attr.receiptEntries = "";
        this.attr.errorReceiptEntries = "";
      }
      if (data.customFields && data.customFields.length > 0) {
        this.select("customFieldsContainerSelector")
          .append(CustomFieldSetTemplate(data))
          .show();

        if (this.select("urlParamsSelector").val()) {
          const urlParams = JSON.parse(this.select("urlParamsSelector").val());
          const permalink = this.attr.permalink || data.permalink;

          data.customFields.map(customField => {
            if (customField.name in urlParams) {
              this.select("customFieldSetSelector")
                .filter('[data-permalink="' + permalink + '"]')
                .find("input")
                .filter('[name="' + customField.name + '"]')
                .val(urlParams[customField.name]);
            }
          });
        }
      }

      delete data.sha;
      data.sha = this.stringToSha(JSON.stringify(data));

      this.attr.productsInBundle.push(data);
      this.attr.sellerId = data.sellerId;

      this.updateBundleButton();
    };

    this.removeProductFromBundle = function(ev, data) {
      this.attr.productsInBundle.splice(data.index, 1);

      if (this.select("customFieldSetSelector").get(data.index)) {
        this.select("customFieldSetSelector")
          .get(data.index)
          .remove();
      }
      if (this.select("customFieldSetSelector").length < 1) {
        this.select("customFieldsContainerSelector").hide();
      }
      this.updateBundleButton();
    };

    this.totalBundlePriceCents = function() {
      var totalPrice = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        totalPrice += this.attr.productsInBundle[i].perceivedPriceCents;
      }

      return totalPrice;
    };

    this.totalBundlePrice = function() {
      var totalPrice = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i];
        totalPrice += this.toDisplayCurrency(
          product.perceivedPriceCents,
          product.isSingleUnit,
          product.exchangeRate
        );
      }

      return totalPrice;
    };

    this.formattedUSDTotalBundlePrice = function(cents) {
      return "US$" + Math.round(this.totalBundlePrice() * 100) / 100;
    };

    this.totalBundleTax = function() {
      var totalTax = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i];
        totalTax += this.toDisplayCurrency(
          parseInt(this.attr.productsInBundle[i].taxCents),
          product.isSingleUnit,
          product.exchangeRate
        );
      }

      return totalTax;
    };

    this.updateCustomerSurchargeForBundle = function(ev, data) {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].permalink == data.permalink) {
          var product = this.attr.productsInBundle[i];

          product["taxCents"] = data.tax_cents;
          product["isTaxExclusive"] = data.is_price_tax_exclusive;
          product["isVat"] = data.tax_info && data.tax_info.is_vat;
          product["taxCountry"] = data.tax_info && data.tax_info.country;
          product["shippingRateCents"] = data.shipping_rate_cents;
          product["formattedShippingRate"] = data.formatted_shipping_rate;
          product["businessVatStatus"] = data.business_vat_status;
        }
      }

      this.updateCustomerSurcharge();
      this.updateShippingRate();
    };

    this.totalBundleShippingRateDisplayCurrencyCents = function() {
      var totalShippingCents = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i],
          shippingCents = parseInt(
            this.attr.productsInBundle[i].shippingRateCents
          );

        totalShippingCents += this.toDisplayCurrencyCents(
          shippingCents,
          product.isSingleUnit,
          product.exchangeRate
        );
      }

      return totalShippingCents;
    };

    this.totalBundleShippingRate = function() {
      var totalShipping = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i],
          shippingCents = parseInt(
            this.attr.productsInBundle[i].shippingRateCents
          );

        totalShipping += this.toDisplayCurrency(
          shippingCents,
          product.isSingleUnit,
          product.exchangeRate
        );
      }

      return totalShipping;
    };

    this.totalBundleShippingRateCents = function() {
      var totalShippingRate = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        totalShippingRate += parseInt(
          this.attr.productsInBundle[i].shippingRateCents
        );
      }

      return totalShippingRate;
    };

    this.productsWithExchangeRates = function() {
      var totalProducts = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (parseInt(this.attr.productsInBundle[i].exchangeRate) != 1)
          totalProducts += 1;
      }

      return totalProducts;
    };

    this.bundleExclusiveTax = function() {
      var exclusive_tax = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (
          this.attr.productsInBundle[i].isTaxExclusive &&
          !this.attr.productsInBundle[i].isVat
        ) {
          var product = this.attr.productsInBundle[i],
            exclusive_tax_local = parseInt(product.taxCents);

          exclusive_tax += this.toDisplayCurrency(
            exclusive_tax_local,
            product.isSingleUnit,
            product.exchangeRate
          );
        }
      }

      return exclusive_tax;
    };

    this.bundleBusinessVatStatus = function() {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i];
        var businessVatStatus = product.businessVatStatus;

        if (businessVatStatus) {
          return businessVatStatus;
        }
      }

      return null;
    };

    this.bundleVat = function() {
      var vat = 0;
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].isVat) {
          var product = this.attr.productsInBundle[i],
            vatLocalCents = parseInt(product.taxCents);

          vat += this.toDisplayCurrency(
            vatLocalCents,
            product.isSingleUnit,
            product.exchangeRate
          );
        }
      }

      return vat;
    };

    this.bundleExclusiveVat = function() {
      var vat = 0;
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (
          this.attr.productsInBundle[i].isTaxExclusive &&
          this.attr.productsInBundle[i].isVat
        ) {
          var product = this.attr.productsInBundle[i],
            vatLocalCents = parseInt(product.taxCents);

          vat += this.toDisplayCurrency(
            vatLocalCents,
            product.isSingleUnit,
            product.exchangeRate
          );
        }
      }

      return vat;
    };

    this.bundleTaxCountry = function() {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].taxCountry)
          return this.attr.productsInBundle[i].taxCountry;
      }

      return false;
    };

    this.productsWithShipping = function() {
      var totalProducts = 0;

      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].shippingRequired) totalProducts += 1;
      }

      return totalProducts;
    };

    this.bundleContainsSubscriptions = function() {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].isRecurringBilling) {
          return true;
        }
      }
      return false;
    };

    this.bundleContainsPreOrders = function() {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].isPreorder) {
          return true;
        }
      }
      return false;
    };

    this.bundleIsGiftable = function() {
      return this.attr.productsInBundle.every(
        product => !product.isPreorder && !product.isRecurringBilling
      );
    };

    this.bundleContainsOfferCodeField = function() {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        if (this.attr.productsInBundle[i].hasOfferCodeField) {
          return true;
        }
      }
      return false;
    };

    this.bundleNeedsCardSaved = function() {
      return (
        this.bundleContainsSubscriptions() || this.bundleContainsPreOrders()
      );
    };

    this.startBundleCheckout = function(ev) {
      ev && ev.preventDefault();

      this.showStripeElements();
      this.trigger("uiNeedsToStartBundleCheckout");
      this.attr.showingCheckout = true;
      this.select("bundledListContainerSelector").slideUp();
      this.select("bundleCheckoutFormSelector").slideDown();
      this.updateBundleButton();
    };

    this.cancelBundleCheckout = function(ev) {
      ev && ev.preventDefault();

      this.attr.showingCheckout = false;

      this.select("bundledListContainerSelector").slideDown();
      this.select("bundleCheckoutFormSelector").slideUp(
        function() {
          this.trigger("uiNeedsToCancelBundleCheckout");
        }.bind(this)
      );

      this.updateBundleButton();
    };

    this.initiateValidationForProductBundle = function(ev) {
      ev && ev.preventDefault();

      if (this.validateBundleCheckoutForm()) {
        this.select("bundledListContainerSelector").slideUp();
        this.showPaymentAsProcessing();
        if (GumroadUtils.isExternalPresentation()) {
          this.continueWithPayment();
        } else {
          this.verifyShippingAddress();
        }
      }

      return false;
    };

    this.completeAccountCreation = function(ev, response) {
      this.select("buyerBarContentSelector").html(
        BuyerBarTemplate(response.buyer_bar_data)
      );
    };

    this.failedAccountCreation = function(ev, data) {
      this.trigger("uiBundledProductPaymentFailed", data);
    };

    this.makePaymentForProductBundle = function() {
      this.setPaymentAsProcessingForMultiBuyMode();
      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: "process_payment"
      });

      this.trigger("uiSetFriendValue");

      this.setCreditCardFormInUse(
        this.totalBundlePriceCents() != 0 ||
          this.totalBundleShippingRateCents() != 0
      );

      this.getReusableCardParams(
        function(data) {
          this.makePaymentForProducts(data.cardParams);
        }.bind(this)
      );

      return false;
    };

    this.makePaymentForProducts = function(reusableCardParams) {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var product = this.attr.productsInBundle[i];
        this.makePaymentForProduct(product, reusableCardParams);
      }
    };

    this.makePaymentForProduct = function(product, reusableCardParams) {
      if (product.price == 0 && product.shippingRateCents == 0) {
        this.makePaymentForProductWithCard(product, {});
      } else {
        if (reusableCardParams) {
          this.makePaymentForProductWithCard(product, reusableCardParams);
        } else {
          this.getCardParams(
            function(data) {
              this.makePaymentForProductWithCard(product, data.cardParams);
            }.bind(this)
          );
        }
      }
    };

    this.makePaymentForProductWithCard = function(product, card) {
      var dataToBeSerialized = {
        permalink: product.permalink,
        is_multi_buy: "true",
        is_gift: this.select("giftSignifierSelector").val(),
        cc_zipcode_required: this.select("ccZipcodeRequiredSelector").val(),
        cc_zipcode: this.select("ccZipcodeFieldSelector").val(),
        perceived_price_cents: product.perceivedPriceCents,
        is_preorder: product.isPreorder,
        is_physical: product.isPhysical,
        offer_code_name: product.offerCodeName ? product.offerCodeName : "",
        was_product_recommended: product.wasProductRecommended,
        recommended_by: product.recommendedBy
      };
      if (product.variants) {
        $.each(product.variants, function(index, variant) {
          dataToBeSerialized["variants[" + index + "]"] = variant;
        });
      }

      this.select("customFieldSetSelector")
        .filter('[data-permalink="' + product.permalink + '"]')
        .find("input")
        .each(function(idx, customField) {
          dataToBeSerialized[
            encodeURIComponent(
              "custom_fields[" + $(customField).attr("name") + "]"
            )
          ] = $(customField).val();
        });

      if (typeof product.priceRange != "undefined") {
        dataToBeSerialized.price_range = product.priceRange;
      }

      var serializedData =
        this.getSerializedBundleForm() +
        "&" +
        this.simpleSerialize(dataToBeSerialized) +
        "&" +
        $.param(card) +
        "&" +
        $.param({ tax_country_election: this.attr.taxCountry }) +
        "&" +
        $.param({ vat_id: this.attr.vatId }) +
        "&" +
        $.param({ is_rental: product.isRentalModeActive }) +
        "&" +
        $.param({ quantity: product.quantity });
      if (product.isRecurringBilling) {
        serializedData +=
          "&" + $.param({ price_id: product.selectedPriceInfo.id });
      }

      this.trigger("uiNeedsToTrackProductSpecificEvent", {
        name: "process_payment",
        permalink: product.permalink
      });

      this.trigger("uiNeedsToMakePayment", {
        permalink: product.permalink,
        is_bundled: true,
        payingWithPayPal: this.attr.payingWithPayPal,
        paypalOrderDetails: this.attr.paypalOrderDetails,
        productsInBundle: this.attr.productsInBundle,
        name: product.name,
        formattedPrice: product.currency + product.formattedPrice,
        serializedForm: serializedData,
        sha: product.sha
      });
    };

    this.handleCompletionForBundledProduct = function(ev, data) {
      var receiptEntry = ReceiptEntryTemplate(data);
      if (data.error_message) {
        this.attr.errorReceiptEntries += receiptEntry;
        this.attr.failedBundledProducts.push(data.permalink);
      } else {
        this.attr.receiptEntries += receiptEntry;
        this.attr.succeededBundledProducts.push(data.name);

        if (data.success) {
          if (data.has_third_party_analytics) {
            this.trigger("uiThirdPartyAnalyticsEnabled");
            this.trigger(this.$node, "uiToAddThirdPartyAnalytics", {
              permalink: data.permalink,
              purchase_id: data.id
            });
          }

          if (!this.attr.onDiscoverPage && !this.attr.onMultiOverlay) {
            this.trigger("uiNeedsToTrackActivityWithSellerAnalytics", {
              permalink: data.permalink,
              action: "purchased",
              value: data.non_formatted_price,
              valueIsSingleUnit: this.attr.isSingleUnit,
              currency: data.currency_type.toUpperCase()
            });
            this.trigger("uiNeedsToTrackECommerceEventWithSellerAnalytics", {
              permalink: data.permalink,
              purchase_external_id: data.id,
              currency: data.currency_type.toUpperCase(),
              product_name: data.name,
              price: data.non_formatted_price,
              priceIsSingleUnit: this.attr.isSingleUnit,
              quantity: data.quantity,
              tax: data.non_formatted_seller_tax_amount
            });
          }
        }
      }

      this.attr.numProductsInBundlePaymentCompleted++;

      if (
        this.attr.numProductsInBundlePaymentCompleted ==
        this.attr.productsInBundle.length
      ) {
        this.showProductBundleReceipt();

        this.attr.failedBundledProducts = [];
        this.attr.succeededBundledProducts = [];
      }
    };

    this.handleErrorForBundledProduct = function(ev, data) {
      var error_message =
        data && data.error_message
          ? data.error_message
          : I18n.t("js.something_went_wrong_notice");
      data.error_message = error_message;

      if (data.zipcode_required) {
        this.select("bundlePayButtonSelector")
          .prop("disabled", false)
          .text(I18n.t("js.pay_prompt"));
        this.select("receiptContainerSelector").fadeOut();
      } else if (data.is_tax_mismatch) {
        this.select("bundlePayButtonSelector")
          .prop("disabled", false)
          .text(I18n.t("js.pay_prompt"));
        this.select("receiptContainerSelector").fadeOut();
        this.showNewVatCountryOptions(ev, data);
      } else {
        this.trigger("uiBundledProductPaymentCompleted", data);
      }
    };

    this.handleTimeoutForBundledProduct = function(ev, data) {
      data.extra_purchase_notice = I18n.t(
        "js.purchase_with_name_processing_check_email_notice",
        { name: data.name }
      );

      this.trigger("uiNeedsToRemoveProductFromPersistentBundle", data);
      this.trigger("uiBundledProductPaymentCompleted", data);
    };

    this.showProductBundleReceipt = function() {
      this.select("bundleMessageSelector").text(
        I18n.t("js.back_to_bundle_product_count_completed", {
          count: this.attr.succeededBundledProducts.length
        })
      );

      this.select("bundleCancelCheckoutButtonSelector").hide();

      this.trigger("completedBundle");

      this.updateReceiptSuccessTextForProductBundle();
      this.updateSocialShareTextForProductBundle(
        this.attr.succeededBundledProducts
      );
      this.select("receiptEntriesSelector").html(
        this.attr.receiptEntries + this.attr.errorReceiptEntries
      );
      if (
        this.attr.failedBundledProducts &&
        this.attr.failedBundledProducts.length > 0
      ) {
        this.select("receiptEntriesSelector").append(
          TryAgainTemplate({ message: I18n.t("js.try_again_message") })
        );
      }
      this.showReceipt(false);
    };

    this.tryAgain = function() {
      this.attr.productsInBundle = [];
      this.trigger("uiNeedsToResetBundledList");
      this.select("purchaseFormSelector").show();
      this.select("bundleCancelCheckoutButtonSelector").show();
      this.select("receiptEntriesSelector").empty();
      this.hideReceipt();
      this.trigger("uiNeedsToStartBundleCheckout");
      this.updateBundleButton();
    };

    this.getSerializedBundleForm = function() {
      var formSelector =
        "textarea, input:not(" +
        this.attr.utf8Selector +
        ", " +
        this.attr.authenticityTokenSelector +
        ", " +
        this.attr.permalinkSelector +
        ", " +
        this.attr.isPreorderSelector +
        ", " +
        this.attr.isPhysicalSelector +
        ", " +
        this.attr.parentIdSelector +
        ", " +
        this.attr.saveCardSelector;

      // Remove country if not visible, as it is always part of the form on bundled purchases:
      if (this.productsWithShipping() == 0) {
        formSelector +=
          ", " +
          this.attr.zipCodeSelector +
          "), select:not(" +
          this.attr.countrySelector +
          ")";
      } else {
        formSelector += "), select";
      }

      return this.select("bundleCheckoutFormSelector")
        .find(formSelector)
        .serialize();
    };

    this.setCurrentUserInfo = function(ev, data) {
      if (!this.attr.isCurrentUserSet) {
        this.attr.user = data;

        this.setCreditCard();
        this.setEmail();

        this.attr.isCurrentUserSet = true;
      }
    };

    this.simpleSerialize = function(obj) {
      var result = [];
      for (var key in obj) {
        result.push(
          key + "=" + encodeURIComponent(obj[key]).replace(/%20/g, "+")
        );
      }
      return result.join("&");
    };

    this.attachThirdPartyAnalytics = function() {
      ThirdPartyAnalytics.attachTo(this.$node);
    };

    this.addOfferCodeToBundle = function(_ev, data) {
      this.updateOfferCode(null, data);
    };

    this.after("initialize", function() {
      this.on(document, "dataCurrentUserInfo", this.setCurrentUserInfo);
      this.on(document, "dataAccount", this.completeAccountCreation);
      this.on(
        document,
        "dataFailedToCreateAccount",
        this.failedAccountCreation
      );
      this.on(document, "addProductToBundle", this.addProductToBundle);
      this.on(
        document,
        "removeProductFromBundle",
        this.removeProductFromBundle
      );
      this.on(
        document,
        "uiBundledProductPaymentCompleted",
        this.handleCompletionForBundledProduct
      );
      this.on(
        document,
        "uiBundledProductPaymentFailed",
        this.handleErrorForBundledProduct
      );
      this.on(
        document,
        "uiBundledProductPaymentTimeout",
        this.handleTimeoutForBundledProduct
      );
      this.on(
        document,
        "dataCustomerSurchargeForBundle",
        this.updateCustomerSurchargeForBundle
      );
      this.on(
        document,
        "uiThirdPartyAnalyticsEnabled",
        this.attachThirdPartyAnalytics
      );
      this.on(
        document,
        "uiShowOverlayCreditCardForm",
        this.startBundleCheckout
      );
      this.on(document, "uiShouldHideBundle", this.hideBundle);
      this.on(
        document,
        "uiNeedsToShowProductCountInCircle",
        this.showProductCountInCircle
      );
      this.on(
        document,
        "uiNeedsToAddOfferCodeToBundle",
        this.addOfferCodeToBundle
      );
      this.on("click", {
        bundlePayButtonSelector: this.initiateValidationForProductBundle,
        bundleStartCheckoutButtonSelector: this.startBundleCheckout,
        bundleCancelCheckoutButtonSelector: this.cancelBundleCheckout,
        bundleTooltipTriggerSelector: this.toggleBundleTooltip,
        tryAgainSelector: this.tryAgain,
        bundleCloseButtonSelector: this.hideBundle,
        bundleCartSelector: this.toggleBundleTooltip,
        bundleOverlaySelector: this.hideBundle
      });
    });
  }
});
