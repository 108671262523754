"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(CardData);

  function CardData() {
    this.defaultAttrs({
      cardDataHandlingMode: null
    });

    this.prepareCardData = function(ev, data) {
      const responseData = {
        cardParams: {
          card_data_handling_mode: this.attr.cardDataHandlingMode
        }
      };

      // associated data passed in will be passed unaltered to the response event
      if (typeof data.associatedData !== "undefined") {
        responseData.associatedData = data.associatedData;
      }

      const impl = {
        "stripejs.0": this.prepareCardDataStripeJs_0
      };

      const cardDataHandlingMode = this.attr.cardDataHandlingMode.split(",")[0];

      if (typeof impl[cardDataHandlingMode] !== "undefined") {
        impl[cardDataHandlingMode].call(this, data, responseData);
      }

      return false;
    };

    this.prepareCardDataStripeJs_0 = function(data, responseData) {
      const tokenData = {
        address_zip:
          typeof data.cardData.zipCode === "undefined"
            ? ""
            : data.cardData.zipCode,
        currency: "usd" // Set so that we can use the card for payouts. Has no affect at Stripe on Charges, etc.
      };

      window.grStripe
        .createToken(data.cardData.cardNumber, tokenData)
        .then(result => {
          this.stripeTokenHandler(
            result,
            data.cardData.cardNumber,
            responseData
          );
        })
        .catch(error => {
          console.log("error", error);
        });
    };

    this.stripeTokenHandler = function(
      result,
      cardNumberElement,
      responseData
    ) {
      if (result.error) {
        responseData.cardParams.stripe_error = result.error;
      } else {
        responseData.cardParams.stripe_token = result.token.id;
        responseData.cardParams.card_country = result.token.card.country;
        responseData.cardParams.card_country_source = $(
          'meta[property="gr:stripe:card_country_source"]'
        ).attr("value");
      }
      this.trigger("dataCardPrepared", responseData);
    };

    this.after("initialize", function() {
      this.on("uiNeedsCardPrepared", this.prepareCardData);
    });
  }
});
