"use strict";

define(["handlebars"], function(Handlebars) {
  function I18nUpcaseHelper(key, args) {
    key = "js." + key;
    if (args == undefined) {
      return I18n.t(key).toUpperCase();
    } else {
      return I18n.t(key, args).toUpperCase();
    }
  }
  Handlebars.registerHelper("t_upcase", I18nUpcaseHelper);
  return I18nUpcaseHelper;
});
