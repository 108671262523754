"use strict";

define([
  "flight/lib/compose",
  "$app/ui/shared/with_player",
  "$app/ui/shared/with_math"
], function(compose, WithPlayer, WithMath) {
  return WithAudioPlayer;

  function WithAudioPlayer() {
    compose.mixin(this, [WithPlayer, WithMath]);

    this.defaultAttrs({
      playerContainerSelector: ".js-audio-player",
      playerSelector: ".js-audio-player .player",
      audioProgressSelector: ".js-audio-player .audio-progress",
      playButtonSelector: ".js-audio-player .play-button",
      pauseButtonSelector: ".js-audio-player .pause-button",
      circleProgressSelector: ".js-audio-player .circle-progress",
      textProgressSelector: ".js-audio-player .progress-text",
      playerOutsideContainerSelector: ".js-audio-player-container"
    });

    this.stopAudio = function(ev) {
      if (ev.data == "stopPreviews") {
        this.pause();
      }
    };

    this.togglePlayAndPause = function(ev) {
      ev && ev.preventDefault();

      if (this.select("playButtonSelector").is(":visible")) {
        this.trigger("pauseAudioPlayers");
        this.playAudio();
      } else {
        this.pauseAudio();
      }
    };

    this.playAudio = function(ev) {
      ev && ev.preventDefault();

      var urlRedirectId = this.select("playerOutsideContainerSelector").data(
          "url-redirect-id"
        ),
        productFileId = this.select("playerOutsideContainerSelector").data(
          "product-file-id"
        ),
        purchaseId = this.select("playerOutsideContainerSelector").data(
          "purchase-id"
        );
      this.trigger("uiNeedsToTrackConsumptionEvent", {
        eventType: "listen",
        platform: "web",
        urlRedirectId: urlRedirectId,
        productFileId: productFileId,
        purchaseId: purchaseId
      });
      this.play();
    };

    this.pauseAudio = function(ev) {
      ev && ev.preventDefault();
      this.pause();
    };

    this.around("onPlay", function() {
      this.select("pauseButtonSelector").show();
      this.select("playButtonSelector").hide();
    });

    this.around("onPause", function() {
      this.select("playButtonSelector").show();
      this.select("pauseButtonSelector").hide();
    });

    this.around("onTimeUpdate", function(originalOnTimeUpdate, ev) {
      if (ev.jPlayer.status.duration > 0) {
        var progress =
            ev.jPlayer.status.currentTime / ev.jPlayer.status.duration,
          halfway = "0";

        if (progress > 0.5) {
          halfway = "1";
        }
        this.select("circleProgressSelector").attr(
          "d",
          "M 40 3 a 37 37 0 " +
            halfway +
            " 1 " +
            this.polarToCartesian(37, progress * 360 - 90)
        );

        this.select("textProgressSelector").text(
          $.jPlayer.convertTime(ev.jPlayer.status.currentTime) +
            "/" +
            $.jPlayer.convertTime(ev.jPlayer.status.duration)
        );

        this.trigger("uiUpdateReceiptHeight");
        this.trigger("uiAnimatePaymentFormHeight", { animate: false });
      }
    });

    this.around("onEnded", function() {
      this.select("playButtonSelector").show();
      this.select("pauseButtonSelector").hide();

      var nextListenTriggers = this.select("playerSelector")
        .parents()
        .andSelf()
        .nextAll()
        .find(".js-listen-trigger")
        .andSelf()
        .filter(".js-listen-trigger");

      $(nextListenTriggers[0]).click();
    });

    this.initializeAudioPlayer = function() {
      this.initializePlayer();
    };

    this.after("initialize", function() {
      if (window.addEventListener) {
        window.addEventListener("message", this.stopAudio.bind(this));
      } else {
        window.attachEvent("message", this.stopAudio.bind(this));
      }

      this.on("click", {
        playerContainerSelector: this.togglePlayAndPause
      });
    });
  }
});
