"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Shipping);

  function Shipping() {
    this.verifyShippingAddress = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.verify_shipping_address_path(),
        data: data.shipping_data,
        timeout: 2500,
        error: function(jqXHR, textStatus, errorThrown) {
          this.trigger("dataVerifyShippingAddressTimeout");
        }.bind(this)
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataVerifyShippingAddressSuccessful", response);
          } else {
            this.trigger("dataVerifyShippingAddressFail", response);
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToVerifyShippingAddress",
        this.verifyShippingAddress
      );
    });
  }
});
