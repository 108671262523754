"use strict";

define([
  "flight/lib/component",
  "$app/ui/product/with_product_main",
  "$app/ui/product/with_payment_form",
  "$app/ui/product/with_facebook_login",
  "$app/ui/product/with_payment_blurb",
  "$app/ui/product/with_multi_buy",
  "$app/ui/product/with_offer_code",
  "$app/ui/product/with_price",
  "$app/ui/product/with_receipt",
  "$app/ui/product/with_rating",
  "$app/ui/product/with_quantity",
  "$app/ui/product/with_variants",
  "$app/ui/product/with_click_event_tracking",
  "$app/ui/shared/with_audio_player",
  "$app/ui/shared/with_video_player",
  "$app/ui/shared/with_preview",
  "$app/utils/with_url"
], function(
  defineComponent,
  WithProductMain,
  WithPaymentForm,
  WithFacebookLogin,
  WithPaymentBlurb,
  WithMultiBuy,
  WithOfferCode,
  WithPrice,
  WithReceipt,
  WithRating,
  WithQuantity,
  WithVariants,
  WithClickEventTracking,
  WithAudioPlayer,
  WithVideoPlayer,
  WithPreview,
  WithUrl
) {
  return defineComponent(
    Product,
    WithProductMain,
    WithPaymentForm,
    WithFacebookLogin,
    WithPaymentBlurb,
    WithMultiBuy,
    WithOfferCode,
    WithPrice,
    WithReceipt,
    WithRating,
    WithQuantity,
    WithVariants,
    WithClickEventTracking,
    WithAudioPlayer,
    WithVideoPlayer,
    WithPreview,
    WithUrl
  );

  function Product() {
    this.defaultAttrs({
      productSelector: ".product",
      isShowingPaymentForm: false,
      isCurrentUserSet: false,
      isCustomerSurchargeSet: false,
      slideAnimationSpeed: 400,
      isProductDisabled: false,
      hasViewIncremented: false,
      isMobile: false,
      isModal: false,
      isEmbed: false,
      isOutboundEmbed: false,
      incrementViewCountOnInit: false,
      onUserProfile: false,
      onProductPage: false,
      onEditPage: false,
      wasProductRecommended: false
    });

    this.switchToPaymentForm = function(animate) {
      animate = animate && !this.attr.isMobile;

      var action = animate ? "animate" : "css",
        height = this.getPaymentFormHeight(),
        paymentFormComplete = function() {
          this.attr.isAnimatingPaymentForm = false;
        }.bind(this);

      if (animate) {
        this.attr.isAnimatingPaymentForm = true;
      }
      // Fetch braintree client token for payment from product page
      if (
        $('meta[property="gr:paypal:pay_with_paypal_enabled"]').attr(
          "value"
        ) === "true"
      ) {
        this.trigger("uiNeedsBraintreeClientTokenFetched", {
          isOverlay: this.attr.isOverlay,
          isMultiBuyEnabled:
            this.attr.onMultiOverlay || this.attr.isMultiBuyEnabled
        });
      }
      this.updateDefaultHeight();
      this.select("productMainSelector")[action](
        { height: 0 },
        this.attr.slideAnimationSpeed
      );
      this.select("paymentFormSelector")[action](
        { height: height },
        this.attr.slideAnimationSpeed,
        null,
        paymentFormComplete
      );
      if (
        !this.attr.isShowingPaymentForm &&
        !this.attr.payingWithPayPal &&
        !this.attr.onEditPage
      ) {
        this.showStripeElements(
          ".js-product #card-number",
          ".js-product #card-expiry",
          ".js-product #card-cvc"
        );
      }
      this.attr.isShowingPaymentForm = true;
      this.trigger("uiPaymentFormShown");
    };

    this.switchToProductMain = function(animate) {
      var action = animate && !this.attr.isMobile ? "animate" : "css",
        currentHeight = this.select("productMainSelector").height(),
        $mainSelector = this.select("productMainSelector");

      $mainSelector.css("height", "auto");
      var newHeight = $mainSelector.height();

      this.attr.defaultProductMainHeight = newHeight;
      this.updateDefaultHeight();
      this.select("paymentFormSelector")[action](
        { height: 0 },
        this.attr.slideAnimationSpeed
      );
      this.select("productMainSelector").height(currentHeight);

      $mainSelector[action](
        { height: newHeight },
        {
          duration: this.attr.slideAnimationSpeed,
          complete: function() {
            $mainSelector.css("height", "auto").removeAttr("height");
          }
        }
      );
      this.attr.isShowingPaymentForm = false;
      this.trigger("uiProductMainShown");
    };

    this.animatePaymentFormHeight = function(ev, data) {
      if (this.attr.isShowingPaymentForm) {
        this.switchToPaymentForm(data.animate);
      }
    };

    this.setCustomerSurcharge = function() {
      this.attr.isCustomerSurchargeSet = true;
      this.triggerPaymentFormIfWanted();
    };

    this.setOverlayOfferCode = function() {
      var code =
        this.attr.user &&
        this.attr.user.product &&
        this.attr.user.product.offer_code;
      if (this.attr.overlayOfferCode && code && code.is_valid) {
        var data = $.extend(
          {
            name: this.attr.overlayOfferCode,
            offer_code_amount: code.amount,
            permalink: this.attr.permalink,
            success: true
          },
          code
        );
        this.trigger("uiNeedsToAddOfferCodeToBundle", data);
      }
    };

    this.setOverlayUrlParams = function() {
      if (this.attr.overlayUrlParams) {
        this.trigger("uiUpdateUrlParameters", this.attr.overlayUrlParams);
      }
    };

    this.setCurrentUserInfo = function(ev, data) {
      if (this.attr.permalink === data.permalink) {
        this.attr.user = data;
        this.setOfferCode();
        this.setOverlayOfferCode();
        this.setOverlayUrlParams();
        if (!this.attr.isCurrentUserSet) {
          this.setRemaining();
          this.setTerritoryBlockedNotice();
          this.setCreditCard();
          this.setPreviewProcessing();
          this.setLoginInfo();
          this.setShippingInfo();
          this.setVariants();
          this.setReceipt();
          this.setEmail();
          this.setAlreadyBought();
          this.displayReviewsSection();

          this.attr.isCurrentUserSet = true;
        }

        if (this.attr.canGift && this.getUrlParams().gift) {
          this.showGiftInputs();
        }
        this.setCustomFieldsFromUrlParams();
        this.triggerPaymentOptionUpdate();
        this.triggerPaymentFormIfWanted();
      }
    };

    this.triggerPaymentFormIfWanted = function() {
      if (
        (this.attr.wantThis || this.getUrlParams().wanted) &&
        !this.attr.user.purchase.redirect_token &&
        !this.attr.isProductDisabled &&
        this.attr.isCustomerSurchargeSet &&
        this.attr.isCurrentUserSet &&
        !this.attr.onUserProfile
      ) {
        this.showPaymentFormIfPossible(null, false);
        this.attr.wantThis = false;
      }
    };

    this.triggerPaymentOptionUpdate = function() {
      if (this.attr.isRecurringBilling) {
        const validDurations = ["monthly", "quarterly", "biannually", "yearly"];
        const duration = this.attr.overlayDuration
          ? this.attr.overlayDuration
          : this.getUrlParams()["duration"];
        if (validDurations.indexOf(duration) >= 0) {
          this.updatePaymentOptionSelection(duration);
        } else {
          for (var index = 0; index < validDurations.length; index++) {
            const duration = validDurations[index];
            if (this.getUrlParams()[duration]) {
              this.updatePaymentOptionSelection(duration);
              break;
            }
          }
        }
      }
    };

    this.incrementViewCountIfNotDoneYet = function() {
      if (!this.attr.hasViewIncremented) {
        this.trigger("uiNeedsToTrackViewEvent", {
          permalink: this.attr.permalink,
          wasProductRecommended: !!this.attr.wasProductRecommended,
          recommendedBy: this.attr.recommendedBy
        });
        this.attr.hasViewIncremented = true;
      }
    };

    this.initializeProduct = function() {
      if (!this.attr.onUserProfile) {
        this.setCardSaveRequired(this.productNeedsCardSaved());
        this.trigger("uiSetPermalinkForSharing", {
          permalink: this.attr.permalink
        });
      }

      if (this.$node.data("product-info")) {
        var productInfo = this.$node.data("product-info");
        productInfo.permalink = this.attr.permalink;
        this.setCurrentUserInfo({}, productInfo);
      } else {
        this.trigger("uiNeedsToGetCurrentUserInfo", {
          permalink: this.attr.permalink,
          offerCodeName: this.attr.offerCodeName
        });
      }

      if (this.attr.incrementViewCountOnInit) {
        this.incrementViewCountIfNotDoneYet();
      }
    };

    this.productNeedsCardSaved = function() {
      return this.attr.isRecurringBilling || this.attr.isPreorder;
    };

    this.updatePreviews = function(ev, data) {
      const { covers } = data;
      this.attr.assetPreviews = covers;
      this.attr.activePreviewId = covers[0] ? covers[0].id : null;
      this.showPreview();
    };

    this.determineRecommended = function() {
      if (this.attr.recommendedBy) {
        this.attr.wasProductRecommended = true;
      } else if (this.getUrlParams().recommended_by) {
        this.attr.wasProductRecommended = true;
        this.attr.recommendedBy = this.getUrlParams().recommended_by.match(
          /[a-zA-Z]+/
        )[0];
      } else if (this.attr.overlayRecommendedBy) {
        this.attr.wasProductRecommended = true;
        this.attr.recommendedBy = this.attr.overlayRecommendedBy;
      }
    };

    this.setupUserAnalytics = function() {
      if (
        this.attr.onDiscoverPage ||
        (this.attr.onOverlayPage && this.attr.isMultiBuyEnabled)
      ) {
        return;
      }

      const googleAnalyticsId = this.select("productSelector").data(
          "seller-google-analytics-id"
        ),
        googleAnalyticsCrossDomains = this.select("productSelector").data(
          "seller-google-analytics-cross-domains"
        ),
        facebookPixelId = this.select("productSelector").data(
          "seller-facebook-pixel-id"
        ),
        trackFreeSales = !!this.select("productSelector").data(
          "free-sale-analytics"
        );

      if (googleAnalyticsId || facebookPixelId) {
        this.trigger("uiNeedsToStartTrackingForSellerAnalytics", {
          googleAnalyticsId: googleAnalyticsId,
          googleAnalyticsCrossDomains: googleAnalyticsCrossDomains,
          googleAnalyticsClientId: this.attr.userGoogleAnalyticsClientId,
          facebookPixelId: facebookPixelId,
          trackFreeSales
        });
        this.trigger("uiNeedsToTrackActivityWithSellerAnalytics", {
          permalink: this.attr.permalink,
          action: "viewed"
        });
      }
    };

    this.after("initialize", function() {
      this.determineRecommended();
      this.attr.onOverlayPage =
        this.$node.parents("body#overlay-page").length > 0;
      this.attr.user = { product: { offer_code: {} }, purchase: {} };
      this.attr.permalink = this.select("productSelector").data(
        "unique-permalink"
      );
      this.attr.externalId = this.select("productSelector").data("external-id");
      this.attr.name = this.select("productSelector").data("name");
      this.attr.isPayWhatYouWant =
        this.select("priceRangeSelector").filter(":visible").length == 1;
      this.attr.offerCodeName =
        this.attr.overlayOfferCode || this.getOfferCodeNameFromUrl();
      this.attr.offerCodeUsed = !!this.attr.offerCodeName;
      this.attr.shippingCents = 0;
      this.attr.isSingleUnit = this.select("priceSelector").data("single-unit");
      this.attr.hasVariants = !!this.select("productSelector").data(
        "has-variants"
      );
      this.attr.hasOfferCodeField =
        this.select("offerCodeFieldSelector").length > 0;
      this.attr.isPreorder = !!this.select("productSelector").data(
        "is-preorder"
      );
      this.attr.isPhysical = !!this.select("productSelector").data(
        "is-physical"
      );
      this.attr.isRecurringBilling = this.select("productSelector").data(
        "subscription"
      );
      this.attr.shippingInfoRequired = this.select("productSelector").data(
        "shipping-info-required"
      );
      this.attr.priceCents = parseInt(
        this.select("productSelector").data("price-cents")
      );
      this.attr.currency = this.select("productSelector").data("currency");
      this.attr.price = this.attr.isSingleUnit
        ? this.attr.priceCents
        : this.attr.priceCents / 100;
      this.attr.purchaseType = this.select("productSelector").data(
        "purchase-type"
      );
      this.attr.isRentalModeActive = this.attr.purchaseType === "rent_only"; // Buy mode is the default for buy_and_rent products.
      this.attr.exchangeRate = this.select("productSelector").data(
        "exchange-rate"
      );
      this.attr.isProductDisabled = this.select("notForSaleNoticeSelector").is(
        ":visible"
      );
      this.attr.zeroPlusEnabled =
        parseFloat(this.select("priceRangeSelector").data("min-price")) == 0;
      this.attr.isTaxedExclusively = !!this.select("productSelector").data(
        "taxed-exclusively"
      );
      this.attr.sellerId = this.select("productSelector").data("seller-id");
      this.attr.vertical = this.select("productSelector").data("vertical");
      this.attr.customFields = this.select("productSelector").data(
        "custom-fields"
      );

      if (this.attr.purchaseType !== "buy_only") {
        this.attr.rentalPriceCents = parseInt(
          this.select("productSelector").data("rental-price-cents")
        );
        this.attr.rentalPrice = this.attr.isSingleUnit
          ? this.attr.rentalPriceCents
          : this.attr.rentalPriceCents / 100;
      }

      this.attr.selectedPriceInfo = this.select("productSelector").data(
        "selected-price-info"
      );

      this.setupUserAnalytics();

      this.on(document, "uiUpdateCovers", this.updatePreviews);
      this.on(document, "dataCurrentUserInfo", this.setCurrentUserInfo);
      this.on(document, "dataCustomerSurcharge", this.setCustomerSurcharge);
      this.on(
        document,
        "uiAnimatePaymentFormHeight",
        this.animatePaymentFormHeight
      );
      this.initializeProduct();
      this.initializeAudioPlayer();
      this.initializeVideoPlayer();
    });
  }
});
