"use strict";

define([], function() {
  return WithSocialShare;

  function WithSocialShare() {
    this.getTwitterShareUrl = function(text) {
      return "https://twitter.com/intent/tweet?text=" + encodeURI(text);
    };
  }
});
