"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(LoginTrigger);

  function LoginTrigger() {
    this.showLoginModal = function(ev) {
      ev && ev.preventDefault();

      this.trigger("uiShowLoginModal", { setNext: false });

      return false;
    };

    this.after("initialize", function() {
      this.on("click", this.showLoginModal);
    });
  }
});
