"use strict";

define([
  "$app/ui/megaphone/dropdown",
  "$app/ui/megaphone/tasks/add_a_product",
  "$app/ui/megaphone/tasks/confirm_email_address",
  "$app/data/megaphone/main",
  "$app/data/megaphone/tasks/connect_social_account",
  "$app/data/megaphone/tasks/confirm_email_address"
], function(
  MegaphoneDropdownUI,
  AddAProductUI,
  ConfirmEmailAddressUI,
  MegaphoneData,
  ConnectSocialAccountData,
  ConfirmEmailAddressData
) {
  function MegaphoneSetup() {
    MegaphoneDropdownUI.attachTo(".js-megaphone-group");
    MegaphoneData.attachTo(document);

    // megaphone task setup
    AddAProductUI.attachTo(
      '.js-megaphone-action[data-megaphone-action="uiAddAProduct"]'
    );
    ConfirmEmailAddressUI.attachTo(
      '.js-megaphone-action[data-megaphone-action="uiResendConfirmationEmail"]'
    );
    ConnectSocialAccountData.attachTo(document);
    ConfirmEmailAddressData.attachTo(document);
  }

  return MegaphoneSetup;
});
