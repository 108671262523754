"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(AddAProduct);

  function AddAProduct() {
    this.maybeGoToDashboard = function() {
      if (this.$node.attr("href") != window.location.pathname) {
        window.location.pathname = this.$node.attr("href");
      } else {
        this.trigger("uiHideMegaphone");
      }
    };

    this.after("initialize", function() {
      this.on(document, "uiAddAProductWithMegaphone", this.maybeGoToDashboard);
    });
  }
});
