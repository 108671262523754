"use strict";

/**
 * A mixin that allows data component to upload file both with cors and non-cors.
 * This mixins expect its data component to have following attributes:
 *
 * @attr {string} uploadStartedEvent - The event to be triggered when upload is started.
 * @attr {string} dataEvent - The event to be triggered when upload is completed.
 * @attr {string} uiUploadWithCorsEvent (optional) - The data component will listen to this event and upload a given file with cors if given.
 * @attr {string} uploadProgressedEvent (optional) - The event to be triggered when upload is progressed if given in case of cors.
 * @attr {boolean} isUserAsset (optional) - If true, file will be uploaded to 'attachments/', otherwise 'files/' in case of cors.
 */

define([
  "$app/utils/file",
  "$app/utils/mimetypes",
  "$vendor/evaporate",
  "$vendor/jquery.form"
], function(FileUtils, MimeTypes, Evaporate) {
  function FileUpload() {
    this.uploadWithCors = function(ev, data) {
      var $form = $("#" + data.formId), // TODO: do not rely on jquery.form.js
        originalFormData = $form.serializeArray(),
        userExternalId = data.userExternalId,
        file = data.file,
        rootBucket = this.attr.isUserAsset ? "attachments" : "files",
        s3Key = FileUtils.getS3Key(
          FileUtils.generateGuid(),
          encodeURIComponent(file.name).replace("'", "%27"),
          userExternalId,
          rootBucket
        );

      if (!userExternalId) {
        throw "Could not determine user external id, please make sure to set data-user-external-id on your uploader form element";
      }

      if (!this.attr.evaporate) {
        this.attr.evaporate = new Evaporate({
          signerUrl: Routes.s3_utility_generate_multipart_signature_path(), //The server endpoint used to generate signatures
          aws_key: FileUtils.determineAWSAccessKeyIdForForm($form),
          bucket: FileUtils.determineS3BucketForForm($form),
          fetchCurrentServerTimeUrl: Routes.s3_utility_current_utc_time_string_path(),
          maxFileSize: 20 * 1024 * 1024 * 1024 // 20GB file size limit
        });
      }

      var status = this.attr.evaporate.add({
        name: s3Key,
        originalName: file.name,
        file: file,
        url: $form.data("s3-url"),
        mimeType: MimeTypes.get(file.name),
        previousProgress: 0,
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "public-read"
        },
        complete: function() {
          this.trigger(this.attr.dataEvent, {
            s3Key: s3Key,
            isS3: true,
            originalFormData: originalFormData,
            file: file
          });
        }.bind(this),
        progress: function(progress) {
          if (this.attr.uploadedProgressedEvent) {
            this.trigger(this.attr.uploadProgressedEvent, {
              progress: progress
            });
          }
        }.bind(this),
        initiated: function(uploadId) {}
      });
      if (isNaN(status)) {
        // status contains error string if any, otherwise index of file in array
        this.trigger("uiShowFlashMessage", { message: status });
      }
      this.trigger(this.attr.uploadStartedEvent, data);
    };

    this.determineS3BucketForForm = function($form) {
      var s3Url = $form.data("s3-url");
      if (s3Url) {
        var splittedS3Url = s3Url.split("/");
        return splittedS3Url[splittedS3Url.length - 1];
      }
      throw "Could not determine S3 bucket, please make sure to set data-s3-url on your uploader form element";
    };

    this.determineAWSAccessKeyIdForForm = function($form) {
      if ($form.data("aws-access-key-id")) {
        return $form.data("aws-access-key-id");
      }
      throw "Could not determine AWSAccessKeyId, please make sure to set aws-access-key-id on your uploader form element";
    };

    this.filterNonFileInputs = function(inputs) {
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].name != "file") {
          inputs.splice(i, 1);
          i--;
        }
      }
    };

    this.after("initialize", function() {
      if (typeof this.attr.uiUploadWithCorsEvent === "string") {
        this.on(this.attr.uiUploadWithCorsEvent, this.uploadWithCors);
      }
    });
  }

  return FileUpload;
});
