"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(SignupTrigger);

  function SignupTrigger() {
    this.showSignupModal = function() {
      this.trigger("uiShowSignupModal");
      return false;
    };

    this.after("initialize", function() {
      this.on("click", this.showSignupModal);
    });
  }
});
