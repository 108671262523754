"use strict";

define([
  "$app/utils/with_url",
  "$app/ui/shared/with_form_base",
  "$app/ui/shared/with_shipping_form",
  "flight/lib/compose",
  "$app/utils/delay",
  "mailcheck"
], function(
  WithUrl,
  WithFormBase,
  WithShippingForm,
  compose,
  delay,
  Mailcheck
) {
  return WithEnvelope;

  function WithEnvelope() {
    compose.mixin(this, [WithUrl, WithFormBase, WithShippingForm]);

    this.defaultAttrs({
      envelopeSelector: ".envelope",
      emailFieldsSelector: ".envelope input[type=email]",
      emailSelector: ".envelope input[name=email]",
      emailConfirmContainerSelector: ".js-email-confirm-tool-tip",
      emailConfirmTriggerSelector: ".js-confirm-email-trigger",
      emailDenyTriggerSelector: ".js-deny-email-trigger",
      giftTriggerSelector: ".gift-trigger",
      giftSignifierSelector: 'input[name="is_gift"]',
      giftElementSelector: ".gift-element",
      gifteeEmailSelector: ".envelope input[name=giftee_email]",
      shippingInfoSelector: ".shipping_information",
      shippingInformationSelector: ".js-shipping-information-container",
      keepOnFileHolderSelector: ".js-keep-on-file-holder",
      countrySelector: ".purchase_country",
      streetSelector: ".purchase_street_address",
      citySelector: ".purchase_city",
      stateSelectorDropDown: "select.purchase_state",
      stateSelectorText: "input.purchase_state",
      zipCodeSelector: ".purchase_zip_code",
      shippingFields: [
        "street_address",
        "zip_code",
        "state",
        "city",
        "country"
      ],
      stateInputName: "purchase[state]",
      shippingAddressVerifySelector: ".js-shipping-address-tool-tip",
      streetAddressUpdateTriggerSelector: ".js-street-address-post-trigger",
      shippingAddressConfirmTriggerSelector:
        ".js-shipping-address-confirm-trigger",
      shippingRateHolderSelector: ".js-shipping-rate-holder",
      shippingAddressMessageSelector: ".js-shipping-address-message",
      shippingRateSelector: ".js-shipping-rate-info",
      shippingFormNoun: "purchase",
      usingStateDropdown: false,
      hasVerifiedShippingAddress: false,
      // storing suggestion and field for suggestion when verifying an almost correct international address
      addressSuggestion: "",
      addressFieldForSuggestionSelector: ""
    });

    this.checkEmailForTypos = function() {
      var $input = this.select("emailSelector"),
        that = this,
        tlds = [
          "126.com",
          "163.com",
          "21cn.com",
          "alice.it",
          "aliyun.com",
          "aol.com",
          "aol.it",
          "arnet.com.ar",
          "att.net",
          "bellsouth.net",
          "blueyonder.co.uk",
          "bol.com.br",
          "bt.com",
          "btinternet.com",
          "charter.net",
          "comcast.net",
          "cox.net",
          "daum.net",
          "earthlink.net",
          "email.com",
          "email.it",
          "facebook.com",
          "fastmail.fm",
          "fibertel.com.ar",
          "foxmail.com",
          "free.fr",
          "freeserve.co.uk",
          "games.com",
          "globo.com",
          "globomail.com",
          "gmail.com",
          "gmx.com",
          "gmx.de",
          "gmx.fr",
          "gmx.net",
          "google.com",
          "googlemail.com",
          "hanmail.net",
          "hotmail.be",
          "hotmail.co.uk",
          "hotmail.com",
          "hotmail.com.ar",
          "hotmail.com.br",
          "hotmail.com.mx",
          "hotmail.de",
          "hotmail.es",
          "hotmail.fr",
          "hotmail.it",
          "hush.com",
          "hushmail.com",
          "icloud.com",
          "ig.com.br",
          "iname.com",
          "inbox.com",
          "itelefonica.com.br",
          "juno.com",
          "keemail.me",
          "laposte.net",
          "lavabit.com",
          "libero.it",
          "list.ru",
          "live.be",
          "live.co.uk",
          "live.com",
          "live.com.ar",
          "live.com.mx",
          "live.de",
          "live.fr",
          "live.it",
          "love.com",
          "mac.com",
          "mail.com",
          "mail.ru",
          "me.com",
          "msn.com",
          "nate.com",
          "naver.com",
          "neuf.fr",
          "ntlworld.com",
          "o2.co.uk",
          "oi.com.br",
          "online.de",
          "orange.fr",
          "orange.net",
          "outlook.com",
          "outlook.com.br",
          "pobox.com",
          "poste.it",
          "prodigy.net.mx",
          "protonmail.ch",
          "protonmail.com",
          "qq.com",
          "r7.com",
          "rambler.ru",
          "rocketmail.com",
          "safe-mail.net",
          "sbcglobal.net",
          "sfr.fr",
          "sina.cn",
          "sina.com",
          "sky.com",
          "skynet.be",
          "speedy.com.ar",
          "t-online.de",
          "talktalk.co.uk",
          "telenet.be",
          "teletu.it",
          "terra.com.br",
          "tin.it",
          "tiscali.co.uk",
          "tiscali.it",
          "tuta.io",
          "tutamail.com",
          "tutanota.com",
          "tutanota.de",
          "tvcablenet.be",
          "uol.com.br",
          "verizon.net",
          "virgilio.it",
          "virgin.net",
          "virginmedia.com",
          "voo.be",
          "wanadoo.co.uk",
          "wanadoo.fr",
          "web.de",
          "wow.com",
          "ya.ru",
          "yahoo.co.id",
          "yahoo.co.in",
          "yahoo.co.jp",
          "yahoo.co.kr",
          "yahoo.co.uk",
          "yahoo.com",
          "yahoo.com.ar",
          "yahoo.com.br",
          "yahoo.com.mx",
          "yahoo.com.ph",
          "yahoo.com.sg",
          "yahoo.de",
          "yahoo.fr",
          "yahoo.it",
          "yandex.com",
          "yandex.ru",
          "yeah.net",
          "ygm.com",
          "ymail.com",
          "zipmail.com.br",
          "zoho.com"
        ];

      Mailcheck.run({
        email: $input.val(),
        topLevelDomains: tlds,
        suggested: function(suggestion) {
          if (suggestion !== undefined) {
            that
              .select("emailConfirmContainerSelector")
              .find("span")
              .text(
                I18n.t("js.email_confirm_paragraph", { email: suggestion.full })
              );
            that
              .select("emailConfirmTriggerSelector")
              .data("suggestion", suggestion.full);
            that.select("emailConfirmContainerSelector").addClass("showing");
          }
        }
      });
    };

    this.handleEmailChange = function() {
      this.select("emailSelector").val(
        this.select("emailSelector")
          .val()
          .toLowerCase()
      );
    };

    this.confirmEmail = function(ev) {
      ev.preventDefault();
      this.select("emailSelector").val(
        this.select("emailConfirmTriggerSelector").data("suggestion")
      );
      this.select("emailConfirmContainerSelector").removeClass("showing");
    };

    this.denyEmail = function(ev) {
      ev.preventDefault();
      this.select("emailConfirmContainerSelector").removeClass("showing");
    };

    this.setEmail = function() {
      var $email = this.select("emailSelector");
      $email.val(
        $email.val() ||
          this.getUrlParams().email ||
          (this.attr.user.purchase && this.attr.user.purchase.email) ||
          this.attr.user.email ||
          ""
      );
    };

    this.validateEmailsIfNotAutoFill = function() {
      return this.validateEmails(this.select("emailFieldsSelector"));
    };

    this.showAddressVerify = function() {
      this.select("shippingAddressVerifySelector").show();
      this.trigger("uiAnimatePaymentFormHeight", { animate: false });
    };

    this.hideAddressVerify = function() {
      this.select("shippingAddressVerifySelector").hide();
      this.resetAddressUpdateAndConfirmButtons();
      if (this.attr.addressFieldForSuggestionSelector) {
        this.select("addressFieldForSuggestionSelector").removeClass("error");
      }
      this.trigger("uiAnimatePaymentFormHeight", { animate: false });
    };

    this.resetAddressUpdateAndConfirmButtons = function() {
      this.select("shippingAddressConfirmTriggerSelector")
        .addClass("button-primary")
        .removeClass("button-default")
        .text(I18n.t("js.yes_it_is"));
      this.select("streetAddressUpdateTriggerSelector").hide();
    };

    // if buyer verifies shipping address or none is needed, go ahead with processing payment else trigger event to verify shipping address in the backend
    this.verifyShippingAddress = function() {
      this.hideAddressVerify();

      if (
        this.attr.hasVerifiedShippingAddress ||
        this.select("streetSelector").length == 0 ||
        !this.select("streetSelector").is(":visible") ||
        !this.select("streetSelector").hasClass("required")
      ) {
        this.continueWithPayment();
      } else {
        var shipping_data = {
          street_address: this.select("streetSelector").val(),
          city: this.select("citySelector").val(),
          state: this.select("stateSelectorText").val(),
          zip_code: this.select("zipCodeSelector").val(),
          country: this.select("countrySelector").val()
        };

        this.trigger("uiNeedsToVerifyShippingAddress", {
          shipping_data: shipping_data
        });
      }
    };

    this.verifiedShippingAddress = function(ev, response) {
      // make buyers address verified address so it is serialized in the purchase form
      this.select("streetSelector").val(response.street_address);
      this.select("citySelector").val(response.city);
      this.select("stateSelectorText").val(response.state);
      this.select("zipCodeSelector").val(response.zip_code);

      this.continueWithPayment();
    };

    this.unverifiedShippingAddress = function(ev, response) {
      this.select("shippingAddressMessageSelector").text(
        response.error_message
      );
      if (response.suggestion) {
        this.setAddressFieldErrorAndSuggestion(response);
        this.select("streetAddressUpdateTriggerSelector").show();
        this.select("shippingAddressConfirmTriggerSelector")
          .addClass("button-default")
          .removeClass("button-primary")
          .text(I18n.t("js.no_and_continue"));
      }
      this.showAddressVerify();
      this.trigger("uiShouldResetPayButton");
    };

    this.setAddressFieldErrorAndSuggestion = function(response) {
      this.attr.addressSuggestion = response.suggestion;
      switch (response.error_field) {
        case "street":
          this.attr.addressFieldForSuggestionSelector = this.attr.streetSelector;
          break;
        case "city":
          this.attr.addressFieldForSuggestionSelector = this.attr.citySelector;
          break;
        case "state":
          this.attr.addressFieldForSuggestionSelector = this.attr.stateSelector;
          break;
        case "zip_code":
          this.attr.addressFieldForSuggestionSelector = this.attr.zipCodeSelector;
          break;
        case "country":
          this.attr.addressFieldForSuggestionSelector = this.attr.countrySelector;
          break;
      }
      this.select("addressFieldForSuggestionSelector").addClass("error");
    };

    this.updateStreetAddress = function(ev) {
      ev.preventDefault();

      this.select("addressFieldForSuggestionSelector").val(
        this.attr.addressSuggestion
      );
      this.hideAddressVerify();

      return false;
    };

    this.confirmShippingAddress = function(ev) {
      ev.preventDefault();

      // this will cause verifyShippingAddress to immediately continue to payment when it runs the validations again as the buyer may have changed something
      this.attr.hasVerifiedShippingAddress = true;
      if (this.attr.productsInBundle) {
        this.initiateValidationForProductBundle();
      } else {
        this.payIfAllowed();
      }
      return false;
    };

    this.continueWithPayment = function() {
      if (this.attr.productsInBundle) {
        this.makePaymentForProductBundle();
      } else if (!this.attr.onUserProfile) {
        this.makePayment();
      }
    };

    this.setShippingInfo = function() {
      if (this.attr.user.detected_country) {
        this.select("countrySelector").val(this.attr.user.detected_country);
        if (this.attr.user.detected_state != null) {
          this.currentStateField().val(this.attr.user.detected_state);
        }
      }

      if (this.attr.user.shipping_information) {
        for (var i = 0; i < this.attr.shippingFields.length; i++) {
          var property = this.attr.shippingFields[i];
          $(".purchase_" + property).val(
            this.attr.user.shipping_information[property]
          );
        }
      }

      if (!this.attr.user.is_logged_in) {
        this.select("keepOnFileHolderSelector").remove();
      }

      this.updateStateAndZipCode();
    };

    this.unsetShippingInfo = function() {
      this.select("countrySelector").val("");
      for (var i = 0; i < this.attr.shippingFields.length; i++) {
        var property = this.attr.shippingFields[i];
        $(".purchase_" + property).val("");
      }
    };

    this.showShippingInputs = function() {
      this.select("shippingInformationSelector").show();
    };

    this.hideShippingInputs = function() {
      this.select("shippingInformationSelector").hide();
    };

    this.toggleGiftPaymentForm = function(ev) {
      if (ev) {
        ev.preventDefault();
      }

      if (this.attr.isProcessingPayment || this.attr.isAnimatingPaymentForm) {
        return false;
      }
      if (this.select("giftSignifierSelector").val() === "true") {
        this.hideGiftInputs();
      } else {
        this.showGiftInputs();
      }
      if (this.attr.onProductPage) {
        this.switchToPaymentForm(true);
      }
      return false;
    };

    this.showGiftInputs = function() {
      this.select("giftTriggerSelector").addClass("active");
      this.select("giftSignifierSelector").val(true);
      this.select("envelopeSelector").show();
      this.select("giftElementSelector").show();
      this.unsetShippingInfo();
    };

    this.hideGiftInputs = function() {
      this.select("giftTriggerSelector").removeClass("active");
      this.select("giftSignifierSelector").val(false);
      this.select("giftElementSelector").hide();
      this.setShippingInfo();
    };

    this.updateShippingRate = function(ev, data) {
      var shippingRateCents, formattedShippingRate;

      if (this.attr.productsInBundle) {
        shippingRateCents = this.totalBundleShippingRateDisplayCurrencyCents();
        formattedShippingRate = this.formattedUSDShippingRate(
          shippingRateCents
        );
      } else {
        shippingRateCents = data.shipping_rate_cents;
        formattedShippingRate = data.formatted_shipping_rate;
      }

      this.attr.shippingCents = shippingRateCents;

      if (shippingRateCents > 0) {
        this.select("shippingRateHolderSelector").addClass("has-shipping-rate");
        this.select("shippingRateSelector").text("+ " + formattedShippingRate);
      } else {
        this.select("shippingRateHolderSelector").removeClass(
          "has-shipping-rate"
        );
      }

      this.trigger("uiNeedsToEvaluateCreditCardFormVisibility");
    };

    // Bundles only. Standardized to USD because there's only one UI element to show shipping for all currencies of any product in the bundle.
    this.formattedUSDShippingRate = function(cents) {
      return "US$" + this.cleanedPrice(cents / 100);
    };

    this.changeCountry = function() {
      this.updateStateAndZipCode();
      this.scheduleUpdateCustomerSurcharge();
    };

    this.scheduleUpdateCustomerSurcharge = function() {
      this.trigger("uiShouldDisablePayButton");

      delay(
        function() {
          this.trigger("uiNeedsCustomerSurcharge");
        }.bind(this),
        1000
      );
    };

    this.after("initialize", function() {
      this.updateStateAndZipCode();

      this.on(this.$node, "dataCustomerSurcharge", this.updateShippingRate);

      if (this.attr.isMobile) {
        this.select("envelopeSelector").addClass("small");
      }

      this.on("change", {
        countrySelector: this.changeCountry,
        emailSelector: this.handleEmailChange
      });
      this.on("paste keyup", {
        zipCodeSelector: this.scheduleUpdateCustomerSurcharge
      });
      this.on("focusout", { emailSelector: this.checkEmailForTypos });

      this.on("click", {
        giftTriggerSelector: this.toggleGiftPaymentForm,
        shippingAddressConfirmTriggerSelector: this.confirmShippingAddress,
        streetAddressUpdateTriggerSelector: this.updateStreetAddress,
        emailConfirmTriggerSelector: this.confirmEmail,
        emailDenyTriggerSelector: this.denyEmail
      });

      this.on(
        document,
        "dataVerifyShippingAddressSuccessful",
        this.verifiedShippingAddress
      );
      this.on(
        document,
        "dataVerifyShippingAddressFail",
        this.unverifiedShippingAddress
      );
      this.on(
        document,
        "dataVerifyShippingAddressTimeout",
        this.continueWithPayment
      );
    });
  }
});
