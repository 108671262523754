"use strict";

define(["handlebars"], function(Handlebars) {
  function HighlightMatchesHelper(term, text) {
    if (term && text) {
      var pattern = new RegExp(term, "i");
      var match = text.match(pattern)[0];
      return text.replace(pattern, `<b>${match}</b>`);
    } else {
      return text;
    }
  }
  Handlebars.registerHelper("highlight_matches", HighlightMatchesHelper);
  return HighlightMatchesHelper;
});
