"use strict";

define(["$app/ui/shared/with_d3_formatter"], function(D3Formatter) {
  return WithPaymentBlurb;

  function WithPaymentBlurb() {
    this.defaultAttrs({
      paymentBlurbSelector: ".js-payment-blurb",
      effectivePriceSelector: ".js-price",
      taxAmountTextSelector: ".js-tax-amount",
      vatCountryIndicatorSelector: ".js-vat-country-choice-trigger",
      vatCountryChoiceSelector: ".js-vat-country-choice",
      vatCountryChoiceSelectSelector: ".js-vat-country-select",
      vatBusinessVatIdSelector: ".js-vat-id-blurb",
      paymentBlurbTaxSuppressed: false,
      totalPriceCents: 0
    });

    this.flashPrice = function() {
      var $price = this.select("paymentBlurbSelector").find(
        this.attr.effectivePriceSelector
      );
      $price.addClass("highlighted");

      setTimeout(
        function() {
          $price.removeClass("highlighted");
        }.bind(this),
        1000
      );
    };

    this.updatePrice = function(formattedPrice) {
      this.select("paymentBlurbSelector")
        .find(this.attr.effectivePriceSelector)
        .text(formattedPrice);
    };

    this.updateTax = function(formattedTax) {
      if (
        !this.select("paymentBlurbSelector").find(
          this.attr.taxAmountTextSelector
        )
      ) {
        return;
      }

      this.select("paymentBlurbSelector")
        .find(this.attr.taxAmountTextSelector)
        .text(formattedTax);

      if (this.attr.requireCountryConfirmation) {
        this.select("paymentBlurbSelector")
          .find(this.attr.vatCountryIndicatorSelector)
          .text(this.attr.taxCountry);
        this.select("vatCountryChoiceSelectSelector").val(this.attr.taxCountry);
      }
    };

    this.selectDataPropertyFromBlurb = function(propName) {
      var jsPaymentBlurb = $(".js-payment-blurb");
      if (jsPaymentBlurb.size() > 1) {
        return $(jsPaymentBlurb[1]).data(propName);
      } else {
        return $(jsPaymentBlurb[0]).data(propName);
      }
    };

    this.formattedMoney = function(amount) {
      var precision = this.isWholeNumber(amount) ? 0 : 2;

      return accounting.formatMoney(amount, {
        symbol: "US$",
        precision: precision
      });
    };

    this.setPaymentBlurb = function(totalPrice, totalVat) {
      if (totalPrice == 0) {
        return this.hidePaymentBlurb();
      }

      if (parseFloat(totalVat)) {
        this.updateTax(this.formattedMoney(totalVat));
      } else {
        this.updateTax(this.formattedMoney(0));
      }

      this.updatePrice(this.formattedMoney(totalPrice));

      this.select("paymentBlurbSelector").show();
      this.trigger("uiAnimatePaymentFormHeight", { animate: true });
    };

    this.isWholeNumber = function(floatNum) {
      var numToFixed = floatNum.toFixed(2);
      return !parseInt(
        numToFixed.substring(numToFixed.length - 2, numToFixed.length)
      );
    };

    this.hidePaymentBlurb = function() {
      this.select("paymentBlurbSelector").hide();
      this.trigger("uiAnimatePaymentFormHeight", { animate: true });
    };

    this.ForeignToUSD = function(foreignPrice, exchangeRate) {
      if (!exchangeRate) {
        return foreignPrice;
      }

      return parseFloat(foreignPrice) / exchangeRate;
    };

    this.cleanedPrice = function(price) {
      return price % 1 == 0 ? price : price.toFixed(2);
    };

    this.singleUnitRate = function(isSingleUnit) {
      return isSingleUnit ? 1 : 100;
    };

    this.toDisplayCurrency = function(
      currencyUnits,
      isSingleUnit,
      exchangeRate
    ) {
      var singleUnitRate = this.singleUnitRate(isSingleUnit),
        parsedExchangeRate = 1;
      if (exchangeRate) {
        parsedExchangeRate = parseFloat(exchangeRate);
      }

      return parseFloat(
        this.cleanedPrice(
          this.ForeignToUSD(
            parseFloat(currencyUnits) / singleUnitRate,
            parsedExchangeRate
          )
        )
      );
    };

    this.toDisplayCurrencyCents = function(
      currencyUnits,
      isSingleUnit,
      exchangeRate
    ) {
      return parseFloat(
        this.cleanedPrice(
          this.toDisplayCurrency(currencyUnits, isSingleUnit, exchangeRate) *
            100.0
        )
      );
    };

    this.initiatePriceInformationUpdate = function(ev) {
      if (this.attr.productsInBundle) {
        for (var i = 0; i < this.attr.productsInBundle.length; i++) {
          var product = this.attr.productsInBundle[i],
            priceCents;

          this.trigger("uiNeedsProductCustomerSurcharge", {
            externalId: product.externalId,
            permalink: product.permalink,
            priceCents: product.perceivedPriceCents,
            quantity: product.quantity,
            sellerId: this.attr.sellerId,
            postalCode: product.isPhysical
              ? this.select("zipCodeSelector").val()
              : "",
            country: product.isPhysical
              ? this.select("countrySelector").val()
              : product.taxCountry,
            vatId: this.attr.vatId,
            isPhysical: product.isPhysical,
            isTaxExclusive: product.isTaxedExclusively,
            isPartOfBundle: true
          });
        }
      } else {
        this.attr.isPhysical = this.select("paymentBlurbSelector").data(
          "is-physical"
        );

        this.trigger("uiNeedsProductCustomerSurcharge", {
          externalId: this.attr.externalId,
          priceCents: this.attr.totalPriceCents,
          sellerId: this.attr.sellerId,
          postalCode: this.attr.isPhysical
            ? this.select("zipCodeSelector").val()
            : "",
          country: this.attr.isPhysical
            ? this.select("countrySelector").val()
            : this.attr.taxCountry,
          vatId: this.attr.vatId,
          isPhysical: this.attr.isPhysical,
          isTaxExclusive: this.attr.isTaxedExclusively,
          productNode: this.$node,
          quantity: this.attr.quantity
        });
      }
    };

    this.updateCustomerSurcharge = function(ev, data) {
      var totalPrice = 0,
        totalTax = 0,
        exclusiveTax = 0,
        vat = 0,
        exclusiveVat = 0,
        exchangeRatePresent = false,
        taxCountry,
        businessVatStatus;

      if (this.attr.productsInBundle) {
        totalPrice = this.totalBundlePrice() + this.totalBundleShippingRate();
        totalTax = this.totalBundleTax();
        exclusiveTax = this.bundleExclusiveTax();
        exclusiveVat = this.bundleExclusiveVat();
        vat = this.bundleVat();
        exchangeRatePresent = this.productsWithExchangeRates();
        taxCountry = this.bundleTaxCountry();
        businessVatStatus = this.bundleBusinessVatStatus();
      } else {
        var exchangeRate = this.selectDataPropertyFromBlurb("exchange-rate");
        var isSingleUnit = this.attr.isSingleUnit;
        this.attr.productInfo = data;
        this.attr.productInfo["externalId"] = this.attr.externalId;
        this.attr.productInfo["quantity"] = this.attr.quantity;
        this.attr.productInfo["unitPrice"] = this.attr.unitPrice;
        totalPrice = this.toDisplayCurrency(
          parseInt(data.price_cents) + parseInt(data.shipping_rate_cents),
          isSingleUnit,
          exchangeRate
        );
        totalTax = this.toDisplayCurrency(
          parseInt(data.tax_cents),
          isSingleUnit,
          exchangeRate
        );
        exclusiveTax =
          data.is_price_tax_exclusive && data.tax_info && !data.tax_info.is_vat
            ? this.toDisplayCurrency(
                parseInt(data.tax_cents),
                isSingleUnit,
                exchangeRate
              )
            : 0;
        exchangeRatePresent = exchangeRate != 1;
        vat =
          data.tax_info && data.tax_info.is_vat
            ? this.toDisplayCurrency(
                parseInt(data.tax_cents),
                isSingleUnit,
                exchangeRate
              )
            : 0;
        exclusiveVat = data.is_price_tax_exclusive ? vat : 0;
        taxCountry = data.tax_info && data.tax_info.country;
        businessVatStatus = data.business_vat_status;
      }

      this.attr.requireCountryConfirmation = vat > 0;

      // At least one product cares about tax.
      if (taxCountry) {
        this.attr.taxCountry = taxCountry;
      }

      totalPrice += exclusiveTax + exclusiveVat;

      var totalPriceCents = Math.floor(totalPrice * 100);
      if (this.attr.totalTransactionCents != totalPriceCents) {
        this.attr.totalTransactionCents = totalPriceCents;
        this.trigger(this.node, "uiTotalPriceCentsChanged");
      }

      if (businessVatStatus) {
        if (businessVatStatus === "valid") {
          this.select("paymentBlurbSelector").html(
            I18n.t("js.card_will_be_charged_with_price_excluding_biz_vat_tax")
          );
        } else if (businessVatStatus === "invalid") {
          this.select("vatBusinessVatIdSelector")
            .find("input")
            .addClass("error");
        }
      }

      if (vat > 0) {
        this.select("vatBusinessVatIdSelector")
          .removeClass("soft-hidden")
          .addClass("showing");

        this.select("paymentBlurbSelector").html(
          I18n.t("js.card_will_be_charged_with_price_including_vat_tax")
        );
        this.setPaymentBlurb(totalPrice, vat);
      } else if (totalTax > 0) {
        this.select("paymentBlurbSelector").html(
          I18n.t("js.card_will_be_charged_with_price_including_sales_tax")
        );
        this.setPaymentBlurb(totalPrice);
      } else if (
        exchangeRatePresent ||
        this.select("paymentBlurbSelector").is(":visible") ||
        this.attr.productsInBundle ||
        this.attr.isPayWhatYouWant ||
        this.attr.offerCodeUsed
      ) {
        this.setPaymentBlurb(totalPrice);
      } else {
        this.hidePaymentBlurb();
      }
      this.flashPrice();

      this.trigger("uiShouldEnablePayButton");
    };

    this.selectVatCountry = function(ev, data) {
      this.attr.taxCountry = this.select(
        "vatCountryChoiceSelectSelector"
      ).val();
      if (this.attr.productsInBundle) {
        for (var i = 0; i < this.attr.productsInBundle.length; i++) {
          this.attr.productsInBundle[i]["taxCountry"] = this.attr.taxCountry;
        }
      }

      this.select("vatCountryIndicatorSelector").text(this.attr.taxCountry);

      this.initiatePriceInformationUpdate();
      this.hideVatCountrySelector();
    };

    this._vatIdTypingTimer = null;

    this.changeVatIdWhenFinishedTyping = function(ev) {
      if (this._vatIdTypingTimer) clearTimeout(this._vatIdTypingTimer);
      var self = this;
      this._vatIdTypingTimer = setTimeout(function() {
        self.changeVatId();
      }, 1000);
    };

    this.changeVatId = function() {
      this.select("vatBusinessVatIdSelector")
        .find("input")
        .removeClass("error");

      const vatId = this.select("vatBusinessVatIdSelector")
        .find("input")
        .val();
      this.attr.vatId = vatId && vatId.length > 0 ? vatId : null;
      if (this.attr.productsInBundle) {
        for (var i = 0; i < this.attr.productsInBundle.length; i++) {
          this.attr.productsInBundle[i]["vatId"] = this.attr.vatId;
        }
      }
      this.initiatePriceInformationUpdate();
    };

    this.toggleVatCountrySelector = function(ev) {
      ev && ev.preventDefault();

      var $choice = this.select("vatCountryChoiceSelector"),
        $trigger = this.select("vatCountryIndicatorSelector");

      if ($trigger.length == 1) {
        $choice
          .css({
            top: $trigger.position().top - $choice.outerHeight() + 35,
            left:
              $trigger.position().left -
              $choice.outerWidth() / 2 +
              $trigger.width() / 2
          })
          .toggleClass("showing");
      }

      return false;
    };

    this.hideVatCountrySelector = function(ev, data) {
      this.select("vatCountryChoiceSelector").removeClass("showing");
    };

    this.showNewVatCountryOptions = function(ev, data) {
      if (data.is_tax_mismatch) {
        setTimeout(
          function() {
            this.toggleVatCountrySelector();
          }.bind(this),
          1000
        );

        this.select("vatCountryChoiceSelectSelector")
          .find("option")
          .each(function() {
            if (
              $.inArray(this.value, [data.card_country, data.ip_country]) == -1
            ) {
              this.remove();
            }
          });

        var firstTaxCountry = this.select("vatCountryChoiceSelectSelector")
          .find("option:first")
          .val();
        this.select("vatCountryChoiceSelectSelector").val(firstTaxCountry);
        this.attr.taxCountry = firstTaxCountry;

        this.initiatePriceInformationUpdate();
      }
    };

    this.after("initialize", function() {
      this.on("click", {
        vatCountryIndicatorSelector: this.toggleVatCountrySelector
      });

      this.on("change", {
        vatCountryChoiceSelectSelector: this.selectVatCountry
      });

      this.on("change", {
        vatBusinessVatIdSelector: this.changeVatId
      });
      this.on("keyup", {
        vatBusinessVatIdSelector: this.changeVatIdWhenFinishedTyping
      });

      this.on(
        this.$node,
        "dataCustomerSurcharge",
        this.updateCustomerSurcharge
      );
      this.on(
        this.$node,
        "uiNeedsCustomerSurcharge",
        this.initiatePriceInformationUpdate
      );
      this.on(document, "dataPurchaseFail", this.showNewVatCountryOptions);
      this.on(
        document,
        "uiNeedsToHideVatCountrySelector",
        this.hideVatCountrySelector
      );
    });
  }
});
