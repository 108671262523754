"use strict";

/* When including the credit card form (credit card holder) into a page, you should also include this module.
 * This module will take care of providing you generic card parameters and take care of all the tokenization and
 * preparation of the card parameters for providing to the backend.
 *
 * Call getCardParams with a callback to get the card parameters for the backend.
 *
 * For pages needing to show the form in a saved state on load, either call setCreditCardFormState to set the form
 * into the saved state, or when including the credit card holder view, set the new_hidden attribute to false. This
 * module will pickup the saved state from a data attribute on .credit_card_holder.
 *
 * For credit card forms that are re-used after a delay: clearCvc should be called after successful authorization
 * so that when the form is redisplayed for a second purchase, the CVC will require re-entry.
 * Examples of delayed credit card form re-use: buy now, give more, signup.
 */

define([
  "$app/ui/shared/with_form_base",
  "$app/templates/zipcode_field",
  "$app/data/card_data",
  "$app/ui/shared/with_paypal",
  "flight/lib/compose",
  "$vendor/jquery.payment"
], function(WithFormBase, ZipcodeFieldTemplate, CardData, WithPayPal, compose) {
  return WithCreditCard;

  function WithCreditCard() {
    compose.mixin(this, [WithFormBase, ZipcodeFieldTemplate, WithPayPal]);

    this.defaultAttrs({
      creditCardHolder: ".credit_card_holder",
      rememberedSelector: ".credit_card_holder .credit_card.remembered",
      newCardSelector: ".credit_card_holder .credit_card.new-card",
      testCardSelector: ".credit_card_holder .credit_card.test_card",
      savedCardSelector: ".credit_card_holder .credit_card.saved_card",
      ccIconSelector: ".js-cc-icon",
      cvCodeSelector: ".credit_card_holder #card-cvc",
      ccZipcodeFieldSelector: ".credit_card_holder .js-cc_zipcode",
      ccZipcodeFieldContainerSelector: ".credit_card_holder .js-cc_zipcode_p",
      ccZipcodeLabelSelector: ".js-cc_zipcode_label",
      ccZipcodeRequiredSelector: ".js-cc_zipcode_p .js-cc-zipcode-required",
      expiryDateContainerSelector: ".credit_card_holder .js-expiry_date_p",
      securityIndicatorSelector:
        ".credit_card_holder .cc_number_security_indicator",
      securityBlurbSelector: ".credit_card_holder .cc_security_blurb",
      useDifferentCardSelector: ".credit_card_holder .use_different_card",
      supportedCardImageTypes: [
        "generic_card",
        "visa",
        "mastercard",
        "amex",
        "discover",
        "jcb",
        "cvc_card",
        "cvc_amex"
      ],
      hasTriggeredCallForUserRiskAnalysis: false,
      creditCardFormState: "new",

      grPaypalButtonSelector: ".credit_card_holder .paypal",
      grNativePaypalButtonSelector:
        ".credit_card_holder .paypal.native-paypal-button",
      grBraintreePaypalButtonSelector:
        ".credit_card_holder .paypal.braintree-paypal-button",
      payWithPaypalHolderSelector: ".js-pay-with-paypal-holder",
      payWithCreditCardHolderSelector: ".js-pay-with-credit-card-holder",
      actualBraintreePayPalContainerSelector: ".paypal-braintree-actual",
      actualPayPalContainerSelector: ".credit_card_holder .paypal-actual",
      actualPayPalButton: ".credit_card_holder .paypal.braintree-paypal-button",
      actualPayPalDataEmailSelector:
        ".credit_card_holder .paypal-braintree-actual .js-braintree-paypal-email",
      payWithPayPalPromptSelector: ".credit_card_holder .or-pay-with",
      braintreeNonceFieldSelector: ".js-braintree-paypal-nonce",
      payPalLoadingIndicatorSelector: ".js-paypal-loading-indicator",
      payWithPaypalTrigger: ".js-pay-with-paypal-trigger",
      cancelPayWithPaypalTrigger: ".js-cancel-pay-with-paypal-trigger",
      showPaypalCardSelector: ".credit_card_holder",
      customerPayPalEmailContainerSelector: ".js-paypal-customer-email",
      defaultCreditCardIconSelector: ".js-credit-card-icon",
      orPayWithSelector: ".js-pay-with-paypal-indicator",

      fullNameFieldSelector: ".js-full-name-p",
      fullNameInputSelector: ".js-full-name-input",
      fullNameRequestedVisible: true,
      fullNameTrackingCreditCardFormState: "new",
      cardSaveRequired: false,

      /* These selectors refer to meta elements (that belong in the HEAD) which are outside this components node.
       * Do not use flightjs' this.select to access their nodes, instead use jQuery directly. */
      metaCardDataHandlingModeSelector:
        'meta[property="gr:card_data_handling_mode"]',
      metaStripePublicKeySelector: 'meta[property="stripe:pk"]',
      metaPayPalOverrideSelector:
        'meta[property="gr:payment:show_paypal_override"]',

      cardFieldValidity: {
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false
      }
    });

    this.setCreditCardFormState = function(creditCardFormState) {
      var oldValue = this.attr.creditCardFormState,
        newValue = creditCardFormState;

      this.attr.creditCardFormState = creditCardFormState;
      this.trigger("uiChangedCreditCardFormState", {
        oldCreditCardFormState: oldValue,
        newCreditCardFormState: newValue
      });
    };

    this.showFullNameField = function() {
      this.attr.fullNameRequestedVisible = true;
      if (this.attr.fullNameTrackingCreditCardFormState != "saved") {
        this.select("fullNameFieldSelector").show();
      } else {
        this.select("fullNameFieldSelector").hide();
      }
    };

    this.hideFullNameField = function() {
      this.attr.fullNameRequestedVisible = false;
      this.select("fullNameFieldSelector").hide();
    };

    this.creditCardFormStateChangedToggleFullname = function(ev, data) {
      this.attr.fullNameTrackingCreditCardFormState =
        data.newCreditCardFormState;
      if (this.attr.fullNameRequestedVisible) {
        this.showFullNameField();
      } else {
        this.hideFullNameField();
      }
      this.trigger("uiFullNameVisibilityChanged");
    };

    this.showZipcodeField = function(cardCountry) {
      if (
        this.attr.user.card.credit == "new" &&
        this.select("ccZipcodeFieldContainerSelector").length == 0
      ) {
        this.renderDynamicZipcodeField(cardCountry);
      }
    };

    this.renderDynamicZipcodeField = function(cardCountry) {
      this.hideFullNameField();
      this.select("expiryDateContainerSelector").after(ZipcodeFieldTemplate());
      var NON_UK_INDEX = 0,
        UK_INDEX = 1;
      var type = cardCountry == "GB" ? UK_INDEX : NON_UK_INDEX,
        zipCodeLabelText = [I18n.t("js.zip_code"), I18n.t("js.postal")],
        zipCodePlaceholderText = ["12345", "W11 2BQ"],
        zipCodeInputWidth = ["52px", "76px"],
        zipCodeInputLeft = ["128px", "115px"];
      this.select("ccZipcodeLabelSelector").text(zipCodeLabelText[type]);
      var placeholderText = zipCodePlaceholderText[type];
      if (type == NON_UK_INDEX && cardCountry != "US") {
        placeholderText = "";
      }
      this.select("ccZipcodeFieldSelector").prop(
        "placeholder",
        placeholderText
      );
      this.select("ccZipcodeFieldSelector").css(
        "width",
        zipCodeInputWidth[type]
      );
      this.select("ccZipcodeFieldContainerSelector").css(
        "left",
        zipCodeInputLeft[type]
      );
    };

    this.isAmex = function() {
      return this.attr.currentCardType == "amex";
    };

    this.showStripeElements = function(
      numSelector = "#card-number",
      expSelector = "#card-expiry",
      cvcSelector = "#card-cvc"
    ) {
      window.grStripe =
        window.grStripe ||
        Stripe($(this.attr.metaStripePublicKeySelector).attr("value"));
      const elements = window.grStripe.elements();

      const style = {
        base: {
          fontFamily:
            '-apple-system, ".SFNSDisplay-Regular", "Helvetica Neue", \
                    Helvetica, Arial, sans-serif',
          fontSize: "17px",
          color: "#777",
          "::placeholder": {
            color: "#ccc"
          }
        }
      };

      // Create elements
      this.attr.cardNumber = elements.create("cardNumber", {
        placeholder: "1234 5678 9012 3456",
        style: style
      });
      this.attr.cardExpiry = elements.create("cardExpiry", { style: style });
      this.attr.cardCvc = elements.create("cardCvc", {
        placeholder: "123",
        style: style
      });

      // Mount them
      this.attr.cardNumber.mount(numSelector);
      this.attr.cardExpiry.mount(expSelector);
      this.attr.cardCvc.mount(cvcSelector);

      // Add validity listeners
      this.attr.cardNumber.addEventListener(
        "change",
        this.cardElementValidityListener.bind(this)
      );

      this.attr.cardNumber.addEventListener(
        "change",
        this.updateCardType.bind(this)
      );

      this.attr.cardExpiry.addEventListener(
        "change",
        this.cardElementValidityListener.bind(this)
      );
      this.attr.cardCvc.addEventListener(
        "change",
        this.cardElementValidityListener.bind(this)
      );
    };

    this.cardElementValidityListener = function(event) {
      if (event.error || !event.complete) {
        this.attr.cardFieldValidity[event.elementType] = false;
      } else {
        this.attr.cardFieldValidity[event.elementType] = true;
      }
    };

    this.removeSavedCardAndShowNewCard = function(ev) {
      ev.preventDefault();

      var $rememberedCard = this.select("rememberedSelector"),
        $newCard = this.select("newCardSelector");

      $rememberedCard.addClass("cancelled-element");
      this.trigger("uiRemoveSavedCreditCardStarted");

      setTimeout(
        function() {
          $newCard.hide().removeClass("hidden");
          $newCard.fadeIn(
            100,
            function() {
              $rememberedCard.hide();
              this.attr.cardNumber.focus();
            }.bind(this)
          );
          this.setCreditCardFormState("new");
        }.bind(this),
        300
      );
      if (this.attr.onProductPage) {
        this.showStripeElements(
          ".js-product #card-number",
          ".js-product #card-expiry",
          ".js-product #card-cvc"
        );
      } else {
        this.showStripeElements();
      }
    };

    this.setRequiredFromCardFields = function(cardInfoRequired) {
      this.select("fullNameInputSelector").toggleClass(
        "required",
        cardInfoRequired
      );
    };

    this.updateCardType = function(event) {
      this.attr.currentCardType = event.brand;
      if (this.attr.currentCardType === "unknown") {
        this.attr.currentCardType = "generic_card";
      }
      this.setCard();
    };

    this.setCard = function() {
      this.changeCardIcon(this.attr.currentCardType);
      this.select("cvCodeSelector").animate({ width: this.isAmex() ? 39 : 31 });
      this.attr.cardCvc.update({ placeholder: this.isAmex() ? "1234" : "123" });
    };

    this.changeCardIcon = function(type) {
      this.select("securityIndicatorSelector").fadeOut();
      this.select("ccIconSelector")
        .removeClass(this.attr.supportedCardImageTypes.join(" "))
        .addClass(type)
        .fadeIn();
    };

    this.showSecurityBlurb = function() {
      this.select("securityBlurbSelector").fadeIn();
    };

    this.hideSecurityBlurb = function() {
      setTimeout(
        function() {
          this.select("securityBlurbSelector").fadeOut();
        }.bind(this),
        300
      );
    };

    this.validateCreditCardForm = function() {
      if (
        this.payingWithPayPal() ||
        this.attr.creditCardFormState === "saved" ||
        this.attr.creditCardFormState === "test"
      ) {
        return true;
      }
      const { cardNumber, cardCvc, cardExpiry } = this.attr.cardFieldValidity;
      if (!cardNumber) {
        this.attr.cardNumber.focus();
        return false;
      }
      if (!cardExpiry) {
        this.attr.cardExpiry.focus();
        return false;
      }
      if (!cardCvc) {
        this.attr.cardCvc.focus();
        return false;
      }
      return true;
    };

    this.getReusableCardParams = function(callback) {
      if (!this.payingWithPayPal()) {
        callback({ cardParams: null });
        return;
      }
      this.preparePayPalPayment(callback);
    };

    this.getCardParams = function(callback) {
      // When the credit card form is not being used to collect new credit card data, provide empty card params so
      // that caller can assume it will receive in the callback a consistent structure containing arbitrary card
      // parameters.
      if (this.attr.creditCardFormState != "new") {
        callback({ cardParams: {} });
        return;
      }

      if (this.payingWithPayPal()) {
        this.preparePayPalPayment(callback);
        return;
      }

      const cardData = {
        cardNumber: this.attr.cardNumber,
        cardCvc: this.attr.cardCvc,
        cardExpiry: this.attr.cardExpiry
      };

      this.trigger("uiNeedsCardPrepared", {
        associatedData: { callback: callback },
        cardData: cardData
      });
    };

    // Credit card forms should call this to clear the CVC field after successful authorization, if the form will be
    // redisplayed and re-used after a delayed period.
    this.clearCvc = function() {
      this.attr.cardCvc?.clear();
    };

    this.prepareCardCompleted = function(ev, data) {
      data.associatedData.callback({ cardParams: data.cardParams });
    };

    this.setCardSaveRequired = function(cardSaveRequired) {
      this.attr.cardSaveRequired = cardSaveRequired;
    };

    this.isCardSaveRequired = function() {
      return this.attr.cardSaveRequired;
    };

    this.after("initialize", function() {
      CardData.attachTo(this.$node, {
        cardDataHandlingMode: $(
          this.attr.metaCardDataHandlingModeSelector
        ).attr("value")
      });

      this.on("dataCardPrepared", this.prepareCardCompleted);
      this.on("click", {
        useDifferentCardSelector: this.removeSavedCardAndShowNewCard
      });
      this.on(
        "uiChangedCreditCardFormState",
        this.creditCardFormStateChangedToggleFullname
      );

      this.select("securityIndicatorSelector").hover(
        this.showSecurityBlurb.bind(this),
        this.hideSecurityBlurb.bind(this)
      );

      var presetCreditCardFormState = this.select("creditCardHolder").data(
        "formState"
      );
      if (typeof presetCreditCardFormState !== "undefined") {
        this.attr.creditCardFormState = presetCreditCardFormState;
      }
    });
  }
});
