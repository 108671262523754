"use strict";

define(["flight/lib/component"], function() {
  return WithPrice;

  function WithPrice() {
    this.defaultAttrs({
      perceivedPriceCentsSelector: 'input[name="perceived_price_cents"]'
    });

    this.setPrice = function() {
      var priceBeforeDiscount = 0,
        totalPrice,
        totalPriceCents,
        discountedTotalPriceCents;

      if (this.attr.isRentalModeActive) {
        totalPrice = this.attr.rentalPrice;
        totalPriceCents = this.attr.rentalPriceCents;
      } else {
        totalPrice = this.attr.price;
        totalPriceCents = this.attr.priceCents;
      }

      $.each(this.attr.selectedPriceChanges, function() {
        if (this[0]) {
          totalPrice += this[0];
        }
        if (this[1]) {
          totalPriceCents += this[1];
        }
      });

      if (this.attr.isPayWhatYouWant) {
        totalPriceCents = this.customizedPriceCents();
      }

      if (this.isOfferCodeApplicable()) {
        priceBeforeDiscount = totalPrice;
        discountedTotalPriceCents = totalPriceCents;
        if (this.attr.user.product.offer_code.is_percent) {
          totalPrice -= (
            totalPrice *
            (this.attr.user.product.offer_code.amount / 100)
          ).toFixed(this.attr.isSingleUnit ? 0 : 2);
          discountedTotalPriceCents -= (
            totalPriceCents *
            (this.attr.user.product.offer_code.amount / 100)
          ).toFixed();
        } else {
          totalPrice -=
            this.attr.user.product.offer_code.amount /
            (this.attr.isSingleUnit ? 1 : 100);
          discountedTotalPriceCents -= this.attr.user.product.offer_code.amount;
        }

        if (!this.isUsingPresetOfferCode() || !this.attr.isPayWhatYouWant) {
          totalPriceCents = discountedTotalPriceCents;
        }
      }

      this.setOldPrice(priceBeforeDiscount);

      this.attr.unitPrice = Math.max(totalPriceCents, 0);
      // We allow offer codes that are larger than the price of the product. In that case totalPrice and totalPriceCents will be negative. Set them to 0.
      totalPrice = Math.max(totalPrice, 0);
      this.setPriceTag(totalPrice);

      totalPriceCents =
        Math.max(totalPriceCents, 0) *
        (this.attr.isPayWhatYouWant ? 1 : this.attr.quantity);
      this.attr.totalPriceCents = totalPriceCents;
      this.select("perceivedPriceCentsSelector").val(totalPriceCents);

      this.attr.zeroPlusEnabled =
        this.attr.totalPriceCents == 0 && this.attr.isPayWhatYouWant;

      if (this.attr.quantity > 1) {
        this.setPaymentBlurb(totalPrice);
      }

      if (
        this.isMultiBuyable() &&
        (typeof this.attr.priceToAddToProductBundle == "undefined" ||
          typeof this.select("priceRangeSelector").val() == "undefined")
      ) {
        this.attr.priceToAddToProductBundle = totalPrice * this.attr.quantity;
      }

      this.setPriceRange(totalPrice);
      this.setCreditCardFormVisibility(totalPrice);

      this.trigger("uiShouldDisablePayButton");
      this.initiatePriceInformationUpdate();

      if (this.attr.isRecurringBilling && this.attr.selectedPriceInfo) {
        this.trigger("uiToUpdateChargeOccurrenceMessage", totalPrice);
      }
    };

    this.evaluateCreditCardVisibilty = function(ev) {
      this.setCreditCardFormVisibility(this.attr.totalPriceCents);
    };

    this.setOldPrice = function(priceBeforeDiscount) {
      if (this.isOfferCodeApplicable()) {
        this.showOldPrice(priceBeforeDiscount);
      } else {
        this.hideOldPrice();
      }
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToUpdatePriceDueToPurchaseModeChange",
        this.setPrice
      );
      this.on(
        document,
        "uiNeedsToEvaluateCreditCardFormVisibility",
        this.evaluateCreditCardVisibilty
      );
    });
  }
});
