"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(BraintreeTransientCustomerData);

  function BraintreeTransientCustomerData() {
    this.tokenizeNonceForUserProfilePurchase = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.generate_braintree_transient_customer_token_path(),
        data: { braintree_nonce: data.braintreeNonce },
        dataType: "json"
      }).done(
        function(response) {
          this.trigger(this.node, "dataBraintreeTransientCustomerToken", {
            transient_customer_store_key: response.transient_customer_store_key,
            callback: data.callback
          });
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        "uiNeedsBraintreeTransientCustomerTokenFetched",
        this.tokenizeNonceForUserProfilePurchase
      );
    });
  }
});
