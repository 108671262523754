"use strict";

define([], function() {
  return WithDate;

  function WithDate() {
    this.toUSDateFormat = function(dateSelector) {
      var date = dateSelector.val();
      if (!dateSelector.data("from-us")) {
        date =
          date.split("/")[1] +
          "/" +
          date.split("/")[0] +
          "/" +
          date.split("/")[2];
      }
      return date;
    };

    this.getDateString = function(dateSelector) {
      return new Date(this.toUSDateFormat(dateSelector)).toDateString();
    };
  }
});
