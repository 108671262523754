"use strict";

const defineComponent = require("flight/lib/component");
const WithUrl = require("$app/utils/with_url");
const Mobile = require("$app/utils/mobile");
const AutocompleteSearchResultTemplate = require("$app/templates/autocomplete_search_result");
const queryString = require("query-string");
const { debounce } = require("lodash");
const { keyCodes } = require("$app/utils/keyboard");
const {
  ProductCardFetchTypes
} = require("$app/ui/shared/product_card_constants");

function DiscoverSearch() {
  this.defaultAttrs({
    searchFilterDropdownTriggerSelector:
      ".js-discover-search-filter-dropdown-trigger",
    searchFilterDropdownSelector: ".js-discover-search-filter-dropdown",
    searchFieldSelector: ".js-discover-search-field",
    searchFieldAutocompleteDropdown:
      ".js-discover-search-autocomplete-dropdown",
    searchFilterOptionSelector: ".js-discover-search-filter-option",
    selectedCategory: "everything",
    navigationSelector: ".js-navigation",
    searchButton: ".js-search-button",
    searchResultLinkSelector: ".search-result-link",
    previousAutocompleteSearchQuery: "",
    selectedResultIndex: undefined
  });

  this.searchKeyListener = function(ev) {
    ev.preventDefault();
    this.searchFieldFocusin();
    var query = this.select("searchFieldSelector").val();

    switch (ev.keyCode) {
      case keyCodes.enter: {
        if (this.attr.selectedResultIndex == undefined) {
          this.submitSearchField();
        } else {
          window.location = this.selectedResultNode().attr("href");
        }
        break;
      }

      case keyCodes.escape: {
        this.searchFieldFocusout();
        break;
      }

      case keyCodes.downArrow: {
        this.navigateSearchResult(keyCodes.downArrow);
        break;
      }

      case keyCodes.upArrow: {
        this.navigateSearchResult(keyCodes.upArrow);
        break;
      }

      default: {
        if (query.length > 0) {
          if (this.attr.previousAutocompleteSearchQuery != query) {
            this.getAutocompleteSearchResults(query);
            this.attr.previousAutocompleteSearchQuery = query;
          }
        } else {
          this.select("searchFieldAutocompleteDropdown").html("");
        }
      }
    }
  };

  this.getAutocompleteSearchResults = debounce(function(query) {
    this.trigger("uiNeedsAutocompleteSearchResults", { query });
  }, 300);

  this.submitSearchField = function() {
    this.searchFieldFocusout();
    var query = this.select("searchFieldSelector").val();
    this.select("searchFieldAutocompleteDropdown").removeClass("showing");
    if (this.attr.isOnDiscoverPage) {
      this.trigger("uiNeedsToFetchNewResults", {
        fetchType: ProductCardFetchTypes.SEARCH,
        query: query,
        categorySlug: this.attr.category
      });
      if (query.length > 0) {
        this.toggleSearchFilterDropdown();
        this.trigger("uiNeedsToHideSearchAndHamburgerButtons");
      } else {
        this.toggleQueryClass();
        this.trigger("uiNeedsToShowSearchAndHamburgerButtons");
      }
      this.select("searchFieldSelector").select();
    } else {
      this.navigateToDiscover(query);
    }
  };

  this.selectedResultNode = function() {
    return this.$node.find(
      ".search-result-link:eq(" + this.attr.selectedResultIndex + ")"
    );
  };

  this.updateSelectedResultIndex = function(newIndex) {
    this.selectedResultNode()
      .parent()
      .removeClass("selected");
    this.attr.selectedResultIndex = newIndex;
    this.selectedResultNode()
      .parent()
      .addClass("selected");
    this.select("searchFieldAutocompleteDropdown").removeClass(
      "autocomplete-result-hover"
    );
  };

  this.navigateToDiscover = function(query) {
    window.location.href = Routes.discover_path({
      query: query,
      category: this.attr.category
    });
  };

  this.toggleSearchFilterDropdown = function(ev) {
    ev.preventDefault();
    this.trigger("uiToToggleMobileOverlayToDiscover");
    this.select("searchFilterDropdownSelector").toggleClass("showing");
  };

  this.updateSearchFilter = function(ev) {
    if (
      this.attr.category !== $(ev.target || ev.srcElement).attr("data-value")
    ) {
      this.attr.category = $(ev.target || ev.srcElement).attr("data-value");

      if (this.attr.isOnDiscoverPage) {
        this.toggleSearchFilterDropdown(ev);
        this.updateSearchFilterDropdown();

        this.trigger("uiToClearTags");
        this.trigger("uiNeedsToFetchNewResults", {
          fetchType: ProductCardFetchTypes.CATEGORY,
          categorySlug: this.attr.category
        });
      } else {
        const query = this.select("searchFieldSelector").val();
        this.navigateToDiscover(query);
      }
    }
  };

  this.updateSearchCategory = function(ev, data) {
    this.attr.category = data.category;
    this.updateSearchFilterDropdown();
  };

  this.updateSearchFilterDropdown = function() {
    this.select("searchFilterDropdownTriggerSelector").html(
      $("[data-value='" + this.attr.category + "']").text()
    );
    var dropdownWidth = this.select(
      "searchFilterDropdownTriggerSelector"
    ).outerWidth();
    this.select("searchFieldSelector").css("padding-left", dropdownWidth + 20);
    if (Mobile.isOnDesktop()) {
      this.select("searchFieldAutocompleteDropdown").css({
        left: dropdownWidth,
        width: `calc(100% - ${dropdownWidth}px)`
      });
    }
  };

  this.updateCategoryLinksWithParams = function(ev, data) {
    // update hrefs on the category dropdown elements so users can right click + open new tabs
    delete data.params.category;
    const newParamsString = queryString.stringify(data.params, {
      encode: false
    });
    this.select("searchFilterOptionSelector").each(function(index, element) {
      const category = $(element).data("value");
      const categoryPath = Routes.discover_path({ category: category });
      $(element).attr("href", `${categoryPath}&${newParamsString}`);
    });
  };

  this.toggleQueryClass = function() {
    this.$node.toggleClass("has-query");
  };

  this.navigateSearchResult = function(keyCode) {
    var resultsLength = this.select("searchResultLinkSelector").length;
    if (resultsLength > 0) {
      if (keyCode == keyCodes.downArrow) {
        var index = Math.min(
          this.attr.selectedResultIndex + 1,
          resultsLength - 1
        );
      } else {
        var index = Math.max(this.attr.selectedResultIndex - 1, 0);
      }
      index = this.attr.selectedResultIndex == undefined ? 0 : index;
      this.updateSelectedResultIndex(index);
    }
  };

  this.onResultHover = function(ev) {
    event.stopPropagation();
    this.updateSelectedResultIndex(undefined);
    this.select("searchFieldAutocompleteDropdown").addClass(
      "autocomplete-result-hover"
    );
  };

  this.updateSearchAutocompleteDropdown = function(ev, response) {
    var dropdownElement = this.select("searchFieldAutocompleteDropdown");
    if (response.results.length > 0) {
      dropdownElement.html(AutocompleteSearchResultTemplate(response));
      dropdownElement.addClass("showing");
    } else {
      dropdownElement.removeClass("showing");
    }
  };

  this.searchFieldFocusin = function() {
    $(".js-autocomplete-overlay").addClass("autocomplete-overlay");
    $(".js-autocomplete-nav-overlay").addClass("autocomplete-nav-overlay");
  };

  this.searchFieldFocusout = function(event) {
    this.select("searchFieldAutocompleteDropdown")
      .html("")
      .removeClass("showing");
    this.updateSelectedResultIndex(undefined);
    $(".js-autocomplete-overlay").removeClass("autocomplete-overlay");
    $(".js-autocomplete-nav-overlay").removeClass("autocomplete-nav-overlay");
  };

  this.after("initialize", function() {
    this.attr.isOnDiscoverPage =
      this.$node.parents("body").attr("id") === "discover-page";
    if (this.getUrlParams().query && this.attr.isOnDiscoverPage) {
      this.select("searchFieldSelector").val(this.getUrlParams().query);
      this.select("searchFieldSelector").select();

      if (this.getUrlParams().category || this.getUrlParams().query) {
        this.toggleQueryClass();
        this.trigger("uiNeedsToHideSearchAndHamburgerButtons");
      }
    }

    this.on(document, "uiToToggleQueryClass", this.toggleQueryClass);

    this.on(
      document,
      "uiToUpdateCategoryLinksWithParams",
      this.updateCategoryLinksWithParams
    );
    this.on(document, "uiToUpdateSearchCategory", this.updateSearchCategory);
    this.on("keyup", { searchFieldSelector: this.searchKeyListener });
    this.on("click", {
      searchFilterDropdownTriggerSelector: this.toggleSearchFilterDropdown,
      searchFilterOptionSelector: this.updateSearchFilter,
      searchButton: this.submitSearchField
    });
    this.on("focusin", { searchFieldSelector: this.searchFieldFocusin });
    this.on(
      document,
      "dataAutocompleteSearchResults",
      this.updateSearchAutocompleteDropdown
    );
    this.on(
      document,
      "uiNeedsToHideAutocompleteOverlay",
      this.searchFieldFocusout
    );
    this.on("mouseover", {
      searchFieldAutocompleteDropdown: this.onResultHover
    });
  });
}

module.exports = defineComponent(DiscoverSearch, WithUrl);
