"use strict";

/**
 * Simple show and hide node mixins.
 *
 * @attr {string} showEvent - The event for showing the node.
 * @attr {string} hideEvent - The event for hiding the node.
 */

define([], function() {
  function ShowHide() {
    this.show = function() {
      this.$node.show();
    };

    this.hide = function() {
      this.$node.hide();
    };

    this.after("initialize", function() {
      this.on(document, this.attr.showEvent, this.show);
      this.on(document, this.attr.hideEvent, this.hide);
      this.trigger("uiShowHideInitialized");
    });
  }

  return ShowHide;
});
