"use strict";

define(["flight/lib/component", "$app/data/with_async_form"], function(
  defineComponent,
  WithAsyncForm
) {
  return defineComponent(userSettings, WithAsyncForm);

  function userSettings() {
    this.defaultAttrs({
      // with_async_form attrs.
      submitEvent: "uiNeedsToUpdateUserSettings",
      submitSucceededEvent: "dataUserSettings",
      submitFailedEvent: "dataFailedToUpdateUserSettings"
    });

    this.removeCreditCard = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.remove_settings_credit_card_path(),
        data: data,
        dataType: "json"
      })
        .done(
          function(response) {
            if (response.success) {
              this.trigger("dataCreditCardRemoveSuccess", response);
            } else {
              this.trigger("dataCreditCardRemoveFail", response);
            }
          }.bind(this)
        )
        .fail(
          function() {
            this.trigger("dataCreditCardRemoveFail");
          }.bind(this)
        );
    };

    this.saveCreditCard = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.settings_credit_card_path(),
        data: data,
        dataType: "json"
      })
        .done(
          function(response) {
            if (response.success) {
              this.trigger("dataCreditCardSaveSuccess", response);
            } else {
              this.trigger("dataCreditCardSaveFail", response);
            }
          }.bind(this)
        )
        .fail(
          function() {
            this.trigger("dataCreditCardSaveFail");
          }.bind(this)
        );
    };

    this.changeFanpagePermissions = function(ev, data) {
      FB.login(
        function() {
          this.trigger("dataFacebookLoginForChangingPermissions");

          $.ajax({
            type: "POST",
            dataType: "json",
            url: "/settings",
            data: {
              "user[manage_pages]": true,
              authenticity_token: data.auth_token
            }
          }).done(
            function() {
              this.trigger("dataFanpagePermissionsChanged");
            }.bind(this)
          );
        }.bind(this),
        {
          scope: "email,manage_pages"
        }
      );
    };

    this.deleteAccount = function(ev, data) {
      $.ajax({
        url: Routes.deactivate_account_path(),
        type: "POST",
        dataType: "json"
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataDeletedAccount");
          } else {
            this.trigger("dataFailedToDeleteAccount");
          }
        }.bind(this)
      );
    };

    this.resendConfirmationEmail = function() {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: Routes.resend_confirmation_settings_path()
      }).done(
        function() {
          this.trigger("dataResentConfirmationEmail");
        }.bind(this)
      );
    };

    this.saveUserOrientationInfo = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.update_user_orientation_info_settings_path(),
        data: data.formData,
        dataType: "json"
      })
        .done(
          function(response) {
            if (response.success) {
              this.trigger("dataUserOrientationInfoSaveSuccess", response);
            } else {
              this.trigger("dataUserOrientationSaveFail", response);
            }
          }.bind(this)
        )
        .fail(
          function() {
            this.trigger("dataUserOrientationSaveFail");
          }.bind(this)
        );
    };

    this.previewStyleComponent = function(_ev, data) {
      $.ajax({
        type: "GET",
        url: Routes.preview_style_component_settings_path(),
        data: data,
        dataType: "json"
      }).done(
        function(response) {
          this.trigger("dataStyleComponentPreview", response);
        }.bind(this)
      );
    };

    this.sendTestPing = function() {
      $.ajax({
        url: Routes.test_pings_path(),
        type: "POST"
      }).done(
        function(response) {
          this.trigger("dataTestPingDone", response);
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on("uiNeedsToRemoveCreditCard", this.removeCreditCard);
      this.on("uiNeedsToSaveCreditCard", this.saveCreditCard);
      this.on(
        "uiNeedsToChangeFanpagePermissions",
        this.changeFanpagePermissions
      );
      this.on("uiNeedsToDeleteAccount", this.deleteAccount);
      this.on("uiNeedsToResendConfirmationEmail", this.resendConfirmationEmail);
      this.on("uiNeedsToSaveUserOrientationInfo", this.saveUserOrientationInfo);
      this.on("uiNeedsToPreviewStyleComponent", this.previewStyleComponent);
      this.on("uiNeedsToSendTestPing", this.sendTestPing);
    });
  }
});
