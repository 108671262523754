"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ProductActions);

  function ProductActions() {
    this.defaultAttrs({
      multiFileDropboxSaverContainer: ".js-dropbox-container",
      resendReceiptTriggerSelector: ".js-resend-receipt-trigger"
    });

    this.setupMultifileDropboxSaver = function(ev, data) {
      // There is no multi file dropbox saver button on the page.
      if (this.select("multiFileDropboxSaverContainer").length === 0) {
        return;
      }

      // Dropbox is not loaded.
      if (!window.Dropbox) {
        return;
      }

      // We have already setup the dropbox button.
      if (
        this.select("multiFileDropboxSaverContainer").data(
          "has-setup-dropbox-saver-button"
        )
      ) {
        return;
      }

      // Don't setup Dropbox with no files.
      var files = this.select("multiFileDropboxSaverContainer").data(
        "product-files"
      );
      if (files.length === 0) {
        return;
      }

      var options = {
        files: files,
        // Success is called once all files have been successfully added to the user's
        // Dropbox, although they may not have synced to the user's devices yet.
        success: function() {},
        // Progress is called periodically to update the application on the progress
        // of the user's downloads. The value passed to this callback is a float
        // between 0 and 1. The progress callback is guaranteed to be called at least
        // once with the value 1.
        progress: function(progress) {},
        // Cancel is called if the user presses the Cancel button or closes the Saver.
        cancel: function() {},
        // Error is called in the event of an unexpected response from the server
        // hosting the files, such as not being able to find a file. This callback is
        // also called if there is an error on Dropbox or if the user is over quota.
        error: function(errorMessage) {}
      };
      var button = window.Dropbox.createSaveButton(options);
      this.select("multiFileDropboxSaverContainer").html(button);
      this.select("multiFileDropboxSaverContainer").data(
        "has-setup-dropbox-saver-button",
        true
      );
    };

    this.resendReceipt = function(ev) {
      ev.preventDefault();

      this.select("resendReceiptTriggerSelector").text(
        I18n.t("js.resending_receipt")
      );
      this.trigger("uiNeedsToResendReceipt", {
        id: this.select("resendReceiptTriggerSelector").data("id")
      });
    };

    this.showReceiptResent = function(ev) {
      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.receipt_resent")
      });
      this.select("resendReceiptTriggerSelector").text(
        I18n.t("js.resend_receipt")
      );
    };

    this.receiptResendFailed = function(ev) {
      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.something_went_wrong")
      });
      this.select("resendReceiptTriggerSelector").text(
        I18n.t("js.resend_receipt")
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToSetupMultifileDropboxSaverButton",
        this.setupMultifileDropboxSaver
      );
      this.on(document, "dataReceiptResent", this.showReceiptResent);
      this.on(document, "dataReceiptFailed", this.receiptResendFailed);

      this.setupMultifileDropboxSaver();

      this.on("click", {
        resendReceiptTriggerSelector: this.resendReceipt
      });
    });
  }
});
