"use strict";

function WithDateFilters() {
  this.defaultAttrs({
    dateAfterDatepickerSelector: ".js-date-after-datepicker-calendar",
    dateBeforeDatepickerSelector: ".js-date-before-datepicker-calendar"
  });

  this.hideDatepickers = function() {
    this.select("dateAfterDatepickerSelector").removeClass("showing");
    this.select("dateBeforeDatepickerSelector").removeClass("showing");
  };

  this.showDateAfterDatepicker = function(ev) {
    // Hide the other datepickers
    this.hideDatepickers();

    // Stop propagation so that the click on the document doesn't hide
    // this datepicker after we've shown it
    ev.stopPropagation();

    this.select("dateAfterDatepickerSelector")
      .addClass("showing")
      .show();
  };

  this.showDateBeforeDatepicker = function(ev) {
    // Hide the other datepickers
    this.hideDatepickers();

    // Stop propagation so that the click on the document doesn't hide
    // this datepicker after we've shown it
    ev.stopPropagation();

    this.select("dateBeforeDatepickerSelector")
      .addClass("showing")
      .show();
  };

  this.updateDateAfter = function(date) {
    const locale = this.select("dateAfterFilterSelector").data("from-us")
      ? "en-US"
      : "en-GB";

    this.select("dateAfterFilterSelector").val(date.toLocaleDateString(locale));

    this.hideDatepickers();
    this.trigger("uiNeedsToUpdateFilters");
  };

  this.updateDateBefore = function(date) {
    const locale = this.select("dateBeforeFilterSelector").data("from-us")
      ? "en-US"
      : "en-GB";

    this.select("dateBeforeFilterSelector").val(
      date.toLocaleDateString(locale)
    );

    this.hideDatepickers();
    this.trigger("uiNeedsToUpdateFilters");
  };

  this.initializeDatepickers = function() {
    const date = new Date();

    this.select("dateAfterDatepickerSelector").DatePicker({
      inline: true,
      date: date,
      calendars: 1,
      mode: "single",
      onChange: this.updateDateAfter.bind(this)
    });

    this.select("dateBeforeDatepickerSelector").DatePicker({
      inline: true,
      date: date,
      calendars: 1,
      mode: "single",
      onChange: this.updateDateBefore.bind(this)
    });
  };

  this.after("initialize", function() {
    this.initializeDatepickers();

    this.on("click", {
      dateAfterFilterSelector: this.showDateAfterDatepicker,
      dateBeforeFilterSelector: this.showDateBeforeDatepicker
    });

    this.on(document, "click", this.hideDatepickers);
  });
}

module.exports = WithDateFilters;
