"use strict";

define([], function() {
  return WithShippingForm;

  function WithShippingForm() {
    this.defaultAttrs({
      statePlaceholder: [I18n.t("js.state"), I18n.t("js.county")],
      zipCodePlaceholder: [I18n.t("js.zip_code"), I18n.t("js.postal")],
      multiColumnHolderSelector: ".js-multi-column-holder"
    });

    this.currentStateField = function() {
      if (this.attr.usingStateDropdown) {
        return this.select("stateSelectorDropDown");
      } else {
        return this.select("stateSelectorText");
      }
    };

    this.updateStateAndZipCode = function() {
      var country = this.select("countrySelector").val(),
        type = country == "United States" || country == "Philippines" ? 0 : 1,
        $state = this.currentStateField(),
        $zipCode = this.select("zipCodeSelector");

      $state.attr({ placeholder: this.attr.statePlaceholder[type] });
      $zipCode.attr({ placeholder: this.attr.zipCodePlaceholder[type] });

      if (country == "United States") {
        this.useStateDropdown();
      } else {
        this.useStateTextBox();
      }
    };

    this.useStateDropdown = function() {
      this.usingStateDropdown = true;
      this.select("stateSelectorDropDown")
        .show()
        .attr("name", this.attr.stateInputName)
        .addClass("required");
      this.select("stateSelectorText")
        .hide()
        .attr("name", "")
        .removeClass("required");
      this.select("multiColumnHolderSelector").removeClass("no-dropdown");
    };

    this.useStateTextBox = function() {
      this.usingStateDropdown = false;
      this.select("stateSelectorDropDown")
        .hide()
        .attr("name", "")
        .removeClass("required");
      this.select("stateSelectorText")
        .show()
        .attr("name", this.attr.shippingFormNoun + "[state]")
        .addClass("required");
      this.select("multiColumnHolderSelector").addClass("no-dropdown");

      if (this.attr.shippingFormNoun == "purchase") {
        this.select("stateSelectorText").val("");
      }
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToUpdateStateAndZipCode",
        this.updateStateAndZipCode
      );
    });
  }
});
