"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Social);

  function Social() {
    this.followSeller = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: Routes.follow_user_path(),
        data: data
      }).done(
        function(response) {
          this.trigger(
            "dataFollowSeller",
            response.success === true ? { message: response.message } : {}
          );
        }.bind(this)
      );
    };

    this.unfollowSeller = function(ev, data) {
      $.ajax({
        type: "GET",
        dataType: "json",
        url: Routes.cancel_follow_path(data.follower_id)
      }).done(
        function(response) {
          this.trigger("uiShowUnfollowing", {
            follower_id: response.follower_id
          });
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToFollowUser", this.followSeller);
      this.on(document, "uiNeedsToUnfollowUser", this.unfollowSeller);
    });
  }
});
