"use strict";

const defineComponent = require("flight/lib/component");
const ProductCardUIStates = require("$app/ui/shared/product_card_constants")
  .ProductCardUIStates;
const ProductCardFetchTypes = require("$app/ui/shared/product_card_constants")
  .ProductCardFetchTypes;
const WithUrl = require("$app/utils/with_url");

const FiletypeFilterRowTemplate = require("$app/templates/filetype_filter_row");
const CreatorFilterRowTemplate = require("$app/templates/creator_filter_row");

function ProductCardFilters() {
  this.defaultAttrs({
    productCardMobileFilterSelector: ".js-mobile-filter-button",
    productCardFilterResultsSelector: ".js-filter-results",
    productCardFilterSortSelector: ".js-product-card-sort-by",
    productCardFilterMinPriceSelector: ".js-product-card-filter-min-price",
    productCardFilterMaxPriceSelector: ".js-product-card-filter-max-price",
    productCardFilterRatingSelector: ".js-product-card-filter-rating",
    productCardFilterTagSelector: ".js-product-card-filter-tag",
    productCardFilterTagInputSelector: ".js-product-card-filter-tag-input",
    productCardFilterAllProductsTagInputSelector:
      ".js-product-card-filter-all-products-tag-input",
    productCardFilterShowMoreTagsSelector: ".js-product-card-show-more-tags",
    productCardFiletypesFilterWrapper: ".js-filetype-wrapper",
    productCardFiletypesListSelector: ".js-filter-filetypes-list",
    productCardFiletypesSelector: ".js-product-card-filter-filetype",
    productCardFiletypesInputSelector: ".js-product-card-filter-filetype-input",
    productCardFilterArchivedWrapper: ".js-archived-wrapper",
    productCardFilterHasArchivedProducts:
      ".js-product-card-has-archived-products",
    productCardFilterArchivedInputSelector:
      ".js-product-card-filter-archived-input",
    productCardFilterShowMoreFiletypesSelector:
      ".js-product-card-show-more-filetypes",
    productCardFilterShowMoreCreatorsSelector:
      ".js-product-card-show-more-creators",
    productCardCreatorFilterWrapper: ".js-creator-wrapper",
    productCardCreatorListSelector: ".js-filter-creator-list",
    productCardCreatorSelector: ".js-product-card-filter-creator",
    productCardCreatorInputSelector: ".js-product-card-filter-creator-input",
    productCardFilterAllCreatorsInputSelector:
      ".js-product-card-filter-all-creators-input",
    mobileFilterClass: "show-mobile-filter",
    filtersActiveClass: "filters-active",
    maxTagFiltersOnLoad: 5,
    maxFiletypeFiltersOnLoad: 5,
    maxCreatorFiltersOnLoad: 5
  });

  this.updateResults = function() {
    this.trigger("uiToClearPagination");
    this.trigger("uiNeedsToFetchNewResults", {
      fetchType: ProductCardFetchTypes.FILTER,
      filetypes: this.attr.filetypes,
      creatorExternalIds: this.attr.creatorExternalIds,
      sort: this.attr.sort,
      minPrice: this.attr.minPrice,
      maxPrice: this.attr.maxPrice,
      rating: this.attr.rating,
      tags: this.attr.tags,
      showArchivedOnly: this.attr.showArchivedOnly
    });
  };

  this.updateSort = function(_ev) {
    this.attr.sort = this.select("productCardFilterSortSelector").val();
    this.updateResults();
  };

  this.updateSortFilterSelector = function(_ev, data) {
    this.select("productCardFilterSortSelector").val(data.value);
    this.updateSort();
  };

  this.updateTags = function(_ev, data) {
    this.attr.tags = data.tags;
    this.trigger("uiNeedsToFetchNewResults", {
      fetchType: ProductCardFetchTypes.TAG,
      tags: this.attr.tags
    });
  };

  this.initializeTags = function(_ev, ui) {
    this.attr.tags = [];
    if (this.getUrlParams().tags) {
      this.attr.tags = this.getUrlParams().tags.split(",");
    }
    this.initializeTagInputs();
    this.collapseExtraTags();
  };

  this.initializeTagInputs = function() {
    for (const tag of this.attr.tags) {
      this.select("productCardFilterTagInputSelector")
        .filter(`[data-name="${tag}"]`)
        .attr("checked", true);
    }
    if (this.attr.tags.length == 0) {
      this.select("productCardFilterAllProductsTagInputSelector").attr(
        "checked",
        true
      );
    }
  };

  this.collapseExtraTags = function() {
    for (
      let tagNumber = 0;
      tagNumber < this.select("productCardFilterTagSelector").length;
      tagNumber++
    ) {
      if (
        tagNumber > this.attr.maxTagFiltersOnLoad &&
        !this.attr.showMoreTags
      ) {
        $(this.select("productCardFilterTagSelector")[tagNumber]).addClass(
          "soft-hidden"
        );
        this.select("productCardFilterShowMoreTagsSelector").removeClass(
          "soft-hidden"
        );
      }
    }
  };

  this.setTagAttributes = function() {
    this.attr.tags = this.select("productCardFilterTagInputSelector")
      .filter(":checked")
      .map(function() {
        return $(this).data("name");
      })
      .get();
  };

  this.handleTagFilter = function(ev, ui) {
    this.setTagAttributes();
    this.updateTags({}, { tags: this.attr.tags });
    this.updateAllProductsTagSelector();
  };

  this.handleAllProductsTagFilter = function(ev) {
    for (const tag of this.attr.tags) {
      this.select("productCardFilterTagInputSelector")
        .filter(`[data-name="${tag}"]`)
        .attr("checked", false);
    }
    this.updateTags({}, { tags: [] });
    this.select("productCardFilterAllProductsTagInputSelector").attr(
      "disabled",
      true
    );
  };

  this.handleArchivedFilter = function(ev) {
    if (this.select("productCardFilterArchivedInputSelector").attr("checked")) {
      this.attr.showArchivedOnly = true;
    } else {
      this.attr.showArchivedOnly = false;
    }
    this.updateResults();
  };

  this.updateAllProductsTagSelector = function() {
    let noTagsSelected = this.attr.tags.length === 0;

    this.select("productCardFilterAllProductsTagInputSelector")
      .attr("checked", noTagsSelected)
      .attr("disabled", noTagsSelected);
  };

  this.priceValuesHaveChanged = function(_ev) {
    var _this$attr = this.attr,
      minPrice = _this$attr.minPrice,
      maxPrice = _this$attr.maxPrice;
    this.attr.minPrice = this.select("productCardFilterMinPriceSelector").val();
    this.attr.maxPrice = this.select("productCardFilterMaxPriceSelector").val();

    if (minPrice != this.attr.minPrice || maxPrice != this.attr.maxPrice) {
      return true;
    }
  };

  this.priceValuesAreValid = function(_ev) {
    this.attr.minPrice = this.select("productCardFilterMinPriceSelector").val();
    this.attr.maxPrice = this.select("productCardFilterMaxPriceSelector").val();

    if (
      this.attr.maxPrice !== "" &&
      Number(this.attr.maxPrice) < Number(this.attr.minPrice)
    ) {
      this.select("productCardFilterMinPriceSelector").addClass("error");
      this.select("productCardFilterMaxPriceSelector").addClass("error");
      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.max_price_should_be_greater")
      });
      return false;
    }

    this.select("productCardFilterMinPriceSelector").removeClass("error");
    this.select("productCardFilterMaxPriceSelector").removeClass("error");
    return true;
  };

  this.handlePriceFilter = function(_ev) {
    if (this.priceValuesHaveChanged() && this.priceValuesAreValid()) {
      this.updateResults();
    }
  };

  this.handleRatingFilter = function(ev) {
    // This event is getting fired two times — one for the label,
    // one for the input, per every click. We only want to handle input clicks.
    if (ev.target.tagName !== "INPUT") return;

    const el = $(ev.target).val()
      ? $(ev.target)
      : $(ev.target)
          .parents(this.attr.productCardFilterRatingSelector)
          .children("input");

    if (el.val() == this.attr.rating) {
      this.attr.rating = null;
      el.attr("checked", false);
    } else {
      this.attr.rating = el.val();
    }

    this.updateResults();
  };

  this.toggleMobileFilters = function() {
    this.$node.toggleClass(this.attr.mobileFilterClass);
    this.trigger("uiToToggleMobileOverlayToDiscover");
  };

  this.showMoreTags = function(ev) {
    ev.preventDefault();
    this.select("productCardFilterTagSelector").removeClass("soft-hidden");
    this.select("productCardFilterShowMoreTagsSelector").addClass(
      "soft-hidden"
    );
    this.attr.showMoreTags = true;
  };

  this.addFiletypeOptions = function(ev, data) {
    this.attr.filetypesData = data.filetypesData;

    if (!this.attr.filetypesData || this.attr.filetypesData.length === 0) {
      return;
    }

    this.select("productCardFiletypesFilterWrapper").removeClass("hidden");
    this.select("productCardFiletypesListSelector").empty();
    for (
      let filetypeNumber = 0;
      filetypeNumber < this.attr.filetypesData.length;
      filetypeNumber++
    ) {
      const filetype = this.attr.filetypesData[filetypeNumber];
      this.select("productCardFiletypesListSelector").append(
        this.getFiletypeFilterRow(filetype.key, filetype.doc_count)
      );

      if (
        filetypeNumber >= this.attr.maxFiletypeFiltersOnLoad &&
        !this.attr.showMoreFiletypes
      ) {
        this.hideFiletypeFilterRow(filetypeNumber);
        this.showAllFiletypesLoadMore();
      }
    }
  };

  this.hideFiletypeFilterRow = function(filetypeNumber) {
    $(this.select("productCardFiletypesSelector")[filetypeNumber]).addClass(
      "soft-hidden"
    );
  };

  this.hideCreatorFilterRow = function(creatorNumber) {
    $(this.select("productCardCreatorSelector")[creatorNumber]).addClass(
      "soft-hidden"
    );
  };

  this.showAllFiletypesLoadMore = function() {
    this.select("productCardFilterShowMoreFiletypesSelector").removeClass(
      "soft-hidden"
    );
  };

  this.showAllCreatorsLoadMore = function() {
    this.select("productCardFilterShowMoreCreatorsSelector").removeClass(
      "soft-hidden"
    );
  };

  this.showMoreFiletypes = function(ev) {
    ev.preventDefault();
    this.attr.showMoreFiletypes = true;
    this.select("productCardFiletypesSelector").removeClass("soft-hidden");
    this.select("productCardFilterShowMoreFiletypesSelector").addClass(
      "soft-hidden"
    );
  };

  this.showMoreCreators = function(ev) {
    ev.preventDefault();
    this.attr.showMoreCreators = true;
    this.select("productCardCreatorSelector").removeClass("soft-hidden");
    this.select("productCardFilterShowMoreCreatorsSelector").addClass(
      "soft-hidden"
    );
  };

  this.checkIfFiletypeIsSelected = function(name) {
    return this.attr.filetypes.indexOf(name.toLowerCase()) > -1;
  };

  this.checkIfCreatorIsSelected = function(creatorId) {
    return this.attr.creatorExternalIds.indexOf(creatorId) > -1;
  };

  this.setSelectedFiletypes = function(ev, data) {
    this.attr.filetypes = data.filetypes ? data.filetypes : [];
  };

  this.setSelectedCreatorIds = function(ev, data) {
    this.attr.creatorExternalIds = data.creatorExternalIds
      ? data.creatorExternalIds
      : [];
  };

  this.initializeArchivedFilter = function() {
    const hasArchivedProducts =
      this.select("productCardFilterHasArchivedProducts").length > 0;

    if (hasArchivedProducts) {
      this.showArchivedFilter();
    }
  };

  this.showArchivedFilter = function() {
    this.select("productCardFilterArchivedWrapper").removeClass("hidden");
    this.select("productCardCreatorFilterWrapper").addClass(
      "with-border-bottom"
    );
  };

  this.handleFiletypeFilter = function(ev) {
    const $filetype = $(ev.target || ev.srcElement);
    const filetypeLabel = $filetype.data().filetype.toLowerCase();

    if ($filetype.attr("checked")) {
      this.setFiletypeAttribute(filetypeLabel);
    } else {
      this.removeFiletypeAttribute(filetypeLabel);
    }

    this.updateResults();
  };

  this.setFiletypeAttribute = function(filetypeLabel) {
    this.attr.filetypes.push(filetypeLabel);
  };

  this.removeFiletypeAttribute = function(filetypeLabel) {
    const index = this.attr.filetypes.indexOf(filetypeLabel);
    if (index > -1) {
      this.attr.filetypes.splice(index, 1);
    }
  };

  this.getFiletypeFilterRow = function(name, count) {
    const isFiletypeCheckedAttr = this.checkIfFiletypeIsSelected(name)
      ? "checked"
      : "";
    const filetypesSelectorClass = this.attr.productCardFiletypesSelector.substring(
      1
    );
    const filetypesInputSelectorClass = this.attr.productCardFiletypesInputSelector.substring(
      1
    );

    return FiletypeFilterRowTemplate({
      filetypesSelectorClass: filetypesSelectorClass,
      isFiletypeCheckedAttr: isFiletypeCheckedAttr,
      filetypesInputSelectorClass: filetypesInputSelectorClass,
      name: name,
      count: count
    });
  };

  this.addCreatorOptions = function(ev, data) {
    this.attr.creatorCounts = data.creatorCounts;

    if (!this.attr.creatorCounts || this.attr.creatorCounts.length === 0) {
      return;
    }

    this.select("productCardCreatorListSelector").empty();

    this.initializeCreatorInputs();
    this.initializeCreatorCheckedStates();

    if (this.attr.creatorExternalIds.length == 0) {
      this.setAllCreatorsFilter(true);
    }
  };

  this.initializeCreatorInputs = function() {
    let numberOfCreators = 0;
    for (const creatorMetaString in this.attr.creatorCounts) {
      const creatorMeta = JSON.parse(creatorMetaString);
      const username = creatorMeta[0];
      const name = creatorMeta[1];
      const id = creatorMeta[2];
      const count = this.attr.creatorCounts[creatorMetaString];
      this.select("productCardCreatorListSelector").append(
        this.getCreatorFilterRow(name, username, id, count)
      );
      numberOfCreators++;

      if (
        numberOfCreators > this.attr.maxCreatorFiltersOnLoad &&
        !this.attr.showMoreCreators
      ) {
        this.hideCreatorFilterRow(numberOfCreators);
        this.showAllCreatorsLoadMore();
      }
    }

    if (numberOfCreators > 1) {
      this.select("productCardCreatorFilterWrapper").removeClass("hidden");
    }
  };

  this.initializeCreatorCheckedStates = function() {
    for (const id of this.attr.creatorExternalIds) {
      this.select("productCardCreatorInputSelector")
        .filter(`[data-id="${id}"]`)
        .attr("checked", true);
    }
  };

  this.setAllCreatorsFilter = function(value) {
    this.select("productCardFilterAllCreatorsInputSelector").attr(
      "checked",
      value
    );
    this.select("productCardFilterAllCreatorsInputSelector").attr(
      "disabled",
      value
    );
  };

  this.handleCreatorFilter = function(ev) {
    const $creator = $(ev.target || ev.srcElement);
    const id = $creator.data().id;

    if ($creator.attr("checked")) {
      this.setCreatorAttribute(id);
      this.setAllCreatorsFilter(false);
    } else {
      this.removeCreatorAttribute(id);
    }
    this.updateResults();
  };

  this.handleAllCreatorsFilter = function(ev) {
    this.attr.creatorExternalIds = [];
    for (const id of this.attr.creatorExternalIds) {
      this.select("productCardCreatorInputSelector")
        .filter(`[data-id="${id}"]`)
        .attr("checked", false);
    }
    this.updateResults();
  };

  this.setCreatorAttribute = function(creatorId) {
    this.attr.creatorExternalIds.push(creatorId.toString());
  };

  this.removeCreatorAttribute = function(creatorId) {
    const index = this.attr.creatorExternalIds.indexOf(creatorId.toString());
    if (index > -1) {
      this.attr.creatorExternalIds.splice(index, 1);
    }
  };

  this.getCreatorFilterRow = function(name, username, id, count) {
    const isCreatorCheckedAttr = this.checkIfCreatorIsSelected(id)
      ? "checked"
      : "";
    const creatorSelectorClass = this.attr.productCardCreatorSelector.substring(
      1
    );
    const creatorInputSelectorClass = this.attr.productCardCreatorInputSelector.substring(
      1
    );

    return CreatorFilterRowTemplate({
      creatorSelectorClass: creatorSelectorClass,
      isCreatorCheckedAttr: isCreatorCheckedAttr,
      creatorInputSelectorClass: creatorInputSelectorClass,
      name: name,
      username: username,
      id: id,
      count: count
    });
  };

  this.displayResultCount = function(start, resultCount, totalFormatted) {
    const end = start + resultCount - 1;
    this.select("productCardFilterResultsSelector").text(
      I18n.t("js.search_results", {
        range: `1-${end}`,
        total: totalFormatted
      })
    );
  };

  this.updateEmptyState = function() {
    if (this.attr.uiContext == "LIBRARY") {
      this.trigger("uiNeedsToShowEmptyLibraryState", {
        fromArchivingAction: true,
        showingArchivedOnly: this.attr.showArchivedOnly
      });
      this.trigger("uiToToggleFilterVisibility", { show: false });
    }
  };

  this.adjustResultCount = function(_ev, data) {
    this.attr.resultCount = data.increment
      ? this.attr.resultCount + 1
      : this.attr.resultCount - 1;
    this.displayResultCount(
      this.attr.start,
      this.attr.resultCount,
      this.attr.resultCount
    );

    if (this.attr.resultCount == 0) {
      this.updateEmptyState();
    }
  };

  this.updateInputs = function(_ev, data) {
    this.select("productCardFilterSortSelector").val(data.sort);
    this.select("productCardFilterMinPriceSelector").val(data.minPrice);
    this.select("productCardFilterMaxPriceSelector").val(data.maxPrice);
    this.select("productCardFilterRatingSelector")
      .find(`input[value=${data.rating}]`)
      .attr({ checked: true });
    this.attr.rating = data.rating;
  };

  this.updateFilters = function(_ev, data) {
    const {
      totalFormatted,
      resultCount,
      start,
      uiState,
      sort,
      minPrice,
      maxPrice,
      rating,
      showArchivedOnly,
      uiContext,
      creatorExternalIds
    } = data;

    this.attr.start = start;
    this.attr.resultCount = resultCount;
    this.attr.totalFormatted = totalFormatted;
    this.attr.uiContext = uiContext;
    this.attr.showArchivedOnly = showArchivedOnly;
    this.attr.creatorExternalIds = creatorExternalIds;

    switch (uiState) {
      case ProductCardUIStates.CATEGORY_TOP_PRODUCTS:
      case ProductCardUIStates.PRODUCT_RESULTS:
      case ProductCardUIStates.PRODUCT_RESULTS_IN_CATEGORY:
      case ProductCardUIStates.PRODUCT_RESULTS_ON_PROFILE:
      case ProductCardUIStates.SHOW_CATEGORY_CARDS:
        this.displayResultCount(start, resultCount, totalFormatted);
        break;
      case ProductCardUIStates.NO_RESULTS:
        this.select("productCardFilterResultsSelector").text(
          I18n.t("js.no_search_results")
        );
        break;
      case ProductCardUIStates.LOADING:
        this.select("productCardFilterResultsSelector").text(
          I18n.t("js.search_results_loading")
        );
    }

    const filtersActive =
      sort !== undefined ||
      minPrice !== undefined ||
      maxPrice !== undefined ||
      rating !== undefined;

    this.select("productCardMobileFilterSelector").toggleClass(
      this.attr.filtersActiveClass,
      filtersActive
    );
  };

  this.disableAllFilters = function() {
    this.select("productCardFilterSortSelector").attr("disabled", true);
    this.select("productCardFilterMinPriceSelector").attr("disabled", true);
    this.select("productCardFilterMaxPriceSelector").attr("disabled", true);
    this.select("productCardFilterRatingSelector").attr("disabled", true);
    this.select("productCardFilterTagInputSelector").attr("disabled", true);
  };

  this.enableAllFilters = function() {
    this.select("productCardFilterSortSelector").attr("disabled", false);
    this.select("productCardFilterMinPriceSelector").attr("disabled", false);
    this.select("productCardFilterMaxPriceSelector").attr("disabled", false);
    this.select("productCardFilterRatingSelector").attr("disabled", false);
    this.select("productCardFilterTagInputSelector").attr("disabled", false);
  };

  this.after("initialize", function() {
    this.initializeTags();
    this.initializeArchivedFilter();
    this.on(document, "uiToSetProductCardFilters", this.updateFilters);
    this.on(document, "uiToUpdateProductCardFilterInputs", this.updateInputs);
    this.on(document, "uiToDisableAllFilters", this.disableAllFilters);
    this.on(document, "uiToEnableAllFilters", this.enableAllFilters);
    this.on(document, "uiUpdatedTags", this.updateTags);
    this.on(document, "uiToAddFiletypeOptions", this.addFiletypeOptions);
    this.on(document, "uiToAddCreatorOptions", this.addCreatorOptions);
    this.on(document, "uiToSetSelectedFiletypes", this.setSelectedFiletypes);
    this.on(document, "uiToSetSelectedCreatorIds", this.setSelectedCreatorIds);
    this.on(document, "uiToAdjustResultCount", this.adjustResultCount);
    this.on(document, "uiToUpdateSortFilter", this.updateSortFilterSelector);
    this.on(
      document,
      "uiNeedsToSetHasArchivedProducts",
      this.showArchivedFilter
    );
    this.on("click", {
      productCardFilterRatingSelector: this.handleRatingFilter,
      productCardMobileFilterSelector: this.toggleMobileFilters,
      productCardFilterShowMoreTagsSelector: this.showMoreTags,
      productCardFilterShowMoreFiletypesSelector: this.showMoreFiletypes,
      productCardFilterShowMoreCreatorsSelector: this.showMoreCreators
    });

    this.on("keyup", {
      productCardFilterMinPriceSelector: this.handlePriceFilter,
      productCardFilterMaxPriceSelector: this.handlePriceFilter
    });

    this.on("change", {
      productCardFilterMinPriceSelector: this.handlePriceFilter,
      productCardFilterMaxPriceSelector: this.handlePriceFilter,
      productCardFiletypesInputSelector: this.handleFiletypeFilter,
      productCardCreatorInputSelector: this.handleCreatorFilter,
      productCardFilterArchivedInputSelector: this.handleArchivedFilter,
      productCardFilterSortSelector: this.updateSort,
      productCardFilterTagInputSelector: this.handleTagFilter,
      productCardFilterAllProductsTagInputSelector: this
        .handleAllProductsTagFilter,
      productCardFilterAllCreatorsInputSelector: this.handleAllCreatorsFilter
    });
  });
}

module.exports = defineComponent(ProductCardFilters, WithUrl);
