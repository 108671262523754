"use strict";

const defineComponent = require("flight/lib/component");
const WithFacebookPixel = require("$app/ui/shared/with_facebook_pixel");

function FacebookPixel() {
  this.startTrackingForSeller = function(ev, data) {
    const id = data.facebookPixelId;
    if (id) {
      this.sellerFbPixelInit(id.toString()); // The FB API requires the id to be a string
    }
  };

  this.trackProductEvent = function(ev, data) {
    switch (data.action) {
      case "viewed":
        this.sellerFbPixelTrack("ViewContent", {
          content_ids: [data.permalink],
          content_type: "product"
        });
        break;
      case "iwantthis":
        this.sellerFbPixelTrack("InitiateCheckout", {
          content_ids: [data.permalink],
          content_type: "product"
        });
        break;
      case "purchased":
        if (data.trackFreeSales || data.value !== 0) {
          this.sellerFbPixelTrack("Purchase", {
            content_ids: [data.permalink],
            content_type: "product",
            value: data.value / (data.valueIsSingleUnit ? 1 : 100), // Value in main currency unit
            currency: data.currency
          });
        }
        break;
    }
  };

  this.after("initialize", function() {
    this.on(
      "uiNeedsToStartTrackingForSellerFacebookPixel",
      this.startTrackingForSeller
    );
    this.on(
      "uiNeedsToTrackActivityWithSellerFacebookPixel",
      this.trackProductEvent
    );
  });
}

module.exports = defineComponent(FacebookPixel, WithFacebookPixel);
