"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(JavascriptLoaderIndicator);

  function JavascriptLoaderIndicator() {
    this.after("initialize", function() {
      this.$node.fadeOut();
    });
  }
});
