"use strict";

/**
 * This mixin contains a convenient method that operates on URL.
 */
define(["query-string"], function(queryString) {
  return WithUrl;

  function WithUrl() {
    this.getUrlParams = function() {
      if (typeof this.urlParams == "undefined") {
        var query = window.location.search;
        this.urlParams = queryString.parse(query);
      }

      return this.urlParams;
    };
  }
});
