"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(UpgradeGateTriggers);

  function UpgradeGateTriggers() {
    this.defaultAttrs({
      upgradeGateTriggerSelector: ".js-upgrade-gate-trigger",
      upgradeGatePopoverSelector: ".js-upgrade-gate-popover",
      upgradeMessageSelector: ".js-upgrade-message"
    });

    function isTriggerGated($trigger) {
      return $trigger.data("gated");
    }

    this.disableGateTriggers = function() {
      this.select("upgradeGateTriggerSelector").each(function() {
        var $trigger = $(this);

        if (isTriggerGated($trigger)) {
          $trigger.attr("disabled", true);
        }
      });
    };

    this.shakeTrigger = function(ev) {
      var $trigger = $(ev.target || ev.srcElement);

      if (isTriggerGated($trigger)) {
        ev.preventDefault();
        ev.stopPropagation();

        if ($trigger.hasClass("button")) {
          $trigger.removeClass("shake");
          setTimeout(function() {
            $trigger.addClass("shake");
          }, 0);
        }
      } else {
        this.hidePopover();
      }
    };

    this.showPopover = function(ev) {
      var $trigger = $(ev.target || ev.srcElement),
        $popover = this.select("upgradeGatePopoverSelector");

      if (
        $trigger.data("gate-message") &&
        $trigger.data("gate-message").length > 0
      ) {
        this.select("upgradeMessageSelector").text(
          $trigger.data("gate-message")
        );

        $popover.css({
          top: $trigger.offset().top + $trigger.outerHeight() + 15,
          left:
            $trigger.offset().left -
            $popover.outerWidth() / 2 +
            $trigger.outerWidth() / 2
        });
        $popover.addClass("showing");

        clearTimeout(this.attr.hidePopoverTimeout);
      }
    };

    this.hidePopover = function() {
      this.attr.hidePopoverTimeout = setTimeout(
        function() {
          var $popover = this.select("upgradeGatePopoverSelector");

          if (!$popover.is(":hover")) {
            this.select("upgradeGatePopoverSelector").removeClass("showing");
          }
        }.bind(this),
        400
      );
    };

    this.after("initialize", function() {
      this.disableGateTriggers();

      this.on(
        document,
        "uiShouldDisableGateTriggers",
        this.disableGateTriggers
      );

      this.on(document, "uiShouldHideUpgradePopovers", this.hidePopover);

      this.on("click", { upgradeGateTriggerSelector: this.shakeTrigger });
      this.on("mouseover", { upgradeGateTriggerSelector: this.showPopover });
      this.on("mouseout", {
        upgradeGateTriggerSelector: this.hidePopover,
        upgradeGatePopoverSelector: this.hidePopover
      });
    });
  }
});
