"use strict";

define(["jwplayer", "$app/utils/guid_generator"], function(
  jwplayer,
  GuidGenerator
) {
  jwplayer = window.jwplayer;
  return WithVideoPlayer;

  function WithVideoPlayer() {
    this.defaultAttrs({
      videoPlayerContainerSelector: ".js-video-player-container",
      videoPlayerSelector: ".js-video-player-container .js-player",
      fakeVideoUrlGuidForObfuscation: "ef64f2fef0d6c776a337050020423fc0"
    });

    this.stopVideo = function(ev) {
      if (this.attr.videoPlayerName && ev.data == "stopPreviews") {
        jwplayer(this.attr.videoPlayerName).pause(true);
      }
    };

    this.initializeVideoPlayer = function(args) {
      var $playerContainer = this.select("videoPlayerContainerSelector");
      var playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 2, 3];

      if (
        !$playerContainer.data("playlist") ||
        $playerContainer.data("playlist") === ""
      )
        return;
      this.select("videoPlayerSelector").attr(
        "id",
        "video-player-" + GuidGenerator.generate()
      );
      this.attr.videoPlayerName = this.select("videoPlayerSelector").attr("id");

      var option = {
        width: args && args.width ? args.width : "100%",
        height: args && args.height ? args.height : "100%",
        skin: {
          name: "bekle"
        },
        preload: "auto",
        playbackRateControls: playbackRates,
        playlist: this.formatPlaylist($playerContainer.data("playlist")),
        rtmp: { bufferlength: 4 }
      };

      jwplayer.key = "PRMTi+uBqCMpZ2jh7k9d+l9L0vVPhoqctLGtiiOz78E=";
      var player = jwplayer(this.attr.videoPlayerName);
      player.setup(option);
      player.on("ready", function() {
        if (typeof $playerContainer.data("index-to-play") !== "undefined") {
          player.playlistItem($playerContainer.data("index-to-play"));
        }
      });
    };

    this.formatPlaylist = function(playlistData) {
      for (
        var playlistIndex = 0;
        playlistIndex < playlistData.length;
        ++playlistIndex
      ) {
        var videoData = playlistData[playlistIndex];
        for (
          var sourceIndex = 0;
          sourceIndex < videoData.sources.length;
          ++sourceIndex
        ) {
          videoData.sources[sourceIndex] = {
            file: videoData.sources[sourceIndex].replace(
              this.attr.fakeVideoUrlGuidForObfuscation,
              videoData.guid
            )
          };
        }
        delete videoData.guid;
      }
      return playlistData;
    };

    this.after("initialize", function() {
      if (window.addEventListener) {
        window.addEventListener("message", this.stopVideo.bind(this));
      } else {
        window.attachEvent("message", this.stopVideo.bind(this));
      }
    });
  }
});
