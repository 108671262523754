"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ConfirmEmailAddress);

  function ConfirmEmailAddress() {
    this.resendConfirmationEmail = function() {
      $.ajax({
        type: "POST",
        dataType: "JSON",
        url: Routes.resend_confirmation_settings_path()
      }).done(
        function(data) {
          this.trigger("dataResentConfirmationEmailWithMegaphone", data);
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToResendConfirmationEmailWithMegaphone",
        this.resendConfirmationEmail
      );
    });
  }
});
