"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Authentication);

  function Authentication() {
    this.login = function(ev, data) {
      this.sendRequest(
        Routes.login_path({ format: "json" }),
        data.serializedForm,
        "dataLoginSucceeded",
        "dataFailedToLogin"
      );
    };

    this.signup = function(ev, data) {
      this.sendRequest(
        Routes.signup_path({ format: "json" }),
        data.serializedForm,
        "dataSignupSucceeded",
        "dataFailedToSignup"
      );
    };

    this.renewPassword = function(ev, data) {
      this.sendRequest(
        Routes.forgot_password_path({ format: "json" }),
        data.serializedForm,
        "dataRenewPasswordSucceeded",
        "dataFailedToRenewPassword"
      );
    };

    this.sendRequest = function(url, data, successEvent, failureEvent) {
      $.ajax({
        type: "POST",
        url: url,
        dataType: "json",
        data: data
      }).done(
        function(res) {
          if (res.success) {
            this.trigger(successEvent, res);
          } else {
            this.trigger(failureEvent, res);
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToLogin", this.login);
      this.on(document, "uiNeedsToSignup", this.signup);
      this.on(document, "uiNeedsToRenewPassword", this.renewPassword);
    });
  }
});
