var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"receipt-entry\">\n    <div class=\"product-details\">\n      <h2 class=\"product-name\">"
    + alias1(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"total_amount",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":31},"end":{"line":4,"column":51}}}))
    + "</h2>\n      <span class=\"receipt-price\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"total_price_including_tax_and_shipping") : depth0), depth0))
    + "</span>\n    </div>\n  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"has_sales_tax_or_shipping_to_show") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"useData":true});