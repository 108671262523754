(function() {
  "use strict";

  define([], function() {
    return WithCookies;

    function WithCookies() {
      this.readCookie = function(name) {
        var cookieSet = document.cookie.split(";");
        for (var i = 0; i < cookieSet.length; i++) {
          var cookie = cookieSet[i].split("="),
            key = cookie[0].trim(),
            value = cookie[1];
          if (key === name && typeof value !== "undefined") {
            try {
              return JSON.parse(decodeURIComponent(value));
            } catch (e) {
              this.writeCookie(name, ""); // erase unreadable cookie
            }
          }
        }
        return false;
      };

      this.writeCookie = function(name, data) {
        document.cookie = name + "=" + encodeURIComponent(JSON.stringify(data));
      };
    }
  });
})();
