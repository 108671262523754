"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(UserProducts);

  function UserProducts() {
    this.getProductCard = function(ev, data) {
      $.ajax({
        type: "GET",
        dataType: "html",
        url: Routes.product_card_path(data.permalink, data)
      }).done(
        function(response) {
          this.trigger("dataGotAProductCardForGrid", {
            html: response,
            permalink: data.permalink
          });
        }.bind(this)
      );
    };

    this.getProduct = function(ev, data) {
      $.ajax({
        type: "GET",
        dataType: "html",
        url: Routes.display_path(data.permalink, data)
      }).done(
        function(response) {
          this.trigger("dataGotAProduct", {
            html: response,
            permalink: data.permalink
          });
        }.bind(this)
      );
    };

    this.savePageLayout = function(ev, data) {
      $.ajax({
        type: "PUT",
        dataType: "json",
        url: Routes.user_save_page_layout_path(),
        data: data
      });
    };

    this.after("initialize", function() {
      this.on("uiNeedsToSavePageLayout", this.savePageLayout);
      this.on("uiNeedsAProductCard", this.getProductCard);
      this.on("uiNeedsAProduct", this.getProduct);
    });
  }
});
