"use strict";

/**
 * Different contexts for rendering product cards/filters/pagination
 */
export const ProductCardUIContext = {
  DISCOVER: "DISCOVER",
  PROFILE: "PROFILE",
  LIBRARY: "LIBRARY"
};

/**
 * Different UI states for contexts with product cards
 */
export const ProductCardUIStates = {
  LOADING: "LOADING",
  NO_RESULTS: "NO_RESULTS",
  // Show the category's most popular products
  CATEGORY_TOP_PRODUCTS: "CATEGORY_TOP_PRODUCTS",
  // Show search results within a specific category
  PRODUCT_RESULTS_IN_CATEGORY: "PRODUCT_RESULTS_IN_CATEGORY",
  // Show search results on the creator profile
  PRODUCT_RESULTS_ON_PROFILE: "PRODUCT_RESULTS_ON_PROFILE",
  // Show search results under "Everything"
  PRODUCT_RESULTS: "PRODUCT_RESULTS",
  // Show each category under "Everything"
  SHOW_CATEGORY_CARDS: "SHOW_CATEGORY_CARDS"
};

/**
 * Different ways that a user can manipulate the fetched product results.
 * They can try to change the category, adjust filters, select tags,
 * or enter a specific query. These can all layer on top of one another,
 * and each user interaction results in a fetch request marked with this type.
 */
export const ProductCardFetchTypes = {
  CATEGORY: "category",
  FILTER: "filter",
  TAG: "tag",
  SEARCH: "search",
  PAGINATION: "pagination",
  LOAD_MORE: "loadMore"
};

/**
 * Common class names
 */
export const ProductCardClassNames = {
  LOADING: "loading",
  HIDDEN: "hidden"
};
