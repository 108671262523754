var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span class=\"quantity\">× "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-unique-permalink=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"permalink") : depth0), depth0))
    + "\" data-price=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formattedPrice") : depth0), depth0))
    + "\" data-sha=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sha") : depth0), depth0))
    + "\">\n  <div class=\"name\">\n    <a class=\"title\" href=\""
    + ((stack1 = __default(require("/app/app/javascript/templates/helpers/routes.js")).call(alias3,"short_link_path",(depth0 != null ? lookupProperty(depth0,"permalink") : depth0),{"name":"routes","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":67}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showQuantity") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <a href=\"#\" class=\"button-close remove\">"
    + alias2(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias3,"remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":58}}}))
    + "</a>\n  </div>\n  <div class=\"price-tag\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"currency") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formattedPrice") : depth0), depth0))
    + "</div>\n</li>\n";
},"useData":true});