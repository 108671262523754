"use strict";

define([
  "$app/ui/shared/auth_modal",
  "$app/ui/shared/signup_trigger",
  "$app/ui/shared/login_trigger",
  "$app/data/authentication"
], function(AuthModalUI, SignupTriggerUI, LoginTriggerUI, AuthenticationData) {
  function AuthModalSetup() {
    AuthenticationData.attachTo(document);

    AuthModalUI.attachTo(".modals-container");
    SignupTriggerUI.attachTo(".js-signup-trigger");
    LoginTriggerUI.attachTo(".js-login-trigger");
  }

  return AuthModalSetup;
});
