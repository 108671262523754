"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(LocaleSelector);

  function LocaleSelector() {
    this.defaultAttrs({
      localeTriggerSelector: ".js-locale-trigger"
    });

    this.updateUserLocale = function() {
      var newLanguage = this.select("localeTriggerSelector").val();
      var search = this.getWindowLocationSearch();
      if (search.length === 0) {
        search = "?locale=" + newLanguage;
      } else if (search.indexOf("locale=") >= 0) {
        search = search.replace(/(locale=)[^&]+/, "locale=" + newLanguage);
      } else {
        search += "&locale=" + newLanguage;
      }
      this.setWindowLocationSearch(search);
    };

    this.setWindowLocationSearch = function(searchUrl) {
      window.location.search = searchUrl;
    };

    this.getWindowLocationSearch = function() {
      return window.location.search;
    };

    this.after("initialize", function() {
      this.on("change", {
        localeTriggerSelector: this.updateUserLocale
      });
    });
  }
});
