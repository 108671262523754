"use strict";

define([
  "$app/ui/product/with_envelope",
  "$app/ui/product/with_credit_card_on_payment_form",
  "$app/ui/product/with_pay_what_you_want",
  "$app/ui/product/third_party_analytics",
  "$app/ui/shared/with_form_base",
  "$app/utils/with_url",
  "$app/utils/with_event",
  "flight/lib/compose"
], function(
  WithEnvelope,
  WithCreditCard,
  WithPayWhatYouWant,
  ThirdPartyAnalytics,
  WithFormBase,
  WithUrl,
  WithEventUtils,
  compose
) {
  return WithPaymentForm;

  function WithPaymentForm() {
    compose.mixin(this, [
      WithEnvelope,
      WithCreditCard,
      WithPayWhatYouWant,
      WithFormBase,
      WithUrl,
      WithEventUtils
    ]);

    this.defaultAttrs({
      paymentFormSelector: ".buy-form-main",
      paymentContainerSelector: ".payment-container",
      payButtonContainerSelector: ".pay_button_container",
      purchaseFormNoticeSelector: ".js-purchase-form-notice",
      permalinkSelector: ".permalink",
      payButtonSelector: ".pay_button",
      urlParamsSelector: ".url_parameters",
      fromProfileSelector: ".from_profile",
      fromMultiOverlaySelector: ".from-multi-overlay",
      purchaseFormSelector: ".purchase_form",
      changedMindButtonSelector: ".changed_mind_button",
      parentIdSelector: ".parent_id",
      referrerSelector: ".referrer",
      pluginsSelector: ".plugins",
      sourceSelector: ".source",
      isPreorderSelector: ".is_in_preorder_state",
      isPhysicalSelector: ".is_physical",
      wasProductRecommendedSelector: ".js-was-product-recommended",
      recommendedBySelector: ".js-recommended-by",
      purchaseFormRequiredFieldSelector: ".purchase_form .required",
      envelopeRequiredFieldSelector: ".envelope .required",
      authenticityTokenSelector: 'input[name="authenticity_token"]',
      utf8Selector: 'input[name="utf8"]',
      zipcodeFieldSelector: "input.js-cc_zipcode",
      bundleBuyButtonSelector: ".js-bundle-pay-trigger",
      localeSelector: ".js-locale",
      customFieldSelector: ".js-custom-field",
      isSamplePurchase: false,
      isProcessingPayment: false,
      isSingleUnit: false,
      offerCodeUsed: false,
      taxCountry: "",
      paymentFormPadding: 20
    });

    this.setLoginInfo = function() {
      if (this.attr.user.is_logged_in) {
        this.select("emailReceiptFormSelector").hide();
      } else {
        this.select("keepOnFileSelector").hide();
      }
    };

    this.payIfAllowed = function(ev) {
      ev && ev.preventDefault();

      if (
        this.attr.isShowingPaymentForm &&
        !this.attr.isProcessingPayment &&
        !this.attr.isProductDisabled
      ) {
        this.validatePaymentForm();
      }

      return false;
    };

    this.validatePaymentForm = function() {
      var valid = true;

      var $requiredInputs = this.isFreePurchase()
        ? this.select("envelopeRequiredFieldSelector")
        : this.select("purchaseFormRequiredFieldSelector");

      for (var i = 0; i < $requiredInputs.length; i++) {
        if (valid) valid = this.validateRequiredInput($($requiredInputs[i]));
      }

      valid =
        valid && this.validateEmailsIfNotAutoFill() && this.validateQuantity();

      if (!this.isFreePurchase()) {
        valid = valid && this.validateCreditCardForm();
      }

      if (valid) {
        this.showPaymentAsProcessing();
        this.verifyShippingAddress();
      }

      return valid;
    };

    this.isFreePurchase = function() {
      var $priceRange = this.select("priceRangeSelector"),
        freeShipping = parseFloat(this.attr.shippingCents) == 0.0,
        freePayWhatYouWantProduct =
          parseFloat($priceRange.val()) == 0.0 && freeShipping,
        freeBundle =
          this.attr.productsInBundle &&
          this.totalBundlePriceCents() == 0 &&
          this.totalBundleShippingRateCents() == 0,
        freeFixedPriceProduct =
          $priceRange.length == 0 &&
          parseFloat(this.select("perceivedPriceCentsSelector").val()) == 0.0 &&
          freeShipping;

      return !!(
        freePayWhatYouWantProduct ||
        freeBundle ||
        freeFixedPriceProduct
      );
    };

    this.makePayment = function() {
      this.attr.isProcessingPayment = true;

      if (this.isFreePurchase() || this.attr.isSamplePurchase) {
        this.setPasswordPromptForAccountCreationInReceipt();
      }

      this.select("changedMindButtonSelector").hide();
      this.select("receiptContainerSelector").fadeIn();

      this.processPayment();
      return false;
    };

    this.setPaymentAsProcessingForMultiBuyMode = function() {
      this.attr.isProcessingPayment = true;

      this.select("receiptContainerSelector").fadeIn();

      return true;
    };

    this.showPaymentAsProcessing = function() {
      var $payButton = this.select("payButtonSelector");

      this.select("paymentFormSelector").animate({
        height: this.getPaymentFormHeight()
      });
      $payButton.data("default", $payButton.text());
      this.showPayButtonAsProcessing();
      this.trigger("uiShouldHideTipsys");
    };

    this.showPayButtonAsProcessing = function() {
      const $payButton = this.select("payButtonSelector");
      $payButton.text(I18n.t("js.processing_notice"));
      $payButton.attr("disabled", true);
      this.select("payWithPaypalHolderSelector").hide();
      this.select("payWithCreditCardHolderSelector").hide();
    };

    this.resetPayButton = function() {
      var $payButton = this.select("payButtonSelector");
      $payButton.attr("disabled", false);
      $payButton.text($payButton.data("default"));
      this.select("payWithPaypalHolderSelector").show();
      this.select("payWithCreditCardHolderSelector").show();
    };

    this.processPayment = function() {
      this.trigger("uiNeedsToTrackProductSpecificEvent", {
        name: "process_payment",
        permalink: this.attr.permalink,
        wasProductRecommended: this.attr.wasProductRecommended
      });

      this.trigger("uiSetFriendValue");

      if (this.isFreePurchase()) {
        this.setCreditCardFormInUse(false);
        this.processPaymentWithCardParams({});
      } else {
        this.setCreditCardFormInUse(true);
        this.getCardParams(
          function(data) {
            this.processPaymentWithCardParams(data.cardParams);
          }.bind(this)
        );
      }
    };

    this.changeUiStateToPaymentProcessing = function() {
      this.select("changedMindButtonSelector").hide();
      this.select("receiptContainerSelector").fadeIn();
      this.attr.isProcessingPayment = true;
      this.showPayButtonAsProcessing();
    };

    this.resetPaymentProcessingUiState = function() {
      this.resetPayButton();
      this.attr.isProcessingPayment = false;
      this.select("receiptContainerSelector").fadeOut();
      this.select("changedMindButtonSelector").show();
    };

    this.processPaymentWithCardParams = function(cardParams) {
      const triggerUiNeedsToMakePayment = recaptchaResponse => {
        this.changeUiStateToPaymentProcessing();

        var serializedForm =
          this.select("wantFormSelector").serialize() +
          "&" +
          this.select("purchaseFormSelector").serialize() +
          "&" +
          $.param(cardParams) +
          "&" +
          $.param({ tax_country_election: this.attr.taxCountry }) +
          "&" +
          $.param({ vat_id: this.attr.vatId }) +
          "&" +
          $.param({ is_rental: this.attr.isRentalModeActive });
        if (this.attr.isRecurringBilling) {
          serializedForm +=
            "&" + $.param({ price_id: this.attr.selectedPriceInfo.id });
        }
        if (recaptchaResponse) {
          serializedForm +=
            "&" + $.param({ "g-recaptcha-response": recaptchaResponse });
        }

        this.trigger("uiNeedsToMakePayment", {
          permalink: this.attr.permalink,
          is_bundled: false,
          payingWithPayPal: this.attr.payingWithPayPal,
          paypalOrderDetails: this.attr.paypalOrderDetails,
          serializedForm: serializedForm
        });
      };

      this.resetPaymentProcessingUiState();
      this.activateRecaptcha(triggerUiNeedsToMakePayment);
      // Hack to show the "Pay" button as disabled while the recaptcha overlay loads
      // We need to do this because the recaptcha API does not expose the hooks we want: onClose and/or onOpen
      this.select("payButtonSelector").prop("disabled", true);
      setTimeout(() => {
        this.select("payButtonSelector").prop("disabled", false);
      }, 1000);
    };

    this.activateRecaptcha = function(callback) {
      // Create a copy of the recaptcha widget div and delete the old one so we're able to re-render the widget.
      let $recaptcha = $("#payButtonRecaptcha");
      $recaptcha.replaceWith($recaptcha.clone().empty());

      // Render and execute reCAPTCHA
      const recaptchaWidgetId = grecaptcha.render($recaptcha.attr("id"), {
        sitekey: $recaptcha.data("sitekey"),
        size: "invisible",
        badge: "inline",
        callback: callback
      });
      grecaptcha.execute(recaptchaWidgetId);
    };

    this.notifyUserAnalytics = function(ev, data) {
      this.trigger("uiNeedsToTrackActivityWithSellerAnalytics", {
        permalink: data.permalink,
        action: "purchased",
        value: data.non_formatted_price,
        valueIsSingleUnit: this.attr.isSingleUnit,
        currency: data.currency_type.toUpperCase()
      });

      this.trigger("uiNeedsToTrackECommerceEventWithSellerAnalytics", {
        permalink: data.permalink,
        purchase_external_id: data.id,
        currency: data.currency_type.toUpperCase(),
        product_name: data.name,
        price: data.non_formatted_price,
        priceIsSingleUnit: this.attr.isSingleUnit,
        quantity: data.quantity,
        tax: data.non_formatted_seller_tax_amount
      });
    };

    this.getThirdPartyAnalytics = function(ev, data) {
      this.trigger(this.$node, "uiToAddThirdPartyAnalytics", {
        permalink: data.permalink,
        purchase_id: data.id
      });
    };

    this.notifyPurchaseAndShowReceipt = function(ev, data) {
      this.attr.isProcessingPayment = false;

      this.select("payButtonSelector").attr("disabled", false);

      if (this.attr.permalink == data.permalink) {
        this.fillReceiptWithPurchaseDetails(data);

        this.attr.user.product.remaining = data.remaining;
        this.attr.user.product.variants = data.variants;
        this.hideGiftInputs();

        this.showReceipt(true);

        this.attr.purchaseWasGift =
          this.select("giftSignifierSelector").val() == "true";
      }
    };

    this.notifyClearCvc = function() {
      this.clearCvc();
    };

    this.stopProcessingAndShowError = function(ev, data) {
      if (data.zipcode_required) {
        this.showZipcodeField(data.card_country);
        this.select("zipcodeFieldSelector").addClass("required error");
      }

      if (this.attr.permalink == data.permalink) {
        var error_message =
          data && data.error_message
            ? data.error_message
            : I18n.t("js.something_went_wrong_notice");
        if (
          !this.select("creditCardHolderSelector").is(":visible") ||
          !this.select("envelopeSelector").is(":visible")
        ) {
          this.trigger("uiShowFlashMessage", { message: error_message });
        } else {
          this.popupCreditCardError(error_message);
        }

        this.resetPayButton();
        this.select("changedMindButtonSelector").show();
        this.select("receiptContainerSelector").fadeOut(
          function() {
            this.select("paymentFormSelector").animate({
              height: this.getPaymentFormHeight()
            });
          }.bind(this)
        );

        this.attr.isProcessingPayment = false;
      }
    };

    this.showCheckEmailNotice = function(ev, data) {
      this.attr.isProcessingPayment = false;

      this.select("payButtonSelector").attr("disabled", false);

      if (this.attr.permalink === data.permalink) {
        this.hideGiftInputs();
        this.showCheckEmailReceipt();

        this.attr.purchaseWasGift =
          this.select("giftSignifierSelector").val() == "true";
      }
    };

    this.getPaymentFormHeight = function() {
      var $paymentContainer = this.select("paymentContainerSelector");

      if ($paymentContainer.length > 0) {
        var height =
          $paymentContainer.height() + this.attr.paymentFormPadding * 2;

        if (this.attr.paymentFormHeight > 0) {
          height = this.attr.paymentFormHeight;
          this.setPaymentFormHeight(0);
        }

        return height;
      }

      return 0;
    };

    this.setPaymentFormHeight = function(data) {
      this.attr.paymentFormHeight = data;
    };

    this.resizePaymentForm = function() {
      if (this.attr.isShowingPaymentForm) {
        this.select("paymentFormSelector").height(this.getPaymentFormHeight());
      }
    };

    this.resetAndShowProductMain = function(ev) {
      ev.preventDefault();

      this.switchToProductMain(true);
      return false;
    };

    this.disablePayButton = function() {
      this.disablePayPalButton();
      this.select("payButtonSelector").attr("disabled", true);
    };

    this.enablePayButton = function() {
      this.enablePayPalButton();
      this.select("payButtonSelector").attr("disabled", false);
    };

    // This is only used for multi-buy
    this.getSerializedFormExcludingCreditCard = function() {
      var formSelector =
        "textarea, select, input:not(" +
        this.attr.utf8Selector +
        ", " +
        this.attr.authenticityTokenSelector +
        ", " +
        this.attr.permalinkSelector +
        ", " +
        this.attr.isPreorderSelector +
        ", " +
        this.attr.isPhysicalSelector +
        ", " +
        this.attr.parentIdSelector +
        ", " +
        this.attr.saveCardSelector +
        ")";

      return this.select("purchaseFormSelector")
        .find(formSelector)
        .serialize();
    };

    this.updateUrlParameters = function(_ev, data) {
      this.select("urlParamsSelector").val(JSON.stringify(data));
    };

    this.updatePurchaseFormNotice = function(_ev, data) {
      this.select("purchaseFormNoticeSelector").text(data.text);
    };

    this.validateBundleCheckoutForm = function() {
      var valid = true,
        $fields = this.select("bundleCheckoutFormSelector").find(
          ".required:visible, .required-placeholder:visible"
        );
      $.each(
        $fields,
        function(idx, el) {
          if (!this.validateRequiredInput($(el))) {
            valid = false;
            return false;
          }
        }.bind(this)
      );
      return valid;
    };

    this.setCustomFieldsFromUrlParams = function() {
      if (!this.attr.customFields || !this.select("urlParamsSelector").val())
        return;

      const urlParams = JSON.parse(this.select("urlParamsSelector").val());

      this.attr.customFields.map(customField => {
        if (customField.name in urlParams) {
          this.select("customFieldSelector")
            .filter('[data-custom-field-name="' + customField.name + '"]')
            .val(urlParams[customField.name]);
        }
      });
    };

    this.after("initialize", function() {
      this.select("urlParamsSelector").val(JSON.stringify(this.getUrlParams()));
      this.select("referrerSelector").val(this.getReferrer());
      this.select("pluginsSelector").val(this.getPluginNames());
      this.select("sourceSelector").val(this.getSource());
      this.select("localeSelector").val(I18n.locale);
      this.select("fromProfileSelector").val(
        this.attr.onUserProfile ||
          // Count the purchase as "from the profile" if we started from the profile,
          // even if we're on a separate check out page.
          window.location.search.indexOf("from_profile=1") !== -1
      );
      this.select("fromMultiOverlaySelector").val(this.attr.onMultiOverlay);
      this.select("wasProductRecommendedSelector").val(
        this.attr.wasProductRecommended
      );
      this.select("recommendedBySelector").val(this.attr.recommendedBy);

      if (this.attr.isMobile) {
        this.select("payButtonSelector").addClass("small");
      }

      var thirdPartyAnalytics = this.select("productSelector").data(
        "seller-has-third-party-analytics"
      );
      if (thirdPartyAnalytics) {
        ThirdPartyAnalytics.attachTo(this.$node);
        this.trigger("uiThirdPartyAnalyticsEnabled");
      }

      this.on(document, "dataPurchaseSuccessful", this.notifyClearCvc);
      this.on(
        document,
        "dataPurchaseSuccessful",
        this.notifyPurchaseAndShowReceipt
      );
      this.on(document, "dataPurchaseSuccessful", this.notifyUserAnalytics);
      this.on(document, "dataPurchaseSuccessful", this.getThirdPartyAnalytics);
      this.on(document, "dataPurchaseFail", this.stopProcessingAndShowError);
      this.on(document, "dataPurchaseTimeout", this.showCheckEmailNotice);
      this.on(document, "uiShowProductMain", this.resetAndShowProductMain);
      this.on(document, "uiUpdateUrlParameters", this.updateUrlParameters);
      this.on(document, "uiNeedsToResizePaymentForm", this.resizePaymentForm);
      this.on(
        document,
        "uiShouldUpdatePurchaseFormNotice",
        this.updatePurchaseFormNotice
      );
      this.on(this.$node, "uiShouldDisablePayButton", this.disablePayButton);
      this.on(this.$node, "uiShouldEnablePayButton", this.enablePayButton);
      this.on(this.$node, "uiShouldResetPayButton", this.resetPayButton);
      this.on("click", {
        changedMindButtonSelector: this.resetAndShowProductMain,
        payButtonSelector: this.payIfAllowed
      });
    });
  }
});
