"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(InputPrefixes);

  function InputPrefixes() {
    this.after("initialize", function() {
      var $this = this.$node,
        $input = $this.next();

      if (!$input.is("input") && $this.prev().is("input")) {
        $input = $(this).prev();
      }

      $input.on("focus", function() {
        $this.addClass("focused");
      });

      $input.on("focusout", function() {
        $this.removeClass("focused");
      });
    });
  }
});
