"use strict";

define([], function() {
  var Environment = {
    ENVIRONMENTS: { PRODUCTION: "production", TEST: "test" },
    isProduction: function() {
      return this.current == this.ENVIRONMENTS.PRODUCTION;
    }
  };
  Environment.current = $('meta[property="gr:environment"]').attr("value");

  return Environment;
});
