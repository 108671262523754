"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(OpenInApp);

  function OpenInApp() {
    this.signupAndAddPurchaseToLibrary = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.save_to_library_path({ format: "json" }),
        dataType: "json",
        data: data.formData
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataSignupAndAddSuccess");
          } else {
            this.trigger("dataSignupAndAddFailure", {
              message: response.error_message
            });
          }
        }.bind(this)
      );
    };

    this.sendSMS = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.purchases_send_sms_path(),
        data: data,
        dataType: "json"
      })
        .done(
          function(data, textStatus, jqXHR) {
            this.trigger("dataSendSMSSuccess", data);
          }.bind(this)
        )
        .fail(
          function(jqXHR, textStatus, errorThrown) {
            this.trigger("dataSendSMSFailure", JSON.parse(jqXHR.responseText));
          }.bind(this)
        );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToSendMobileSMSCode", this.sendSMS);
      this.on(
        document,
        "uiNeedsToSignupWithPurchase",
        this.signupAndAddPurchaseToLibrary
      );
    });
  }
});
