"use strict";

define(["$app/ui/shared/with_d3_formatter", "flight/lib/compose"], function(
  withD3Formatter,
  compose
) {
  function StatsFormatter() {
    compose.mixin(this, [withD3Formatter]);

    this.getSalesFlowString = function(views, sales, total) {
      var viewsString = I18n.t("js.views.counting", { count: views }),
        salesString =
          sales > 0
            ? "<br/>" + I18n.t("js.sales.counting", { count: sales })
            : "",
        totalString =
          total > 0
            ? "<br/>" +
              "<strong>$" +
              this.formatMoney(total / 100).replace(".00", "") +
              "</strong>"
            : "";
      return viewsString + salesString + totalString;
    };

    this.getAudienceFlowString = function(newFollowers, total) {
      var newFollowersString = I18n.t("js.new_followers.counting", {
          count: newFollowers
        }),
        totalString =
          total > 0
            ? "<br/>" + I18n.t("js.total_followers.counting", { count: total })
            : "";
      return newFollowersString + totalString;
    };

    this.getConsumptionRetentionString = function(data) {
      return "<strong>" + data + "%</strong> " + I18n.t("js.retention");
    };
  }

  return StatsFormatter;
});
