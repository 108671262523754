"use strict";

define(["$app/utils/guid_generator", "$app/utils/delay"], function(
  GuidGenerator,
  delay
) {
  return WithOfferCode;

  function WithOfferCode() {
    this.defaultAttrs({
      offerCodeSelector: ".offer_code_name",
      offerCodeFieldSelector: ".offer_code_field",
      offerCodePSelector: ".js-offer-code-p"
    });

    this.setOfferCode = function() {
      var $offerCode = this.select("offerCodeSelector");

      if (this.attr.offerCodeUsed) {
        if (this.attr.user.product.offer_code.is_valid) {
          $offerCode.val(decodeURIComponent(this.attr.offerCodeName));
        } else {
          $offerCode.val(this.select("offerCodeFieldSelector").val());
          if (this.attr.user.product.offer_code.error_message !== undefined) {
            this.trigger("uiShowFlashMessage", {
              message: this.attr.user.product.offer_code.error_message
            });
          }
        }

        this.setPrice();
      }
    };

    this.updateOfferCode = function(_ev, data) {
      if (this.attr.productsInBundle) {
        this.updateOfferCodeForBundle(data);
      } else {
        this.updateOfferCodeForProduct(data);
      }
      this.trigger("uiShouldEnablePayButton");
    };

    this.updateOfferCodeForBundle = function(data) {
      for (var i = 0; i < this.attr.productsInBundle.length; i++) {
        var productData = data.products_data[this.attr.productsInBundle[i].uid],
          priceCents = this.attr.productsInBundle[i].originalPriceCents;
        if (productData) {
          if (!data.error_message) {
            if (productData.is_percent) {
              priceCents -= (
                priceCents *
                (productData.offer_code_amount / 100)
              ).toFixed();
            } else {
              priceCents -=
                productData.offer_code_amount *
                this.attr.productsInBundle[i].quantity;
            }
          }
          this.attr.productsInBundle[i].offerCodeName =
            productData.name || this.attr.offerCodeName;
          this.attr.productsInBundle[i].hasOfferCodeField = true;
        }
        this.attr.productsInBundle[i].perceivedPriceCents = priceCents;
      }
      this.updateBundleButton();
      if (data.error_message && this.attr.offerCodeUsed) {
        this.trigger("uiShowFlashMessage", { message: data.error_message });
      }
    };

    this.updateOfferCodeForProduct = function(data) {
      this.attr.user.product.offer_code.is_valid =
        !data.error_message && this.attr.offerCodeUsed;

      if (this.attr.user.product.offer_code.is_valid) {
        this.attr.user.product.offer_code.amount =
          data.products_data[this.attr.uid].offer_code_amount;
        this.attr.user.product.offer_code.is_percent =
          data.products_data[this.attr.uid].is_percent;
      } else {
        this.attr.user.product.offer_code.amount = 0;
        if (this.attr.offerCodeUsed) {
          this.trigger("uiShowFlashMessage", { message: data.error_message });
        }
      }

      this.setPrice();
    };

    this.removeLoadingIndicator = function() {
      this.select("offerCodeFieldSelector").removeClass("loading");
    };

    this.scheduleUpdateOfferCodeName = function() {
      this.select("offerCodeFieldSelector").addClass("loading");
      this.trigger("uiShouldDisablePayButton");
      // Schedule updateOfferCodeName to be called in the next event loop otherwise when reading the value of the
      // offer code input field the old pre-paste value will be returned if the user pasted it using the mouse (not ctrl-v).
      delay(
        function() {
          if (this.attr.productsInBundle) {
            this.updateOfferCodeNameForBundle();
          } else {
            this.updateOfferCodeNameForProduct();
          }
        }.bind(this),
        0
      );
    };

    this.updateOfferCodeNameForBundle = function() {
      this.setOfferCodeName(this.select("offerCodeFieldSelector").val());

      delay(
        function() {
          this.computeOfferDiscountForProducts(this.attr.productsInBundle);
        }.bind(this),
        2000
      );
    };

    this.updateOfferCodeNameForProduct = function() {
      this.setOfferCodeName(this.select("offerCodeFieldSelector").val());

      if (!this.attr.offerCodeUsed) {
        this.setPrice();
      }

      delay(
        function() {
          this.computeOfferDiscountForProducts([this.attr]);
        }.bind(this),
        2000
      );
    };

    this.computeOfferDiscountForProducts = function(products) {
      if (this.attr.offerCodeUsed) {
        var offerCodeData = {
          offer_code_name: this.attr.offerCodeName,
          products: {}
        };
        for (var i = 0; i < products.length; i++) {
          products[i].uid = products[i].uid || GuidGenerator.generate();
          offerCodeData.products[products[i].uid] = {
            permalink: products[i].permalink,
            quantity: products[i].quantity
          };
        }
        this.trigger("uiNeedsToComputeOfferDiscount", offerCodeData);
      } else {
        this.trigger("dataOfferCode", { products_data: {} });
      }
    };

    this.setOfferCodeName = function(name) {
      this.attr.usingSetOfferCode = true;
      this.attr.offerCodeName = name;
      this.select("offerCodeSelector").val(this.attr.offerCodeName);
      this.attr.offerCodeUsed = !!this.attr.offerCodeName;
    };

    this.isOfferCodeApplicable = function() {
      return (
        this.attr.offerCodeUsed && this.attr.user.product.offer_code.is_valid
      );
    };

    this.getOfferCodeNameFromUrl = function() {
      // Try to search the offer code in the query parameters
      if (typeof window.URLSearchParams === "function") {
        var offerCode = new URLSearchParams(location.search).get("offer_code");
        if (offerCode) {
          return offerCode;
        }
      }

      // Try to search the offer code in the URL
      var matches = location.pathname.match(/\/.*\/.*\/(.*)\//);
      if (!matches) {
        matches = location.pathname.match(/\/.*\/.*\/(.*)/);
      }
      return matches ? matches[1] : null;
    };

    this.isUsingPresetOfferCode = function() {
      return !this.attr.usingSetOfferCode && this.isOfferCodeApplicable();
    };

    this.updateOfferCodeFieldVisibility = function(ev, data) {
      const { visible } = data;
      if (visible) {
        this.select("offerCodePSelector").show();
      } else {
        this.select("offerCodePSelector").hide();
      }

      if (this.switchToPaymentForm && this.attr.isShowingPaymentForm) {
        this.switchToPaymentForm(false);
      }
    };

    this.after("initialize", function() {
      this.on(document, "dataOfferCode", this.updateOfferCode);
      this.on(document, "dataOfferCode", this.removeLoadingIndicator);
      this.on(
        document,
        "uiSetOfferCodeFieldVisibility",
        this.updateOfferCodeFieldVisibility
      );
      this.on("keyup", {
        offerCodeFieldSelector: this.scheduleUpdateOfferCodeName
      });
      this.on("paste", {
        offerCodeFieldSelector: this.scheduleUpdateOfferCodeName
      });

      if (this.attr.offerCodeUsed) {
        // don't remove if on edit page
        if (this.select("offerCodePSelector").data("on-edit") == null) {
          this.select("offerCodePSelector").remove();
        }
      }
    });
  }
});
