"use strict";

/**
 * Base page initializes components common to the most of flight pages.
 */
define([
  "$app/data/base_page",
  "$app/ui/shared/flash_message",
  "$app/ui/shared/link_with_event_tracking",
  "$app/ui/shared/click_to_select",
  "$app/ui/shared/tippable_elements",
  "$app/ui/shared/upgrade_gate_triggers",
  "$app/ui/shared/input_placeholders",
  "$app/ui/shared/input_prefixes",
  "$app/ui/shared/javascript_loader",
  "$app/ui/shared/with_navigation",
  "$app/ui/shared/facebook_share",
  "$app/ui/discover/search",
  "$app/utils/auth_modal_setup",
  "$app/utils/megaphone_setup",
  "$app/data/event",
  "$app/ui/shared/locale_selector"
], function(
  BasePageData,
  FlashMessageUI,
  LinkWithEventTrackingUI,
  ClickToSelectUI,
  TippableElementsUI,
  UpgradeGateTriggersUI,
  InputPlaceholdersUI,
  InputPrefixesUI,
  JavascriptLoaderUI,
  NavigationUI,
  FacebookShareUI,
  DiscoverSearchUI,
  AuthModalSetup,
  MegaphoneSetup,
  EventData,
  LocaleSelectorUI
) {
  var BasePage = {
    initialize: function() {
      $(document).ajaxSend(function(event, xhr, settings) {
        // add CSRF header to all AJAX requests
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $("meta[name=csrf-token]").attr("content")
        );
      });

      EventData.attachTo(document);
      BasePageData.attachTo(document);
      FlashMessageUI.attachTo(".js-flash-message");
      LinkWithEventTrackingUI.attachTo(".js-track-click-event");
      ClickToSelectUI.attachTo(".click_to_select");
      TippableElementsUI.attachTo("body");
      UpgradeGateTriggersUI.attachTo("body");
      InputPlaceholdersUI.attachTo(":input[placeholder]");
      InputPrefixesUI.attachTo(".input-prefix");
      JavascriptLoaderUI.attachTo(".js-javascript-loader-indicator");
      NavigationUI.attachTo(document);
      DiscoverSearchUI.attachTo(".js-discover-search");
      FacebookShareUI.attachTo(".js-facebook-share-trigger");
      LocaleSelectorUI.attachTo(".js-locale-holder-footer");
      AuthModalSetup();
      MegaphoneSetup();
    }
  };

  return BasePage;
});
