"use strict";

define([], function() {
  var CurrencyUtils = {
    /**
     * Public - Parses and returns an US currency string from an free form payment price input
     *
     * currencyString - Input from free-form price entry form. A string.
     *
     * currencyString can be compatible with either US or Euro locales
     * parser is lenient towards extraneous thousands separators
     *
     * Returns a US currency formatted string with separators removed and fractional if present
     */
    parseCurrencyString: function(currencyString) {
      if (!currencyString) {
        return null;
      }

      //Remove all non-numericals, period and comma
      var negativePrefix = currencyString.match(/\s*-/) ? "-" : "",
        parsedCurrency = currencyString.replace(/[^0-9.,]/g, ""),
        lastPeriodIndex = parsedCurrency.lastIndexOf("."),
        lastCommaIndex = parsedCurrency.lastIndexOf(","),
        thousandsPeriodIndexInBounds =
          lastPeriodIndex > 0 && lastPeriodIndex == parsedCurrency.length - 4,
        thousandsCommaIndexInBounds =
          lastCommaIndex > 0 && lastCommaIndex == parsedCurrency.length - 4;

      //US style formatting is given precedence in matching.

      //Check for US style formatting
      if (
        (lastPeriodIndex >= parsedCurrency.length - 3 && lastPeriodIndex > 0) ||
        thousandsCommaIndexInBounds
      ) {
        parsedCurrency = parsedCurrency.replace(/,/g, "");

        if (
          parsedCurrency.match(/\./g) &&
          parsedCurrency.match(/\./g).length == 1
        ) {
          parsedCurrency = parsedCurrency.replace(/,/g, "");
        } else {
          parsedCurrency = parsedCurrency.replace(/[^0-9]/g, "");
        }

        return negativePrefix + parsedCurrency;
      }

      //Check for Euro style formatting
      if (
        (lastCommaIndex >= parsedCurrency.length - 3 && lastCommaIndex > 0) ||
        thousandsPeriodIndexInBounds
      ) {
        parsedCurrency = parsedCurrency.replace(/\./g, "");

        if (
          parsedCurrency.match(/,/g) &&
          parsedCurrency.match(/,/g).length == 1
        ) {
          parsedCurrency = parsedCurrency.replace(",", ".");
        } else {
          parsedCurrency = parsedCurrency.replace(/[^0-9]/g, "");
        }

        return negativePrefix + parsedCurrency;
      }

      return lastCommaIndex == -1 && lastPeriodIndex == -1
        ? negativePrefix + parsedCurrency
        : null;
    }
  };
  return CurrencyUtils;
});
