"use strict";

/**
 * A mixin that contains useful functions for file upload.
 * This mixin should only be mixed into component which has form as its node.
 *
 * @attr {string} guidInputSelector (optional) - The selector for guid input field.
 * @attr {string} formSelector (optional) - The selector for a form. $node is used if it is not present.
 * @attr {string} uiUploadWithCorsEvent (optional) - The event to be triggered on upload when browser has cors support.
 * @attr {array of string tuples} fileNameReplacements (optional) - Defines text to be replaced and the string to replace it.
 * This is used to remove characters that may cause issues when rendering urls.
 * Example:
 * [
 *   ['#', '-']
 * ]
 *
 * in this situation all # characters will be replaced by - characters. This is needed when uploading images to S3 using CORS,
 * otherwise the server will fetch the wrong URL.
 */

define(["$app/utils/file", "$app/utils/mimetypes"], function(
  FileUtils,
  MimeTypes
) {
  function FileUpload() {
    this.uploadFile = function(ev) {
      var fileInput = this.getFileInputFromEvent(ev),
        fileName = this.getFileNameFromInput(fileInput);

      this.trigger(this.attr.uiUploadWithCorsEvent, {
        formId: this.getForm().attr("id"),
        guid:
          this.select("guidInputSelector").val() || FileUtils.generateGuid(),
        fileName: fileName,
        userExternalId: FileUtils.determineUserExternalIdForForm(
          this.getForm()
        ),
        file: fileInput
      });
    };

    this.getFileNameFromInput = function(fileInput) {
      if (typeof fileInput != "undefined") {
        var fileName;
        if (fileInput.value != null) {
          //ie
          fileName = fileInput.value.replace(/.*(\/|\\)/, "");
        } else {
          fileName =
            fileInput.fileName != null ? fileInput.fileName : fileInput.name;
        }
        return this.cleanFileName(fileName);
      }
    };

    this.cleanFileName = function(fileName) {
      if (!this.attr.fileNameReplacements || typeof fileName === "undefined") {
        return fileName;
      }
      for (var i = 0; i < this.attr.fileNameReplacements.length; i++) {
        var replacementTuple = this.attr.fileNameReplacements[i];
        var pattern = replacementTuple[0];
        var replacementStr = replacementTuple[1];
        fileName = fileName.replace(pattern, replacementStr);
      }
      return fileName;
    };

    this.validateFileName = function(fileName, allowedExtensions) {
      if (allowedExtensions) {
        var ext =
          fileName.indexOf(".") != -1
            ? fileName.replace(/.*[.]/, "").toLowerCase()
            : "";

        for (var i = 0; i < allowedExtensions.length; i++) {
          if (allowedExtensions[i].toLowerCase() == ext) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    };

    this.setFormAction = function(action) {
      this.getForm().data("default-target", this.getForm().attr("action"));
      this.getForm().attr("action", action);
    };

    this.setFormActionAsDefault = function() {
      this.getForm().attr("action", this.getForm().data("default-target"));
    };

    this.prepareFileInputAndButtonForIEIfNeeded = function(
      $fileInput,
      $button
    ) {
      if ($.browser.msie) {
        $fileInput.appendTo($button);
        $button.off("click");
      }
    };

    this.getFileInputFromEvent = function(ev) {
      return Modernizr.filereader ? ev.target.files[0] : ev.target;
    };

    this.resetFormInputValue = function(ev, data) {
      if (data.isS3) {
        for (var i = 0; i < data.originalFormData.length; i++) {
          this.getForm()
            .find("input[name='" + data.originalFormData[i].name + "']")
            .val(data.originalFormData[i].value);
        }
      }
      this.getForm()
        .find("input[type=file]")
        .val("");
    };

    this.getForm = function() {
      if (!!this.attr.formSelector) {
        return this.select("formSelector");
      } else {
        return this.$node;
      }
    };

    this.after("initialize", function() {
      if (!this.getForm().is("form") && !this.attr.formSelector) {
        throw "File upload mixin has to be attached to form or formSelector must be given.";
      }
    });
  }

  return FileUpload;
});
