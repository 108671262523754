"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(GoogleAnalytics);

  function GoogleAnalytics() {
    const VIEWED = "viewed",
      I_WANT_THIS = "iwantthis",
      PURCHASED = "purchased";

    const EVENT_TO_DESCRIPTION = {
      [VIEWED]: "viewed product",
      [I_WANT_THIS]: 'clicked "I want this!" button',
      [PURCHASED]: "purchased a product"
    };

    this.trackProductEvent = function(ev, data) {
      if (typeof ga !== "undefined") {
        let value = 0;
        if (typeof data.value !== "undefined") {
          value = parseInt(data.value); // Value in currency sub/fractional unit
        }
        const trackingMethodOptions = [
          "sellerTracker.send", // [trackerName].[Command]
          "event", // hitType
          "product-" + data.permalink, // eventCategory
          data.action, // eventAction
          EVENT_TO_DESCRIPTION[data.action], // eventLabel
          value // eventValue
        ];

        switch (data.action) {
          case VIEWED:
          case I_WANT_THIS:
            ga(...trackingMethodOptions);
            break;
          case PURCHASED:
            if (data.trackFreeSales || data.value !== 0) {
              ga(...trackingMethodOptions);
            }
            break;
        }
      }
    };

    this.trackVirtualPageView = function(ev, data) {
      if (typeof ga !== "undefined") {
        let page = window.location.pathname + window.location.search;
        const title = EVENT_TO_DESCRIPTION[data.action];

        switch (data.action) {
          case VIEWED:
            ga("sellerTracker.send", "pageview", { page: page, title: title });
            break;
          case I_WANT_THIS:
            page += "?" + data.action;
            ga("sellerTracker.send", "pageview", { page: page, title: title });
            break;
          case PURCHASED:
            if (data.trackFreeSales || data.value !== 0) {
              page += "?" + data.action;
              ga("sellerTracker.send", "pageview", {
                page: page,
                title: title
              });
            }
            break;
        }
      }
    };

    this.trackECommerceForSeller = function(ev, data) {
      if (
        typeof ga !== "undefined" &&
        (data.trackFreeSales || data.price !== 0)
      ) {
        // Value in main currency unit as a string
        const priceInMainCurrencyUnit = (
          data.price / (data.priceIsSingleUnit ? 1 : 100)
        ).toString();

        var item = {
          id: data.purchase_external_id,
          name: data.product_name,
          sku: data.permalink,
          price: priceInMainCurrencyUnit,
          quantity: data.quantity
        };

        var transaction = {
          id: data.purchase_external_id,
          affiliation: "Gumroad",
          revenue: priceInMainCurrencyUnit,
          tax: data.tax,
          currency: data.currency
        };

        ga("sellerTracker.ecommerce:addTransaction", transaction);
        ga("sellerTracker.ecommerce:addItem", item);
        ga("sellerTracker.ecommerce:send");
      }
    };

    this.startTrackingForSeller = function(ev, data) {
      if (typeof ga !== "undefined" && data.googleAnalyticsId) {
        if (data.googleAnalyticsCrossDomains) {
          var googleAnalyticsAttributes = {
            name: "sellerTracker",
            allowLinker: true
          };

          if (data.googleAnalyticsClientId) {
            googleAnalyticsAttributes.clientId = data.googleAnalyticsClientId;
          }

          ga(
            "create",
            data.googleAnalyticsId,
            "gumroad.com",
            googleAnalyticsAttributes
          );
          ga("sellerTracker.require", "linker");
          ga(
            "sellerTracker.linker:autoLink",
            $.map(data.googleAnalyticsCrossDomains.split(","), function(s) {
              return s.trim();
            })
          );
        } else {
          ga("create", data.googleAnalyticsId, "gumroad.com", {
            name: "sellerTracker"
          });
        }
        ga("sellerTracker.require", "ecommerce");
      }
    };

    this.after("initialize", function() {
      this.on(
        "uiNeedsToTrackActivityWithSellerGoogleAnalytics",
        this.trackProductEvent
      );
      this.on(
        "uiNeedsToTrackActivityWithSellerGoogleAnalytics",
        this.trackVirtualPageView
      );
      this.on(
        "uiNeedsToStartTrackingForSellerGoogleAnalytics",
        this.startTrackingForSeller
      );
      this.on(
        "uiNeedsToTrackECommerceEventWithSellerGoogleAnalytics",
        this.trackECommerceForSeller
      );
    });
  }
});
