"use strict";

/**
 * Async form submission mixin.
 *
 * @attr {string} submitEvent - The event that triggers form submission.
 * @attr {string} submitSucceededEvent - The event that will get triggered on submission success.
 * @attr {string} submitFailedEvent - The event that will get triggered on submission failure.
 */

define([], function() {
  return WithAsyncForm;

  function WithAsyncForm() {
    this.submitForm = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: data.url,
        data: data.serializedFormData
      }).done(
        function(res) {
          if (res.success) {
            this.trigger(this.attr.submitSucceededEvent, res);
          } else {
            this.trigger(this.attr.submitFailedEvent, res);
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(document, this.attr.submitEvent, this.submitForm);
    });
  }
});
