"use strict";

/**
 * This modules let the select event happens on attached dom on click.
 */
define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ClickToSelect);

  function ClickToSelect() {
    this.selectAll = function(ev) {
      this.$node.select();
      return false;
    };

    this.after("initialize", function() {
      this.on("click", this.selectAll);
    });
  }
});
