"use strict";

define([
  "$app/ui/shared/with_paypal_braintree",
  "$app/ui/shared/with_paypal_native",
  "flight/lib/compose"
], function(WithPayPalBraintree, WithPayPalNative, compose) {
  return WithPayPal;

  this.defaultAttrs({
    savedPayPalCardInUse: false,
    paypalBuyerEmail: null,
    payingWithPayPal: false,
    implementationInUse: null,
    // Braintree specific state variables
    braintreePaymentNonce: null,
    braintreeTransientCustomerStoreKey: null,
    // PayPal (native) specific state variables
    paypalPayerId: null
  });

  function WithPayPal() {
    compose.mixin(this, [WithPayPalBraintree, WithPayPalNative]);

    this.setupPayPalImplementations = function(ev, data) {
      var isProductOverlayOnDiscover =
        this.$node.parents("body#discover-page").length > 0 &&
        this.$node.hasClass("js-product-row");
      if (!data.clientToken || isProductOverlayOnDiscover) {
        return;
      }

      this.setupPayPalBraintreeImplementation(data.clientToken);
      // PaypalPhaseOne - Disabling native paypal button for purchase.
      // this.setupPayPalNativeImplementation();

      this.attr.paypalInitialized = true;
      this.enablePayPalButton();
    };

    this.handleBraintreePaypalButtonClick = function(ev) {
      ev && ev.preventDefault();
      if (this.attr.savedPayPalCardInUse) {
        this.setupPaymentFormForPayPalPayment();
        return false;
      }
      this.attr.implementationInUse = this.attr.PayPalImplementations.BRAINTREE;
      this.initiateBraintreePayPalPayment();
      return false;
    };

    this.preparePayPalPayment = function(callback) {
      if (this.attr.savedPayPalCardInUse) {
        callback({});
        return;
      }
      switch (this.attr.implementationInUse) {
        case this.attr.PayPalImplementations.NATIVE:
          this.prepareNativePayPalPayment(callback);
          break;
        case this.attr.PayPalImplementations.BRAINTREE:
          this.prepareBraintreePayPalPayment(callback);
          break;
      }
    };

    this.setupPaymentFormForPayPalPayment = function(ev, data) {
      this.attr.payingWithPayPal = true;

      if (data && "paypalBuyerEmail" in data) {
        this.setPayPalEmail(data.paypalBuyerEmail);
      }
      this.enablePayPalButton();

      if (this.select("showPaypalCardSelector").hasClass("showing-paypal")) {
        return;
      }
      this.select("showPaypalCardSelector").addClass("showing-paypal");

      if (this.attr.savedPayPalCardInUse) {
        this.setCreditCardFormState("saved");
      }

      this.setRequiredFromCardFields(false);
      this.select("rememberedSelector").hide();

      var email = this.attr.paypalBuyerEmail;

      this.select("customerPayPalEmailContainerSelector").text(email);
      this.select("showPaypalCardSelector").addClass("showing-paypal");

      if (this.select("paymentFormSelector").length) {
        this.setPaymentFormHeight(
          this.select("paymentFormSelector").outerHeight() - 58
        );
        setTimeout(
          function() {
            this.trigger("uiAnimatePaymentFormHeight", { animate: true });
          }.bind(this),
          500
        ); // delay to account for PayPal modal disappearing.
      }
    };

    this.resetPayPalPayment = function(ev, data) {
      this.resetBraintreePayPalPayment();
      this.resetNativePayPalPayment();

      this.cancelPayWithPaypal();
    };

    this.resetPayPalPaymentForPriceChange = function() {
      if (
        this.attr.implementationInUse == this.attr.PayPalImplementations.NATIVE
      ) {
        this.resetPayPalPayment();
      }
    };

    this.cancelPayWithPaypal = function(ev) {
      ev && ev.preventDefault();
      if (this.attr.paypalCancelButtonDisabled) {
        return;
      }
      this.attr.payingWithPayPal = false;

      if (this.attr.savedPayPalCardInUse) {
        this.setCreditCardFormState("new");
        if (!this.isCardSaveRequired()) {
          this.attr.savedPayPalCardInUse = false;
        }
      }

      this.enablePayPalButton();
      this.trigger("uiShouldResetPayButton");

      this.setRequiredFromCardFields(true);
      this.select("showPaypalCardSelector").removeClass("showing-paypal");
      if (this.attr.onProductPage || this.attr.onOverlayPage) {
        this.showStripeElements(
          ".js-product #card-number",
          ".js-product #card-expiry",
          ".js-product #card-cvc"
        );
      } else {
        this.showStripeElements();
      }

      if (this.select("paymentFormSelector").length) {
        this.setPaymentFormHeight(
          this.select("paymentFormSelector").outerHeight() + 58
        );
        this.trigger("uiAnimatePaymentFormHeight", { animate: true });
      }
    };

    this.enablePayPalButton = function() {
      if (!this.attr.paypalInitialized) {
        return;
      }

      // PaypalPhaseOne - Disabling native paypal button for purchase.
      this.showBraintreePaypalButton();
      // if (!this.isNativePayPalPaymentEnabled() || this.isCardSaveRequired()) {
      //   this.showBraintreePaypalButton();
      // } else {
      //   this.showNativePaypalButton();
      // }

      this.select("placeholderCardIconSelector").hide();
      this.select("payWithPayPalPromptSelector").show();
      this.select("defaultCreditCardIconSelector").show();
      this.select("orPayWithSelector").show();
      this.select("payPalLoadingIndicatorSelector").hide();
    };

    this.showBraintreePaypalButton = function() {
      this.select("grNativePaypalButtonSelector").hide();
      this.select("grBraintreePaypalButtonSelector").show();
    };

    this.showNativePaypalButton = function() {
      this.select("grBraintreePaypalButtonSelector").hide();
      this.select("grNativePaypalButtonSelector").show();
    };

    this.disablePayPalButton = function() {
      this.select("grPaypalButtonSelector").hide();
      this.select("orPayWithSelector").hide();
      this.select("payPalLoadingIndicatorSelector").show();
    };

    this.payingWithPayPal = function() {
      return this.attr.payingWithPayPal;
    };

    this.setPayPalEmail = function(email) {
      this.attr.paypalBuyerEmail = email;
      this.select("customerPayPalEmailContainerSelector").text(
        this.attr.paypalBuyerEmail
      );
    };

    this.setSavedPayPalCardInUse = function(email) {
      this.attr.payingWithPayPal = true;
      this.attr.savedPayPalCardInUse = true;

      this.setPayPalEmail(email);
      this.select("showPaypalCardSelector").addClass("showing-paypal");
    };

    this.after("initialize", function() {
      if (
        $('meta[property="gr:paypal:pay_with_paypal_enabled"]').attr("value") !=
        "true"
      ) {
        this.select("payPalLoadingIndicatorSelector").remove();
        this.select("defaultCreditCardIconSelector").show();
        return;
      }

      this.attr.PayPalImplementations = {
        NATIVE: "native",
        BRAINTREE: "braintree"
      };

      this.on("click", {
        cancelPayWithPaypalTrigger: this.cancelPayWithPaypal,
        grBraintreePaypalButtonSelector: this.handleBraintreePaypalButtonClick
      });

      this.on(document, "dataPurchaseFail", this.resetPayPalPayment);
      this.on(
        document,
        "dataBraintreeClientToken",
        this.setupPayPalImplementations
      );
      this.on(
        this.node,
        "uiTotalPriceCentsChanged",
        this.resetPayPalPaymentForPriceChange
      );
      this.on(
        this.node,
        "uiHasAuthorizedPayPalBuyer",
        this.setupPaymentFormForPayPalPayment
      );

      // when we are on product or overlay page, don't fetch braintree token on initialization and wait for payment process to start.
      if (
        !(
          this.attr.onDiscoverPage ||
          this.attr.onProductPage ||
          this.attr.onOverlayPage
        )
      ) {
        this.trigger("uiNeedsBraintreeClientTokenFetched", {
          isOverlay: this.attr.isOverlay,
          isMultiBuyEnabled:
            this.attr.onMultiOverlay || this.attr.isMultiBuyEnabled
        });
      }
    });
  }
});
