"use strict";

define([], function() {
  return WithFollow;

  function WithFollow() {
    this.defaultAttrs({
      followUserContainerSelector: ".js-follow-container",
      followUserOneClickButtonSelector: ".js-follow-user-one-click-trigger",
      followUserFormSelector: ".js-follow-user-form",
      followUserEmailSelector: ".js-follow-user-email",
      followUserButtonSelector: ".js-follow-user-trigger",
      followUserSpinnerSelector: ".js-follow-spinner-button",
      unfollowUserButtonSelector: ".js-unfollow-user-trigger",
      checkHTML: '<i class="gi gi-check"></i>',
      isOwner: false
    });

    this.followUser = function(ev) {
      ev.preventDefault();

      if (this.attr.isOwner) {
        this.trigger("uiShowFlashMessage", {
          message: I18n.t("js.cant_follow_self")
        });
        return;
      }

      if (this.select("followUserOneClickButtonSelector").is(":visible")) {
        this.select("followUserOneClickButtonSelector").text(
          I18n.t("js.following_creator")
        );
        this.trigger("uiNeedsToFollowUser", {
          seller_id: this.$node.data("user-id")
        });
      } else {
        if (
          this.select("followUserFormSelector").length &&
          this.validateForm(this.select("followUserFormSelector"))
        ) {
          this.select("followUserButtonSelector").hide();
          this.select("followUserSpinnerSelector").removeClass("hidden");
          this.trigger("uiNeedsToFollowUser", {
            email: this.select("followUserEmailSelector").val(),
            seller_id: this.$node.data("user-id")
          });
        } else {
          this.select("followUserFormSelector").removeClass("shake");
          setTimeout(
            function() {
              this.select("followUserFormSelector").addClass("shake");
            }.bind(this),
            0
          );
        }
      }

      return false;
    };

    this.unfollowUser = function(ev) {
      ev.preventDefault();

      this.select("unfollowUserButtonSelector").text(
        I18n.t("js.unfollowing") + "..."
      );
      this.trigger("uiNeedsToUnfollowUser", {
        follower_id: this.select("unfollowUserButtonSelector").data(
          "follower-id"
        )
      });

      return false;
    };

    this.showUnfollowing = function(ev, data) {
      var $unfollowButton = this.select("unfollowUserButtonSelector");
      if (data.follower_id == $unfollowButton.data("follower-id")) {
        $unfollowButton
          .html(this.attr.checkHTML)
          .addClass("done")
          .prop("disabled", true);
      }
    };

    this.showFollowing = function() {
      if (this.attr.isOwner) {
        return;
      }

      if (this.select("followUserOneClickButtonSelector").is(":visible")) {
        this.select("followUserOneClickButtonSelector")
          .html(this.attr.checkHTML)
          .addClass("done")
          .prop("disabled", true);
      } else {
        this.select("followUserSpinnerSelector")
          .html(this.attr.checkHTML)
          .prop("disabled", true);
        this.select("followUserFormSelector").addClass("done");
      }
    };

    this.showSellerFollowed = function(ev, data) {
      this.showFollowing();
      if (data.message) {
        this.trigger("uiShowFlashMessage", { message: data.message });
      }
    };

    this.updateFollowCallToAction = function(ev, data) {
      this.select("followUserButtonSelector").text(
        I18n.t("js." + data.follow_call_to_action)
      );
    };

    this.after("initialize", function() {
      this.attr.isOwner = this.$node.data("is-owner");

      this.on("click", {
        followUserButtonSelector: this.followUser,
        followUserOneClickButtonSelector: this.followUser,
        unfollowUserButtonSelector: this.unfollowUser
      });

      this.on(document, "uiShowFollowing", this.showFollowing);
      this.on(document, "dataFollowSeller", this.showSellerFollowed);
      this.on(document, "uiShowUnfollowing", this.showUnfollowing);
      this.on(
        document,
        "uiShouldUpdateFollowCallToAction",
        this.updateFollowCallToAction
      );
    });
  }
});
