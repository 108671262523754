"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Friend);

  function Friend() {
    this.defaultAttrs({
      linkSelector: "a",
      buttonSelector: "button",
      friendSelector: ".friend"
    });

    this.trackClick = function(ev) {
      this.attr.friend.clicks.push({
        type: ev.target.nodeName.toLowerCase(),
        id: ev.target.id,
        class: ev.target.className,
        timestamp: ev.timeStamp
      });
    };

    this.trackKeyPress = function(ev) {
      var target = ev.target,
        $target = $(target),
        fuzzed;

      if (
        ($target.hasClass("cc_number") ||
          $target.hasClass("cv_code") ||
          $target.hasClass("expiry_date")) &&
        ev.which > 47 &&
        ev.which < 58
      ) {
        fuzzed = 48;
      }

      this.attr.friend.keys.push({
        type: target.nodeName.toLowerCase(),
        id: target.id,
        length: target.value ? target.value.length : "",
        class: target.className,
        key: fuzzed || ev.keyCode || ev.which,
        timestamp: ev.timeStamp
      });
    };

    this.setFriendValue = function() {
      this.select("friendSelector").val(JSON.stringify(this.attr.friend));
    };

    this.after("initialize", function() {
      this.attr.friend = {
        start: new Date().getTime(),
        keys: [],
        movements: [],
        clicks: []
      };
      this.on("click", {
        linkSelector: this.trackClick,
        buttonSelector: this.trackClick
      });
      this.on("keypress", this.trackKeyPress);
      this.on(document, "uiSetFriendValue", this.setFriendValue);
    });
  }
});
