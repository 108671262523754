"use strict";

define([], function() {
  return WithBarChartBase;

  function WithBarChartBase() {
    this.getTopRoundedRect = function(x, y, width, height, radius) {
      if (height == 0 || width < radius) {
        radius = 0;
      }
      return (
        "M" +
        (x + radius) +
        "," +
        y +
        "h" +
        (width - radius * 2) +
        "a" +
        radius +
        "," +
        radius +
        " 0 0 1 " +
        radius +
        "," +
        radius +
        "v" +
        (height - radius) +
        "h" +
        (0 - width) +
        "v" +
        (0 - (height - radius)) +
        "a" +
        radius +
        "," +
        radius +
        " 0 0 1 " +
        radius +
        "," +
        -radius +
        "z"
      );
    };
  }
});
