"use strict";

/**
 * Date picker UI component.
 *
 * @attr {int} startTime: The default start date in milliseconds.
 * @attr {int} endTime: The default end date in milliseconds.
 * @attr {Date} minDate: The minimum time that can be picked.
 * @attr {Date} maxDate: The maximum time that can be picked.
 * @attr {string} dateRangeChangedEvent: The event to trigger on date range change. The default is 'uiDateRangeChanged'. The event is triggered with startTime and endTime which is string representation of datetime.
 * @attr {boolean} showLoadingOverlayForDateChange: A boolean to determine if the date_picker should trigger 'uiShowLoadingOverlay' on date changes or not. The default is true.
 * @attr {boolean updateHashLocationForDateChange: A boolean to determine if the date_picker should set a window.location.hash or not. The default is true.
 */

define([
  "flight/lib/component",
  "$app/utils/date",
  "$vendor/datepicker"
], function(defineComponent, DateUtils) {
  return defineComponent(DatePicker);

  function DatePicker() {
    this.defaultAttrs({
      datePickerSelector: "#datepicker-calendar",
      dateRangeFieldSelector: "#date-range-field",
      dateRangeFieldTextSelector: "#date-range-field span",
      dateRangePresetDurationSelector: ".datepicker-preset-duration-select",
      arrowSelector: "#date-range-field .js-toggle-arrow",
      dateChanged: false,
      dateRangeChangedEvent: "uiDateRangeChanged",
      softDateRangeChangedEvent: "uiSoftDateRangeChanged",
      showLoadingOverlayForDateChange: true,
      updateHashLocationForDateChange: true,
      downArrowGi: "gi-chevron-right",
      upArrowGi: "gi-chevron-left"
    });

    this.hideDatePickerIfVisible = function() {
      var $datePicker = this.select("datePickerSelector");
      if ($datePicker.is(":visible")) {
        $datePicker.removeClass("showing");
        this.showDownArrow();
        this.notifyDateRangeChange();
      }
    };

    this.hideDatePickerIfEnterKeyIsPressed = function(e) {
      var key = e.which || e.keyCode;
      if (key == 13) {
        this.hideDatePickerIfVisible();
      }
    };

    this.toggleDatePickerVisibility = function(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      var $arrow = this.select("arrowSelector");

      this.select("datePickerSelector").toggleClass("showing");
      if ($arrow.hasClass(this.attr.downArrowGi)) {
        this.showUpArrow();
      } else {
        this.showDownArrow();
        this.notifyDateRangeChange();
      }
      return false;
    };

    this.getDateRangeHash = function(start, end) {
      return (
        "#" +
        this.getDashedDateString(start) +
        "_" +
        this.getDashedDateString(end)
      );
    };

    this.getDashedDateString = function(date) {
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    };

    this.updateDateRangeText = function(from, to) {
      this.select("dateRangeFieldTextSelector").text(
        from.getMonthName() +
          " " +
          from.getDate() +
          ", " +
          from.getFullYear() +
          " - " +
          to.getMonthName() +
          " " +
          to.getDate() +
          ", " +
          to.getFullYear()
      );
    };

    this.updateDateRange = function(dates, picker) {
      this.attr.startTime = dates[0].toString();
      this.attr.endTime = dates[1].toString();
      this.updateDateRangeText(dates[0], dates[1]);
      this.trigger(this.attr.softDateRangeChangedEvent, {
        endTime: this.attr.endTime,
        startTime: this.attr.startTime
      });
      if (this.attr.updateHashLocationForDateChange) {
        this.attr.hashLocation = this.getDateRangeHash(dates[0], dates[1]);
      }
      this.attr.dateChanged = true;
      this.attr.datepicker.DatePickerSetDate(dates, true);
    };

    this.updateCurrentDisplay = function(dates) {
      return new Date(to.getFullYear(), to.getMonth(), 1);
    };

    this.getPresetDurationDate = function(preset) {
      var today = new Date();
      var firstDate =
        this.select("datePickerSelector").data("firstDate") ||
        new Date(2011, 0, 1);
      var durationDates = {
        last_30_days: [
          DateUtils.getMonthsBefore(today, 1, today.getDate() + 1),
          today
        ],
        last_month: [
          DateUtils.getMonthsBefore(today, 1, 1),
          DateUtils.getMonthsBefore(today, 0, 0)
        ],
        last_3_months: [
          DateUtils.getMonthsBefore(today, 3, 1),
          DateUtils.getMonthsBefore(today, 0, 0)
        ],
        this_year: [new Date(today.getFullYear(), 0, 1), today],
        last_year: [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear() - 1, 11, 31)
        ],
        all_time: [firstDate, today]
      };
      return durationDates[preset];
    };

    this.updateAllTimeFirstDate = function(_ev, data) {
      this.select("datePickerSelector").data("firstDate", data.date);
    };

    this.updateDateRangeDurationPreset = function(ev) {
      ev.preventDefault();
      var presetDate = this.getPresetDurationDate(ev.target.dataset.duration);
      presetDate[0] = DateUtils.convertToDateIfNotAlready(presetDate[0]);
      presetDate[1] = DateUtils.convertToDateIfNotAlready(presetDate[1]);
      this.updateDateRange(presetDate);
      this.notifyDateRangeChange();
    };

    this.notifyDateRangeChange = function() {
      if (this.attr.dateChanged) {
        this.trigger(this.attr.dateRangeChangedEvent, {
          endTime: this.attr.endTime,
          startTime: this.attr.startTime
        });
        if (this.attr.showLoadingOverlayForDateChange) {
          this.trigger("uiShowLoadingOverlay");
        }
        this.attr.dateChanged = false;
        if (this.attr.updateHashLocationForDateChange) {
          this.updateHashLocationWithoutEvent();
        }
      }
    };

    this.updateHashLocationWithoutEvent = function() {
      if (this.attr.hashLocation) {
        this.attr.skipHashLocationEvent = true;
        window.location.hash = this.attr.hashLocation;
        this.trigger("dataDateHash", { dateHash: this.attr.hashLocation });
        this.attr.hashLocation = null;
      }
    };

    this.updateDateRangeWithHash = function() {
      if (this.attr.skipHashLocationEvent) {
        this.attr.skipHashLocationEvent = false;
      } else {
        var dateRange = DateUtils.getDateRangeFromHash();
        this.updateDateRange(dateRange);
        this.select("datePickerSelector").DatePickerSetDate(dateRange);
        this.notifyDateRangeChange();
      }
    };

    this.disableInvalidDates = function(el, date) {
      var options = {};
      if (date < this.attr.minDate || date > this.attr.maxDate) {
        options.disabled = true;
        options.className = "datepickerFuture";
      }
      return options;
    };

    this.showUpArrow = function() {
      var $dateRange = this.select("dateRangeFieldSelector"),
        $arrow = this.select("arrowSelector");
      $arrow.addClass(this.attr.upArrowGi);
      $arrow.removeClass(this.attr.downArrowGi);
      $dateRange.css({ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 });
      $arrow.css({ borderBottomRightRadius: 0 });
    };

    this.showDownArrow = function() {
      var $dateRange = this.select("dateRangeFieldSelector"),
        $arrow = this.select("arrowSelector");
      $arrow.removeClass(this.attr.upArrowGi);
      $arrow.addClass(this.attr.downArrowGi);
      $dateRange.css({ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 });
      $arrow.css({ borderBottomRightRadius: 5 });
    };

    this.updateDatePickerPosition = function() {
      var $datePicker = this.select("datePickerSelector");
      var $dateRange = this.select("dateRangeFieldSelector");
      if (
        $dateRange.offset().top + $dateRange.height() + $datePicker.height() >
        $(window).height()
      ) {
        $datePicker.addClass("position-top");
      } else $datePicker.removeClass("position-top");
    };

    this.after("initialize", function() {
      var to = new Date(this.attr.endTime),
        from = new Date(this.attr.startTime);

      this.attr.datepicker = this.select("datePickerSelector").DatePicker({
        inline: true,
        date: [from, to],
        calendars: $(window).width() > 630 ? 2 : 1,
        mode: "range",
        current: new Date(to.getFullYear(), to.getMonth(), 1),
        onChange: this.updateDateRange.bind(this),
        onRenderCell: this.disableInvalidDates.bind(this)
      });
      this.updateDateRangeText(from, to);
      this.on("click", {
        dateRangeFieldSelector: this.toggleDatePickerVisibility,
        dateRangePresetDurationSelector: this.updateDateRangeDurationPreset
      });
      this.on(document, "keyup", this.hideDatePickerIfEnterKeyIsPressed);
      this.on(document, "click", this.hideDatePickerIfVisible);
      this.on(window, "hashchange", this.updateDateRangeWithHash);
      this.on(
        document,
        "datePickerSetAllTimeFirstDate",
        this.updateAllTimeFirstDate
      );
      this.updateDatePickerPosition();
      this.on(window, "resize", this.updateDatePickerPosition);
    });
  }
});
