"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(UserInfo);

  function UserInfo() {
    this.getCurrentUserInfo = function(ev, data) {
      $.ajax({
        type: "GET",
        url:
          Routes.user_info_link_path(data.permalink) +
          (data.offerCodeName ? "?code=" + data.offerCodeName : ""),
        dataType: "json"
      }).done(
        function(response) {
          response.permalink = data.permalink;
          this.trigger("dataCurrentUserInfo", response);
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToGetCurrentUserInfo", this.getCurrentUserInfo);
    });
  }
});
