"use strict";

define([], function() {
  var Mobile = {
    isOnMobile: function() {
      return window.innerWidth <= 480;
    },
    isOnTablet: function() {
      return (
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)
      );
    },
    isOnMobileiOSDevice: function() {
      return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !window.MSStream &&
        document.cookie.indexOf("iphone_redirect=false") == -1
      );
    },
    isOnAndroidDevice: function() {
      return navigator.userAgent.match(/Android/i);
    },
    isOnDesktop: function() {
      return window.innerWidth > 992;
    }
  };
  return Mobile;
});
