define([], function() {
  var DateUtils = {
    getDateRangeFromHash: function(
      minDate,
      maxDate,
      defaultStartDate,
      defaultEndDate
    ) {
      var pattern = /#(\d*)-(\d*)-(\d*)_(\d*)-(\d*)-(\d*)/,
        matches = pattern.exec(window.location.hash),
        startDate,
        endDate,
        msecInDay = 24 * 60 * 60 * 1000;

      if (matches) {
        // Months are 0 indexed
        startDate = new Date(matches[1], matches[2] - 1, matches[3]);
        endDate = new Date(matches[4], matches[5] - 1, matches[6]);
      } else {
        if (defaultEndDate) {
          endDate = defaultEndDate;
        } else {
          endDate = new Date();
        }
        if (defaultStartDate) {
          startDate = defaultStartDate;
        } else {
          startDate = new Date(endDate.getTime() - 29 * msecInDay);
        }
      }
      if (startDate < minDate) startDate = minDate;
      if (endDate > maxDate) endDate = maxDate;
      return [startDate, endDate];
    },
    getDashedDateString: function(date) {
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    convertToDateIfNotAlready: function(date) {
      if (typeof date === "Date") {
        return date;
      } else {
        return new Date(date);
      }
    },
    getMonthsBefore: function(date, month, day) {
      return new Date(date).setMonth(date.getMonth() - month, day);
    }
  };

  return DateUtils;
});
