"use strict";

define([
  "braintree",
  "$app/data/braintree_client_token_data",
  "$app/data/braintree_transient_customer_data",
  "$app/utils/environment"
], function(
  Braintree,
  PayPalBraintreeClientData,
  PayPalBraintreeTransientCustomerData,
  Environment
) {
  return WithPayPalBraintree;

  function WithPayPalBraintree() {
    this.setupPayPalBraintreeImplementation = function(clientToken) {
      Braintree.client.create(
        {
          authorization: clientToken,
          singleUse: false,
          container: this.select("actualBraintreePayPalContainerSelector"),
          paymentMethodNonceInputField: this.select(
            "braintreeNonceFieldSelector"
          )
        },
        this.braintreeClientCreationCallback.bind(this)
      );
    };

    this.braintreeClientCreationCallback = function(clientErr, clientInstance) {
      if (clientErr) {
        return;
      }

      Braintree.paypal.create(
        {
          client: clientInstance
        },
        this.paypalInstanceCreationCallback.bind(this)
      );

      // Used for fraud detection on Braintree gateway via Kount. It is not enabled on our account as of now though.
      // Ref: https://developers.braintreepayments.com/guides/advanced-fraud-tools/client-side/javascript/v3
      // Ref: https://braintree.github.io/braintree-web/current/module-braintree-web_data-collector.html
      Braintree.dataCollector.create(
        {
          client: clientInstance,
          kount: {
            environment: Environment.isProduction() ? "production" : "sandbox"
          },
          paypal: true
        },
        this.dataCollectorCallback.bind(this)
      );
    };

    this.paypalInstanceCreationCallback = function(paypalErr, paypalInstance) {
      if (paypalErr) {
        return;
      }

      this.paypalInstance = paypalInstance;
      // When the button is clicked, attempt to tokenize.
      this.paypalButton.onclick = this.onPaypalButtonClick.bind(this);
    };

    this.onPaypalButtonClick = function() {
      // Disable the button so that we don't attempt to open multiple popups.
      this.paypalButton.onclick = null;
      // Because tokenization opens a popup, this has to be called as a result of
      // customer action, like clicking a button. You cannot call this at any time.
      this.paypalInstance.tokenize(
        {
          flow: "vault"
          // For more tokenization options, see the full PayPal tokenization documentation
          // https://braintree.github.io/braintree-web/current/PayPal.html#tokenize
        },
        this.paypalTokeniationCallback.bind(this)
      );
    };

    this.paypalTokeniationCallback = function(tokenizeErr, payload) {
      // Re-enable the button on return from paypal popup
      this.paypalButton.onclick = this.onPaypalButtonClick.bind(this);

      if (tokenizeErr) {
        this.trigger("uiShowFlashMessage", {
          message: I18n.t(
            "js.native_paypal_incompatibility_detected_retry_purchase"
          )
        });
        return;
      }

      this.attr.braintreePaymentNonce = payload.nonce;
      this.select("braintreeNonceFieldSelector").val(payload.nonce);
      this.select("actualPayPalDataEmailSelector").text(payload.details.email);
      this.handleBraintreePaypalButtonClick();
    };

    this.dataCollectorCallback = function(err, dataCollectorInstance) {
      if (err) {
        return;
      }
      // At this point, you should access the dataCollectorInstance.deviceData value and provide it
      // to your server, e.g. by injecting it into your form as a hidden input.
      this.attr.deviceData = dataCollectorInstance.deviceData;
    };

    this.initiateBraintreePayPalPayment = function() {
      if (this.attr.braintreeTransientCustomerStoreKey != null) {
        this.trigger(this.node, "uiHasAuthorizedPayPalBuyer");
      } else if (this.attr.braintreePaymentNonce) {
        this.handlePayPalNonce(this.attr.braintreePaymentNonce);
      } else {
        // Hide this if showing. Makes the VAT process more smooth because then they can go straight to Pay:
        this.trigger("uiNeedsToHideVatCountrySelector");
      }
    };

    this.handlePayPalNonce = function(nonce) {
      this.attr.braintreePaymentNonce = nonce;
      this.trigger(this.node, "uiHasAuthorizedPayPalBuyer", {
        paypalBuyerEmail: this.select("actualPayPalDataEmailSelector").text()
      });
    };

    this.handleTransientClientToken = function(ev, data) {
      this.attr.braintreeTransientCustomerStoreKey =
        data.transient_customer_store_key;

      if (data.callback) {
        data.callback(this.getBraintreePayPalCardParams());
      }
    };

    this.prepareBraintreePayPalPayment = function(callback) {
      var actualNonce =
        this.attr.braintreePaymentNonce ||
        $(this.attr.braintreeNonceFieldSelector).val();
      if (actualNonce) {
        this.trigger("uiNeedsBraintreeTransientCustomerTokenFetched", {
          braintreeNonce: actualNonce,
          callback: callback
        });
      }
    };

    this.getBraintreePayPalCardParams = function() {
      return {
        cardParams: {
          braintree_transient_customer_store_key: this.attr
            .braintreeTransientCustomerStoreKey,
          braintree_device_data: this.attr.deviceData
        }
      };
    };

    this.resetBraintreePayPalPayment = function() {
      this.attr.braintreePaymentNonce = null;
      this.attr.braintreeTransientCustomerStoreKey = null;
    };

    this.after("initialize", function() {
      PayPalBraintreeClientData.attachTo(this.$node);
      PayPalBraintreeTransientCustomerData.attachTo(this.$node);

      this.paypalButton = this.select("actualPayPalButton")[0];
      this.on(
        this.node,
        "dataBraintreeTransientCustomerToken",
        this.handleTransientClientToken
      );
    });
  }
});
