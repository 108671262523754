"use strict";

define([], function() {
  return {
    resetRecaptcha: function(identifier) {
      var recaptchaWidgetId = window.gumroad.recaptchaIds[identifier];

      if (typeof recaptchaWidgetId === "undefined") {
        return;
      }

      window.grecaptcha.reset(recaptchaWidgetId);
    }
  };
});
