"use strict";

define([], function() {
  var types = {
      ez: "application/andrew-inset",
      aw: "application/applixware",
      atom: "application/atom+xml",
      atomcat: "application/atomcat+xml",
      atomsvc: "application/atomsvc+xml",
      ccxml: "application/ccxml+xml",
      cu: "application/cu-seeme",
      davmount: "application/davmount+xml",
      ecma: "application/ecmascript",
      emma: "application/emma+xml",
      epub: "application/epub+zip",
      pfr: "application/font-tdpfr",
      stk: "application/hyperstudio",
      jar: "application/java-archive",
      ser: "application/java-serialized-object",
      class: "application/java-vm",
      js: "application/javascript",
      json: "application/json",
      lostxml: "application/lost+xml",
      hqx: "application/mac-binhex40",
      cpt: "application/mac-compactpro",
      mrc: "application/marc",
      "ma,nb,mb": "application/mathematica",
      mathml: "application/mathml+xml",
      mbox: "application/mbox",
      mscml: "application/mediaservercontrol+xml",
      mp4s: "application/mp4",
      "doc,dot": "application/msword",
      mxf: "application/mxf",
      oda: "application/oda",
      opf: "application/oebps-package+xml",
      ogx: "application/ogg",
      "onetoc,onetoc2,onetmp,onepkg": "application/onenote",
      xer: "application/patch-ops-error+xml",
      pdf: "application/pdf",
      pgp: "application/pgp-encrypted",
      "asc,sig": "application/pgp-signature",
      prf: "application/pics-rules",
      p10: "application/pkcs10",
      "p7m,p7c": "application/pkcs7-mime",
      p7s: "application/pkcs7-signature",
      cer: "application/pkix-cert",
      crl: "application/pkix-crl",
      pkipath: "application/pkix-pkipath",
      pki: "application/pkixcmp",
      pls: "application/pls+xml",
      "ai,eps,ps": "application/postscript",
      cww: "application/prs.cww",
      rdf: "application/rdf+xml",
      rif: "application/reginfo+xml",
      rnc: "application/relax-ng-compact-syntax",
      rl: "application/resource-lists+xml",
      rld: "application/resource-lists-diff+xml",
      rs: "application/rls-services+xml",
      rsd: "application/rsd+xml",
      rss: "application/rss+xml",
      rtf: "application/rtf",
      sbml: "application/sbml+xml",
      scq: "application/scvp-cv-request",
      scs: "application/scvp-cv-response",
      spq: "application/scvp-vp-request",
      spp: "application/scvp-vp-response",
      sdp: "application/sdp",
      setpay: "application/set-payment-initiation",
      setreg: "application/set-registration-initiation",
      shf: "application/shf+xml",
      "smi,smil": "application/smil+xml",
      rq: "application/sparql-query",
      srx: "application/sparql-results+xml",
      gram: "application/srgs",
      grxml: "application/srgs+xml",
      ssml: "application/ssml+xml",
      plb: "application/vnd.3gpp.pic-bw-large",
      psb: "application/vnd.3gpp.pic-bw-small",
      pvb: "application/vnd.3gpp.pic-bw-var",
      tcap: "application/vnd.3gpp2.tcap",
      pwn: "application/vnd.3m.post-it-notes",
      aso: "application/vnd.accpac.simply.aso",
      imp: "application/vnd.accpac.simply.imp",
      acu: "application/vnd.acucobol",
      "atc,acutc": "application/vnd.acucorp",
      air: "application/vnd.adobe.air-application-installer-package+zip",
      xdp: "application/vnd.adobe.xdp+xml",
      xfdf: "application/vnd.adobe.xfdf",
      azf: "application/vnd.airzip.filesecure.azf",
      azs: "application/vnd.airzip.filesecure.azs",
      azw: "application/vnd.amazon.ebook",
      acc: "application/vnd.americandynamics.acc",
      ami: "application/vnd.amiga.ami",
      apk: "application/vnd.android.package-archive",
      cii: "application/vnd.anser-web-certificate-issue-initiation",
      fti: "application/vnd.anser-web-funds-transfer-initiation",
      atx: "application/vnd.antix.game-component",
      mpkg: "application/vnd.apple.installer+xml",
      swi: "application/vnd.arastra.swi",
      aep: "application/vnd.audiograph",
      mpm: "application/vnd.blueice.multipass",
      bmi: "application/vnd.bmi",
      rep: "application/vnd.businessobjects",
      cdxml: "application/vnd.chemdraw+xml",
      mmd: "application/vnd.chipnuts.karaoke-mmd",
      cdy: "application/vnd.cinderella",
      cla: "application/vnd.claymore",
      "c4g,c4d,c4f,c4p,c4u": "application/vnd.clonk.c4group",
      csp: "application/vnd.commonspace",
      cdbcmsg: "application/vnd.contact.cmsg",
      cmc: "application/vnd.cosmocaller",
      clkx: "application/vnd.crick.clicker",
      clkk: "application/vnd.crick.clicker.keyboard",
      clkp: "application/vnd.crick.clicker.palette",
      clkt: "application/vnd.crick.clicker.template",
      clkw: "application/vnd.crick.clicker.wordbank",
      wbs: "application/vnd.criticaltools.wbs+xml",
      pml: "application/vnd.ctc-posml",
      ppd: "application/vnd.cups-ppd",
      car: "application/vnd.curl.car",
      pcurl: "application/vnd.curl.pcurl",
      rdz: "application/vnd.data-vision.rdz",
      fe_launch: "application/vnd.denovo.fcselayout-link",
      dna: "application/vnd.dna",
      mlp: "application/vnd.dolby.mlp",
      dpg: "application/vnd.dpgraph",
      dfac: "application/vnd.dreamfactory",
      geo: "application/vnd.dynageo",
      mag: "application/vnd.ecowin.chart",
      nml: "application/vnd.enliven",
      esf: "application/vnd.epson.esf",
      msf: "application/vnd.epson.msf",
      qam: "application/vnd.epson.quickanime",
      slt: "application/vnd.epson.salt",
      ssf: "application/vnd.epson.ssf",
      "es3,et3": "application/vnd.eszigno3+xml",
      ez2: "application/vnd.ezpix-album",
      ez3: "application/vnd.ezpix-package",
      fdf: "application/vnd.fdf",
      mseed: "application/vnd.fdsn.mseed",
      "seed,dataless": "application/vnd.fdsn.seed",
      gph: "application/vnd.flographit",
      ftc: "application/vnd.fluxtime.clip",
      "fm,frame,maker,book": "application/vnd.framemaker",
      fnc: "application/vnd.frogans.fnc",
      ltf: "application/vnd.frogans.ltf",
      fsc: "application/vnd.fsc.weblaunch",
      oas: "application/vnd.fujitsu.oasys",
      oa2: "application/vnd.fujitsu.oasys2",
      oa3: "application/vnd.fujitsu.oasys3",
      fg5: "application/vnd.fujitsu.oasysgp",
      bh2: "application/vnd.fujitsu.oasysprs",
      ddd: "application/vnd.fujixerox.ddd",
      xdw: "application/vnd.fujixerox.docuworks",
      xbd: "application/vnd.fujixerox.docuworks.binder",
      fzs: "application/vnd.fuzzysheet",
      txd: "application/vnd.genomatix.tuxedo",
      ggb: "application/vnd.geogebra.file",
      ggt: "application/vnd.geogebra.tool",
      "gex,gre": "application/vnd.geometry-explorer",
      gmx: "application/vnd.gmx",
      kml: "application/vnd.google-earth.kml+xml",
      kmz: "application/vnd.google-earth.kmz",
      "gqf,gqs": "application/vnd.grafeq",
      gac: "application/vnd.groove-account",
      ghf: "application/vnd.groove-help",
      gim: "application/vnd.groove-identity-message",
      grv: "application/vnd.groove-injector",
      gtm: "application/vnd.groove-tool-message",
      tpl: "application/vnd.groove-tool-template",
      vcg: "application/vnd.groove-vcard",
      zmm: "application/vnd.handheld-entertainment+xml",
      hbci: "application/vnd.hbci",
      les: "application/vnd.hhe.lesson-player",
      hpgl: "application/vnd.hp-hpgl",
      hpid: "application/vnd.hp-hpid",
      hps: "application/vnd.hp-hps",
      jlt: "application/vnd.hp-jlyt",
      pcl: "application/vnd.hp-pcl",
      pclxl: "application/vnd.hp-pclxl",
      "sfd-hdstx": "application/vnd.hydrostatix.sof-data",
      x3d: "application/vnd.hzn-3d-crossword",
      mpy: "application/vnd.ibm.minipay",
      "afp,listafp,list3820": "application/vnd.ibm.modcap",
      irm: "application/vnd.ibm.rights-management",
      sc: "application/vnd.ibm.secure-container",
      "icc,icm": "application/vnd.iccprofile",
      igl: "application/vnd.igloader",
      ivp: "application/vnd.immervision-ivp",
      ivu: "application/vnd.immervision-ivu",
      "xpw,xpx": "application/vnd.intercon.formnet",
      qbo: "application/vnd.intu.qbo",
      qfx: "application/vnd.intu.qfx",
      rcprofile: "application/vnd.ipunplugged.rcprofile",
      irp: "application/vnd.irepository.package+xml",
      xpr: "application/vnd.is-xpr",
      jam: "application/vnd.jam",
      rms: "application/vnd.jcp.javame.midlet-rms",
      jisp: "application/vnd.jisp",
      joda: "application/vnd.joost.joda-archive",
      "ktz,ktr": "application/vnd.kahootz",
      karbon: "application/vnd.kde.karbon",
      chrt: "application/vnd.kde.kchart",
      kfo: "application/vnd.kde.kformula",
      flw: "application/vnd.kde.kivio",
      kon: "application/vnd.kde.kontour",
      "kpr,kpt": "application/vnd.kde.kpresenter",
      ksp: "application/vnd.kde.kspread",
      "kwd,kwt": "application/vnd.kde.kword",
      htke: "application/vnd.kenameaapp",
      kia: "application/vnd.kidspiration",
      "kne,knp": "application/vnd.kinar",
      "skp,skd,skt,skm": "application/vnd.koan",
      sse: "application/vnd.kodak-descriptor",
      lbd: "application/vnd.llamagraphics.life-balance.desktop",
      lbe: "application/vnd.llamagraphics.life-balance.exchange+xml",
      "123": "application/vnd.lotus-1-2-3",
      apr: "application/vnd.lotus-approach",
      pre: "application/vnd.lotus-freelance",
      nsf: "application/vnd.lotus-notes",
      org: "application/vnd.lotus-organizer",
      scm: "application/vnd.lotus-screencam",
      lwp: "application/vnd.lotus-wordpro",
      portpkg: "application/vnd.macports.portpkg",
      mcd: "application/vnd.mcd",
      mc1: "application/vnd.medcalcdata",
      cdkey: "application/vnd.mediastation.cdkey",
      mwf: "application/vnd.mfer",
      mfm: "application/vnd.mfmp",
      flo: "application/vnd.micrografx.flo",
      igx: "application/vnd.micrografx.igx",
      mif: "application/vnd.mif",
      daf: "application/vnd.mobius.daf",
      dis: "application/vnd.mobius.dis",
      mbk: "application/vnd.mobius.mbk",
      mqy: "application/vnd.mobius.mqy",
      msl: "application/vnd.mobius.msl",
      plc: "application/vnd.mobius.plc",
      txf: "application/vnd.mobius.txf",
      mpn: "application/vnd.mophun.application",
      mpc: "application/vnd.mophun.certificate",
      xul: "application/vnd.mozilla.xul+xml",
      cil: "application/vnd.ms-artgalry",
      cab: "application/vnd.ms-cab-compressed",
      "xls,xlm,xla,xlc,xlt,xlw": "application/vnd.ms-excel",
      xlam: "application/vnd.ms-excel.addin.macroenabled.12",
      xlsb: "application/vnd.ms-excel.sheet.binary.macroenabled.12",
      xlsm: "application/vnd.ms-excel.sheet.macroenabled.12",
      xltm: "application/vnd.ms-excel.template.macroenabled.12",
      eot: "application/vnd.ms-fontobject",
      chm: "application/vnd.ms-htmlhelp",
      ims: "application/vnd.ms-ims",
      lrm: "application/vnd.ms-lrm",
      cat: "application/vnd.ms-pki.seccat",
      stl: "application/vnd.ms-pki.stl",
      "ppt,pps,pot": "application/vnd.ms-powerpoint",
      ppam: "application/vnd.ms-powerpoint.addin.macroenabled.12",
      pptm: "application/vnd.ms-powerpoint.presentation.macroenabled.12",
      sldm: "application/vnd.ms-powerpoint.slide.macroenabled.12",
      ppsm: "application/vnd.ms-powerpoint.slideshow.macroenabled.12",
      potm: "application/vnd.ms-powerpoint.template.macroenabled.12",
      "mpp,mpt": "application/vnd.ms-project",
      docm: "application/vnd.ms-word.document.macroenabled.12",
      dotm: "application/vnd.ms-word.template.macroenabled.12",
      "wps,wks,wcm,wdb": "application/vnd.ms-works",
      wpl: "application/vnd.ms-wpl",
      xps: "application/vnd.ms-xpsdocument",
      mseq: "application/vnd.mseq",
      mus: "application/vnd.musician",
      msty: "application/vnd.muvee.style",
      nlu: "application/vnd.neurolanguage.nlu",
      nnd: "application/vnd.noblenet-directory",
      nns: "application/vnd.noblenet-sealer",
      nnw: "application/vnd.noblenet-web",
      ngdat: "application/vnd.nokia.n-gage.data",
      "n-gage": "application/vnd.nokia.n-gage.symbian.install",
      rpst: "application/vnd.nokia.radio-preset",
      rpss: "application/vnd.nokia.radio-presets",
      edm: "application/vnd.novadigm.edm",
      edx: "application/vnd.novadigm.edx",
      ext: "application/vnd.novadigm.ext",
      odc: "application/vnd.oasis.opendocument.chart",
      otc: "application/vnd.oasis.opendocument.chart-template",
      odb: "application/vnd.oasis.opendocument.database",
      odf: "application/vnd.oasis.opendocument.formula",
      odft: "application/vnd.oasis.opendocument.formula-template",
      odg: "application/vnd.oasis.opendocument.graphics",
      otg: "application/vnd.oasis.opendocument.graphics-template",
      odi: "application/vnd.oasis.opendocument.image",
      oti: "application/vnd.oasis.opendocument.image-template",
      odp: "application/vnd.oasis.opendocument.presentation",
      ods: "application/vnd.oasis.opendocument.spreadsheet",
      ots: "application/vnd.oasis.opendocument.spreadsheet-template",
      odt: "application/vnd.oasis.opendocument.text",
      otm: "application/vnd.oasis.opendocument.text-master",
      ott: "application/vnd.oasis.opendocument.text-template",
      oth: "application/vnd.oasis.opendocument.text-web",
      xo: "application/vnd.olpc-sugar",
      dd2: "application/vnd.oma.dd2+xml",
      oxt: "application/vnd.openofficeorg.extension",
      pptx:
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      sldx:
        "application/vnd.openxmlformats-officedocument.presentationml.slide",
      ppsx:
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      potx:
        "application/vnd.openxmlformats-officedocument.presentationml.template",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      xltx:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      docx:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      dotx:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      dp: "application/vnd.osgi.dp",
      "pdb,pqa,oprc": "application/vnd.palm",
      str: "application/vnd.pg.format",
      ei6: "application/vnd.pg.osasli",
      efif: "application/vnd.picsel",
      plf: "application/vnd.pocketlearn",
      pbd: "application/vnd.powerbuilder6",
      box: "application/vnd.previewsystems.box",
      mgz: "application/vnd.proteus.magazine",
      qps: "application/vnd.publishare-delta-tree",
      ptid: "application/vnd.pvi.ptid1",
      "qxd,qxt,qwd,qwt,qxl,qxb": "application/vnd.quark.quarkxpress",
      mxl: "application/vnd.recordare.musicxml",
      musicxml: "application/vnd.recordare.musicxml+xml",
      cod: "application/vnd.rim.cod",
      rm: "application/vnd.rn-realmedia",
      link66: "application/vnd.route66.link66+xml",
      see: "application/vnd.seemail",
      sema: "application/vnd.sema",
      semd: "application/vnd.semd",
      semf: "application/vnd.semf",
      ifm: "application/vnd.shana.informed.formdata",
      itp: "application/vnd.shana.informed.formtemplate",
      iif: "application/vnd.shana.informed.interchange",
      ipk: "application/vnd.shana.informed.package",
      "twd,twds": "application/vnd.simtech-mindmapper",
      mmf: "application/vnd.smaf",
      teacher: "application/vnd.smart.teacher",
      "sdkm,sdkd": "application/vnd.solent.sdkm+xml",
      dxp: "application/vnd.spotfire.dxp",
      sfs: "application/vnd.spotfire.sfs",
      sdc: "application/vnd.stardivision.calc",
      sda: "application/vnd.stardivision.draw",
      sdd: "application/vnd.stardivision.impress",
      smf: "application/vnd.stardivision.math",
      sdw: "application/vnd.stardivision.writer",
      vor: "application/vnd.stardivision.writer",
      sgl: "application/vnd.stardivision.writer-global",
      sxc: "application/vnd.sun.xml.calc",
      stc: "application/vnd.sun.xml.calc.template",
      sxd: "application/vnd.sun.xml.draw",
      std: "application/vnd.sun.xml.draw.template",
      sxi: "application/vnd.sun.xml.impress",
      sti: "application/vnd.sun.xml.impress.template",
      sxm: "application/vnd.sun.xml.math",
      sxw: "application/vnd.sun.xml.writer",
      sxg: "application/vnd.sun.xml.writer.global",
      stw: "application/vnd.sun.xml.writer.template",
      "sus,susp": "application/vnd.sus-calendar",
      svd: "application/vnd.svd",
      "sis,sisx": "application/vnd.symbian.install",
      xsm: "application/vnd.syncml+xml",
      bdm: "application/vnd.syncml.dm+wbxml",
      xdm: "application/vnd.syncml.dm+xml",
      tao: "application/vnd.tao.intent-module-archive",
      tmo: "application/vnd.tmobile-livetv",
      tpt: "application/vnd.trid.tpt",
      mxs: "application/vnd.triscape.mxs",
      tra: "application/vnd.trueapp",
      "ufd,ufdl": "application/vnd.ufdl",
      utz: "application/vnd.uiq.theme",
      umj: "application/vnd.umajin",
      unityweb: "application/vnd.unity",
      uoml: "application/vnd.uoml+xml",
      vcx: "application/vnd.vcx",
      "vsd,vst,vss,vsw": "application/vnd.visio",
      vis: "application/vnd.visionary",
      vsf: "application/vnd.vsf",
      wbxml: "application/vnd.wap.wbxml",
      wmlc: "application/vnd.wap.wmlc",
      wmlsc: "application/vnd.wap.wmlscriptc",
      wtb: "application/vnd.webturbo",
      wpd: "application/vnd.wordperfect",
      wqd: "application/vnd.wqd",
      stf: "application/vnd.wt.stf",
      xar: "application/vnd.xara",
      xfdl: "application/vnd.xfdl",
      hvd: "application/vnd.yamaha.hv-dic",
      hvs: "application/vnd.yamaha.hv-script",
      hvp: "application/vnd.yamaha.hv-voice",
      osf: "application/vnd.yamaha.openscoreformat",
      osfpvg: "application/vnd.yamaha.openscoreformat.osfpvg+xml",
      saf: "application/vnd.yamaha.smaf-audio",
      spf: "application/vnd.yamaha.smaf-phrase",
      cmp: "application/vnd.yellowriver-custom-menu",
      "zir,zirz": "application/vnd.zul",
      zaz: "application/vnd.zzazz.deck+xml",
      vxml: "application/voicexml+xml",
      hlp: "application/winhlp",
      wsdl: "application/wsdl+xml",
      wspolicy: "application/wspolicy+xml",
      abw: "application/x-abiword",
      ace: "application/x-ace-compressed",
      "aab,x32,u32,vox": "application/x-authorware-bin",
      aam: "application/x-authorware-map",
      aas: "application/x-authorware-seg",
      bcpio: "application/x-bcpio",
      torrent: "application/x-bittorrent",
      bz: "application/x-bzip",
      "bz2,boz": "application/x-bzip2",
      vcd: "application/x-cdlink",
      chat: "application/x-chat",
      pgn: "application/x-chess-pgn",
      cpio: "application/x-cpio",
      csh: "application/x-csh",
      "deb,udeb": "application/x-debian-package",
      "dir,dcr,dxr,cst,cct,cxt,w3d,fgd,swa": "application/x-director",
      wad: "application/x-doom",
      ncx: "application/x-dtbncx+xml",
      dtb: "application/x-dtbook+xml",
      res: "application/x-dtbresource+xml",
      dvi: "application/x-dvi",
      bdf: "application/x-font-bdf",
      gsf: "application/x-font-ghostscript",
      psf: "application/x-font-linux-psf",
      otf: "application/x-font-otf",
      pcf: "application/x-font-pcf",
      snf: "application/x-font-snf",
      "ttf,ttc": "application/x-font-ttf",
      "pfa,pfb,pfm,afm": "application/x-font-type1",
      spl: "application/x-futuresplash",
      gnumeric: "application/x-gnumeric",
      gtar: "application/x-gtar",
      hdf: "application/x-hdf",
      jnlp: "application/x-java-jnlp-file",
      latex: "application/x-latex",
      "prc,mobi": "application/x-mobipocket-ebook",
      application: "application/x-ms-application",
      wmd: "application/x-ms-wmd",
      wmz: "application/x-ms-wmz",
      xbap: "application/x-ms-xbap",
      mdb: "application/x-msaccess",
      obd: "application/x-msbinder",
      crd: "application/x-mscardfile",
      clp: "application/x-msclip",
      "exe,dll,com,bat,msi": "application/x-msdownload",
      "mvb,m13,m14": "application/x-msmediaview",
      wmf: "application/x-msmetafile",
      mny: "application/x-msmoney",
      pub: "application/x-mspublisher",
      scd: "application/x-msschedule",
      trm: "application/x-msterminal",
      wri: "application/x-mswrite",
      "nc,cdf": "application/x-netcdf",
      "p12,pfx": "application/x-pkcs12",
      "p7b,spc": "application/x-pkcs7-certificates",
      p7r: "application/x-pkcs7-certreqresp",
      rar: "application/x-rar-compressed",
      sh: "application/x-sh",
      shar: "application/x-shar",
      swf: "application/x-shockwave-flash",
      xap: "application/x-silverlight-app",
      sit: "application/x-stuffit",
      sitx: "application/x-stuffitx",
      sv4cpio: "application/x-sv4cpio",
      sv4crc: "application/x-sv4crc",
      tar: "application/x-tar",
      tcl: "application/x-tcl",
      tex: "application/x-tex",
      tfm: "application/x-tex-tfm",
      "texinfo,texi": "application/x-texinfo",
      ustar: "application/x-ustar",
      src: "application/x-wais-source",
      "der,crt": "application/x-x509-ca-cert",
      fig: "application/x-xfig",
      xpi: "application/x-xpinstall",
      xenc: "application/xenc+xml",
      "xhtml,xht": "application/xhtml+xml",
      "xml,xsl": "application/xml",
      dtd: "application/xml-dtd",
      xop: "application/xop+xml",
      xslt: "application/xslt+xml",
      xspf: "application/xspf+xml",
      "mxml,xhvml,xvml,xvm": "application/xv+xml",
      zip: "application/zip",
      adp: "audio/adpcm",
      "au,snd": "audio/basic",
      "mid,midi,kar,rmi": "audio/midi",
      mp4a: "audio/mp4",
      "m4a,m4p": "audio/mp4a-latm",
      "mpga,mp2,mp2a,mp3,m2a,m3a": "audio/mpeg",
      "oga,ogg,spx": "audio/ogg",
      eol: "audio/vnd.digital-winds",
      dts: "audio/vnd.dts",
      dtshd: "audio/vnd.dts.hd",
      lvp: "audio/vnd.lucent.voice",
      pya: "audio/vnd.ms-playready.media.pya",
      ecelp4800: "audio/vnd.nuera.ecelp4800",
      ecelp7470: "audio/vnd.nuera.ecelp7470",
      ecelp9600: "audio/vnd.nuera.ecelp9600",
      aac: "audio/x-aac",
      "aif,aiff,aifc": "audio/x-aiff",
      m3u: "audio/x-mpegurl",
      wax: "audio/x-ms-wax",
      wma: "audio/x-ms-wma",
      "ram,ra": "audio/x-pn-realaudio",
      rmp: "audio/x-pn-realaudio-plugin",
      wav: "audio/x-wav",
      cdx: "chemical/x-cdx",
      cif: "chemical/x-cif",
      cmdf: "chemical/x-cmdf",
      cml: "chemical/x-cml",
      csml: "chemical/x-csml",
      xyz: "chemical/x-xyz",
      bmp: "image/bmp",
      cgm: "image/cgm",
      g3: "image/g3fax",
      gif: "image/gif",
      ief: "image/ief",
      jp2: "image/jp2",
      "jpeg,jpg,jpe": "image/jpeg",
      "pict,pic,pct": "image/pict",
      png: "image/png",
      btif: "image/prs.btif",
      "svg,svgz": "image/svg+xml",
      "tiff,tif": "image/tiff",
      psd: "image/vnd.adobe.photoshop",
      "djvu,djv": "image/vnd.djvu",
      dwg: "image/vnd.dwg",
      dxf: "image/vnd.dxf",
      fbs: "image/vnd.fastbidsheet",
      fpx: "image/vnd.fpx",
      fst: "image/vnd.fst",
      mmr: "image/vnd.fujixerox.edmics-mmr",
      rlc: "image/vnd.fujixerox.edmics-rlc",
      mdi: "image/vnd.ms-modi",
      npx: "image/vnd.net-fpx",
      wbmp: "image/vnd.wap.wbmp",
      xif: "image/vnd.xiff",
      ras: "image/x-cmu-raster",
      cmx: "image/x-cmx",
      "fh,fhc,fh4,fh5,fh7": "image/x-freehand",
      ico: "image/x-icon",
      "pntg,pnt,mac": "image/x-macpaint",
      pcx: "image/x-pcx",
      pnm: "image/x-portable-anymap",
      pbm: "image/x-portable-bitmap",
      pgm: "image/x-portable-graymap",
      ppm: "image/x-portable-pixmap",
      "qtif,qti": "image/x-quicktime",
      rgb: "image/x-rgb",
      xbm: "image/x-xbitmap",
      xpm: "image/x-xpixmap",
      xwd: "image/x-xwindowdump",
      "eml,mime": "message/rfc822",
      "igs,iges": "model/iges",
      "msh,mesh,silo": "model/mesh",
      dwf: "model/vnd.dwf",
      gdl: "model/vnd.gdl",
      gtw: "model/vnd.gtw",
      mts: "model/vnd.mts",
      vtu: "model/vnd.vtu",
      "wrl,vrml": "model/vrml",
      "ics,ifb": "text/calendar",
      css: "text/css",
      csv: "text/csv",
      "html,htm": "text/html",
      "txt,text,conf,def,list,log,in": "text/plain",
      dsc: "text/prs.lines.tag",
      rtx: "text/richtext",
      "sgml,sgm": "text/sgml",
      tsv: "text/tab-separated-values",
      "t,tr,roff,man,me,ms": "text/troff",
      "uri,uris,urls": "text/uri-list",
      curl: "text/vnd.curl",
      dcurl: "text/vnd.curl.dcurl",
      scurl: "text/vnd.curl.scurl",
      mcurl: "text/vnd.curl.mcurl",
      fly: "text/vnd.fly",
      flx: "text/vnd.fmi.flexstor",
      gv: "text/vnd.graphviz",
      "3dml": "text/vnd.in3d.3dml",
      spot: "text/vnd.in3d.spot",
      jad: "text/vnd.sun.j2me.app-descriptor",
      wml: "text/vnd.wap.wml",
      wmls: "text/vnd.wap.wmlscript",
      "s,asm": "text/x-asm",
      "c,cc,cxx,cpp,h,hh,dic": "text/x-c",
      "f,for,f77,f90": "text/x-fortran",
      "p,pas": "text/x-pascal",
      java: "text/x-java-source",
      etx: "text/x-setext",
      uu: "text/x-uuencode",
      vcs: "text/x-vcalendar",
      vcf: "text/x-vcard",
      "3gp": "video/3gpp",
      "3g2": "video/3gpp2",
      h261: "video/h261",
      h263: "video/h263",
      h264: "video/h264",
      jpgv: "video/jpeg",
      "jpm,jpgm": "video/jpm",
      "mj2,mjp2": "video/mj2",
      "mp4,mp4v,mpg4,m4v": "video/mp4",
      "mpeg,mpg,mpe,m1v,m2v": "video/mpeg",
      ogv: "video/ogg",
      "qt,mov": "video/quicktime",
      fvt: "video/vnd.fvt",
      "mxu,m4u": "video/vnd.mpegurl",
      pyv: "video/vnd.ms-playready.media.pyv",
      viv: "video/vnd.vivo",
      "dv,dif": "video/x-dv",
      f4v: "video/x-f4v",
      fli: "video/x-fli",
      flv: "video/x-flv",
      m4v: "video/x-m4v",
      "asf,asx": "video/x-ms-asf",
      wm: "video/x-ms-wm",
      wmv: "video/x-ms-wmv",
      wmx: "video/x-ms-wmx",
      wvx: "video/x-ms-wvx",
      avi: "video/x-msvideo",
      movie: "video/x-sgi-movie",
      ice: "x-conference/x-cooltalk",
      mobi: "application/x-mobipocket-ebook",
      "README,LICENSE,COPYING,TODO,ABOUT,AUTHORS,CONTRIBUTORS": "text/plain"
    },
    defaultMimeType = "binary/octet-stream";

  var MimeTypes = {
    charset: "UTF-8",

    catalog: {},

    get: function(name, opts) {
      if (!name) {
        return defaultMimeType;
      }
      var type = this.catalog[
        name
          .toString()
          .split(".")
          .slice(-1)
          .toString()
          .toLowerCase()
      ];
      opts = opts ? opts : {};

      if (type) {
        if (opts.includeCharset && type.match(/^text\//)) {
          type += "; charset=" + (opts.charset || this.charset);
        }
      }

      return type || defaultMimeType;
    },

    set: function(extString, type) {
      var exts = extString.split(",");

      for (var i = 0; i < exts.length; i++) {
        this.catalog[exts[i]] = type;
      }
    }
  };

  for (var key in types) {
    MimeTypes.set(key, types[key]);
  }

  return MimeTypes;
});
