"use strict";

define(["flight/lib/component", "$app/ui/shared/with_show_hide"], function(
  defineComponent,
  withShowHide
) {
  return defineComponent(LoadingOverlay, withShowHide);

  function LoadingOverlay() {
    this.defaultAttrs({
      showEvent: "uiShowLoadingOverlay",
      hideEvent: "uiHideLoadingOverlay"
    });

    this.setMiddleOpacityLoading = function() {
      if (!this.$node.hasClass("middle")) {
        this.$node.addClass("middle");
      }
    };

    this.removeMiddleOpacityLoading = function() {
      if (this.$node.hasClass("middle")) {
        this.$node.removeClass("middle");
      }
    };

    this.after("initialize", function() {
      this.on(document, this.attr.hideEvent, this.setMiddleOpacityLoading);
      this.on(document, this.attr.showEvent, this.removeMiddleOpacityLoading);
    });
  }
});
