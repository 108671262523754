"use strict";

var compose = require("flight/lib/compose");
var WithFileUpload = require("$app/data/with_file_upload");
var FileUtils = require("$app/utils/file");
require("$app/utils/string");

function WithRichTextImageUpload() {
  compose.mixin(this, [WithFileUpload]);

  this.defaultAttrs({
    isUserAsset: false,
    richTextImageCredentialsSelector: ".js-rich-text-image-credentials",
    dataEvent: "dataUploadingComplete",
    uploadStartedEvent: "dataUploadingRichTextImageStarted",
    uiUploadWithCorsEvent: "uiNeedsToUploadRichTextImageWithCors",
    imageUploadProgressSelector: ".medium-insert-images-progress"
  });

  this.enableImageUpload = function() {
    this.imageUploadSettings = {
      preview: true,
      context: this,
      onImageSelect: this.uploadImage,
      label:
        "<span class='medium-insert-button-icon image-upload-icon'></span>",
      toolbar: false,
      deleteScript: function(ev, data) {
        return false;
      }
    };
  };

  this.uploadImage = function(file) {
    this.trigger("dataFileUploadIsInProgress");
    this.trigger("uiNeedsToUploadRichTextImageWithCors", {
      formId: this.select("richTextImageCredentialsSelector").attr("id"),
      guid: FileUtils.generateGuid(),
      fileName: file.name,
      userExternalId: FileUtils.determineUserExternalIdForForm(
        this.select("richTextImageCredentialsSelector")
      ),
      file: file
    });
  };

  this.uploadComplete = function(ev, data) {
    var url =
      this.select("richTextImageCredentialsSelector").data("s3-url") +
      "/" +
      data.s3Key;
    var tempImageClassName = (data.file.name + data.file.size).hashCode();
    var imageContainer = this.$node.find(
      ".contains-image-" + tempImageClassName
    );
    imageContainer.find("img").attr("src", url);
    imageContainer.find(this.attr.imageUploadProgressSelector).remove();
    imageContainer.trigger("finishedUploading");
    this.trigger("dataFileUploadIsDone");
  };

  this.after("initialize", function() {
    this.on("dataUploadingComplete", this.uploadComplete);
  });
}

module.exports = WithRichTextImageUpload;
