"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(LinkWithEventTracking);

  function LinkWithEventTracking() {
    this.trackUserClick = function(ev) {
      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: $(ev.target).data("event-name")
      });
    };

    this.after("initialize", function() {
      this.on("click", this.trackUserClick);
    });
  }
});
