(function() {
  "use strict";

  define([
    "flight/lib/compose",
    "$app/utils/with_cookies",
    "$app/utils/with_sha"
  ], function(compose, WithCookies, WithSha) {
    return WithPersistentBundle;

    function WithPersistentBundle() {
      compose.mixin(this, [WithCookies, WithSha]);

      this.defaultAttrs({
        cookieName: "gumroad-bundle",
        activeProducts: {}
      });

      this.addEntryToBundle = function(ev, data) {
        if (!this.attr.activeProducts[data.sha]) {
          this.attr.activeProducts[data.sha] = [];
        }
        this.attr.activeProducts[data.sha].push(data);
        this.updateBundle();
      };

      this.addProductsFromActiveList = function() {
        var productList = $.extend({}, this.attr.activeProducts);
        this.attr.activeProducts = {};
        for (var sha in productList) {
          this.addProductsFromList(productList[sha]);
        }
      };

      this.addProductsFromList = function(products) {
        $.each(
          products,
          function(idx, product) {
            if (product.externalId) {
              this.trigger("uiAddProductToBundledList", product);
              this.trigger("addProductToBundle", product);
            }
          }.bind(this)
        );
      };

      this.findBundle = function() {
        var bundle = this.readCookie(this.attr.cookieName);
        if (bundle && bundle.products) {
          this.addProductsFromList(bundle.products);
        }
      };

      this.removeEntryFromBundle = function(ev, data) {
        if (this.attr.activeProducts[data.sha]) {
          this.attr.activeProducts[data.sha].splice(-1, 1);
          if (this.attr.activeProducts[data.sha].length === 0) {
            delete this.attr.activeProducts[data.sha];
          }
        }
        this.updateBundle();
      };

      this.updatePurchasedProduct = function(ev, data) {
        if (data.success) {
          this.removeEntryFromBundle(null, data);
        }
      };

      this.updateBundle = function(ev, data) {
        var products = [];
        for (var product in this.attr.activeProducts) {
          products = products.concat(this.attr.activeProducts[product]);
        }
        this.writeBundleCookie(null, { products: products });
      };

      this.writeBundleCookie = function(ev, data) {
        $.each(data.products, function(idx, product) {
          product.serializedForm = null;
        });
        this.writeCookie(this.attr.cookieName, data);
      };

      this.after("initialize", function() {
        this.on(
          document,
          "uiNeedsToSetPersistentBundle",
          this.writeBundleCookie
        );
        this.on(document, "uiNeedsToGetPersistentBundle", this.findBundle);
        this.on(
          document,
          "uiNeedsToRemoveProductFromPersistentBundle",
          this.removeEntryFromBundle
        );
        this.on(
          document,
          "uiNeedsToAddProductToPersistentBundle",
          this.addEntryToBundle
        );
        this.on(
          document,
          "uiBundledProductPaymentCompleted",
          this.updatePurchasedProduct
        );
        this.on(
          document,
          "uiNeedsToPopulateBundledListFromActiveList",
          this.addProductsFromActiveList
        );
      });
    }
  });
})();
