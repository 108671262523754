var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <li>\n            <i class=\"icn-subtitles js-upload-subtitles-trigger tippable\" data-tipsy-gravity=\"w\" title=\""
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"upload_subtitles",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":104},"end":{"line":24,"column":128}}}))
    + "\"></i>\n            <i class=\"icn-subtitles js-hide-upload-subtitles-trigger active tippable soft-hidden\"></i>\n          </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"subtitle-upload-drawer js-subtitle-upload-drawer soft-hidden\">\n      <ul class=\"subtitle-list js-subtitle-list soft-hidden\"></ul>\n      <a class=\"edit-uploader subtitle-upload-box js-subtitle-upload-box\">\n        <span class=\"prompt\">"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"upload_subtitle_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":29},"end":{"line":39,"column":57}}}))
    + "</span>\n      </a>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"file-row-container js-file-subtitle-wrapper complete\" data-existing-file-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-file-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">\n  <div class=\"file-row js-file-row relative showing-check last-in-file-wrapper\">\n    <div class=\"uploading-bar rainbox-bar\"></div>\n\n      <div class=\"file-row-left\">\n        <i class=\"js-reorder-handle gi gi-hamburger soft-hidden\"></i>\n\n        <div class=\"file-name js-file-name-holder\">\n          <span class=\"js-file-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n          <i class=\"gi gi-pencil\"></i>\n        </div>\n\n        <div class=\"file-name-form js-file-name-form soft-hidden\">\n          <input type=\"text\" class=\"file-name-input js-file-name-input\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" name=\"filename\">\n          <button class=\"button-primary button-small js-change-file-name-trigger change-file-name-trigger\"><i class=\"gi gi-check\"></i></button>\n        </div>\n      </div>\n\n      <ul class=\"file-row-right\">\n        <li><i class=\"remove-button js-remove-trigger tippable\" data-tipsy-gravity=\"w\" title=\""
    + alias2(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias3,"remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":94},"end":{"line":20,"column":108}}}))
    + "\"></i></li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isStreamable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "\n        <li class=\"meta-holder\">\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileInfo") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"extension") : depth0), depth0))
    + "\n        </li>\n      </ul>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isStreamable") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":2},"end":{"line":42,"column":9}}})) != null ? stack1 : "")
    + "</li>\n";
},"useData":true});