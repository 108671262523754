"use strict";

define([], function() {
  return WithMath;

  function WithMath() {
    this.getAverage = function(values) {
      if (!(values instanceof Array) || values.length == 0) return null;

      var total = 0;
      $.each(values, function(i, value) {
        total += value;
      });
      return total / values.length;
    };

    this.getMedian = function(values) {
      if (!(values instanceof Array) || values.length == 0) return null;

      var middle = Math.floor(values.length / 2),
        sortedValues = values.sort(this.ascendingNumericSort);

      if (values.length % 2 == 0) {
        return (sortedValues[middle] + sortedValues[middle - 1]) / 2;
      } else {
        return sortedValues[middle];
      }
    };

    this.ascendingNumericSort = function(a, b) {
      return a - b;
    };

    this.polarToCartesian = function(r, degrees_turned) {
      var radians = (degrees_turned * Math.PI) / 180.0,
        x = r * Math.cos(radians),
        y = r * (Math.sin(radians) + 1);

      return x + " " + y;
    };
  }
});
