(function() {
  "use strict";

  define(["flight/lib/component"], function(defineComponent) {
    return defineComponent(FacebookShare);

    function FacebookShare() {
      this.facebookShare = function(ev) {
        ev.preventDefault();
        var $link = this.$node;
        FB &&
          FB.ui(
            {
              href: $link.data("url"),
              method: "share",
              name: $link.data("name"),
              picture: $link.data("preview")
            },
            function(response) {
              if (response !== undefined) {
                $link
                  .find(".gi-facebook")
                  .removeClass("gi-facebook")
                  .addClass("gi-check");
              }
            }
          );
      };

      this.after("initialize", function() {
        this.on("click", this.facebookShare);
      });
    }
  });
})();
