"use strict";

define([
  "$app/utils/with_url",
  "$app/ui/shared/with_form_base",
  "$app/templates/custom_attributes",
  "flight/lib/compose",
  "query-string",
  "$vendor/jquery-ui-effects-core.min",
  "$vendor/jquery.tipsy"
], function(
  WithUrl,
  WithFormBase,
  CustomAttributesTemplate,
  compose,
  queryString
) {
  return WithProductMain;

  function WithProductMain() {
    compose.mixin(this, [WithUrl, WithFormBase]);

    this.defaultAttrs({
      productMainSelector: ".product-main",
      productContentSelector: ".product-content",
      productInformationSelector: ".js-product-information",
      previewContainerSelector: ".preview-container",
      processingPreviewContainerSelector: ".processing.preview_container",
      productLeftContainerSelector: ".products-left-container",
      productLeftSelector: ".products-left",
      contentHeaderSelector: ".product-content h1",
      productPriceSelector: ".product-price-tag",
      priceSelector: ".product-price-tag .price",
      oldPriceSelector: ".product-price-tag .old-price",
      wantContainerSelector: ".want-container",
      wantFormSelector: ".js-i-want-this-form",
      alreadyBoughtNoticeSelector: ".js-already-bought-notice",
      alreadyBoughtNoticeButtonSelector: ".js-already-bought-notice-button",
      actionsContainerSelector: ".actions-container",
      wantThisContainerSelector: ".i-want-this-container",
      wantButtonSelector: ".i_want_this_button",
      territoryBlockedSelector: ".territory_blocked",
      notForSaleNoticeSelector: ".not-for-sale-notice",
      descriptionContainerSelector: ".description-container",
      descriptionSelector: ".description-container .product-description",
      nameSelector: 'h1[itemprop="name"] strong',
      noneRemainingNoticeSelector: ".none-remaining-notice",
      linkCustomSummarySelector: "#js-main-product-pane ul li.summary",
      linkInfoAttributesSelector: "#js-main-product-pane",
      linkInfoAttributesListSelector: "#js-main-product-pane ul",
      linkFileInfoAttributesSelector: ".js-file-info-attribute",
      linkCustomFileInfoAttributesSelector: ".js-custom-file-info-attribute",
      canGiftSelector: 'input[name="can_gift"]',
      creatorProfileLinkSelector: ".js-creator-profile-link",
      subscriptionChargeOccurrencesMessageSelector:
        ".js-subscription-charge-occurrences-message",
      recurrenceSelectTriggerSelector: ".js-recurrence-select-trigger",
      recurrenceSelectSelector: ".js-recurrence-select",
      priceTagSelector: ".js-price-tag",
      payWhatYouWantSelection: ".js-pay-what-you-want-selection",
      purchaseTypeSelector: ".js-purchase-selector",
      buyTypeSelector: ".js-buy-option",
      rentTypeSelector: ".js-rental-option",
      wantThis: false,
      showingAddToBundle: false,
      isOutboundEmbed: false,
      isUsingBundle: true,
      quickAddToCart: false
    });

    this.redirectToPaymentForm = function() {
      var queryString = "?wanted=true&referrer=" + document.referrer;
      if (
        this.attr.selectedPriceInfo &&
        this.attr.selectedPriceInfo.recurrence
      ) {
        queryString += "&" + this.attr.selectedPriceInfo.recurrence + "=true";
      }

      if (this.attr.quantity) {
        queryString += "&quantity=" + this.attr.quantity;
      }

      window.top.location.href =
        Routes.short_link_path(this.attr.permalink) + queryString;
    };

    this.trackAction = function() {
      if (
        !this.attr.onDiscoverPage &&
        !(this.attr.onOverlayPage && this.attr.isMultiBuyEnabled)
      ) {
        this.trigger("uiNeedsToTrackActivityWithSellerAnalytics", {
          permalink: this.attr.permalink,
          action: "iwantthis"
        });
      }

      this.trigger("uiNeedsToTrackProductSpecificEvent", {
        name: "i_want_this",
        permalink: this.attr.permalink,
        wasProductRecommended: this.attr.wasProductRecommended
      });
    };

    this.canShowPaymentFormFromOverlay = function() {
      if (this.attr.isOverlayPage && this.attr.wantThis) {
        if (this.attr.overlayDuration) {
          this.triggerPaymentOptionUpdate();
          this.attr.overlayDuration = false;
          return false;
        }
        if (this.attr.hasVariants && !this.attr.variantsHaveBeenShown) {
          this.attr.variantSelectionRequired = true;
          return false;
        }
      }
      return true;
    };

    this.showPaymentFormIfPossible = function(ev, animated) {
      ev && ev.preventDefault();

      if (animated === undefined) {
        animated = true;
      }
      if (this.attr.isOutboundEmbed) {
        this.redirectToPaymentForm();
        return;
      }

      var isPurchased =
        this.attr.user &&
        this.attr.purchase &&
        this.attr.user.purchase.should_show_receipt;
      if (
        isPurchased ||
        !this.validateForm(this.select("wantFormSelector")) ||
        !this.validatePriceRange()
      ) {
        this.trigger("uiCannotShowPaymentForm");
        return;
      }

      this.trackAction();

      if (this.isMultiBuyable()) {
        if (this.canShowPaymentFormFromOverlay()) {
          this.addSelfToBundle();
          this.trigger("uiCanShowPaymentForm");
        } else {
          this.trigger("uiCannotShowPaymentForm");
          return;
        }
      } else if (this.attr.isCurrentUserSet) {
        if (this.attr.onOverlayPage && !this.attr.singleProductMode) {
          this.trigger("uiCanShowPaymentForm");
        } else {
          this.switchToPaymentForm(animated);
          this.trigger("uiCannotShowPaymentForm");
        }
      }
    };

    this.showPaymentForm = function() {
      this.switchToPaymentForm(true);
    };

    this.setPreviewProcessing = function() {
      if (this.attr.user.card.credit == "test") {
        this.select("processingPreviewContainerSelector").fadeIn();
      }
    };

    this.setPriceTag = function(price) {
      var displayedPrice = this.priceWithCommas(
        price % 1 == 0
          ? price
          : typeof price === "number"
          ? price.toFixed(2)
          : price
      );

      if (this.attr.isPayWhatYouWant) {
        displayedPrice += "+";
      }

      if (this.attr.isRecurringBilling) {
        displayedPrice += this.attr.selectedPriceInfo.recurrence_formatted;
      }

      this.select("priceSelector").text(
        this.select("productSelector").data("currency-symbol") + displayedPrice
      );
    };

    this.priceWithCommas = function(price) {
      var parts = price.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    };

    this.showOldPrice = function(oldPrice) {
      this.select("oldPriceSelector")
        .text(
          this.select("productSelector").data("currency-symbol") +
            this.priceWithCommas(
              oldPrice % 1 == 0 ? oldPrice : oldPrice.toFixed(2)
            )
        )
        .show();
    };

    this.hideOldPrice = function() {
      this.select("oldPriceSelector").hide();
    };

    this.updateDefaultHeightAttributes = function() {
      this.attr.defaultProductMainHeight = this.select(
        "productMainSelector"
      ).outerHeight();
      this.attr.previewHeightOnInitialize = this.select("productSelector")
        .find(".preview-container")
        .outerHeight();
    };

    this.updateDefaultHeight = function() {
      if (typeof this.attr.defaultProductMainHeight == "undefined") {
        this.updateDefaultHeightAttributes();
      }
    };

    this.setRemaining = function() {
      if (
        this.attr.user.product.remaining ||
        this.attr.user.product.remaining == 0
      ) {
        this.select("productLeftSelector").text(
          I18n.t("js.left_with_count", {
            count: this.attr.user.product.remaining
          })
        );
        this.select("productLeftContainerSelector").show();
        if (!this.attr.hasPreview) {
          this.select("contentHeaderSelector").css("padding-left", 50);
        }

        if (this.attr.user.product.remaining == 0) {
          this.select("noneRemainingNoticeSelector").show();
          this.select("wantThisContainerSelector").hide();
          this.attr.isProductDisabled = true;
        }
      }
    };

    this.setTerritoryBlockedNotice = function() {
      if (this.attr.user.product.compliance_blocked) {
        this.select("territoryBlockedSelector").show();
        this.select("wantThisContainerSelector").hide();
      }
    };

    this.updateProductName = function(ev, data) {
      this.select("nameSelector").text(data.name);
    };

    this.updateProductDescription = function(ev, data) {
      if (data.html != null) {
        this.select("descriptionSelector").html(data.html);
      } else {
        this.select("descriptionSelector").html("");
      }
    };

    this.updateProductPrice = function(ev, data) {
      if (data.buy != null && data.rent != null) {
        this.select("purchaseTypeSelector").show();
        const currencySymbol = this.select("productSelector").data(
          "currency-symbol"
        );
        this.select("buyTypeSelector")
          .find(".variant-holder__variant-option__price-tag")
          .text(currencySymbol + data.buy);
        this.select("rentTypeSelector")
          .find(".variant-holder__variant-option__price-tag")
          .text(currencySymbol + data.rent);
      } else {
        this.select("purchaseTypeSelector").hide();
      }

      const showPriceRange = range => {
        this.attr.isPayWhatYouWant = range.indexOf("+") !== -1;
        try {
          this.setPriceTag(range.replace("+", ""));
        } catch (e) {
          // Improperly formatted price range string, ignore silently
        }

        if (this.attr.isPayWhatYouWant) {
          this.select("payWhatYouWantSelection").show();
        } else {
          this.select("payWhatYouWantSelection").hide();
        }
      };

      if (data.buy != null && data.buy.length > 0) {
        showPriceRange(data.buy);
      } else if (data.rent != null && data.rent.length > 0) {
        showPriceRange(data.rent);
      }
    };

    this.updateWantButtonText = function(ev, data) {
      this.select("wantButtonSelector").text(data.buttonText);
    };

    this.updateCustomAttributes = function(ev, data) {
      if (this.select("linkCustomSummarySelector").length == 0) {
        this.select("linkInfoAttributesListSelector").prepend(
          '<li class="summary"></li>'
        );
      }
      this.select("linkCustomSummarySelector").text(data.linkCustomSummaryText);

      const fileAttributes = this.select("linkFileInfoAttributesSelector").each(
        (_, fileAttribute) => {
          const attributeName = fileAttribute.dataset.fileInfoKeyName;
          const shouldRemove =
            data.removedFileAttributes.indexOf(attributeName) !== -1;
          if (shouldRemove) {
            fileAttribute.remove();
          }
        }
      );

      this.select("linkCustomFileInfoAttributesSelector").remove();
      this.select("linkInfoAttributesListSelector").append(
        CustomAttributesTemplate({ custom_attributes: data.customAttributes })
      );
      if (this.select("productMainSelector").height() > 0) {
        this.select("productMainSelector").height("auto");
      }

      if (data.linkCustomSummaryText.length == 0) {
        this.select("linkCustomSummarySelector").remove();
      }

      const remainingFileInfoAttributesCount = this.select(
        "linkFileInfoAttributesSelector"
      ).length;

      if (
        data.customAttributes.length > 0 ||
        data.linkCustomSummaryText.length > 0 ||
        remainingFileInfoAttributesCount > 0
      ) {
        this.select("linkInfoAttributesSelector").removeClass("hidden");
      } else {
        this.select("linkInfoAttributesSelector").addClass("hidden");
      }
    };

    this.switchToBuyMode = function(ev) {
      ev.preventDefault();
      this.select("buyTypeSelector").addClass("selected");
      this.select("rentTypeSelector").removeClass("selected");
      this.attr.isRentalModeActive = false;
      this.trigger("uiNeedsToUpdatePriceDueToPurchaseModeChange");
    };

    this.switchToRentMode = function(ev) {
      ev.preventDefault();
      this.select("rentTypeSelector").addClass("selected");
      this.select("buyTypeSelector").removeClass("selected");
      this.attr.isRentalModeActive = true;
      this.trigger("uiNeedsToUpdatePriceDueToPurchaseModeChange");
    };

    this.updatePaymentOptionSelection = function(paymentRecurrenceString) {
      var $selectedPrice = $(
        this.select("recurrenceSelectSelector").find(
          'option[data-recurrence="' + paymentRecurrenceString + '"]'
        )[0]
      );
      $selectedPrice.attr("selected", "selected");
      this.select("recurrenceSelectSelector")
        .trigger("change")
        .hide();
    };

    this.paymentOptionChanged = function(ev) {
      var $selectedPrice = this.select("recurrenceSelectSelector").find(
        ":selected"
      );
      this.select("recurrenceSelectTriggerSelector").text($selectedPrice.val());
      this.attr.selectedPriceInfo = $selectedPrice.data().priceInfo;
      this.attr.priceCents = this.attr.selectedPriceInfo.price_cents;
      this.attr.price =
        this.attr.selectedPriceInfo.price_cents /
        (this.attr.isSingleUnit ? 1 : 100);
      this.setPrice();
      this.setPurchaseNotice();
      this.setVariants();
      this.select("recurrenceSelectSelector").hide();
    };

    this.showRecurrenceSelect = function(ev) {
      ev.preventDefault();
      this.select("recurrenceSelectSelector").show();
    };

    this.setAlreadyBought = function() {
      if (!this.attr.user.purchase.should_show_receipt) {
        return;
      }

      this.select("alreadyBoughtNoticeButtonSelector")
        .text(this.attr.user.purchase.view_content_button_text)
        .attr("href", this.attr.user.purchase.content_url);
      this.select("alreadyBoughtNoticeSelector").show();
      this.select("wantButtonSelector").text(I18n.t("js.purchase_again"));
      this.trigger("uiSetInitialRating", {
        purchase_id: this.attr.user.purchase.id,
        rating: this.attr.user.purchase.product_rating
      });
    };

    this.updateChargeOccurrenceMessage = function(ev, price) {
      var formattedPrice =
        this.select("productSelector").data("currency-symbol") +
        (price % 1 == 0 ? price : price.toFixed(2));
      if (price === 0) {
        this.select("subscriptionChargeOccurrencesMessageSelector").hide();
      } else {
        this.select("subscriptionChargeOccurrencesMessageSelector")
          .find(".js-price")
          .text(formattedPrice);
        this.select("subscriptionChargeOccurrencesMessageSelector").show();
      }
    };

    this.updateDefaultRecurrence = function(ev, data) {
      this.updatePaymentOptionSelection(data.duration);
    };

    this.setRecommendedBy = function() {
      let link = this.select("creatorProfileLinkSelector");
      if (link.attr("href") && this.attr.recommendedBy) {
        let parsedHref = queryString.parseUrl(link.attr("href"));
        let newQueryParams = {
          ...parsedHref.query,
          recommended_by: this.attr.recommendedBy
        };
        let newHref = `${parsedHref.url}?${queryString.stringify(
          newQueryParams
        )}`;
        link.attr("href", newHref);
      }
    };

    this.after("initialize", function() {
      this.select("productPriceSelector").tipsy();
      this.setRecommendedBy();

      this.attr.hasPreview = this.select("previewContainerSelector").length > 0;
      this.attr.canGift = this.select("canGiftSelector").length > 0;

      if (this.select("recurrenceSelectSelector").length > 0) {
        var $selectedPrice = this.select("recurrenceSelectSelector").find(
          ":selected"
        );
        this.attr.selectedPriceInfo = $selectedPrice.data().priceInfo;
      }

      var $previewContainer = this.select("productSelector").find(
          ".preview-container"
        ),
        $previewImage = this.select("productSelector").find(
          ".image-preview-container img"
        );

      $previewImage.load(
        function() {
          $previewContainer.data("height", $previewImage.height());
          //add height of image loaded to default-height for slide if it's already been set before load.
          if (typeof this.attr.defaultProductMainHeight != "undefined") {
            this.attr.defaultProductMainHeight +=
              $previewContainer.outerHeight() -
              this.attr.previewHeightOnInitialize;
          }
          if (!this.attr.isShowingPaymentForm && !this.attr.isShowingReceipt) {
            this.switchToProductMain(false);
          }

          this.trigger("uiNeedsToToggleScrollButton");
        }.bind(this)
      );

      if (this.isMultiBuyable()) {
        this.select("wantButtonSelector").text(I18n.t("js.add_to_cart"));
      }

      this.on(document, "uiForceShowPaymentForm", this.showPaymentForm);
      this.on(document, "uiShowPaymentForm", this.showPaymentFormIfPossible);
      this.on(document, "uiUpdateProductName", this.updateProductName);
      this.on(
        document,
        "uiUpdateProductDescription",
        this.updateProductDescription
      );
      this.on(document, "uiUpdateProductPrice", this.updateProductPrice);
      this.on(document, "uiUpdateWantButton", this.updateWantButtonText);
      this.on(
        document,
        "uiUpdateCustomAttributes",
        this.updateCustomAttributes
      );
      this.on(
        document,
        "uiToUpdateChargeOccurrenceMessage",
        this.updateChargeOccurrenceMessage
      );
      this.on(
        document,
        "uiUpdateDefaultRecurrence",
        this.updateDefaultRecurrence
      );

      this.on("change", {
        recurrenceSelectSelector: this.paymentOptionChanged
      });

      this.on("click", {
        recurrenceSelectTriggerSelector: this.showRecurrenceSelect,
        buyTypeSelector: this.switchToBuyMode,
        rentTypeSelector: this.switchToRentMode
      });

      this.select("recurrenceSelectTriggerSelector").show();

      this.on("click", { wantButtonSelector: this.showPaymentFormIfPossible });

      if (this.attr.quickAddToCart) {
        this.select("wantButtonSelector").click();
      }

      this.trigger("uiNeedsToToggleScrollButton");
    });
  }
});
