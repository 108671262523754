(function() {
  "use strict";

  define(["flight/lib/component", "$app/ui/shared/with_preview"], function(
    defineComponent,
    WithPreview
  ) {
    return defineComponent(ProductOverlayTrigger, WithPreview);

    function ProductOverlayTrigger() {
      this.defaultAttrs({
        productSelector: ".product",
        previewIframeSelector: ".js-preview-container iframe",
        productLeftSelector: ".products-left",
        productLeftContainerSelector: ".products-left-container",
        quickAddToCartTriggerSelector: ".js-quick-add-to-cart-trigger",
        shouldTriggerOverlay: true,
        skipOverlay: false
      });

      this.isOnPreviewElement = function(ev) {
        // If clicking on an iframe within the preview, like a YouTube video:
        if (!ev) {
          return true;
        }
        var $target = $(ev.target || ev.srcElement);
        return (
          $target.hasClass("preview-arrow") ||
          $target.parents(".preview-arrow").length ||
          $target.hasClass("preview-picker") ||
          $target.parents(".preview-picker").length ||
          $target.hasClass("show-product-page-button") ||
          $target.parents(".show-product-page-button").length ||
          $target.hasClass("js-creator-profile-link") ||
          $target.parents(".js-creator-profile-link").length
        );
      };

      this.fetchProductInformation = function(ev) {
        if (this.isOnPreviewElement(ev) || !this.attr.shouldTriggerOverlay) {
          return;
        }
        ev && ev.preventDefault();
        ev && ev.stopPropagation();
        this.trigger(document, "uiNeedsAProduct", {
          permalink: this.attr.permalink,
          recommended_by: this.attr.recommendedBy
        });
      };

      this.showMoreProductInformation = function(ev) {
        if (this.isOnPreviewElement(ev) || !this.attr.shouldTriggerOverlay) {
          return;
        }
        this.fetchProductInformation(ev);
        this.trigger("uiNeedsToShowSkeletonProductInOverlay");
      };

      this.showProductOverlay = function(ev, data) {
        // Normally, this opens up an overlay with the product showing.
        // If this.attr.skipOverlay is true, no overlay is shown to the user
        // while the product is added to their cart. We still render the
        // product into the (hidden) modal div though because a lot of
        // pre-existing wiring depends on it.
        ev.preventDefault();

        window.location.hash = data.permalink;
        if (data.permalink == this.attr.permalink) {
          if (this.attr.skipOverlay) {
            // Change the "add to cart" button to a checkmark to let the
            // user know that they successfully added the product
            this.select("skipOverlayTriggerSelector").html(
              '<i class="icn-check"></i>'
            );
          }

          this.trigger("uiNeedsToShowSelectedProductInOverlay", {
            productNode: data.html,
            recommendedBy: this.attr.recommendedBy,
            quickAddToCart: this.attr.skipOverlay
          });

          this.attr.skipOverlay = false;
        }
      };

      this.skipOverlay = function(ev) {
        // Change the "add to cart" button to a loading symbol to
        // acknowledge the user's click on the button
        this.select("skipOverlayTriggerSelector").html(
          '<i class="icn-loading"></i>'
        );

        const target = this.select("quickAddToCartTriggerSelector");
        if (
          target.data("has-variants") ||
          target.data("has-customizable-price") ||
          target.data("has-billing-options")
        ) {
          // If there are variants or a customizable price, show the overlay
          // so the user can select variants or name a price before adding to cart
          this.changeIconToCart(null, {
            permalink: this.attr.permalink
          });
          this.showMoreProductInformation(ev);
        } else {
          // Otherwise, skip the overlay
          this.attr.skipOverlay = true;
          this.fetchProductInformation(ev);
        }
      };

      this.changeIconToCart = function(_ev, data) {
        if (data.permalink === this.attr.permalink) {
          // Change the "add to cart" button back to its original cart icon
          this.select("skipOverlayTriggerSelector").html(
            '<i class="icn-cart"></i>'
          );
        }
      };

      this.trackInsideIframe = function(ev) {
        this.attr.insideIframe = true;
        this.attr.currentIframe = $(ev.target || ev.srcElement);
      };

      this.trackOutsideIframe = function() {
        this.attr.insideIframe = false;
        this.attr.currentIframe = undefined;
      };

      this.stopProductTriggeringOverlay = function() {
        this.attr.shouldTriggerOverlay = false;
      };

      this.makeProductTriggeringOverlay = function() {
        this.attr.shouldTriggerOverlay = true;
      };

      this.setRemaining = function() {
        if (
          this.attr.user.product.remaining ||
          this.attr.user.product.remaining == 0
        ) {
          this.select("productLeftContainerSelector").show();
          this.select("productLeftSelector").text(
            I18n.t("js.left_with_count", {
              count: this.attr.user.product.remaining
            })
          );
          if (this.attr.user.product.remaining == 0) {
            this.select("quickAddToCartTriggerSelector").hide();
          }
        }
      };

      this.setCurrentUserInfo = function(ev, data) {
        if (this.attr.permalink === data.permalink) {
          this.attr.user = data;
          this.setRemaining();
        }
      };

      this.after("initialize", function() {
        this.on(document, "dataGotAProduct", this.showProductOverlay);
        this.on(document, "dataCurrentUserInfo", this.setCurrentUserInfo);
        this.on(document, "uiNeedsToHideProductOverlay", this.changeIconToCart);
        this.$node.addClass("image-as-background");

        this.attr.permalink = this.select("productSelector").data(
          "unique-permalink"
        );
        this.attr.recommendedBy = this.select("overlayTriggerSelector").data(
          "recommended-by"
        );
        this.trigger("uiNeedsToGetCurrentUserInfo", {
          permalink: this.attr.permalink
        });
        this.on(
          document,
          "uiShouldShowEditing",
          this.stopProductTriggeringOverlay
        );
        this.on(
          document,
          "uiShouldHideEditing",
          this.makeProductTriggeringOverlay
        );
        this.on("mouseover", { previewIframeSelector: this.trackInsideIframe });
        this.on("mouseout", { previewIframeSelector: this.trackOutsideIframe });
        this.on("click", {
          multiPreviewContainerSelector: this.showMoreProductInformation,
          skipOverlayTriggerSelector: this.skipOverlay,
          overlayTriggerSelector: this.showMoreProductInformation
        });
      });
    }
  });
})();
