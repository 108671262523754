"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(DashboardLink);

  function DashboardLink() {
    this.updateDate = function(ev, data) {
      this.$node.attr("href", this.attr.defaultHref + data.dateHash);
    };

    this.after("initialize", function() {
      this.attr.defaultHref = this.$node.attr("href");
      this.on(document, "dataDateHash", this.updateDate);
    });
  }
});
