"use strict";

/**
 * A mixin that allows ui to switch between default and loading text.
 *
 * @attr {string} loadingText (optional) - The text to be used as loading text. If not given, js.uploading will be used.
 * @attr {string} defaultText (optional) - The text to be used as default text. If not given, target's text will be used.
 * @attr {string} loadingTextTargetSelector (optional) - The selector for a dom to use as a target. If not given, $node will be used.
 */

define([], function() {
  function LoadingText() {
    this.showLoadingText = function() {
      var $target = this.attr.loadingTextTargetSelector
        ? this.select("loadingTextTargetSelector")
        : this.$node;
      $target.text(this.attr.loadingText);
      $target.prop("disabled", "disabled");
    };

    this.showDefaultText = function() {
      var $target = this.attr.loadingTextTargetSelector
        ? this.select("loadingTextTargetSelector")
        : this.$node;
      $target.text(this.attr.defaultText);
      $target.removeProp("disabled");
    };

    this.after("initialize", function() {
      var $target = this.attr.loadingTextTargetSelector
        ? this.select("loadingTextTargetSelector")
        : this.$node;

      if (typeof this.attr.defaultText == "undefined") {
        this.attr.defaultText = $target.text();
      }
      if (typeof this.attr.loadingText == "undefined") {
        this.attr.loadingText = I18n.t("js.uploading");
      }
    });
  }

  return LoadingText;
});
