"use strict";

/**
 * A mixin that contains useful functions for multi-file upload.
 * This mixin should only be mixed into component which has form as its node.
 *
 * This mixin can also accept dropbox uploads from the user. This component will act on the following triggered
 * events to display dropbox files to the user:
 *
 * - uiNeedsToDisplayNewDropboxFile
 * - dataDropboxFilesFetchWasSuccessful
 *
 * There is some tight coupling now but in the future I hope to remove this coupling and allow users to do dropbox
 * uploads for any type of file. The only use case users want right now for dropbox upload is product files. (Maxwell)
 *
 * @attr {string} formSelector (optional) - The selector for a form. $node is used if it is not present.
 * @attr {boolean} isFileReorderAllowed
 * @attr {string} fileListSelector
 * @attr {string} productFileInputSelector
 */

const FileRowTemplate = require("$app/templates/file_row");
const ExistingFileRowTemplate = require("$app/templates/existing_file_row");
const SubtitleRowTemplate = require("$app/templates/subtitle_row");
const FileUtils = require("$app/utils/file");
const MimeTypes = require("$app/utils/mimetypes");
const Sortable = require("sortablejs").default;
const Evaporate = require("$vendor/evaporate");
const { SUBTITLE_LANGUAGES } = require("$app/utils/subtitle_languages");

function MultiFileUpload() {
  this.defaultAttrs({
    cancelButtonSelector: ".js-cancel-trigger",
    removeButtonSelector: ".js-remove-trigger",
    reorderHandleSelector: ".js-reorder-handle",
    inProgressFileUploadCount: 0,
    fileRowSelector: ".js-file-row",
    uploadSubtitleDrawerSelector: ".js-subtitle-upload-drawer",
    uploadSubtitlesButtonSelector: ".js-upload-subtitles-trigger",
    hideUploadSubtitlesButtonSelector: ".js-hide-upload-subtitles-trigger",
    fileSubtitleWrapperSelector: ".js-file-subtitle-wrapper",
    subtitleUploaderSelector: ".js-subtitle-upload-box",
    subtitleListSelector: ".js-subtitle-list",
    subtitleRowSelector: ".js-subtitle-row",
    subtitlesFileInputSelector: "#subtitles-file",
    removeSubtitleButtonSelector: ".js-remove-subtitle-trigger",
    cancelSubtitleButtonSelector: ".js-cancel-subtitle-trigger",
    dropboxUploadTriggerSelector: ".js-dropbox-upload",
    chooseExistingFilesTriggerSelector: ".js-choose-existing-trigger",
    chooseExistingFilesContainerSelector: ".js-choose-existing-holder",
    addFilesHolderSelector: ".js-add-files-trigger",
    chooseFilesTriggerSelector: ".js-choose-files-trigger",
    selectFileTriggerSelector: ".js-select-file-trigger",
    fileSelectTriggerSelector: ".js-file-selector",
    selectedTriggerSelector: ".js-selected",
    findYourFilesInputSelector: ".js-find-files-input"
  });

  this.validateFileName = function(fileName, allowedExtensions) {
    if (allowedExtensions) {
      var ext =
        fileName.indexOf(".") != -1
          ? fileName.replace(/.*[.]/, "").toLowerCase()
          : "";

      for (var i = 0; i < allowedExtensions.length; i++) {
        if (allowedExtensions[i].toLowerCase() == ext) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  this.showFileChooser = function(ev) {
    ev.preventDefault();

    if (!this.attr.evaporate.supported) {
      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.browser_unable_to_upload_files_prompt")
      });
      return false;
    }

    this.select("productFileInputSelector").click();

    return false;
  };

  this.getFileUrlList = function() {
    var $filesList = this.select("fileListSelector"),
      urlList = [];
    $filesList.children().each(function() {
      urlList.push($(this).data("file-url"));
    });

    return urlList;
  };

  this.getFilePositions = function() {
    const $filesList = this.select("fileListSelector");
    const that = this;
    let filePositions = {};

    $filesList.children().each(function(position) {
      const $this = $(this);
      const fileUrl = $this.data("file-url");
      const uniqueProductIdentifier = FileUtils.extractUniqueUrlIdentifier(
        fileUrl
      );

      filePositions[uniqueProductIdentifier] = position;
    });

    return filePositions;
  };

  this.getFileNameFromInput = function(fileInput) {
    if (typeof fileInput != "undefined") {
      if (fileInput.value != null) {
        //ie
        return fileInput.value.replace(/.*(\/|\\)/, "");
      } else {
        return fileInput.fileName != null ? fileInput.fileName : fileInput.name;
      }
    }
  };

  this.incrementInProgressFileUploadCount = function() {
    this.attr.inProgressFileUploadCount++;
    if (this.attr.inProgressFileUploadCount == 1) {
      this.trigger("dataFileUploadIsInProgress");
    }
  };

  this.decrementInProgressFileUploadCount = function() {
    this.attr.inProgressFileUploadCount--;
    if (this.attr.inProgressFileUploadCount == 0) {
      this.trigger("dataFileUploadIsDone");
    }
  };

  this.cancelFileUpload = function(ev) {
    ev.preventDefault();
    var $cancelButton = $(ev.target || ev.srcElement);
    if (
      $cancelButton
        .closest(this.attr.fileRowSelector)
        .data("dropbox-file-external-id")
    ) {
      this.trigger("uiNeedsToCancelDropboxUpload", {
        dropbox_file_id: $cancelButton
          .closest(this.attr.fileRowSelector)
          .data("dropbox-file-external-id")
      });
    } else {
      var uploadId = $cancelButton.data("upload-id");
      this.attr.evaporate.cancel(uploadId);
    }
    $cancelButton.parents(this.attr.fileSubtitleWrapperSelector).remove();

    if (this.select("filesBoxSelector").is(":empty")) {
      this.select("filesBoxSelector").hide();
    }

    this.decrementInProgressFileUploadCount();
    this.removeFileListIfEmpty();
    this.trigger("uiPageHeightHasChanged");
    this.trigger("uiShouldHideTipsys");
    return false;
  };

  this.removeFile = function(ev) {
    ev.preventDefault();

    $(ev.target || ev.srcElement)
      .parents(this.attr.fileSubtitleWrapperSelector)
      .remove();
    if (this.select("filesBoxSelector").is(":empty")) {
      this.select("filesBoxSelector").hide();
    }

    var dropboxFileExternalId = $(ev.target || ev.srcElement)
      .closest(this.attr.fileRowSelector)
      .data("dropbox-file-external-id");
    if (dropboxFileExternalId) {
      this.trigger("uiNeedsToCancelDropboxUpload", {
        dropbox_file_id: dropboxFileExternalId
      });
    }

    var chooseFromExistingFileExternalId = $(ev.target || ev.srcElement)
      .parents(this.attr.fileSubtitleWrapperSelector)
      .data("existing-file-id");
    if (chooseFromExistingFileExternalId) {
      this.trigger("uiNeedsToUnselectChooseFromExistingFileRow", {
        chooseFromExistingFileExternalId: chooseFromExistingFileExternalId
      });
    }

    this.trigger("uiFileListHasChanged");
    this.removeFileListIfEmpty();
    this.updateFileListUI();

    this.trigger("uiPageHeightHasChanged");
    this.trigger("uiShouldHideTipsys");
    return false;
  };

  this.getForm = function() {
    if (!!this.attr.formSelector) {
      if (this.select("formSelector").length == 0) {
        return $(this.attr.formSelector);
      } else {
        return this.select("formSelector");
      }
    } else {
      return this.$node;
    }
  };

  this.removeFileListIfEmpty = function() {
    var $filesList = this.select("fileListSelector");
    if ($filesList.children().length == 0) {
      $filesList.hide();
    }
  };

  this.setupFiles = function(filesData) {
    var $filesList = this.select("fileListSelector"),
      that = this;
    $.each(filesData.files, function(i, file) {
      var hasSubtitles =
          file.subtitle_files != undefined && file.subtitle_files.length > 0,
        $fileRow = $(
          FileRowTemplate({
            name: file.file_name,
            fileInfo:
              file.file_size > 0
                ? FileUtils.getFullFileSizeString(file.file_size)
                : "",
            uploading: false,
            complete: true,
            isStreamable: file.is_streamable,
            isTranscodingInProgress: file.is_transcoding_in_progress,
            extension: file.extension,
            id: file.id
          })
        );

      $fileRow.data("file-url", file.url);
      if (hasSubtitles) {
        var $subtitleList = $fileRow.find(that.attr.subtitleListSelector);
        $.each(file.subtitle_files, function(j, subtitleFile) {
          var $subtitleRow = $(
            that.SubtitleRowTemplateWrapper({
              name: subtitleFile.file_name,
              uploading: false,
              complete: true,
              language: subtitleFile.language,
              fileInfo: subtitleFile.size,
              extension: subtitleFile.extension
            })
          );
          $subtitleRow.data("file-url", subtitleFile.url);
          $subtitleList.append($subtitleRow);
        });
        $subtitleList.show();
      }
      $filesList.append($fileRow);
    });

    if ($filesList.children().length > 0) {
      $filesList.show();
    } else {
      $filesList.hide();
    }

    this.updateFileListUI();
  };

  this.updateFileListUI = function() {
    var $handles = this.select("reorderHandleSelector");

    if (this.attr.isFileReorderAllowed && $handles.length > 1) {
      $handles.show();
    } else {
      $handles.hide();
    }

    this.hideRenameOptionsIfOnDashboardPage();

    this.trigger("uiShouldTipsyElements");
    this.trigger("uiFileListHasChanged");
  };

  this.renderExistingDropboxFileUploads = function(ev, data) {
    var $filesList = this.select("fileListSelector");
    var dropboxFiles = data.dropbox_files;
    for (var i = 0; i < dropboxFiles.length; i++) {
      var dropboxFile = dropboxFiles[i];
      var $existingFileRow = $filesList
        .children()
        .has(
          this.attr.fileRowSelector +
            "[data-dropbox-file-external-id='" +
            dropboxFile.external_id +
            "']"
        );
      var currentDropboxFileState = $existingFileRow
        .find(this.attr.fileRowSelector)
        .data("dropbox-file-upload-state");
      var isValidState =
        dropboxFile.state == "successfully_uploaded" ||
        dropboxFile.state == "in_progress";
      if ($existingFileRow.length == 0) {
        if (isValidState) {
          this.addNewDropboxFileRowToFileList(dropboxFile);
        }
      } else {
        if (isValidState) {
          if (currentDropboxFileState == dropboxFile.state) {
            continue;
          }
          var isComplete = dropboxFile.state == "successfully_uploaded";
          var extension = FileUtils.getFileExtension(
              dropboxFile.name
            ).toUpperCase(),
            videoRegex = /mp4|m4v|mov|mpeg|mpeg4|wmv|movie|ogv|avi/i,
            isStreamable = videoRegex.test(extension),
            $newFileRow = $(
              FileRowTemplate({
                name: dropboxFile.name.substr(
                  0,
                  dropboxFile.name.length - extension.length - 1
                ),
                uploading: dropboxFile.state == "in_progress",
                complete: isComplete,
                fileInfo: FileUtils.getFullFileSizeString(dropboxFile.bytes),
                isStreamable: isStreamable,
                isTranscodingInProgress: false,
                extension: extension,
                dropboxFileExternalId: dropboxFile.external_id,
                dropboxFileUploadState: dropboxFile.state
              })
            );
          $newFileRow.data("file-url", dropboxFile.s3_url);
          $existingFileRow.replaceWith($newFileRow);

          if (isComplete) {
            $newFileRow.addClass("showing-check");
          }

          this.updateFileListUI();
          if (isStreamable) {
            this.trigger("dataStreamableFileWasAdded");
          }
          if (dropboxFile.state != "in_progress") {
            this.decrementInProgressFileUploadCount();
          }
        } else {
          $existingFileRow.remove();
          this.decrementInProgressFileUploadCount();
          this.removeFileListIfEmpty();
          if ($(this.attr.dropboxUploadTriggerSelector).is(":visible")) {
            this.trigger("uiPageHeightHasChanged");
          }
        }
      }
    }
  };

  this.renderNewDropboxFileRow = function(ev, data) {
    var dropbox_file = data.dropbox_file;
    this.addNewDropboxFileRowToFileList(dropbox_file);
  };

  this.addNewDropboxFileRowToFileList = function(dropboxFile) {
    var $filesList = this.select("fileListSelector");
    if ($filesList.children().length == 0) {
      $filesList.show();
    }

    var extension = FileUtils.getFileExtension(dropboxFile.name).toUpperCase(),
      videoRegex = /mp4|m4v|mov|mpeg|mpeg4|wmv|movie|ogv|avi/i,
      isStreamable = videoRegex.test(extension),
      $newFileRow = $(
        FileRowTemplate({
          name: dropboxFile.name.substr(
            0,
            dropboxFile.name.length - extension.length - 1
          ),
          fileInfo: FileUtils.getFullFileSizeString(dropboxFile.bytes),
          extension: extension,
          dropboxFileExternalId: dropboxFile.external_id,
          dropboxFileUploadState: dropboxFile.state,
          complete: dropboxFile.state === "successfully_uploaded",
          uploading: dropboxFile.state === "in_progress",
          isStreamable: isStreamable,
          isTranscodingInProgress: false
        })
      );
    $newFileRow.data("file-url", dropboxFile.s3_url);
    $filesList.append($newFileRow);
    if (dropboxFile.state == "in_progress") {
      this.incrementInProgressFileUploadCount();
    }

    this.trigger("uiFileListHasChanged");
    if ($(this.attr.dropboxUploadTriggerSelector).is(":visible")) {
      this.trigger("uiPageHeightHasChanged");
    }
    this.updateFileListUI();
  };

  this.beginUpload = function(ev, data) {
    this.uploadFiles(ev, this.select("fileListSelector"), FileRowTemplate);
  };

  this.uploadFiles = function(ev, $filesList, template) {
    var files = ev.target.files,
      that = this;

    for (var i = 0; i < files.length; i++) {
      var file = files[i],
        extension = FileUtils.getFileExtension(file.name).toUpperCase();

      if ($filesList.children().length == 0) {
        $filesList.show();
      }

      $filesList.append(
        template({
          name: file.name.substr(0, file.name.length - extension.length - 1),
          fileInfo: FileUtils.getFullFileSizeString(file.size),
          extension: extension
        })
      );

      var status = this.attr.evaporate.add({
        // Firefox does not handle the encoding of ' characters correctly, we have to force it to work here
        name: FileUtils.getS3Key(
          FileUtils.generateGuid(),
          encodeURIComponent(file.name).replace("'", "%27"),
          FileUtils.determineUserExternalIdForForm(that.getForm()),
          "attachments"
        ),
        originalName: file.name,
        file: file,
        $fileRow: $filesList.children().last(),
        url: that.getForm().data("s3-url"),
        mimeType: MimeTypes.get(file.name),
        previousProgress: 0,
        xAmzHeadersAtInitiate: {
          "x-amz-acl": "private"
        },
        complete: function() {
          if (this.$fileRow) {
            var file = this.file,
              fileUrl = this.url + "/" + decodeURIComponent(this.name),
              extension = FileUtils.getFileExtension(
                this.originalName
              ).toUpperCase(),
              videoRegex = /mp4|m4v|mov|mpeg|mpeg4|wmv|movie|ogv|avi/i,
              isStreamable = videoRegex.test(extension),
              $newFileRow = $(
                template({
                  name: this.originalName.substr(
                    0,
                    this.originalName.length - extension.length - 1
                  ),
                  uploading: false,
                  complete: true,
                  fileInfo: FileUtils.getFullFileSizeString(file.size),
                  isStreamable: isStreamable,
                  isTranscodingInProgress: false,
                  extension: extension
                })
              );
            $newFileRow.data("file-url", fileUrl);
            this.$fileRow.replaceWith($newFileRow);
            that.updateFileListUI();
            that.trigger("uiFileListHasChanged");
            that.trigger("uiPageHeightHasChanged");

            if (isStreamable) {
              that.trigger("dataStreamableFileWasAdded");
            }
          }
          that.decrementInProgressFileUploadCount();
        },
        progress: function(progress) {
          if (this.$fileRow) {
            // Calculate the bitrate of the file upload by subtracting the completed percentage from the last iteration from the current iteration percentage
            // and multiplying that by the bytesize.  I have found this to be accurate enough by comparing my upload
            // speed to the speed reported by this method.
            var percentProgress = progress * 100,
              progressSinceLastIteration = progress - this.previousProgress;
            this.$fileRow.find(".uploading-bar").width(percentProgress + "%");
            this.$fileRow
              .find(".meta-holder")
              .text(
                percentProgress.toFixed(0) +
                  "% of " +
                  FileUtils.getFullFileSizeString(this.file.size) +
                  " (" +
                  FileUtils.getReadableFileSize(
                    this.sizeBytes * progressSinceLastIteration
                  ) +
                  "/second)"
              );
            this.previousProgress = progress;
          }
        },
        initiated: function(uploadId) {
          // initiated is called immediately before the uploader starts the upload of a file,
          // the uploadId here is needed for cancelling uploads (cancelling uploads requires an uploadId)
          this.$fileRow
            .find(that.attr.cancelButtonSelector)
            .data("upload-id", uploadId);
          this.$fileRow
            .find(that.attr.fileRowSelector)
            .find("i.gi-pencil")
            .hide();
        }
      });

      if (isNaN(status)) {
        // status contains error string if any, otherwise index of file in array
        this.trigger("uiShowFlashMessage", { message: status });
      }

      $filesList.show();
      this.trigger("uiPageHeightHasChanged");
      this.incrementInProgressFileUploadCount();
      this.updateFileListUI();
    }
    $(ev.target || ev.srcElement).val("");
  };

  this.setupEvaporateUploader = function() {
    var $form = this.getForm();
    if ($form.length == 0) {
      return;
    }
    // Determine the bucket we should use for the upload
    var bucket = FileUtils.determineS3BucketForForm($form);

    this.attr.evaporate = new Evaporate({
      signerUrl: Routes.s3_utility_generate_multipart_signature_path(), //The server endpoint used to generate signatures
      aws_key: FileUtils.determineAWSAccessKeyIdForForm(this.getForm()),
      bucket: bucket,
      fetchCurrentServerTimeUrl: Routes.s3_utility_current_utc_time_string_path(),
      maxFileSize: 20 * 1024 * 1024 * 1024 // 20GB file size limit
    });
  };

  //TODO(anish): simplify code once updatesUI is refactored and this file isn't loaded twice
  this.showUploadSubtitleDrawer = function(ev) {
    ev.preventDefault();
    var $target = $(ev.target),
      $parent = $target.parents(this.attr.fileSubtitleWrapperSelector),
      $subtitleDrawer = $parent.find(this.attr.uploadSubtitleDrawerSelector);
    $target.hide();
    $parent
      .find(this.attr.hideUploadSubtitlesButtonSelector)
      .css("display", "block");
    $target.closest(this.attr.fileRowSelector).addClass("last-in-file-wrapper");
    $subtitleDrawer.show();
    this.trigger("uiPageHeightHasChanged");
  };

  this.hideUploadSubtitleDrawer = function(ev) {
    ev.preventDefault();
    var $target = $(ev.target),
      $parent = $target.parents(this.attr.fileSubtitleWrapperSelector),
      $subtitleDrawer = $parent.find(this.attr.uploadSubtitleDrawerSelector);
    $target.hide();
    $parent
      .find(this.attr.uploadSubtitlesButtonSelector)
      .css("display", "block");
    $target
      .closest(this.attr.fileRowSelector)
      .removeClass("last-in-file-wrapper");
    $subtitleDrawer.hide();
    this.trigger("uiPageHeightHasChanged");
  };

  this.SubtitleRowTemplateWrapper = function(options) {
    options.languages = $.map(SUBTITLE_LANGUAGES, function(language) {
      return {
        language: language,
        selected: language === options.language
      };
    });
    return SubtitleRowTemplate(options);
  };

  this.addSubtitleRow = function(ev) {
    ev.preventDefault();
    var $subtitleDrawer = $(ev.target).closest(
        this.attr.uploadSubtitleDrawerSelector
      ),
      $subtitleList = $subtitleDrawer.find(this.attr.subtitleListSelector),
      $subtitlesFileInput = this.select("subtitlesFileInputSelector");
    $subtitlesFileInput.data("subtitle-list", $subtitleList);
    $subtitlesFileInput.click();
  };

  this.uploadSubtitleFile = function(ev) {
    this.uploadFiles(
      ev,
      $(ev.target).data("subtitle-list"),
      this.SubtitleRowTemplateWrapper
    );
  };

  this.serializeSubtitleFiles = function() {
    var $fileRows = this.select("fileListSelector").children(),
      serializedData = "",
      that = this;
    $fileRows.each(function() {
      var $this = $(this),
        $list = $this.find(that.attr.subtitleListSelector),
        $subtitleRow = $list.find(that.attr.subtitleRowSelector),
        preamble =
          "&link[subtitles][" +
          FileUtils.extractUniqueUrlIdentifier($this.data("file-url")) +
          "]";
      if ($subtitleRow.length > 0) {
        $subtitleRow.each(function() {
          var fileUrl = $(this).data("file-url");
          var uriEncodedFileUrl = fileUrl ? encodeURIComponent(fileUrl) : null;
          if (uriEncodedFileUrl) {
            var uniqueSubtitleIdentifier = FileUtils.extractUniqueUrlIdentifier(
              fileUrl
            );
            serializedData +=
              preamble +
              "[" +
              uniqueSubtitleIdentifier +
              "][language]=" +
              $(this)
                .find(".js-subtitle-language-select")
                .val();
            serializedData +=
              preamble +
              "[" +
              uniqueSubtitleIdentifier +
              "][url]=" +
              uriEncodedFileUrl;
          }
        });
      }
    });

    if (serializedData === "") {
      serializedData = "&link[subtitles]=";
    }

    return serializedData;
  };

  this.subtitleFiles = function() {
    var $fileRows = this.select("fileListSelector").children(),
      subtitleFiles = {},
      that = this;

    $fileRows.each(function() {
      var $this = $(this),
        $list = $this.find(that.attr.subtitleListSelector),
        $subtitleRow = $list.find(that.attr.subtitleRowSelector),
        productFileUrl = $this.data("file-url");
      subtitleFiles[productFileUrl] = {};
      if ($subtitleRow.length > 0) {
        $subtitleRow.each(function() {
          var url = $(this).data("file-url");
          subtitleFiles[productFileUrl][url] = $(this)
            .find(".js-subtitle-language-select")
            .val();
        });
      }
    });
    return subtitleFiles;
  };

  this.removeSurroundingSubtitleRow = function($element) {
    var $subtitleList = $element.closest(this.attr.subtitleListSelector);
    if ($subtitleList.children().length === 1) {
      $subtitleList.hide();
    }
    $element.closest(this.attr.subtitleRowSelector).remove();

    this.trigger("uiPageHeightHasChanged");
  };

  this.cancelSubtitleFile = function(ev) {
    ev.preventDefault();

    var $target = $(ev.target || ev.srcElement),
      uploadId = $target.data("upload-id");
    this.attr.evaporate.cancel(uploadId);
    this.removeSurroundingSubtitleRow($target);

    this.decrementInProgressFileUploadCount();
    return false;
  };

  this.removeSubtitleFile = function(ev) {
    ev.preventDefault();

    this.removeSurroundingSubtitleRow($(ev.target || ev.srcElement));

    return false;
  };

  this.addErrorStateToDropboxButton = function(ev, data) {
    this.select("dropboxUploadTriggerSelector").addClass("error");
  };

  this.removeErrorStateFromDropboxButton = function(ev, data) {
    this.select("dropboxUploadTriggerSelector").removeClass("error");
  };

  this.showUploadChoices = function(ev) {
    ev.preventDefault();

    this.select("chooseFilesTriggerSelector").toggleClass("not-showing");
    this.select("addFilesHolderSelector").toggleClass("showing");

    return false;
  };

  this.toggleExistingFilesChoices = function(ev) {
    ev.preventDefault();
    this.select("chooseExistingFilesTriggerSelector").toggleClass("active");
    this.select("chooseExistingFilesContainerSelector").toggleClass("showing");
    this.select("rotateContainerSelector").toggleClass(
      "showing-existing-files-container"
    );
    this.setupRotateContainerHeightForChooseFromExistingFilesDrawer();
  };

  this.renderChooseFromExistingFilesRow = function(ev, data) {
    var $filesList = this.select("fileListSelector");
    $filesList.show();
    if (
      this.existingFileRowWithChooseFromExistingFileIdAndUrl(data.id, data.url)
    ) {
      return;
    }
    var $chooseExistingFileRow = $(
      ExistingFileRowTemplate({
        name: data.fileName,
        isStreamable: typeof data.isStreamable !== "undefined",
        isTranscodingInProgress:
          typeof data.isTranscodingInProgress !== "undefined",
        fileInfo: data.fileSize
          ? FileUtils.getFullFileSizeString(data.fileSize)
          : "",
        extension: data.extension,
        id: data.id,
        url: data.url
      })
    );
    $filesList.append($chooseExistingFileRow);
    this.trigger("uiFileListHasChanged");
    this.updateFileListUI();
    this.trigger("uiPageHeightHasChanged");
  };

  this.removeChooseFromExistingFilesRow = function(ev, data) {
    var $existingFileRow = this.existingFileRowWithChooseFromExistingFileIdAndUrl(
      data.id,
      data.url
    );
    $existingFileRow.remove();
    this.removeFileListIfEmpty();
    this.trigger("uiPageHeightHasChanged");
  };

  this.existingFileRowWithChooseFromExistingFileIdAndUrl = function(
    fileExternalId,
    fileUrl
  ) {
    var $filesList = this.select("fileListSelector"),
      $previouslyAddedFile = null;
    $filesList.children().each(function() {
      if (
        $(this).data("existing-file-id") === fileExternalId ||
        $(this).data("existing-file-url") === fileUrl
      ) {
        $previouslyAddedFile = $(this);
        return false;
      }
    });
    return $previouslyAddedFile;
  };

  this.selectExistingFile = function(ev) {
    ev.preventDefault();
    var $fileSelectRow = $(ev.target || ev.srcElement);
    if (!$fileSelectRow.hasClass("js-select-file-trigger")) {
      $fileSelectRow = $fileSelectRow.parents(
        this.attr.selectFileTriggerSelector
      );
    }
    $fileSelectRow.toggleClass("showing");
    $fileSelectRow
      .find(this.attr.fileSelectTriggerSelector)
      .toggleClass("showing");
    $fileSelectRow
      .find(this.attr.selectedTriggerSelector)
      .toggleClass("not-showing");
    if ($fileSelectRow.hasClass("showing")) {
      this.trigger(
        "uiNeedsToDisplayFileRowForExistingFile",
        $fileSelectRow.data()
      );
    } else {
      this.trigger(
        "uiNeedsToRemoveFileRowForExistingFile",
        $fileSelectRow.data()
      );
    }

    return false;
  };

  this.hideSelectFromExistingFileDrawer = function(ev, data) {
    this.select("chooseExistingFilesTriggerSelector").removeClass("active");
    this.select("chooseExistingFilesContainerSelector").removeClass("showing");
    this.select("rotateContainerSelector").removeClass(
      "showing-existing-files-container"
    );
    this.setupRotateContainerHeightForChooseFromExistingFilesDrawer();
    this.trigger("uiPageHeightHasChanged");
  };

  this.deselectChooseFromExistingFileRow = function(ev, data) {
    var existingFileExternalId = data.chooseFromExistingFileExternalId;
    var $selectedFileRow = this.$node.find(
      this.attr.selectFileTriggerSelector +
        "[data-id='" +
        existingFileExternalId +
        "']"
    );
    $selectedFileRow.toggleClass("showing");
    $selectedFileRow
      .find(this.attr.fileSelectTriggerSelector)
      .toggleClass("showing");
    $selectedFileRow
      .find(this.attr.selectedTriggerSelector)
      .toggleClass("not-showing");
  };

  this.filterChooseFromExistingFilesList = function(ev, data) {
    var $chooseFromExistingFiles = this.select("findYourFilesInputSelector");
    if (
      !this.select("findYourFilesInputSelector").data(
        "original-file-list-height"
      )
    ) {
      this.select("findYourFilesInputSelector").data(
        "original-file-list-height",
        this.select("rotateContainerSelector").outerHeight()
      );
    }
    this.filterExistingFileRowsBySearchTerm($chooseFromExistingFiles.val());
  };

  this.setupRotateContainerHeightForChooseFromExistingFilesDrawer = function() {
    var newHeight = 85; // This is the base height of the container

    if (this.select("chooseExistingFilesTriggerSelector").hasClass("active")) {
      var $visibleFiles = this.select("selectFileTriggerSelector").filter(
          ":visible"
        ),
        // This is the base height of the choose from existing files form
        newHeight = 180;
      if ($visibleFiles.length) {
        newHeight += $visibleFiles.length * $visibleFiles.first().outerHeight();
        // Let the user scroll if more than 500px tall
        if (newHeight > 500) {
          newHeight = 500;
        }
      }
    }
    this.select("rotateContainerSelector").css({ height: newHeight });
    this.trigger("uiPageHeightHasChanged");
  };

  this.filterExistingFileRowsBySearchTerm = function(searchTerm) {
    var $chooseFromExistingFileList = this.select("selectFileTriggerSelector");
    if (searchTerm.length > 0) {
      var fileNameRegex = new RegExp(searchTerm, "i");
      $chooseFromExistingFileList.each(function() {
        var $chooseFromExistingFileRow = $(this),
          fileName = $chooseFromExistingFileRow.data("file-name");
        if (fileNameRegex.test(fileName)) {
          $chooseFromExistingFileRow.show();
        } else {
          $chooseFromExistingFileRow.hide();
        }
      });
    } else {
      $chooseFromExistingFileList.each(function() {
        $(this).show();
      });
    }
    this.setupRotateContainerHeightForChooseFromExistingFilesDrawer();
  };

  this.hideRenameOptionsIfOnDashboardPage = function() {
    if (this.$node.is(this.attr.inlineLinkAddFormSelector)) {
      this.select("fileNameHolderSelector").each(function() {
        var $fileNameHolder = $(this);
        $fileNameHolder.find(".gi-pencil").remove();
      });
    }
  };

  this.backgroundDropboxFilesFetch = function() {
    var $form = this.getForm();
    var dropboxFilesFetchParams = {};
    if ($form.data("id")) {
      dropboxFilesFetchParams = { link_id: $form.data("id") };
    }
    this.trigger("uiNeedsToFetchDropboxFiles", dropboxFilesFetchParams);
    var that = this;
    setInterval(function() {
      that.trigger("uiNeedsToFetchDropboxFiles", dropboxFilesFetchParams);
    }, 10000);
  };

  this.makeFilesSortable = function() {
    var fileList = this.select("fileListSelector")[0];
    if (fileList != null) {
      new Sortable(fileList, {
        handle: this.attr.reorderHandleSelector,
        ghostClass: "sortable-ghost"
      });
    }
  };

  this.after("initialize", function() {
    if (!this.getForm().is("form") && !this.attr.formSelector) {
      throw "File upload mixin has to be attached to form or formSelector must be given.";
    }

    if (this.attr.isFileReorderAllowed) {
      this.makeFilesSortable();
    }

    this.on("click", {
      cancelButtonSelector: this.cancelFileUpload,
      removeButtonSelector: this.removeFile,
      uploadSubtitlesButtonSelector: this.showUploadSubtitleDrawer,
      hideUploadSubtitlesButtonSelector: this.hideUploadSubtitleDrawer,
      subtitleUploaderSelector: this.addSubtitleRow,
      removeSubtitleButtonSelector: this.removeSubtitleFile,
      cancelSubtitleButtonSelector: this.cancelSubtitleFile,
      chooseFilesTriggerSelector: this.showUploadChoices,
      chooseExistingFilesTriggerSelector: this.toggleExistingFilesChoices,
      selectFileTriggerSelector: this.selectExistingFile
    });

    this.on("change", {
      productFileInputSelector: this.beginUpload,
      subtitlesFileInputSelector: this.uploadSubtitleFile
    });

    this.on("keyup", {
      findYourFilesInputSelector: this.filterChooseFromExistingFilesList
    });

    this.setupEvaporateUploader();

    this.on(
      document,
      "uiNeedsToDisplayNewDropboxFile",
      this.renderNewDropboxFileRow
    );
    this.on(
      document,
      "dataDropboxFilesFetchWasSuccessful",
      this.renderExistingDropboxFileUploads
    );
    this.on(
      document,
      "uiNeedsToDisplayFileRowForExistingFile",
      this.renderChooseFromExistingFilesRow
    );
    this.on(
      document,
      "uiNeedsToRemoveFileRowForExistingFile",
      this.removeChooseFromExistingFilesRow
    );
    this.on(
      document,
      "uiNeedsToUnselectChooseFromExistingFileRow",
      this.deselectChooseFromExistingFileRow
    );
    this.on(
      document,
      "uiNeedsToHideChooseFromExistingFilesDrawer",
      this.hideSelectFromExistingFileDrawer
    );
    this.on(
      document,
      "uiNeedsToSetupBackgroundDropboxFilesFetch",
      this.backgroundDropboxFilesFetch
    );

    if (this.select("dropboxUploadTriggerSelector").length > 0) {
      this.backgroundDropboxFilesFetch();
    }
  });
}
module.exports = MultiFileUpload;
