"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ConfirmEmailAddress);

  function ConfirmEmailAddress() {
    this.defaultAttrs({
      loadingTextField: "loading",
      defaultTextField: "default"
    });

    this.setLoadingText = function() {
      var $this = this.$node;

      $this.data(this.attr.defaultTextField, $this.text());
      $this.text($this.data(this.attr.loadingTextField));
    };

    this.setDefaultText = function() {
      this.$node.text(this.$node.data(this.attr.defaultTextField));
    };

    this.resendConfirmationEmail = function() {
      this.setLoadingText();
      this.trigger("uiNeedsToResendConfirmationEmailWithMegaphone");
    };

    this.finishSendingConfirmationEmail = function(ev, data) {
      this.setDefaultText();
      if (data.success) {
        this.trigger("uiShowFlashMessage", {
          message: I18n.t("js.resend_confirmation_done")
        });
      } else {
        this.trigger("uiShowFlashMessage", {
          message: I18n.t("js.resend_confirmation_failure")
        });
      }
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiResendConfirmationEmailWithMegaphone",
        this.resendConfirmationEmail
      );
      this.on(
        document,
        "dataResentConfirmationEmailWithMegaphone",
        this.finishSendingConfirmationEmail
      );
    });
  }
});
