"use strict";

define(["flight/lib/component", "$app/utils/with_social_connect"], function(
  defineComponent,
  WithSocialConnect
) {
  return defineComponent(ConnectSocialAccount, WithSocialConnect);

  function ConnectSocialAccount() {
    this.authWithFacebook = function() {
      var that = this;
      var connectOptions = {
        success: function() {
          that.trigger(document, "dataCompletedMegaphoneTask", {
            megaphone_task: "connect_social_account"
          });
        },
        failure: function() {
          that.trigger("uiShowFlashMessage", {
            message: I18n.t("js.something_went_wrong")
          });
        },
        facebookOptions: { scope: "email,manage_pages" }
      };

      this.connectFacebook(connectOptions);
    };

    this.authWithTwitter = function() {
      var that = this;
      var connectOptions = {
        success: function() {
          that.trigger(document, "dataCompletedMegaphoneTask", {
            megaphone_task: "connect_social_account"
          });
        },
        failure: function() {
          that.trigger("uiShowFlashMessage", {
            message: I18n.t("js.something_went_wrong")
          });
        }
      };

      this.connectTwitter(connectOptions);
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToConnectFacebookWithMegaphone",
        this.authWithFacebook
      );
      this.on(
        document,
        "uiNeedsToConnectTwitterWithMegaphone",
        this.authWithTwitter
      );
    });
  }
});
