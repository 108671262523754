"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Tooltip);

  function Tooltip() {
    this.defaultAttrs({
      showTooltipEvent: "uiShowTooltip",
      hideTooltipEvent: "uiHideTooltip",
      fadeoutTooltipEvent: "uiFadeoutTooltipEvent"
    });

    this.showTooltip = function(ev, data) {
      if (typeof data != "undefined") {
        this.$node.html(data.content + "<span>" + data.metaInfo + "</span>");
        this.$node.addClass("showing").css({
          top: data.top - this.$node.outerHeight(),
          left: data.left - this.$node.outerWidth() / 2
        });
      }
    };

    this.hideTooltip = function() {
      this.$node.removeClass("showing");
    };

    this.fadeoutTooltip = function() {
      setTimeout(
        function() {
          this.$node.removeClass("showing");
        }.bind(this),
        300
      );
    };

    this.after("initialize", function() {
      this.on(document, this.attr.showTooltipEvent, this.showTooltip);
      this.on(document, this.attr.hideTooltipEvent, this.hideTooltip);
      this.on(document, this.attr.fadeoutTooltipEvent, this.fadeoutTooltip);
    });
  }
});
