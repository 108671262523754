"use strict";

define([], function() {
  return WithRating;

  function WithRating() {
    this.defaultAttrs({
      averageRatingContainerSelector: ".js-average-rating-container",
      averageRatingStarSelector: ".js-average-rating-star",
      reviewsCountSelector: ".js-reviews-count",
      reviewsBoxSelector: ".js-ratings-box",
      ratingPercentContainer: ".js-rating-percent-container",
      ratingPercent: ".js-rating-percent",
      ratingPercentBar: ".js-rating-percent-bar",
      fullStarClass: "full-star",
      halfStarClass: "half-star",
      emptyStarClass: "empty-star",
      maxReviewCountShownWithText: 9
    });

    this.displayReviewsSection = function() {
      var averageRating = this.attr.user.product.average_rating;
      var reviewsCount = this.attr.user.product.reviews_count;
      var reviewsCountDelimited = this.attr.user.product
        .reviews_count_delimited;
      var fullStarClass = this.attr.fullStarClass;
      var halfStarClass = this.attr.halfStarClass;
      var emptyStarClass = this.attr.emptyStarClass;
      var starClasses = [fullStarClass, halfStarClass, emptyStarClass];

      if (averageRating && reviewsCount) {
        this.select("averageRatingStarSelector").each(function(
          _index,
          element
        ) {
          $(element).removeClass(starClasses.join(" "));
          var rating = $(element).data("value");
          if (
            rating <= averageRating ||
            (Math.ceil(averageRating) === rating && averageRating % 1 > 0.5)
          ) {
            $(element).addClass(fullStarClass);
          } else if (
            rating === Math.ceil(averageRating) &&
            averageRating % 1 === 0.5
          ) {
            $(element).addClass(halfStarClass);
          } else {
            $(element).addClass(emptyStarClass);
          }
        });
        if (reviewsCount > this.attr.maxReviewCountShownWithText) {
          this.select("reviewsCountSelector").text(reviewsCountDelimited);
        } else {
          this.select("reviewsCountSelector").text(
            I18n.t("ratings_count", { count: reviewsCount })
          );
        }
        this.displayRatingsHistogram();
        this.select("averageRatingContainerSelector").show();
      }
    };

    this.displayRatingsHistogram = function() {
      if (typeof this.attr.user.product.rating_distribution === "undefined") {
        return;
      }

      const {
        percentages: ratingPercentDistribution
      } = this.attr.user.product.rating_distribution;
      this.select("ratingPercentContainer").each(
        function(_index, element) {
          const $element = $(element);
          const rating = $element.data("value");
          if (typeof ratingPercentDistribution[rating] === "undefined") {
            return;
          }

          $element.find(this.attr.ratingPercent).text(
            I18n.t("rating_percent", {
              percent: ratingPercentDistribution[rating]
            })
          );
          $element
            .find(this.attr.ratingPercentBar)
            .css("width", ratingPercentDistribution[rating] + "%");
        }.bind(this)
      );
    };

    this.toggleHistogram = function() {
      this.select("reviewsBoxSelector").toggleClass("showing-histogram");
    };

    this.after("initialize", function() {
      this.on("click", {
        averageRatingContainerSelector: this.toggleHistogram
      });
    });
  }
});
