"use strict";

define([], function() {
  return WithQuantity;

  function WithQuantity() {
    this.defaultAttrs({
      decreaseQuantitySelector: ".js-decrease-quantity-trigger",
      increaseQuantitySelector: ".js-increase-quantity-trigger",
      quantityIndicatorSelector: ".js-quantity-indicator",
      quantitySelector: ".js-quantity-selector",
      quantityInputSelector: ".js-quantity",
      quantity: 1
    });

    this.decreaseQuantity = function(ev) {
      ev.preventDefault();

      if (this.attr.quantity > 1) {
        this.attr.quantity--;
      }

      this.updateQuantityUI();
      return false;
    };

    this.increaseQuantity = function(ev) {
      ev.preventDefault();

      if (this.allowIncreasingOfQuantity()) {
        this.attr.quantity++;
      }

      this.updateQuantityUI();
      return false;
    };

    this.validateProductQuantity = function() {
      return (
        !this.attr.user.product.remaining ||
        this.attr.quantity <= this.attr.user.product.remaining
      );
    };

    this.validateVariantQuantity = function() {
      if (!this.select("variantOptionSelector").length) {
        return true;
      } else {
        var variantQuantityLeft = this.select("variantOptionSelector")
          .filter(".selected")
          .data("quantity-left");
        return (
          !variantQuantityLeft || this.attr.quantity <= variantQuantityLeft
        );
      }
    };

    this.allowIncreasingOfQuantity = function() {
      var variantQuantityLeft = this.select("variantOptionSelector")
        .filter(".selected")
        .data("quantity-left");

      if (
        this.attr.user.product.remaining &&
        this.attr.quantity == this.attr.user.product.remaining
      ) {
        return false;
      } else if (
        variantQuantityLeft &&
        this.attr.quantity == variantQuantityLeft
      ) {
        return false;
      }

      return true;
    };

    this.validateQuantity = function() {
      if (this.select("quantitySelector").length == 0) {
        return true;
      }

      if (!this.validateProductQuantity() || !this.validateVariantQuantity()) {
        this.select("quantitySelector").addClass("error");
        this.select("quantityIndicatorSelector").addClass("error");
        this.trigger("uiShowFlashMessage", {
          message: I18n.t("js.exceeding_quantity")
        });
        return false;
      }

      return true;
    };

    this.updateQuantityUI = function() {
      this.resetQuantityButtonsAndLabels();

      this.select("quantityInputSelector").val(this.attr.quantity);
      this.select("quantityIndicatorSelector").text(this.attr.quantity);

      if (this.attr.quantity == 1) {
        this.select("decreaseQuantitySelector").addClass("disabled");
      }
      if (!this.allowIncreasingOfQuantity()) {
        this.select("increaseQuantitySelector").addClass("disabled");
      }

      this.setPrice();
    };

    this.resetQuantityUI = function() {
      this.attr.quantity = 1;
      this.updateQuantityUI();
    };

    this.resetQuantityButtonsAndLabels = function() {
      this.select("quantitySelector").removeClass("error");
      this.select("quantityIndicatorSelector").removeClass("error");
      this.select("decreaseQuantitySelector").removeClass("disabled");
      this.select("increaseQuantitySelector").removeClass("disabled");
    };

    this.setInitialQuantity = function() {
      if (this.select("quantitySelector").length === 0) {
        return;
      }

      if (this.attr.onOverlayPage && this.attr.overlayQuantity) {
        this.attr.quantity = this.attr.overlayQuantity;
      } else {
        const urlParams = this.getUrlParams();
        if (urlParams.quantity) {
          this.attr.quantity = urlParams.quantity;
        }
      }

      if ($.isNumeric(this.attr.quantity) && this.attr.quantity % 1 === 0) {
        this.updateQuantityUI();
        this.updateInitialPrice();
      }
    };

    this.after("initialize", function() {
      this.on(document, "uiShouldResetQuantityUI", this.resetQuantityUI);
      this.on("click", {
        decreaseQuantitySelector: this.decreaseQuantity,
        increaseQuantitySelector: this.increaseQuantity
      });

      this.setInitialQuantity();
    });
  }
});
