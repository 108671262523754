"use strict";

define(["handlebars"], function(Handlebars) {
  function BreaklinesHelper(text) {
    text = Handlebars.Utils.escapeExpression(text);
    text = text.replace(/(\r\n|\n|\r)/gm, "<br>");
    return new Handlebars.SafeString(text);
  }
  Handlebars.registerHelper("breaklines", BreaklinesHelper);
  return BreaklinesHelper;
});
