var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"filter-filetype "
    + alias4(((helper = (helper = lookupProperty(helpers,"filetypesSelectorClass") || (depth0 != null ? lookupProperty(depth0,"filetypesSelectorClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filetypesSelectorClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":56}}}) : helper)))
    + "\" data-filetype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":73},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n  <input class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"filetypesInputSelectorClass") || (depth0 != null ? lookupProperty(depth0,"filetypesInputSelectorClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filetypesInputSelectorClass","hash":{},"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":49}}}) : helper)))
    + "\" type=\"checkbox\" id=\"filetype_"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":80},"end":{"line":2,"column":90}}}) : helper)))
    + "\" data-filetype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":107},"end":{"line":2,"column":117}}}) : helper)))
    + "\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"isFiletypeCheckedAttr") || (depth0 != null ? lookupProperty(depth0,"isFiletypeCheckedAttr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"isFiletypeCheckedAttr","hash":{},"data":data,"loc":{"start":{"line":2,"column":119},"end":{"line":2,"column":146}}}) : helper)))
    + ">\n  <label class=\"for-checkbox\" for=\"filetype_"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":44},"end":{"line":3,"column":54}}}) : helper)))
    + "\">\n    ."
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":5},"end":{"line":4,"column":15}}}) : helper)))
    + "\n    <span class=\"count\">\n      "
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":17}}}) : helper)))
    + "\n    </span>\n  </label>\n</div>\n";
},"useData":true});