"use strict";

define([
  "flight/lib/compose",
  "$app/templates/bundled_product_list_entry",
  "$app/utils/with_sha"
], function(compose, BundledProductListEntry, WithSha) {
  return WithBundledProductList;

  function WithBundledProductList() {
    compose.mixin(this, [WithSha]);

    this.defaultAttrs({
      bundledProductListSelector: ".js-bundled-product-list",
      bundledProductListEntrySelector: ".js-bundled-product-list li",
      bundledProductListCloseSelector: ".js-bundled-product-list .button-close"
    });

    this.appendBundledProductListEntry = function(ev, data) {
      delete data.sha;
      data.sha = this.stringToSha(JSON.stringify(data));
      this.select("bundledProductListSelector").append(
        BundledProductListEntry(data)
      );
      this.trigger("uiNeedsToAddProductToPersistentBundle", data);
    };

    this.removeSelectedProductListEntry = function(ev) {
      var $entry = $(ev.target).closest("li");
      this.removeBundledProductListEntry($entry);
      this.trigger("uiNeedsToRemoveProductFromPersistentBundle", {
        sha: $entry.data("sha")
      });
    };

    this.removeBundledProductListEntry = function($entry) {
      if ($entry.length > 0) {
        this.trigger("removeProductFromBundle", { index: $entry.index() });
        $entry.remove();
      }
    };

    this.resetBundledProductList = function() {
      this.select("bundledProductListSelector").empty();
      this.trigger("uiNeedsToPopulateBundledListFromActiveList");
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiAddProductToBundledList",
        this.appendBundledProductListEntry
      );
      this.on(
        document,
        "uiNeedsToResetBundledList",
        this.resetBundledProductList
      );
      this.on("click", {
        bundledProductListCloseSelector: this.removeSelectedProductListEntry
      });
    });
  }
});
