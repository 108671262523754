"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Subscription);

  function Subscription() {
    this.cancelSubscriptionBySeller = function(ev, sale) {
      $.ajax({
        url: Routes.unsubscribe_by_seller_subscription_path(
          sale.subscription_id
        ),
        type: "POST",
        dataType: "json"
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataSubscriptionCancelledBySeller", { id: sale.id });
          } else {
            this.trigger("dataSubscriptionCancelBySellerFailed");
          }
        }.bind(this)
      );
    };

    this.cancelSubscriptionByUser = function(ev, data) {
      $.ajax({
        url: Routes.unsubscribe_by_user_subscription_path(data.id),
        type: "POST",
        dataType: "json"
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("dataCancelledSubscription");
          } else {
            this.trigger("dataCancelSubscriptionFailed");
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsToCancelSubscriptionBySeller",
        this.cancelSubscriptionBySeller
      );
      this.on(
        document,
        "uiNeedsToCancelSubscription",
        this.cancelSubscriptionByUser
      );
    });
  }
});
