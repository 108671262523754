"use strict";

/**
 * A component for authentication modal.
 *
 * Expecting event data component on the document for user behavior tracking.
 */
define([
  "flight/lib/component",
  "$app/ui/shared/with_form_base",
  "$app/ui/shared/with_facebook_pixel",
  "$app/utils/recaptcha_utils"
], function(defineComponent, WithFormBase, WithFacebookPixel, RecaptchaUtils) {
  return defineComponent(WithFormBase, WithFacebookPixel, AuthModal);

  function AuthModal() {
    this.defaultAttrs({
      authModalSelector: "#hp-auth-modal",
      loginModalSelector: "#hp-login",
      signupModalSelector: "#hp-signup",
      forgotPasswordFormSelector: "#forgot-password-form",
      switchToSignupSelector: "#hp-login .switch-to a",
      switchToLoginSelector: "#hp-signup .switch-to a",
      signupEmailSelector: "#hp-signup [name='user[email]']",
      loginEmailSelector: "#hp-login #user_login",
      forgotPasswordEmailSelector: "#forgot-password-form .email",
      forgotPasswordSelector:
        "#hp-auth-modal .js-show-forgot-password-form-trigger",
      closeModalButtonSelector: "#hp-auth-modal .js-close-modal-trigger",
      cancelForgotPasswordButtonSelector:
        "#forgot-password-form .js-hide-forgot-password-form-trigger",
      loginButtonSelector: ".js-login-button",
      signupButtonSelector: ".js-signup-button",
      forgotPasswordButtonSelector: ".js-forgot-password-button",
      errorIndicatorSelector: ".error-indicator",
      socialAuthButtonSelector: ".social-auth .button",
      nextSelector: "#next",
      modalShowing: false
    });

    this.showLoginModal = function(ev, data) {
      var nextUrl = this.extractNextUrlFromHashLocation();

      if (data.setNext && nextUrl) {
        this.select("nextSelector").val(nextUrl);
        this.select("socialAuthButtonSelector").each(function() {
          if (!this.href.match(/\?referer/)) {
            this.href += "?referer=" + nextUrl;
          }
          this.href += "&origin=" + nextUrl;
        });
      }
      this.showModal(false);
    };

    this.extractNextUrlFromHashLocation = function() {
      if (window.location.hash.match(/next/)) {
        return window.location.hash.split("?")[1].split("=")[1];
      } else {
        return null;
      }
    };

    this.showSignupModal = function(ev, data) {
      this.showModal(true);
    };

    this.showModal = function(showSignup) {
      if (this.attr.modalShowing) return;

      //signup default true
      var showSignup = typeof showSignup == "undefined" ? true : showSignup,
        $authModal = this.select("authModalSelector"),
        $loginModal = this.select("loginModalSelector"),
        $signupModal = this.select("signupModalSelector");

      $loginModal.hide();
      $signupModal.hide();

      if (showSignup) {
        $signupModal.show();
      } else {
        $loginModal.show();
        this.select("loginEmailSelector").focus();
      }

      $authModal.removeClass("cancelled-element");
      this.$node.addClass("showing");
      $authModal.addClass("showing");

      this.attr.modalShowing = true;
    };

    this.hideModal = function(ev) {
      if (this.attr.modalShowing) {
        var $authModal = this.select("authModalSelector");

        $authModal.addClass("cancelled-element");
        this.$node.removeClass("showing");
        this.attr.modalShowing = false;
      }

      ev && ev.preventDefault();
      return false;
    };

    this.switchToSignup = function(ev) {
      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: "modal_switch_to_signup"
      });
      this.select("loginModalSelector").hide();
      this.select("signupEmailSelector").val(
        this.select("loginEmailSelector").val()
      );
      this.select("signupModalSelector").show();

      ev.preventDefault();
      return false;
    };

    this.switchToLogin = function(ev) {
      var $loginEmail = this.select("loginEmailSelector");

      this.trigger("uiNeedsToTrackUserActionEvent", {
        name: "modal_switch_to_login"
      });
      $loginEmail.val(this.select("signupEmailSelector").val());
      this.select("loginModalSelector").show();
      $loginEmail.focus();
      this.select("signupModalSelector").hide();

      ev.preventDefault();
      return false;
    };

    this.showForgotPasswordForm = function(ev) {
      this.select("loginModalSelector").addClass("faded");
      this.select("forgotPasswordEmailSelector")
        .val(this.select("loginEmailSelector").val())
        .focus();
      this.select("forgotPasswordFormSelector").show();

      ev.preventDefault();
      return false;
    };

    this.hideForgotPasswordForm = function(ev) {
      this.select("loginModalSelector").removeClass("faded");
      this.select("forgotPasswordFormSelector").hide();

      ev.preventDefault();
      return false;
    };

    this.hideModalIfEscapeIsPressed = function(ev) {
      if (ev.keyCode == 27) {
        this.hideModal();
      }
    };

    this.hideModalIfClickedOutsideModal = function(ev) {
      if (this.select("authModalSelector").has(ev.target).length == 0) {
        this.hideModal();
      }
    };

    this.login = function(ev) {
      this.submitForm(
        "loginButtonSelector",
        I18n.t("js.logging_in"),
        "uiNeedsToLogin"
      );

      ev.preventDefault();
      return false;
    };

    this.signup = function(ev) {
      this.submitForm(
        "signupButtonSelector",
        I18n.t("js.creating"),
        "uiNeedsToSignup"
      );

      ev.preventDefault();
      return false;
    };

    this.renewPassword = function(ev) {
      this.submitForm(
        "forgotPasswordButtonSelector",
        I18n.t("js.sending"),
        "uiNeedsToRenewPassword"
      );

      ev.preventDefault();
      return false;
    };

    this.submitForm = function(buttonSelector, loadingText, dataEvent) {
      var $button = this.select(buttonSelector),
        $form = $button.closest("form");

      if (this.validateForm($form)) {
        $form.find(this.attr.errorIndicatorSelector).hide();
        $button.data("default", $button.text());
        $button.text(loadingText);
        this.trigger(dataEvent, { serializedForm: $form.serialize() });
      }
    };

    this.getSubmitFormSuccessHandler = function(type) {
      var buttonSelector = type + "ButtonSelector";

      return function(ev, data) {
        var $button = this.select(buttonSelector);

        if (data.redirect_location) {
          window.location = data.redirect_location;
        }

        if (data.success_message) {
          this.trigger("uiShowFlashMessage", { message: data.success_message });
          $button.text($button.data("default"));
        }
      };
    };

    this.getSubmitFormErrorHandler = function(type) {
      var buttonSelector = type + "ButtonSelector";

      return function(ev, data) {
        var $button = this.select(buttonSelector),
          $errorIndicator = $button
            .closest("form")
            .find(this.attr.errorIndicatorSelector);

        RecaptchaUtils.resetRecaptcha(type);

        $errorIndicator.text(data.error_message).show();
        $button.text($button.data("default"));
      }.bind(this);
    };

    this.autoShowAuthModal = function(id) {
      if (id == "/#login" || id == "#login") {
        this.trigger("uiShowLoginModal", { setNext: true });
      } else if (id == "/#signup" || id == "#signup") {
        this.trigger("uiShowSignupModal");
      }
    };

    this.handleSignupSuccess = function(ev, data) {
      this.gumroadFbPixelTrack("CompleteRegistration");

      const submitSuccessHandler = this.getSubmitFormSuccessHandler(
        "signup"
      ).bind(this);
      submitSuccessHandler(ev, data);
    };

    this.after("initialize", function() {
      this.on(document, "uiShowLoginModal", this.showLoginModal);
      this.on(document, "uiShowSignupModal", this.showSignupModal);
      this.on(
        document,
        "dataLoginSucceeded",
        this.getSubmitFormSuccessHandler("login")
      );
      this.on(document, "dataSignupSucceeded", this.handleSignupSuccess);
      this.on(
        document,
        "dataRenewPasswordSucceeded",
        this.getSubmitFormSuccessHandler("forgotPassword")
      );
      this.on(
        document,
        "dataFailedToLogin",
        this.getSubmitFormErrorHandler("login")
      );
      this.on(
        document,
        "dataFailedToSignup",
        this.getSubmitFormErrorHandler("signup")
      );
      this.on(
        document,
        "dataFailedToRenewPassword",
        this.getSubmitFormErrorHandler("forgotPassword")
      );
      this.on("click", {
        switchToSignupSelector: this.switchToSignup,
        switchToLoginSelector: this.switchToLogin,
        forgotPasswordSelector: this.showForgotPasswordForm,
        cancelForgotPasswordButtonSelector: this.hideForgotPasswordForm,
        closeModalButtonSelector: this.hideModal,
        loginButtonSelector: this.login,
        signupButtonSelector: this.signup,
        forgotPasswordButtonSelector: this.renewPassword
      });
      this.on(document, "keydown", this.hideModalIfEscapeIsPressed);
      this.on(document, "mouseup", this.hideModalIfClickedOutsideModal);
      this.on(document, "touchend", this.hideModalIfClickedOutsideModal);

      if (window.location.hash.replace("#", "")) {
        this.autoShowAuthModal(window.location.hash.split("?")[0]);
      }
    });
  }
});
