"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Account);

  function Account() {
    this.createAccount = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.signup_path({ format: "json" }),
        dataType: "json",
        data: data.serializedForm
      }).done(
        function(response) {
          response.permalink = data.permalink;
          if (response.success) {
            this.trigger("dataAccount", response);
          } else {
            this.trigger("dataFailedToCreateAccount", response);
          }
        }.bind(this)
      );
    };

    this.addPurchaseToLibrary = function(ev, data) {
      $.ajax({
        type: "POST",
        url: Routes.add_purchase_to_library_path({ format: "json" }),
        dataType: "json",
        data: data.serializedForm
      }).done(
        function(response) {
          response.permalink = data.permalink;
          if (response.success) {
            this.trigger("dataPurchaseAddedToLibrary", response);
          } else {
            this.trigger("dataFailedToAddPurchaseToLibrary", response);
          }
        }.bind(this)
      );
    };

    this.loginWithFacebook = function() {
      FB.login(
        function(response) {
          if (response.authResponse) {
            var data = { accessToken: response.authResponse.accessToken };

            this.trigger("dataFacebookLoginVerifying");

            $.ajax({
              url: Routes.ajax_facebook_path(),
              type: "POST",
              dataType: "json",
              data: data
            }).done(
              function(response) {
                if (response.success) {
                  this.trigger("dataFackbookLoginSucceeded");
                } else {
                  this.trigger("dataFackbookLoginFailed");
                }
              }.bind(this)
            );
          }
        }.bind(this),
        { scope: "email,manage_pages" }
      );
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsToCreateAccount", this.createAccount);
      this.on(
        document,
        "uiNeedsToAddPurchaseToLibrary",
        this.addPurchaseToLibrary
      );
      this.on(document, "uiNeedsToLoginWithFacebook", this.loginWithFacebook);
    });
  }
});
