"use strict";

/**
 * A base form component that can be mixed into the most of forms.
 * This contains common functionalies:
 *   - show / hide errors.
 *   - common validation logic.
 */

define([], function() {
  return WithFormBase;

  function WithFormBase() {
    this.defaultAttrs({
      inputSelector: "input",
      textareaSelector: "textarea",
      selectSelector: "select",
      radioSelector: "input[type='radio']",
      requiredInputSelector: "input.required",
      requiredSelectSelector: "select.required",
      numericalInputSelector: "input.numerical",
      emailInputSelector: "input.email",
      minLengthInputSelector: "input[minlength]",
      emailValidationRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    });

    this.validateRequiredInput = function($input) {
      if ($input.length == 0 || !$input.is(":visible")) return true;

      if ($input.val().length == 0) {
        if ($input.hasClass("proxy") && $input.data("proxy")) {
          $input = $("." + $input.data("proxy"));
        }

        this.showError($input);
        $input.focus();

        return false;
      }

      return true;
    };

    this.validateRadioInput = function($radio) {
      if (
        $radio.parent().is(":visible") &&
        $radio.filter(":checked").val() == null
      ) {
        this.showError($radio);
        $radio.focus();
        return false;
      }
      return true;
    };

    this.validateSelectInput = function($select) {
      if ($select.is(":visible") && $select.val() == null) {
        this.showError($select);
        $select.focus();
        return false;
      }
      return true;
    };

    this.validateNumericalInput = function($input) {
      if (
        $input.length == 0 ||
        !$input.is(":visible") ||
        $input.val().length == 0
      )
        return true;

      if (isNaN(parseFloat($input.val()))) {
        this.showError($input);
        $input.focus();
        return false;
      }

      return true;
    };

    this.validateMinLengthInput = function($input) {
      if (
        $input.length == 0 ||
        !$input.is(":visible") ||
        $input.val().length == 0
      )
        return true;

      var minLength = parseInt($input.attr("minlength"));
      var value = $input.val();
      if (value.length > 0 && value.length < minLength) {
        this.showError($input);
        $input.focus();
        return false;
      }

      return true;
    };

    this.validateEmails = function($emails) {
      var isValid = true,
        self = this;

      $emails.each(function() {
        var $email = $(this);
        if (
          $email.is(":visible") &&
          ($email.hasClass("required") || $email.val() != "")
        ) {
          if (!self.attr.emailValidationRegex.test($email.val())) {
            self.showError($email);
            $email.focus();
            isValid = false;
            return isValid;
          }
        }
      });

      return isValid;
    };

    this.validateForm = function($form) {
      var isValid = true,
        self = this;

      $form.find(this.attr.numericalInputSelector).each(function() {
        return self.validateNumericalInput($(this));
      });

      if (!isValid) {
        return isValid;
      }

      $form.find(this.attr.requiredInputSelector).each(function() {
        isValid = isValid && self.validateRequiredInput($(this));
      });

      if (!isValid) {
        return isValid;
      }

      $form.find(this.attr.requiredSelectSelector).each(function() {
        isValid = isValid && self.validateSelectInput($(this));
      });

      if (!isValid) {
        return isValid;
      }

      $form.find(this.attr.minLengthInputSelector).each(function() {
        isValid = isValid && self.validateMinLengthInput($(this));
      });

      isValid =
        isValid &&
        this.validateEmails($form.find(this.attr.emailInputSelector));

      return isValid;
    };

    this.showError = function($input) {
      $input.addClass("error");
    };

    this.hideError = function($input) {
      $input.removeClass("error");
    };

    this.hideErrorIfNotEnterKey = function(ev) {
      if (13 != ev.which) {
        if (ev.target.type == "radio") {
          this.hideError($("input[name=" + ev.target.name + "]"));
        } else {
          this.hideError($(ev.target));
        }
      }
    };

    this.after("initialize", function() {
      this.on("keyup", {
        inputSelector: this.hideErrorIfNotEnterKey,
        textareaSelector: this.hideErrorIfNotEnterKey
      });

      this.on("change", {
        selectSelector: this.hideErrorIfNotEnterKey,
        radioSelector: this.hideErrorIfNotEnterKey
      });
    });
  }
});
