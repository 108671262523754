"use strict";

define(["clipboard"], function(ClipboardJS) {
  return {
    hookClipboard: function($elems, callback, isDisabled) {
      if (isDisabled) {
        return {};
      }

      $elems.each(function() {
        var $elem = $(this),
          clip = new ClipboardJS(this);

        $elem.attr("title", $elem.data("copy"));

        $elem.on({
          click: function(e) {
            e.preventDefault();
          },

          mouseover: function() {
            $elem.tipsy("show");
          },

          mouseout: function() {
            $elem.tipsy("hide");
          }
        });

        clip.on("success", function(event) {
          $elem.attr("title", $elem.data("copied")).tipsy("show");
          $elem.on("mouseout", function() {
            $elem.attr("title", $elem.data("copy")).tipsy("hide");
          });
          event.clearSelection();
        });

        if (callback !== undefined) {
          callback();
        }
      });
    }
  };
});
