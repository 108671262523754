"use strict";

define(["handlebars"], function(Handlebars) {
  function I18nHelper(key, args) {
    key = "js." + key;
    if (args.hash !== undefined) {
      return I18n.t(key, args.hash);
    } else {
      return I18n.t(key);
    }
  }
  Handlebars.registerHelper("t", I18nHelper);
  return I18nHelper;
});
