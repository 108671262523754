"use strict";

define([
  "flight/lib/component",
  "$app/ui/shared/with_preview",
  "$app/ui/shared/product_actions"
], function(defineComponent, WithPreview, ProductActionsUI) {
  return defineComponent(Product, WithPreview);

  function Product() {
    this.defaultAttrs({
      productSelector: ".product",
      productContainerSelector: ".js-product-card",
      archiveSelector: ".js-archive-button",
      creatorLinkClassName: "js-creator-profile-link",
      productLinkClassName: "js-show-product-page-trigger",
      libraryClassName: ".library",
      productActionsSelector: ".js-actions",
      previewContainerSelector: ".js-preview-container",
      productActionsContainerSelector: ".js-selected-product-actions"
    });

    this.showProductActions = function() {
      this.trigger("showLibraryProduct", {
        purchase_id: this.attr.purchaseId
      });
    };

    /**
     * Toggles the purchase's archived state in the user's library
     */
    this.toggleArchiveProduct = function(ev) {
      ev && ev.stopPropagation();

      this.attr.purchaseId = this.select("productSelector").data("purchase-id");

      // Disable the product card to give the user feedback that something's
      // happening. This also disallows them from clicking the button again
      this.select("productSelector")
        .parents(this.attr.productContainerSelector)
        .addClass("disabled");

      this.trigger("toggleArchive", {
        purchase_id: this.attr.purchaseId,
        is_archived: !this.attr.showArchivedOnly
      });

      this.trigger("uiNeedsToSetHasArchivedProducts", { value: true });
      this.trigger("uiToAdjustResultCount", { increment: false });
    };

    this.onToggleArchiveSuccess = function(_ev, data) {
      if (this.attr.purchaseId !== data.purchase_id) {
        return;
      }

      // First, the product fades out
      this.select("productSelector")
        .parents(this.attr.productContainerSelector)
        .fadeOut(500);

      // Then, the product is removed so that the other cards shift
      setTimeout(() => {
        this.select("productSelector")
          .parents(this.attr.productContainerSelector)
          .remove();
      }, 600);

      let message = I18n.t("js.product_archived");
      if (!data.is_archived) {
        message = I18n.t("js.product_unarchived");
      }

      this.trigger("uiShowFlashMessage", {
        message: message
      });
    };

    this.onToggleArchiveFailure = function(_ev, data) {
      if (this.attr.purchaseId !== data.purchase_id) {
        return;
      }

      // Re-enable the product card because it wasn't archived/unarchived
      this.select("productSelector")
        .parents(this.attr.productContainerSelector)
        .removeClass("disabled");

      this.trigger("uiShowFlashMessage", {
        message: I18n.t("js.error_placeholder")
      });
    };

    this.renderProductActions = function(ev, data) {
      if (this.attr.purchaseId !== data.purchase_id) {
        return;
      }

      this.select("productActionsContainerSelector").html(data.productNode);
      ProductActionsUI.attachTo(this.attr.productActionsSelector);
    };

    this.after("initialize", function() {
      this.$node.addClass("image-as-background");
      this.attr.purchaseId = this.select("productSelector").data("purchase-id");

      this.on(
        document,
        "uiNeedsToShowToggleArchiveSuccess",
        this.onToggleArchiveSuccess
      );

      this.on(
        document,
        "uiNeedsToShowToggleArchiveFailure",
        this.onToggleArchiveFailure
      );

      this.on("click", {
        archiveSelector: this.toggleArchiveProduct
      });

      this.on("hover", {
        productContainerSelector: this.showProductActions
      });

      this.on(
        document,
        "uiNeedsToShowLibraryProductActions",
        this.renderProductActions
      );
    });
  }
});
