"use strict";

define([
  "flight/lib/component",
  "$app/data/with_async_form",
  "$app/data/with_file_upload"
], function(defineComponent, WithAsyncForm, withFileUpload) {
  return defineComponent(Product, WithAsyncForm, withFileUpload);

  function Product() {
    this.defaultAttrs({
      // with_async_form attrs
      submitEvent: "uiNeedsToCreateProduct",
      submitSucceededEvent: "dataNewProduct",
      submitFailedEvent: "dataFailedToCreateProduct",
      // with_file_upload attrs
      isUserAsset: true,
      uploadStartedEvent: "dataUploadingProductFileStarted",
      uploadProgressedEvent: "dataUploadingProductFileProgressed",
      dataEvent: "dataProductFile",
      uiUploadWithCorsEvent: "uiNeedsToUploadProductFileWithCors"
    });

    this.updateProduct = function(ev, data) {
      var serializedFormData = data.serializedFormData;

      if (data.customPermalink != null) {
        serializedFormData += "&";
        serializedFormData += $.param({
          link: { custom_permalink: data.customPermalink }
        });
      }

      $.ajax({
        type: "POST",
        dataType: "json",
        url: Routes.link_path(data.uniquePermalink, { format: "json" }),
        data: serializedFormData
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataProduct", res);
            this.trigger("uiNeedsToResetOfferCodes", res.offer_code_list);
            this.trigger(
              "uiNeedsToResetShippingDestinations",
              res.serialized_shipping_destinations
            );
            this.trigger("uiResetDatePicker");
            this.trigger("uiNeedsToDrawFileList", {
              files_data: res.files_data
            });
          } else {
            this.trigger("dataFailedToUpdateProduct", res);
            if (res.offer_code_list) {
              this.trigger("uiNeedsToResetOfferCodes", res.offer_code_list);
            }
          }
        }.bind(this)
      );
      return false;
    };

    this.duplicateProduct = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: Routes.product_duplicates_path(),
        data: { id: data.permalink }
      }).done(
        function(res) {
          res.permalink = data.permalink;
          if (res.success) {
            this.trigger("dataProductDuplicated", res);
          } else {
            this.trigger("dataFailedToDuplicateProduct", res);
          }
        }.bind(this)
      );
    };

    this.deleteProduct = function(ev, data) {
      $.ajax({
        type: "DELETE",
        dataType: "json",
        url: Routes.link_path(data.permalink)
      }).done(
        function(res) {
          res.permalink = data.permalink;
          if (res.success) {
            this.trigger("dataProductDeleted", res);
          } else {
            this.trigger("dataFailedToDeleteProduct", res);
          }
        }.bind(this)
      );
    };

    this.undoDeleteProduct = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: data.url
      }).done(
        function(res) {
          this.trigger("dataProductUndone", res);
        }.bind(this)
      );
    };

    this.releasePreorder = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: Routes.release_preorder_path(data.permalink)
      }).done(
        function(res) {
          if (res.success) {
            this.trigger("dataReleasedPreorder");
          } else {
            this.trigger("dataFailedToReleasePreorder", res);
          }
        }.bind(this)
      );
    };

    this.loadMoreProducts = function(ev, data) {
      $.ajax({
        type: "GET",
        dataType: "html",
        url: Routes.links_paged_path({ page: data.page })
      }).done(
        function(res) {
          this.trigger("dataProducts", { html: res });
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on("uiNeedsToUpdateProduct", this.updateProduct);
      this.on("uiNeedsToReleasePreorder", this.releasePreorder);
      this.on("uiNeedsToDeleteProduct", this.deleteProduct);
      this.on("uiNeedsToDuplicateProduct", this.duplicateProduct);
      this.on("uiNeedsToUndoProductDelete", this.undoDeleteProduct);
      this.on("uiNeedsToLoadMoreProducts", this.loadMoreProducts);
    });
  }
});
