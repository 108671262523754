"use strict";

define([], function() {
  return WithClickEventTracking;

  function WithClickEventTracking() {
    this.defaultAttrs({
      trackProductClickEventSelector: ".js-track-product-click-event"
    });

    this.trackProductClickEvent = function(ev) {
      this.trigger("uiNeedsToTrackProductSpecificEvent", {
        name: $(ev.target).data("product-event-name"),
        permalink: this.attr.permalink
      });
    };

    this.after("initialize", function() {
      this.on("click", {
        trackProductClickEventSelector: this.trackProductClickEvent
      });
    });
  }
});
