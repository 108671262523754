(function() {
  "use strict";

  define(["flight/lib/component"], function(defineComponent) {
    return defineComponent(BasePage);

    function BasePage() {
      this.getAutocompleteSearchResults = function(ev, data) {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: Routes.discover_search_autocomplete_path(),
          data: data
        })
          .done(
            function(response) {
              this.trigger("dataAutocompleteSearchResults", response);
            }.bind(this)
          )
          .fail(
            function() {
              this.trigger("dataAutocompleteSearchError");
            }.bind(this)
          );
      };

      this.showAutocompleteSearchError = function(ev, response) {
        this.trigger("uiShowFlashMessage", {
          message: I18n.t("js.something_went_wrong")
        });
      };

      this.after("initialize", function() {
        this.on(
          document,
          "uiNeedsAutocompleteSearchResults",
          this.getAutocompleteSearchResults
        );
        this.on(
          document,
          "dataAutocompleteSearchError",
          this.showAutocompleteSearchError
        );
      });
    }
  });
})();
