"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(FlashMessage);

  function FlashMessage() {
    this.defaultAttrs({
      messageSelector: ".js-message"
    });

    this.showFlashMessage = function(ev, data) {
      this.select("messageSelector").text(data.message);
      this.$node.addClass("showing");

      setTimeout(
        function() {
          this.hideFlashMessage();
        }.bind(this),
        data.delay ? data.delay : 5000
      );
    };

    this.initializeFlashMessage = function() {
      var $message = this.select("messageSelector");

      if ($message.text().length > 0) {
        this.trigger("uiShowFlashMessage", { message: $message.text() });
      }
    };

    this.hideFlashMessage = function() {
      this.$node.removeClass("showing");
    };

    this.after("initialize", function() {
      this.on(document, "uiShowFlashMessage", this.showFlashMessage);
      this.initializeFlashMessage();
      this.on("click", { messageSelector: this.hideFlashMessage });
    });
  }
});
