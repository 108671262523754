"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(PaypalOrder);

  function PaypalOrder() {
    this.defaultAttrs({
      productsSHA: {},
      data: {}
    });

    this.fetchOrder = function(ev, data) {
      $.ajax({
        type: "GET",
        url: Routes.fetch_order_paypal_path(),
        dataType: "json",
        data: { order_id: data.order_id },
        error: function(jqXHR, textStatus, errorThrown) {
          this.trigger(data.errorCallbackEvent, {});
        }.bind(this)
      }).done(
        function(response) {
          this.trigger(data.successCallbackEvent, response);
        }.bind(this)
      );
    };

    this.payForSingleBuy = function(ev, data) {
      this.attr.data = data;
      this.pay(
        this.successCallbackForSingleBuy,
        this.errorCallbackForSingleBuy
      );
    };

    this.errorCallbackForSingleBuy = function(jqXHR, textStatus, errorThrown) {
      this.trigger("dataPurchaseFail", this.attr.data.purchaseResponse);
    };

    this.successCallbackForSingleBuy = function(response) {
      if (response.order_id) {
        this.trigger("dataPurchaseSuccessful", this.attr.data.purchaseResponse);
      } else {
        this.trigger("dataPurchaseFail", this.attr.data.purchaseResponse);
      }
    };

    this.pay = function(successCallback, errorCallback) {
      $.ajax({
        type: "POST",
        url: Routes.pay_paypal_path(),
        dataType: "json",
        data: this.attr.data.paymentDetails,
        error: errorCallback.bind(this)
      }).done(successCallback.bind(this));
    };

    this.markBundleProductAndPayForBundle = function(ev, data) {
      this.attr.data = data;
      this.attr.productsSHA[data.sha] = data.response.success;
      this.payForBundle();
    };

    this.payForBundle = function() {
      if (this.isReadyToPayForBundle() && this.attr.data.paypalOrderDetails) {
        this.attr.data.paymentDetails = this.attr.data.paypalOrderDetails;
        this.pay(
          this.successCallbackForBundleBuy,
          this.errorCallbackForBundleBuy
        );
      } else {
        this.handleBundleUIPostPurchaseEvents();
      }
    };

    this.isReadyToPayForBundle = function() {
      var areAllPurchasesRequested = true;
      var isAnyPurchaseSuccessful = false;

      this.attr.data.productsInBundle.forEach(function(item, i) {
        if (!(item.sha in this.attr.productsSHA)) {
          areAllPurchasesRequested = false;
        }

        if (this.attr.productsSHA[item.sha]) {
          isAnyPurchaseSuccessful = true;
        }
      }, this);
      return isAnyPurchaseSuccessful && areAllPurchasesRequested;
    };

    this.handleBundleUIPostPurchaseEvents = function() {
      var response = this.attr.data.response;
      this.trigger(this.bundleUIPostPurchaseEventName(response), response);
    };

    this.bundleUIPostPurchaseEventName = function(data) {
      if (data.success) {
        return "uiBundledProductPaymentCompleted";
      } else {
        if (data.textStatus == "timeout") {
          return "uiBundledProductPaymentTimeout";
        } else {
          return "uiBundledProductPaymentFailed";
        }
      }
    };

    this.successCallbackForBundleBuy = function(response) {
      if (response.order_id) {
        this.handleBundleUIPostPurchaseEvents();
      } else {
        this.clearBundlePurchaseData();
        this.trigger("dataPurchaseFail", {});
      }
    };

    this.errorCallbackForBundleBuy = function(jqXHR, textStatus, errorThrown) {
      this.clearBundlePurchaseData();
      this.trigger("dataPurchaseFail", {});
    };

    this.clearBundlePurchaseData = function() {
      this.attr = {
        productsSHA: {},
        data: {}
      };
    };

    this.after("initialize", function() {
      this.on(document, "uiNeedsPaypalOrderPayment", this.payForSingleBuy);
      this.on(
        document,
        "uiNeedsMarkBundleProductAndOrderPayment",
        this.markBundleProductAndPayForBundle
      );
      this.on(document, "uiNeedsOrderInformation", this.fetchOrder);
    });
  }
});
