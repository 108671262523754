"use strict";

const defineComponent = require("flight/lib/component");
const WithUrl = require("$app/utils/with_url");
const ProductOverlayTriggerUI = require("$app/ui/shared/product_overlay_trigger");
const LibraryProductUI = require("$app/ui/library/product");
const {
  ProductCardUIStates,
  ProductCardFetchTypes,
  ProductCardClassNames,
  ProductCardUIContext
} = require("$app/ui/shared/product_card_constants");

const omitBy = require("lodash/omitBy");
const isNil = require("lodash/isNil");
const queryString = require("query-string");

require("$vendor/jquery.viewport.mini");

function ProductCards() {
  this.defaultAttrs({
    productCardHolderSelector: ".js-product-card-holder",
    productCardFilterContainerSelector: ".js-product-card-filters",
    productCardWrapper: ".js-product-card-wrapper",
    productSelector: ".js-product",
    productWrapperSelector: ".js-product-card",
    quickAddToCartTriggerSelector: ".js-quick-add-to-cart-trigger",
    scrollLoadingSelector: ".js-product-card-loading-container",
    scrollTriggerSelector: ".js-product-card-load-more",
    archiveSelector: ".js-archive-button",
    everythingCategory: "everything",
    hasArchivedProductsSelector: ".js-product-card-has-archived-products",
    filterOverlayClass: "filter-overlay",
    startIndex: 1,
    uiContext: null,
    isProfileOwner: false,
    scrollIsLocked: true
  });

  // 1. PROCESS URL, PARAMS, FILTER OPTIONS

  this.getDataFromUrlAndSetInputs = function() {
    if (this.getUrlParams().query) {
      this.attr.query = this.getUrlParams().query.replace(/\+/g, " ");
      this.trigger("uiToUpdateSearchField", { query: this.attr.query });
    }

    if (this.getUrlParams().bought) {
      this.attr.boughtProduct = this.getUrlParams().bought.match(
        /[a-zA-Z]+/
      )[0];
    }

    if (this.getUrlParams().category) {
      this.attr.categorySlug = this.getUrlParams().category.match(
        /[a-zA-Z\ ]+/
      )[0];
      this.trigger("uiToUpdateSearchCategory", {
        category: this.attr.categorySlug
      });
    }

    if (this.getUrlParams().tags) {
      this.attr.tags = this.getUrlParams().tags.split(",");
    }

    if (this.getUrlParams().filetypes) {
      this.attr.filetypes = this.getUrlParams().filetypes.split(",");
    }

    if (this.getUrlParams().creator_external_ids) {
      this.attr.creatorExternalIds = this.getUrlParams().creator_external_ids.split(
        ","
      );
    }

    for (const key of [
      "sort",
      "minPrice",
      "maxPrice",
      "rating",
      "userId",
      "recommended_by"
    ]) {
      const value = this.getUrlParams()[key];
      if (value) {
        this.attr[key] = value;
      }
    }

    if (this.getUrlParams().show_archived_only) {
      this.attr.showArchivedOnly = this.getUrlParams().show_archived_only;
    }

    if (this.attr.uiContext == ProductCardUIContext.LIBRARY) {
      this.attr.userPurchasesOnly = true;
    }

    this.attr.hasArchivedProducts =
      this.select("hasArchivedProductsSelector").length > 0;

    this.updateFilterInputs();
  };

  this.updateFilterInputs = function() {
    this.trigger("uiToUpdateProductCardFilterInputs", {
      sort: this.attr.sort,
      minPrice: this.attr.minPrice,
      maxPrice: this.attr.maxPrice,
      rating: this.attr.rating,
      user_id: this.attr.userId,
      filetypes: this.attr.filetypes,
      recommended_by: this.attr.recommended_by,
      show_archived_only: this.attr.showArchivedOnly
    });
  };

  this.resetAllParams = function() {
    window.location.replace(window.location.pathname);
    this.getDataFromUrlAndSetInputs();
  };

  this.setFilterValuesInUrl = function() {
    const params = queryString.parse(window.location.search);

    // Copy these attributes over to params if they exist
    const keys = [
      "query",
      "sort",
      "minPrice",
      "maxPrice",
      "rating",
      "filetypes",
      "recommended_by",
      "creator_external_ids"
    ];
    for (const key of keys) {
      if (this.attr[key]) {
        params[key] = this.attr[key];
      } else {
        delete params[key];
      }
    }

    // Tags, filetypes and categories are slightly special
    if (this.attr.tags && this.attr.tags.length > 0) {
      params.tags = this.attr.tags.sort().join(",");
    } else {
      delete params.tags;
    }

    if (this.attr.filetypes && this.attr.filetypes.length > 0) {
      params.filetypes = this.attr.filetypes.sort().join(",");
    } else {
      delete params.filetypes;
    }

    if (
      this.attr.creatorExternalIds &&
      this.attr.creatorExternalIds.length > 0
    ) {
      params.creator_external_ids = this.attr.creatorExternalIds
        .sort()
        .join(",");
    } else {
      delete params.creator_external_ids;
    }

    if (this.attr.categorySlug) {
      params.category = this.attr.categorySlug;
    } else {
      delete params.category;
    }

    if (this.attr.showArchivedOnly) {
      params.show_archived_only = this.attr.showArchivedOnly;
    } else {
      delete params.show_archived_only;
    }

    // The `encode: false` prevents `?tags=draw,paint` being converted to `?tags=draw%2Cpaint`.
    // Both versions work but we're going with the former just for the sake of readability.
    const newParamsString = queryString.stringify(params, {
      encode: false
    });

    let queryPrefix = newParamsString.length > 0 ? "?" : "";
    window.history.pushState(
      {},
      document.title,
      window.location.pathname +
        queryPrefix +
        newParamsString +
        window.location.hash
    );

    this.trigger("uiToUpdateCategoryLinksWithParams", { params: params });
  };

  this.hasNoParams = function() {
    for (const key of [
      "minPrice",
      "maxPrice",
      "rating",
      "query",
      "tags",
      "show_archived_only"
    ]) {
      if (this.getUrlParams()[key] != null || this.attr[key] != null) {
        return false;
      }
    }
    if (!this.isQueryBlank()) {
      return false;
    }
    return true;
  };

  this.isQueryBlank = function() {
    return (
      this.attr.query === undefined ||
      this.attr.query === null ||
      this.attr.query.length == 0
    );
  };

  this.isEverythingCategory = function() {
    return (
      this.attr.userId == null &&
      this.attr.userPurchasesOnly == null &&
      (this.attr.categorySlug === this.attr.everythingCategory ||
        this.attr.categorySlug === null ||
        this.attr.categorySlug === undefined)
    );
  };

  // 2. ASK FOR RESULTS

  this.fetchResults = function(_ev, data) {
    // Reset to the beginning every time the user interacts with
    // the tags, filters, categories, or search
    this.attr.startIndex = 1;

    // Deselect all tags if the user changes the category or query
    if (
      this.attr.uiContext == ProductCardUIContext.DISCOVER &&
      (data.fetchType === ProductCardFetchTypes.CATEGORY ||
        data.fetchType === ProductCardFetchTypes.SEARCH)
    ) {
      this.attr.tags = [];
    }

    if (data.fetchType === ProductCardFetchTypes.TAG) {
      this.fetchResultsForTags(data);
    } else if (data.fetchType === ProductCardFetchTypes.FILTER) {
      this.fetchResultsForFilters(data);
    } else if (data.fetchType === ProductCardFetchTypes.CATEGORY) {
      this.fetchResultsForCategory(data);
    } else if (data.fetchType === ProductCardFetchTypes.SEARCH) {
      this.fetchResultsForQuery(data);
    } else if (data.fetchType === ProductCardFetchTypes.PAGINATION) {
      this.fetchResultsForPagination(data);
    } else if (data.fetchType === ProductCardFetchTypes.LOAD_MORE) {
      this.fetchResultsForLoadMore(data);
    }
  };

  this.fetchResultsForQuery = function(data) {
    const query = data.query.replace(/\+/g, " ");
    this.attr.query = query;
    this.attr.categorySlug = data.categorySlug;
    this.askForResults({ showLoadingState: true });
  };

  this.fetchResultsForFilters = function(data) {
    this.attr.sort = data.sort;
    this.attr.minPrice = data.minPrice;
    this.attr.maxPrice = data.maxPrice;
    this.attr.filetypes = data.filetypes;
    this.attr.rating = data.rating;
    this.attr.recommended_by = data.recommended_by;
    this.attr.creatorExternalIds = data.creatorExternalIds;
    this.attr.showArchivedOnly = data.showArchivedOnly;

    this.askForResults({ showLoadingState: true });
  };

  this.fetchResultsForCategory = function(data) {
    this.attr.categorySlug = data.categorySlug;
    this.attr.tags = data.tags;
    this.askForResults({ showLoadingState: true });
  };

  this.fetchResultsForTags = function(data) {
    this.attr.tags = data.tags;
    this.askForResults({ showLoadingState: true });
  };

  this.fetchResultsForPagination = function(data) {
    this.attr.startIndex = data.index;
    this.askForResults({ showLoadingState: true });
  };

  this.fetchResultsForLoadMore = function(data) {
    this.attr.startIndex = data.index;
    this.askForResults({ showLoadingState: true });
  };

  this.askForResults = function(data) {
    if (data.showLoadingState) {
      this.attr.uiState = ProductCardUIStates.LOADING;
      this.toggleProductCardRefreshLoader();
      this.showScrollLoader();
    }
    this.setFilterValuesInUrl();

    var searchParams = {
      from: this.attr.startIndex,
      categories: this.attr.categorySlug,
      tags: this.attr.tags,
      filetypes: this.attr.filetypes,
      sort: this.attr.sort,
      min_price: this.attr.minPrice,
      max_price: this.attr.maxPrice,
      rating: this.attr.rating,
      user_id: this.attr.userId,
      recommended_by: this.attr.recommended_by,
      user_purchases_only: this.attr.userPurchasesOnly,
      show_archived_only: this.attr.showArchivedOnly,
      creator_external_ids: this.attr.creatorExternalIds
    };

    searchParams = omitBy(searchParams, isNil);

    this.trigger(
      "uiNeedsSearchResults",
      $.extend(searchParams, { query: this.attr.query })
    );
  };

  // 3. INITIALIZE SCAFFOLDING UI

  this.updateUiStateFromResponse = function() {
    const { resultCount } = this.attr;
    let uiState;
    if (resultCount === 0) {
      uiState = ProductCardUIStates.NO_RESULTS;
    } else if (this.attr.uiContext == ProductCardUIContext.PROFILE) {
      uiState = ProductCardUIStates.PRODUCT_RESULTS_ON_PROFILE;
    } else if (this.isQueryBlank()) {
      if (this.isEverythingCategory()) {
        uiState = ProductCardUIStates.SHOW_CATEGORY_CARDS;
      } else {
        uiState = ProductCardUIStates.CATEGORY_TOP_PRODUCTS;
      }
    } else if (!this.isQueryBlank()) {
      if (this.isEverythingCategory()) {
        uiState = ProductCardUIStates.PRODUCT_RESULTS;
      } else {
        uiState = ProductCardUIStates.PRODUCT_RESULTS_IN_CATEGORY;
      }
    }
    this.attr.uiState = uiState;
  };

  this.renderScaffolding = function() {
    const {
      categoryName,
      categorySlug,
      total,
      totalFormatted,
      filetypes,
      filetypesData,
      creatorExternalIds,
      creatorCounts,
      maxPrice,
      minPrice,
      query,
      rating,
      resultCount,
      showArchivedOnly,
      size,
      sort,
      startIndex,
      uiState,
      uiContext
    } = this.attr;

    switch (this.attr.uiContext) {
      case ProductCardUIContext.DISCOVER:
        this.renderDiscoverScaffolding();
        break;
      case ProductCardUIContext.PROFILE:
        this.renderProfileScaffolding();
        break;
      case ProductCardUIContext.LIBRARY:
        this.renderLibraryScaffolding();
        break;
    }

    this.toggleProductCardRefreshLoader();
    this.hideScrollLoader();

    if (!this.attr.fullWidthNoFilters) {
      this.updateFilterInputs();
      this.trigger("uiToSetProductCardFilters", {
        uiState,
        totalFormatted,
        resultCount,
        start: startIndex,
        sort,
        minPrice,
        maxPrice,
        rating,
        uiContext,
        showArchivedOnly,
        creatorExternalIds
      });
    }

    this.attr.start = startIndex;
    this.attr.pageCount = Math.ceil(total / size);
    this.attr.pageIndex = Math.ceil(startIndex / this.attr.resultsPerPage);

    this.trigger("uiToSetSelectedFiletypes", { filetypes: filetypes });
    this.trigger("uiToSetSelectedCreatorIds", {
      creatorExternalIds: creatorExternalIds
    });
    this.trigger("uiToAddFiletypeOptions", { uiState, filetypesData });
    this.trigger("uiToAddCreatorOptions", { uiState, creatorCounts });
  };

  this.renderDiscoverScaffolding = function() {
    const {
      categoryName,
      categorySlug,
      tagsData,
      filetypesData,
      creatorCounts,
      tags,
      filetypes,
      uiState,
      query
    } = this.attr;
    this.trigger("uiToSetSelectedTags", { tags: tags });
    this.trigger("uiToAddTagOptions", { uiState, tagsData });
    this.trigger("uiToSetDiscoverHeader", {
      uiState,
      categoryName,
      categorySlug,
      query
    });

    if (uiState === ProductCardUIStates.SHOW_CATEGORY_CARDS) {
      this.trigger("uiShouldToggleCategoryLoader", {
        state: uiState
      });
    }

    const showCategoryCards =
      uiState === ProductCardUIStates.SHOW_CATEGORY_CARDS;

    if (showCategoryCards) {
      this.trigger("uiShouldToggleCategoryLoader", {
        state: uiState
      });
    }

    this.select("productCardWrapper").toggleClass(
      ProductCardClassNames.HIDDEN,
      showCategoryCards
    );
  };

  this.renderLibraryScaffolding = function() {
    if (this.attr.resultCount == 0 && this.hasNoParams()) {
      this.trigger("uiNeedsToShowEmptyLibraryState", {
        fromArchiving: false,
        showingArchivedOnly: this.attr.showArchivedOnly
      });
    } else {
      this.select("productCardWrapper").toggleClass(
        ProductCardClassNames.HIDDEN,
        false
      );
      this.trigger("uiToEnableSearch");
    }
  };

  this.renderProfileScaffolding = function() {
    if (this.attr.resultCount == 0 && this.hasNoParams()) {
      this.trigger("uiToToggleEmptyProductsClass", { empty: true });
    }
    if (this.attr.editMode === undefined) {
      this.attr.editMode = this.attr.isProfileOwner;
    }
    if (this.attr.isProfileOwner && this.attr.editMode) {
      this.trigger("uiToEnableSearch");
      this.showEditMode();
    } else {
      this.hideEditMode();
    }
    this.trigger("uiToShowProfileHeader");
    this.trigger("uiToSetupDynamicGrid");
  };

  this.toggleProductCardRefreshLoader = function() {
    this.select("productCardHolderSelector").toggleClass(
      ProductCardClassNames.LOADING,
      this.attr.uiState === ProductCardUIStates.LOADING
    );
  };

  // 4. RENDER RESULTS WHEN WE GET THEM BACK

  this.handleSearchResponseSuccess = function(ev, data) {
    this.attr.tagsData = data.tags_data;
    this.attr.filetypesData = data.filetypes_data;
    this.attr.creatorCounts = data.creator_counts;
    this.attr.total = data.total;
    this.attr.totalFormatted = data.total_formatted;
    this.attr.size = data.size;
    this.attr.categorySlug = data.category_slug;
    this.attr.categoryName = data.category_name;
    this.attr.resultCount = data.result_count;
    this.attr.sort = data.sort_key;
    this.attr.resultsPerPage = this.attr.size;
    const hideProductFiltersOnProfile =
      this.attr.uiContext == ProductCardUIContext.PROFILE &&
      data.hide_product_filters_on_profile;
    const libraryProductsCountBelowFilterThreshold =
      this.attr.uiContext == ProductCardUIContext.LIBRARY &&
      !this.attr.hasArchivedProducts && // if there are archived products, we should always let them toggle those w/ the filter
      data.purchase_results_count &&
      data.purchase_results_count <= this.attr.size;
    this.attr.fullWidthNoFilters =
      hideProductFiltersOnProfile || libraryProductsCountBelowFilterThreshold;

    this.updateUiStateFromResponse();
    this.renderScaffolding();
    this.renderResultsFromResponse(data);
    this.getPersistentBundle();
  };

  this.renderResultsFromResponse = function(data) {
    if (this.attr.uiState == ProductCardUIStates.NO_RESULTS) {
      this.renderNoResults();
    } else if (this.attr.uiState == ProductCardUIStates.SHOW_CATEGORY_CARDS) {
      this.renderCategoryCards(data);
    } else if (this.attr.uiState === undefined) {
    } else {
      if (this.attr.uiContext == ProductCardUIContext.PROFILE) {
        this.renderProfileResults(data);
      } else if (this.attr.uiContext == ProductCardUIContext.DISCOVER) {
        this.renderDiscoverResults(data);
      } else if (this.attr.uiContext == ProductCardUIContext.LIBRARY) {
        this.renderLibraryResults(data);
      }
    }
  };

  this.renderProfileResults = function(data) {
    this.renderProductCards(data);
    if (this.attr.isProfileOwner && this.attr.editMode) {
      if (this.attr.pageIndex < this.attr.pageCount) {
        this.fetchResultsForLoadMore({
          index: this.attr.start + this.attr.resultsPerPage
        });
      } else {
        this.trigger("uiToInitializeProductVisibilities");
      }
    } else {
      this.renderPaginator();
    }
    this.initializeProductOverlayTrigger();
  };

  this.renderDiscoverResults = function(data) {
    this.renderProductCards(data);
    this.initializeProductOverlayTrigger();
    this.renderPaginator();
    this.trigger("uiShouldHideCategoryContainer");
  };

  this.renderLibraryResults = function(data) {
    this.renderProductCards(data);
    this.initializeLibraryOverlayTrigger();
    this.renderPaginator();
  };

  this.renderProductCards = function(data) {
    if (data) {
      if (this.attr.start == 1) {
        this.select("productCardHolderSelector")
          .html(data.products_html)
          .show();
      } else {
        this.select("productCardHolderSelector").append(data.products_html);
      }
    }
    if (this.attr.fullWidthNoFilters) {
      this.select("productCardWrapper").addClass("with-no-filters");
      this.toggleFilterVisibility({}, { show: false });
    } else {
      this.toggleFilterVisibility({}, { show: true });
    }
    this.trigger("uiShouldTipsyElements");
  };

  this.renderNoResults = function() {
    this.trigger("uiShouldHideCategoryContainer");
    this.select("productCardHolderSelector").empty();
    this.trigger("uiToClearPagination");
    if (
      (this.attr.userId || this.attr.uiContext == "LIBRARY") &&
      this.hasNoParams()
    ) {
      this.toggleFilterVisibility({}, { show: false });
    } else {
      this.toggleFilterVisibility({}, { show: true });
    }
  };

  this.renderCategoryCards = function(data) {
    this.toggleFilterVisibility({}, { show: false });
    if (data) {
      this.trigger("uiShouldUpdateCategoryContainer", {
        response: data.categories_html
      });
    }
    this.hideProductsContainer();
    this.initializeProductOverlayTrigger();
    this.trigger("uiToClearPagination");
  };

  this.initializeProductOverlayTrigger = function() {
    ProductOverlayTriggerUI.attachTo(this.attr.productWrapperSelector, {
      productSelector: this.attr.productSelector,
      overlayTriggerSelector: this.attr.productSelector,
      skipOverlayTriggerSelector: this.attr.quickAddToCartTriggerSelector
    });
  };

  this.initializeLibraryOverlayTrigger = function() {
    LibraryProductUI.attachTo(this.attr.productWrapperSelector, {
      productSelector: this.attr.productSelector,
      showArchivedOnly: this.attr.showArchivedOnly
    });
  };

  this.getPersistentBundle = function() {
    if (!this.attr.persistentBundleRequested) {
      this.attr.persistentBundleRequested = true;
      this.trigger("uiNeedsToGetPersistentBundle");
    }
  };

  this.hideProductsContainer = function() {
    this.select("productCardHolderSelector")
      .empty()
      .hide();
  };

  this.toggleFilterVisibility = function(_ev, data) {
    if (!this.attr.fullWidthNoFilters) {
      this.select("productCardFilterContainerSelector").toggleClass(
        ProductCardClassNames.HIDDEN,
        !data.show
      );

      this.setProductFiltersSidebarHeight();
    }
  };

  this.setProductFiltersSidebarHeight = function() {
    // We set the height dynamically so the filters sidebar gets its own overflow: scroll bar
    const windowHeight = $(window).height();
    let heightOffset;

    if (this.attr.isProfileOwner) {
      // If they're the profile owner, we need some extra offset for the gumroad header
      heightOffset = 160;
    } else {
      heightOffset = 25;
    }

    this.select("productCardFilterContainerSelector").height(
      windowHeight - heightOffset + "px"
    );
  };

  this.renderPaginator = function() {
    if (this.attr.pageIndex >= this.attr.pageCount) {
      this.hideScrollTrigger();
    } else {
      this.showScrollTrigger();
    }
  };

  this.hideScrollTrigger = function() {
    this.select("scrollTriggerSelector").hide();
    this.off(window, "scroll", this.scrollPage);
  };

  this.showScrollTrigger = function() {
    this.select("scrollTriggerSelector").show();
    this.off(window, "scroll", this.scrollPage);
    this.on(window, "scroll", this.scrollPage);
  };

  this.showScrollLoader = function() {
    this.attr.scrollIsLocked = true;
    if (this.select("scrollTriggerSelector").length) {
      this.select("scrollLoadingSelector").addClass(
        ProductCardClassNames.LOADING
      );
      this.select("scrollTriggerSelector").hide();
    }
  };

  this.hideScrollLoader = function() {
    if (this.attr.startIndex > 1) {
      this.attr.scrollIsLocked = false;
    }
    this.select("scrollLoadingSelector").removeClass(
      ProductCardClassNames.LOADING
    );
    if (!this.attr.editMode && this.attr.total > 0) {
      this.select("scrollTriggerSelector").show();
    }
  };

  this.scrollPage = function(ev) {
    ev.preventDefault();
    if (
      (this.attr.scrollIsLocked && ev.type != "click") ||
      this.productPopped() ||
      this.select("scrollTriggerSelector").not(":in-viewport").length
    ) {
      return;
    }
    if (this.attr.pageIndex < this.attr.pageCount) {
      this.showScrollLoader();
      this.fetchResultsForLoadMore({
        index: this.attr.start + this.attr.resultsPerPage
      });
    } else {
      this.hideScrollTrigger();
    }
  };

  this.productPopped = function() {
    return this.select("productSelector").filter(".popped").length > 0;
  };

  this.showError = function() {
    this.trigger("uiShowFlashMessage", {
      message: I18n.t("js.something_went_wrong_reload_page")
    });
    this.getPersistentBundle();
  };

  this.setEditMode = function(value) {
    this.attr.editMode = value;
    this.select("productCardFilterContainerSelector").toggleClass(
      "disabled-filters",
      value
    );
  };

  this.showEditMode = function() {
    this.setEditMode(true);
    this.trigger("uiToDisableAllFilters");
    this.trigger("uiToDisableSearch");
  };

  this.hideEditMode = function() {
    this.setEditMode(false);
    this.trigger("uiToEnableAllFilters");
    this.trigger("uiToEnableSearch");
  };

  this.after("initialize", function() {
    this.on(
      document,
      "dataGotDiscoverSearchResult",
      this.handleSearchResponseSuccess
    );
    this.on(document, "uiShouldShowEditing", this.showEditMode);
    this.on(document, "uiShouldHideEditing", this.hideEditMode);
    this.on(document, "dataDiscoverSearchError", this.showError);
    this.on(document, "uiNeedsToFetchNewResults", this.fetchResults);
    this.on(document, "uiToResetAllParams", this.resetAllParams);
    this.on("click", { scrollTriggerSelector: this.scrollPage });
    this.on(window, "resize", this.setProductFiltersSidebarHeight);

    this.on(
      document,
      "uiToToggleFilterVisibility",
      this.toggleFilterVisibility
    );

    // Initialize UI
    this.getDataFromUrlAndSetInputs(); // set attr values from url
    this.askForResults({ showLoadingState: false }); // request uiNeedsSearchResults
    this.renderResultsFromResponse(); // render container / filters
  });
}

module.exports = defineComponent(ProductCards, WithUrl);
