"use strict";

const defineComponent = require("flight/lib/component");
const GoogleAnalyticsData = require("$app/data/google_analytics");
const FacebookPixelData = require("$app/data/facebook_pixel");

function UserAnalytics() {
  this.defaultAttrs({
    trackFreeSales: true
  });

  this.startTrackingForSeller = function(ev, data) {
    this.trigger("uiNeedsToStartTrackingForSellerGoogleAnalytics", data);
    this.trigger("uiNeedsToStartTrackingForSellerFacebookPixel", data);
    this.attr.trackFreeSales = data.trackFreeSales;
  };

  this.trackProductEvent = function(ev, data) {
    this.trigger("uiNeedsToTrackActivityWithSellerGoogleAnalytics", {
      ...data,
      trackFreeSales: this.attr.trackFreeSales
    });
    this.trigger("uiNeedsToTrackActivityWithSellerFacebookPixel", {
      ...data,
      trackFreeSales: this.attr.trackFreeSales
    });
  };

  this.trackECommerceForSeller = function(ev, data) {
    this.trigger("uiNeedsToTrackECommerceEventWithSellerGoogleAnalytics", {
      ...data,
      trackFreeSales: this.attr.trackFreeSales
    });
  };

  this.after("initialize", function() {
    GoogleAnalyticsData.attachTo(this.$node);
    FacebookPixelData.attachTo(this.$node);

    this.on(
      document,
      "uiNeedsToStartTrackingForSellerAnalytics",
      this.startTrackingForSeller
    );
    this.on(
      document,
      "uiNeedsToTrackActivityWithSellerAnalytics",
      this.trackProductEvent
    );
    this.on(
      document,
      "uiNeedsToTrackECommerceEventWithSellerAnalytics",
      this.trackECommerceForSeller
    );
  });
}

module.exports = defineComponent(UserAnalytics);
