var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "complete";
},"3":function(container,depth0,helpers,partials,data) {
    return "showing-check";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-unsaved-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"unsaved_id") : depth0), depth0))
    + "\" ";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-dropbox-file-upload-state=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dropboxFileUploadState") : depth0), depth0))
    + "\" data-dropbox-file-external-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dropboxFileExternalId") : depth0), depth0))
    + "\" ";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <li><i class=\"remove-button js-remove-trigger tippable\" data-tipsy-gravity=\"w\" title=\""
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":94},"end":{"line":21,"column":108}}}))
    + "\"></i></li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "        <li><i class=\"remove-button js-cancel-trigger tippable\" data-tipsy-gravity=\"w\" title=\""
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":94},"end":{"line":23,"column":108}}}))
    + "\"></i></li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "        <li>\n          <i class=\"icn-subtitles js-upload-subtitles-trigger tippable\" data-tipsy-gravity=\"w\" title=\""
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"upload_subtitles",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":102},"end":{"line":28,"column":126}}}))
    + "\"></i>\n          <i class=\"icn-subtitles js-hide-upload-subtitles-trigger active tippable soft-hidden\"></i>\n        </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"uploading-circle js-uploading-circle\">\n            <i class=\"gi gi-rainbow gi-spin "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"complete") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":44},"end":{"line":36,"column":78}}})) != null ? stack1 : "")
    + "\"></i>\n            <i class=\"gi gi-check\"></i>\n          </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "soft-hidden";
},"20":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"subtitle-upload-drawer js-subtitle-upload-drawer soft-hidden\">\n      <ul class=\"subtitle-list js-subtitle-list soft-hidden\"></ul>\n      <a class=\"edit-uploader subtitle-upload-box js-subtitle-upload-box\">\n        <span class=\"prompt\">"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"upload_subtitle_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":29},"end":{"line":50,"column":57}}}))
    + "</span>\n      </a>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"file-row-container js-file-subtitle-wrapper "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"complete") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":88}}})) != null ? stack1 : "")
    + "\" data-file-url=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">\n  <div class=\"file-row js-file-row relative "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"complete") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":44},"end":{"line":2,"column":80}}})) != null ? stack1 : "")
    + " last-in-file-wrapper\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":103},"end":{"line":2,"column":139}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unsaved_id") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":140},"end":{"line":2,"column":202}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dropboxFileExternalId") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":203},"end":{"line":2,"column":358}}})) != null ? stack1 : "")
    + ">\n    <div class=\"uploading-bar rainbox-bar\"></div>\n\n    <div class=\"file-row-left\">\n      <i class=\"js-reorder-handle gi gi-hamburger soft-hidden\"></i>\n\n      <div class=\"file-name js-file-name-holder\">\n        <span class=\"js-file-name\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n        <i class=\"gi gi-pencil\"></i>\n      </div>\n\n      <div class=\"file-name-form js-file-name-form soft-hidden\">\n        <input type=\"text\" class=\"file-name-input js-file-name-input\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" name=\"filename\">\n        <button class=\"button-primary button-small js-change-file-name-trigger change-file-name-trigger\"><i class=\"gi gi-check\"></i></button>\n      </div>\n    </div>\n\n    <ul class=\"file-row-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"complete") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStreamable") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":31,"column":13}}})) != null ? stack1 : "")
    + "\n      <li class=\"meta-holder\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dropboxFileExternalId") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "\n        "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"fileInfo") : depth0), depth0))
    + " "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"extension") : depth0), depth0))
    + "\n      </li>\n    </ul>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStreamable") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":2},"end":{"line":53,"column":9}}})) != null ? stack1 : "")
    + "</li>\n";
},"useData":true});