"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(Megaphone);

  function Megaphone() {
    this.dismissMegaphoneTasks = function(ev, data) {
      $.ajax({
        type: "POST",
        dataType: "JSON",
        url: Routes.megaphone_dismiss_path(),
        data: { megaphone_tasks: data.megaphoneTasks }
      }).done(
        function(response) {
          if (response.success) {
            this.trigger("uiNeedsToGetMegaphoneTasks");
          } else {
            if (data.userDismissed) {
              this.trigger("uiShowFlashMessage", {
                message: I18n.t("js.something_went_wrong")
              });
            }
          }
        }.bind(this)
      );
    };

    this.getMegaphoneTasks = function() {
      $.ajax({
        type: "GET",
        dataType: "JSON",
        url: Routes.megaphone_tasks_path()
      }).done(
        function(response) {
          // if not successful, do nothing, we'll load new tasks on next page load
          if (response.success) {
            this.trigger("dataMegaphoneTasks", response);
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on("uiNeedsToGetMegaphoneTasks", this.getMegaphoneTasks);
      this.on("uiNeedsToDismissMegaphoneTasks", this.dismissMegaphoneTasks);
    });
  }
});
