"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(ThirdPartyAnalytics);

  function ThirdPartyAnalytics() {
    this.defaultAttrs({
      metaAnalyticsURLSelector: 'meta[property="gr:third_party_analytics_url"]'
    });

    this.addIFrameWithAnalytics = function(ev, data) {
      if (!Modernizr.sandbox) {
        return;
      }

      var iFrame = document.createElement("IFRAME");
      iFrame.setAttribute("hidden", true);
      iFrame.setAttribute("sandbox", "allow-scripts allow-same-origin");
      iFrame.setAttribute("id", "third-party-analytics-iframe");
      iFrame.setAttribute(
        "src",
        $(this.attr.metaAnalyticsURLSelector).attr("value") +
          Routes.third_party_analytics_path({
            link_id: data.permalink,
            purchase_id: data.purchase_id
          })
      );
      document.body.appendChild(iFrame);
    };

    this.after("initialize", function() {
      this.on(
        this.$node,
        "uiToAddThirdPartyAnalytics",
        this.addIFrameWithAnalytics
      );
    });
  }
});
