"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(CustomerSurcharge);

  function CustomerSurcharge() {
    // Gets additional pricing that will be applied to the purchase.
    // This includes US sales tax, VAT in the EU, and shipping rates.
    this.getCustomerSurcharge = function(ev, data) {
      $.ajax({
        url: Routes.customer_surcharge_path(
          data.externalId,
          data.sellerId,
          data.isTaxExclusive | 0,
          Math.round(data.priceCents),
          data.quantity
        ),
        data: {
          postal_code: data.postalCode,
          country: data.country,
          vat_id: data.vatId,
          physical: data.isPhysical
        },
        type: "GET"
      }).done(
        function(response) {
          if (data.isPartOfBundle) {
            response["permalink"] = data.permalink;
            this.trigger("dataCustomerSurchargeForBundle", response);
          } else {
            this.trigger(data.productNode, "dataCustomerSurcharge", response);
          }
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        document,
        "uiNeedsProductCustomerSurcharge",
        this.getCustomerSurcharge
      );
    });
  }
});
