"use strict";

define([
  "paypal",
  "$app/utils/mobile",
  "$app/utils/gumroad_utils",
  "$app/utils/environment"
], function(PayPal, Mobile, GumroadUtils, Environment) {
  return WithPayPalNative;

  function WithPayPalNative() {
    this.defaultAttrs({
      /* These selectors refer to meta elements (that belong in the HEAD) which are outside this components node.
       * Do not use flightjs' this.select to access their nodes, instead use jQuery directly. */
      metaPayPalClientIDSelector: 'meta[property="paypal:client_id"]',
      metaNativePayPalPaymentDisabledSelector:
        'meta[property="gr:paypal:native_paypal_payment_disabled"]',
      nativePayPalCompatible: true
    });

    this.setupPayPalNativeImplementation = function() {
      // PaypalPhaseOne - Disabling native paypal button for purchase.
      return;
      this.select("grNativePaypalButtonSelector").each(
        $.proxy(function(index, element) {
          PayPal.Button.render(this.paypalSetupConfiguration(), element.id);
        }, this)
      );
    };

    this.paypalSetupConfiguration = function() {
      // PaypalPhaseOne - Disabling native paypal button for purchase.
      return;
      return {
        env: Environment.isProduction() ? "production" : "sandbox",

        style: {
          size: "responsive",
          color: "silver",
          shape: "pill",
          label: "generic",
          tagline: false
        },

        payment: function() {
          return new PayPal.Promise(
            function(resolve, _reject) {
              $.ajax({
                type: "POST",
                url: Routes.order_paypal_path(),
                dataType: "json",
                data: {
                  // Skip out any hash params
                  window_location:
                    window.location.origin +
                    window.location.pathname +
                    window.location.search,
                  products: this.attr.productsInBundle || [
                    this.attr.productInfo
                  ],
                  meta_data: this.attr
                }
              }).always(
                function(data) {
                  if (data.order_id) {
                    resolve(data.order_id);
                  } else {
                    var failureOptions = {
                      error_message: I18n.t(
                        "js.something_went_wrong_not_charged_notice"
                      )
                    };

                    if (!this.attr.productsInBundle) {
                      failureOptions.permalink = this.attr.permalink;
                    }

                    this.trigger("dataPurchaseFail", failureOptions);
                    resolve(null);
                  }
                }.bind(this)
              );
            }.bind(this)
          );
        }.bind(this),

        onAuthorize: function(data, _actions) {
          this.trigger("uiShouldDisablePayButton");
          this.attr.paypalOrderDetails = data;
          this.attr.paypalPayerId = data.payerID;
          this.attr.implementationInUse = this.attr.PayPalImplementations.NATIVE;
          this.trigger("uiNeedsOrderInformation", {
            order_id: data.paymentToken,
            successCallbackEvent: "setupPaypalPaymentForm",
            errorCallbackEvent: "setupPaypalPaymentForm"
          });
        }.bind(this)
      };
    };

    this.setupPaypalForm = function(ev, data) {
      var paypalBuyerEmail = "";
      if (data.payer_info && data.payer_info.email) {
        paypalBuyerEmail = data.payer_info.email;
      }

      this.trigger("uiHasAuthorizedPayPalBuyer", {
        paypalBuyerEmail: paypalBuyerEmail
      });
      this.trigger("uiShouldEnablePayButton");
    };

    this.prepareNativePayPalPayment = function(callback) {
      if (this.attr.paypalPayerId) {
        if (!this.attr.paypalOrderDetails) {
          this.trigger("uiNeedsToShowCreditCardError", {
            error_message: I18n.t("js.something_went_wrong_not_charged_notice")
          });
          this.trigger(this.$node, "uiShouldResetPayButton");
          this.trigger("uiHideReceiptWithoutAnimation");
          this.resetPayPalPayment();
          return;
        }

        if (callback) {
          callback({ cardParams: this.attr.paypalOrderDetails });
        }
      }
    };

    this.resetNativePayPalPayment = function() {
      this.attr.implementationInUse = null;
      this.attr.paypalPayerId = null;
      this.attr.authorizedAmountCents = null;
    };

    this.isNativePayPalPaymentEnabled = function() {
      var displayCompatible =
        !Mobile.isOnMobile() &&
        !Mobile.isOnTablet() &&
        !GumroadUtils.isExternalPresentation();

      return (
        !this.attr.nativePayPalPaymentDisabled &&
        displayCompatible &&
        this.attr.nativePayPalCompatible
      );
    };

    this.after("initialize", function() {
      // PaypalPhaseOne - Disabling native paypal button for purchase.
      this.attr.nativePayPalPaymentDisabled = true;
      // this.attr.nativePayPalPaymentDisabled = $(this.attr.metaNativePayPalPaymentDisabledSelector).attr('value') === 'true';
      this.on(document, "setupPaypalPaymentForm", this.setupPaypalForm);
    });
  }
});
