"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(BraintreeClientTokenData);

  function BraintreeClientTokenData() {
    this.fetchBraintreeClientToken = function(ev, data) {
      if (
        (!data.isOverlay || data.isMultiBuyEnabled) &&
        window.grBraintreeClientSetup
      ) {
        return;
      }

      window.grBraintreeClientSetup = true;

      $.ajax({
        type: "GET",
        url: Routes.braintree_client_token_path(),
        dataType: "json"
      }).done(
        function(response) {
          this.trigger("dataBraintreeClientToken", response);
        }.bind(this)
      );
    };

    this.after("initialize", function() {
      this.on(
        "uiNeedsBraintreeClientTokenFetched",
        this.fetchBraintreeClientToken
      );
    });
  }
});
