"use strict";

define([
  "flight/lib/component",
  "$app/ui/shared/with_follow",
  "$app/ui/shared/with_form_base"
], function(defineComponent, WithFollow, WithFormBase) {
  return defineComponent(Seller, WithFollow, WithFormBase);

  function Seller() {
    this.showFollowForm = function(ev, data) {
      if (!data.card || data.card.credit !== "test") {
        if (data.email) {
          this.select("followUserButtonSelector")
            .data("email", data.email)
            .show();
        } else {
          this.select("followUserFormSelector").show();
          this.select("followUserOneClickButtonSelector").hide();
        }

        this.select("followUserContainerSelector").show();
      }
    };

    this.showFollowState = function(ev, data) {
      if (data.is_following) {
        this.showFollowing();
      }
    };

    this.after("initialize", function() {
      this.on(document, "uishowFollowForm", this.showFollowForm);
      this.on(document, "dataCurrentUserInfo", this.showFollowForm);
      this.on(document, "dataCurrentUserInfo", this.showFollowState);
    });
  }
});
