"use strict";

define(["flight/lib/component"], function(defineComponent) {
  return defineComponent(TippableElements);

  function TippableElements() {
    this.defaultAttrs({
      tipsyElementsSelector: ".tippable",
      tipsysSelector: ".tipsy"
    });

    this.tipsyElements = function() {
      this.select("tipsyElementsSelector").each(function() {
        var $element = $(this);

        $element.tipsy({
          trigger: $element.data("tipsy-trigger") || "hover",
          gravity: $element.data("tipsy-gravity") || "w",
          html: "true"
        });
      });
    };

    this.hideTipsyElements = function() {
      this.select("tipsysSelector").hide();
    };

    this.after("initialize", function() {
      this.tipsyElements();

      this.on(document, "uiShouldTipsyElements", this.tipsyElements);
      this.on(document, "uiShouldHideTipsys", this.hideTipsyElements);
    });
  }
});
