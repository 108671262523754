var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <a href=\"#\" class=\"js-buyer-menu-trigger buyer-circle-link\">\n    <div class=\"profile-picture-small\">\n      <div class=\"profile-picture js-buyer-circle\"></div>\n    </div>\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n      <a href=\"/settings\"><i class=\"gi gi-gear\"></i> "
    + alias2(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias1,"settings_prompt",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":76}}}))
    + "</a>\n    </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_library_link") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\n    <li>\n      <a href=\"/logout\" class=\"js-logout-link\"><i class=\"gi gi-logout\"></i> "
    + alias2(__default(require("/app/app/javascript/templates/helpers/t.js")).call(alias1,"logout_prompt",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":76},"end":{"line":22,"column":97}}}))
    + "</a>\n    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "      <li>\n        <a href=\"/library\"><i class=\"gi gi-library\"></i> "
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"library_prompt",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":57},"end":{"line":17,"column":79}}}))
    + "</a>\n      </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <li>\n      <a href=\"#\" class=\"js-login-trigger\">"
    + container.escapeExpression(__default(require("/app/app/javascript/templates/helpers/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":43},"end":{"line":26,"column":56}}}))
    + "</a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_on_custom_domain") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\n<ul class=\"account nav core js-buyer-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_logged_in") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":28,"column":9}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});