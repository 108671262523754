(function() {
  "use strict";

  define([], function() {
    return WithSha;

    function WithSha() {
      this.convertHex = function(value) {
        var string = "";
        for (var i = 7; i >= 0; i--) {
          var v = (value >>> (i * 4)) & 0x0f;
          string += v.toString(16);
        }
        return string;
      };

      this.rotateLeft = function(n, string) {
        return (n << string) | (n >>> (32 - string));
      };

      this.stringToSha = function(string) {
        string = unescape(encodeURIComponent(string));
        var W = new Array(80),
          H0 = 0x67452301,
          H1 = 0xefcdab89,
          H2 = 0x98badcfe,
          H3 = 0x10325476,
          H4 = 0xc3d2e1f0,
          stringLength = string.length,
          wordArray = [],
          i,
          j,
          A,
          B,
          C,
          D,
          E,
          temp;

        for (i = 0; i < stringLength - 3; i += 4) {
          j =
            (string.charCodeAt(i) << 24) |
            (string.charCodeAt(i + 1) << 16) |
            (string.charCodeAt(i + 2) << 8) |
            string.charCodeAt(i + 3);
          wordArray.push(j);
        }

        switch (stringLength % 4) {
          case 0:
            wordArray.push(0x080000000);
            break;
          case 1:
            wordArray.push(
              (string.charCodeAt(stringLength - 1) << 24) | 0x0800000
            );
            break;
          case 2:
            wordArray.push(
              (string.charCodeAt(stringLength - 2) << 24) |
                (string.charCodeAt(stringLength - 1) << 16) |
                0x08000
            );
            break;
          case 3:
            wordArray.push(
              (string.charCodeAt(stringLength - 3) << 24) |
                (string.charCodeAt(stringLength - 2) << 16) |
                (string.charCodeAt(stringLength - 1) << 8) |
                0x80
            );
        }

        while (wordArray.length % 16 != 14) {
          wordArray.push(0);
        }

        wordArray.push(stringLength >>> 29);
        wordArray.push((stringLength << 3) & 0x0ffffffff);

        for (i = 0; i < wordArray.length; i += 16) {
          for (j = 0; j < 16; j++) {
            W[j] = wordArray[i + j];
          }
          for (j = 16; j <= 79; j++) {
            W[j] = this.rotateLeft(
              W[j - 3] ^ W[j - 8] ^ W[j - 14] ^ W[j - 16],
              1
            );
          }

          A = H0;
          B = H1;
          C = H2;
          D = H3;
          E = H4;

          for (j = 0; j <= 19; j++) {
            temp =
              (this.rotateLeft(A, 5) +
                ((B & C) | (~B & D)) +
                E +
                W[j] +
                0x5a827999) &
              0x0ffffffff;
            E = D;
            D = C;
            C = this.rotateLeft(B, 30);
            B = A;
            A = temp;
          }

          for (j = 20; j <= 39; j++) {
            temp =
              (this.rotateLeft(A, 5) + (B ^ C ^ D) + E + W[j] + 0x6ed9eba1) &
              0x0ffffffff;
            E = D;
            D = C;
            C = this.rotateLeft(B, 30);
            B = A;
            A = temp;
          }

          for (j = 40; j <= 59; j++) {
            temp =
              (this.rotateLeft(A, 5) +
                ((B & C) | (B & D) | (C & D)) +
                E +
                W[j] +
                0x8f1bbcdc) &
              0x0ffffffff;
            E = D;
            D = C;
            C = this.rotateLeft(B, 30);
            B = A;
            A = temp;
          }

          for (j = 60; j <= 79; j++) {
            temp =
              (this.rotateLeft(A, 5) + (B ^ C ^ D) + E + W[j] + 0xca62c1d6) &
              0x0ffffffff;
            E = D;
            D = C;
            C = this.rotateLeft(B, 30);
            B = A;
            A = temp;
          }

          H0 = (H0 + A) & 0x0ffffffff;
          H1 = (H1 + B) & 0x0ffffffff;
          H2 = (H2 + C) & 0x0ffffffff;
          H3 = (H3 + D) & 0x0ffffffff;
          H4 = (H4 + E) & 0x0ffffffff;
        }

        return (
          this.convertHex(H0) +
          this.convertHex(H1) +
          this.convertHex(H2) +
          this.convertHex(H3) +
          this.convertHex(H4)
        ).toLowerCase();
      };
    }
  });
})();
