"use strict";

define([
  "flight/lib/component",
  "$app/utils/auth_modal_setup",
  "$app/templates/buyer_bar",
  "$app/templates/helpers/t"
], function(defineComponent, AuthModalSetup, BuyerBarTemplate) {
  return defineComponent(Login);

  function Login() {
    this.defaultAttrs({
      buyerMenuTriggerSelector: ".js-buyer-menu-trigger",
      buyerCircleSelector: ".js-buyer-circle",
      buyerMenuSelector: ".js-buyer-menu",
      buyerBarContentSelector: ".js-buyer-bar-content",
      userInfoSet: false
    });

    this.toggleBuyerMenu = function(ev) {
      ev.preventDefault();

      this.select("buyerMenuSelector").toggleClass("showing");

      if (this.select("buyerMenuSelector").hasClass("showing")) {
        this.trigger("uiShouldHideBundle");
      }
    };

    this.showBuyerMenu = function(ev) {
      this.select("buyerMenuSelector").addClass("showing");
      this.trigger("uiNeedsToShowBundle");
    };

    this.hideBuyerMenu = function(ev) {
      this.select("buyerMenuSelector").removeClass("showing");
    };

    this.setCurrentUserInfo = function(ev, data) {
      if (this.userInfoSet) {
        return;
      }
      this.userInfoSet = true;
      this.select("buyerBarContentSelector").html(BuyerBarTemplate(data));
      if (data.profile_picture_url) {
        this.select("buyerCircleSelector")
          .attr(
            "style",
            "background-image: url(" + data.profile_picture_url + ");"
          )
          .addClass("showing-profile-picture");
      } else {
        this.select("buyerCircleSelector").addClass(
          "empty showing-profile-picture"
        );
      }
      AuthModalSetup();
      this.$node.fadeIn();

      this.trigger("uiFadeInSharing");
    };

    this.hideLoginSection = function() {
      this.$node.fadeOut();
    };

    this.showLoginSection = function() {
      this.$node.fadeIn();
    };

    this.after("initialize", function() {
      this.on(document, "dataCurrentUserInfo", this.setCurrentUserInfo);
      this.on(document, "uiNeedsToShowBuyerMenu", this.showBuyerMenu);
      this.on(document, "uiNeedsToHideBuyerMenu", this.hideBuyerMenu);
      this.on(document, "uiShouldShowEditing", this.hideLoginSection);
      this.on(document, "uiShouldHideEditing", this.showLoginSection);

      var user = this.$node.data("user");

      if (user != undefined) {
        this.trigger("dataCurrentUserInfo", user);
        if (user.is_owner && this.attr.username) {
          this.trigger("uiNeedsToGetCustomizationModules", {
            username: this.attr.username
          });
        }
      }

      this.on("click", {
        buyerMenuTriggerSelector: this.toggleBuyerMenu
      });
    });
  }
});
