var Handlebars = require("/app/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li>\n    <a href="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + " class=\"search-result-link\">\n      <div class=\"search-result-icon\">\n        <i class=\"gi gi-custom gi-box-custom\"></i>\n      </div>\n      <div class=\"search-result\">\n        <div class=\"search-result-name\"> "
    + ((stack1 = __default(require("/app/app/javascript/templates/helpers/highlight_matches.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"query") : depths[1]),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"highlight_matches","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":78}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"search-result-information\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"information") : depth0), depth0))
    + "</div>\n      </div>\n    </a>\n  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"results") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + "<li>\n  <a class=\"button button-primary search-button js-search-button\" href=\"#\">\n    <p>Search</p>\n  </a>\n</li>\n";
},"useData":true,"useDepths":true});