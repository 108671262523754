"use strict";

/**
 * A collection of d3 helpers for transformation.
 */

define([], function() {
  function D3() {
    this.getTransformTranslation = function(x, y) {
      return "translate(" + x + "," + y + ")";
    };
  }

  return D3;
});
